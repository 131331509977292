import { DatesProvider } from '@mantine/dates';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { LoginResponse } from '../hooks/useHfwLogin';
import OauthServiceMS from '../services/OauthServiceMS';
import ProfileService from '../services/ProfileService';
import type { User } from '../types/types';
import {
  getPersistedObject,
  persistUser,
  removePersistedObject,
} from '../utils/localStorage';
import { APP_NAME } from '../variables/GlobalVariables';

type AuthContextValue = {
  user: User;
  updateUser: (user: User, access_token: string, refresh_token: string) => void;
  logout: () => void;
  access_token: string;
  refresh_token: string;
  loginResponse: any;
  appInitialized: boolean;
  isWhiteLabelGma: boolean;
};

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue
);
const useAuthContext = () => useContext(AuthContext);
export const useAuth = useAuthContext;

type OauthDecoded = {
  exp: number;
  firstname: string;
  iss: string;
  lastname: string;
  username: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>(getPersistedObject('user'));
  const [accessToken, setAccessToken] = useState<any>(
    getPersistedObject('access_token')
  );
  const [refreshToken, setRefreshToken] = useState<any>(
    getPersistedObject('refresh_token')
  );
  const [loginResponse, setLoginResponse] = useState<LoginResponse | undefined>(
    undefined
  );
  const userLang = useMemo(() => {
    if (user?.lang) return user.lang;

    return 'fr';
  }, [user]);

  const isWhiteLabelGma: boolean = useMemo(() => {
    return (
      APP_NAME.toLowerCase().includes('gma') ||
      APP_NAME.toLowerCase().includes('gerermesaffaires')
    );
  }, [APP_NAME]);

  useEffect(() => {
    dayjs.locale(userLang);
  }, [userLang]);

  useEffect(() => {
    if (user && accessToken) {
      const oauthDecoded: OauthDecoded = jwtDecode(accessToken);
      if (oauthDecoded) {
        // @ts-ignore
        window.Visence?.init({
          apiKey: '7BSiK1tb1YwzWJW0/4w0kEoR31BxFajmfS+Jg7IsrsM=',
          endUser: {
            idExt: `${oauthDecoded.username}-${user.id}`, // required, string, must be unique
            lastName: oauthDecoded.lastname, // required, string
            firstName: oauthDecoded.firstname, // optional, string
            email: oauthDecoded.username, // optional, string
            phone: user.phone, // optional, string
            registerDate: new Date(user.created), // optional, date
            custom: {
              from: document.location.origin,
              userId: user.id,
              userEmail: user.mail,
              userLastName: user.lastname,
              userFirstName: user.firstname,
              oauthUsername: oauthDecoded.username,
              oauthLastName: oauthDecoded.lastname,
              oauthFirstName: oauthDecoded.firstname,
            },
          },
          client: {
            // optional
            idExt: user.company.id, // required if client defined, string, must be unique
            name: user.company.name, // required if client defined, string
          },
        });
      } else {
        console.warn(`Cannot decoded access_token ${accessToken}`);
      }
    }
  }, [user, accessToken]);

  useQuery({
    enabled: !!accessToken,
    queryKey: ['ProfileService.getUserHfwProfiles', accessToken],
    queryFn: () => ProfileService.getUserHfwProfiles(accessToken),
    onSuccess: (data) => {
      setLoginResponse({
        access_token: accessToken,
        refresh_token: refreshToken,
        users: data,
      });
      if (data?.length && user?.id) {
        const _user = data.find((item) => item.id === user.id);
        if (_user) {
          updateUser(_user, accessToken, refreshToken);
        }
      }
    },
  });
  const { refetch: refetchLogout } = useQuery({
    enabled: false,
    queryKey: ['OauthServiceMS.logout'],
    queryFn: OauthServiceMS.logout,
  });

  function updateUser(
    _user: User,
    access_token: string,
    refresh_token: string
  ) {
    setUser(_user);
    setAccessToken(access_token);
    setRefreshToken(refresh_token);
    persistUser(_user, access_token, refresh_token);
  }

  function logout() {
    clearStorageAndRedirect();
    removePersistedObject('gma');
  }

  function clearStorageAndRedirect() {
    refetchLogout();
    removePersistedObject('user');
    removePersistedObject('access_token');
    removePersistedObject('refresh_token');
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        logout,
        access_token: accessToken,
        refresh_token: refreshToken,
        loginResponse,
        appInitialized: Boolean(user && accessToken && refreshToken),
        isWhiteLabelGma,
      }}
    >
      <DatesProvider settings={{ locale: userLang }}>{children}</DatesProvider>
    </AuthContext.Provider>
  );
}
