import {
  Center,
  Grid,
  Image,
  Stepper,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import {
  IconCalendarTime,
  IconCheck,
  IconFile,
  IconUser,
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import s from '../features/company/employees/components/AddEmployeeInformationForm/AddEmployeeInformationForm.module.sass';
import ProfileService from '../services/ProfileService';
import { UserPersonalInfo } from '../types/types';
import { hasPermission } from '../utils/authorization';
import { ALL, UserPersonalInfoEdit } from '../variables/BuiltInPermissions';
import BankForm from './BankForm';
import CustomDropzone from './CustomDropzone/CustomDropzone';
import FileViewer from './FileViewer/FileViewer';
import LabelText from './LabelText/LabelText';

type Props = {
  form: any;
  request: boolean;
  edit: boolean;
  userPersonalInfo?: UserPersonalInfo | null;
};

export default function SensitiveInfoStepper({
  form,
  request,
  edit,
  userPersonalInfo,
}: Props) {
  const { t } = useI18n();
  const checkMediaQuery = useMediaQuery('(min-width: 1025px)');
  const [activeStepper, setActiveStepper] = useState(0);
  const { height } = useViewportSize();
  const { access_token, user } = useAuth();
  const [file, setFile] = useState({
    opened: false,
    path: '',
    type: '',
    loading: false,
  });

  useEffect(() => {
    if (activeStepper === 4) {
      openImage();
    }
  }, [activeStepper]);

  function openImage() {
    setFile({ ...file, loading: true });
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], {
          type: 'application/pdf',
        });
        const filePath = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = filePath;
        link.download = `exportpdf`;
        link.target = '_blank';
        setFile({
          path: filePath,
          type: newBlob.type,
          opened: true,
          loading: false,
        });
      }
    };
    if (activeStepper === 1) {
      xhr.open('GET', ProfileService.getSecuritySocial(form.userId), true);
    } else if (activeStepper === 2) {
      xhr.open('GET', ProfileService.getResidencePermit(form.userId), true);
    } else {
      xhr.open('GET', ProfileService.getIdentityCard(form.userId), true);
    }

    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  function renderComponentRequest() {
    switch (activeStepper) {
      case 1:
        return (
          <Grid gutter={'xl'}>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput
                label={<LabelText text={t('w.socialSecurityNumber')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('sSNumber')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Title order={3}>{t('w.vitaleCard')}</Title>
              <Text fz={'sm'}>
                Sélectionner un fichier ou déplacer ou glisser déposer plusieurs
                fichiers ici.
              </Text>
              <CustomDropzone
                onDrop={(files) => form.setFieldValue('sSFiles', files)}
                files={form.values.sSFiles}
                mimeType={'pdf'}
                type={'simple'}
                resetFiles={(index) => form.removeListItem('sSFiles', index)}
              />
              {form.values?.sSFiles.length > 0 ? (
                <Center>
                  {form.values.sSFiles[0].type === 'application/pdf' ? (
                    <FileViewer
                      id={`document-${form.values.id}`}
                      srcUrl={URL.createObjectURL(form.values.sSFiles[0])}
                      title={'contract.pdf'}
                    />
                  ) : (
                    <Center>
                      <Image
                        w={'40%'}
                        h={'40%'}
                        src={URL.createObjectURL(form.values.sSFiles[0])}
                      />
                    </Center>
                  )}
                </Center>
              ) : (
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {t('w.noFile')}
                </Text>
              )}
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Switch
                label={<LabelText text={t('w.handicappedWorker')} />}
                labelPosition={'left'}
                checked={form.values.handicappedWorker}
                {...form.getInputProps('handicappedWorker')}
              />
            </Grid.Col>
          </Grid>
        );
      case 2:
        return !edit ? <BankForm form={form} /> : null;
      case 3:
        return (
          <Grid gutter={'xl'}>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput
                label={<LabelText text={t('w.residenceNumber')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('residencePermitNumber')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Title order={3}>{t('w.residencePermitFile')}</Title>
              <Text fz={'sm'}>
                Sélectionner un fichier ou déplacer ou glisser déposer plusieurs
                fichiers ici.
              </Text>
              <CustomDropzone
                onDrop={(files) =>
                  form.setFieldValue('residencePermitFiles', files)
                }
                files={form.values.residencePermitFiles}
                mimeType={'pdf'}
                type={'simple'}
                resetFiles={(index) =>
                  form.removeListItem('residencePermitFiles', index)
                }
              />
              {form.values?.residencePermitFiles.length > 0 ? (
                <Center>
                  {form.values.residencePermitFiles[0].type ===
                  'application/pdf' ? (
                    <FileViewer
                      id={`document-${form.values.id}`}
                      srcUrl={URL.createObjectURL(
                        form.values.residencePermitFiles[0]
                      )}
                      title={'contract.pdf'}
                    />
                  ) : (
                    <Center>
                      <Image
                        w={'40%'}
                        h={'40%'}
                        src={URL.createObjectURL(
                          form.values.residencePermitFiles[0]
                        )}
                      />
                    </Center>
                  )}
                </Center>
              ) : (
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {t('w.noFile')}
                </Text>
              )}
            </Grid.Col>
          </Grid>
        );

      default:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.nationalIdentityCardNumber')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('identityCardNumber')}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Title order={3}>{t('w.identityDocument')}</Title>
                <Text fz={'sm'}>
                  Sélectionner un fichier ou déplacer ou glisser déposer
                  plusieurs fichiers ici.
                </Text>
                <CustomDropzone
                  onDrop={(files) =>
                    form.setFieldValue('identityCardFiles', files)
                  }
                  files={form.values.identityCardFiles}
                  mimeType={'pdf'}
                  type={'simple'}
                  resetFiles={(index) =>
                    form.removeListItem('identityCardFiles', index)
                  }
                />
                {form.values?.identityCardFiles.length > 0 ? (
                  <Center>
                    {form.values.identityCardFiles[0].type ===
                    'application/pdf' ? (
                      <FileViewer
                        id={`document-${form.values.id}`}
                        srcUrl={URL.createObjectURL(
                          form.values.identityCardFiles[0]
                        )}
                        title={'contract.pdf'}
                      />
                    ) : (
                      <Center>
                        <Image
                          w={'40%'}
                          h={'40%'}
                          src={URL.createObjectURL(
                            form.values.identityCardFiles[0]
                          )}
                        />
                      </Center>
                    )}
                  </Center>
                ) : (
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {t('w.noFile')}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
          </>
        );
    }
  }

  return (
    <Grid
      gutter="lg"
      styles={{
        root: {
          position: 'sticky',
          top: 120,
        },
      }}
    >
      <Grid.Col span={2}>
        <Stepper
          className={checkMediaQuery ? s.stepper : undefined}
          active={activeStepper}
          orientation={'vertical'}
          onStepClick={setActiveStepper}
        >
          <Stepper.Step
            icon={<IconUser size="1.1rem" />}
            label={checkMediaQuery ? 'Pièce' : ''}
            description={checkMediaQuery ? "D'identité" : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconUser size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconCalendarTime size="1.1rem" />}
            label={checkMediaQuery ? 'Sécurité' : ''}
            description={checkMediaQuery ? 'Sociale' : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconCalendarTime size="1.1rem" />
              )
            }
          />
          {!edit && (
            <Stepper.Step
              icon={<IconFile size="1.1rem" />}
              label={checkMediaQuery ? 'Informations' : ''}
              description={checkMediaQuery ? 'Bancaires' : ''}
              completedIcon={
                request ? (
                  <IconCheck size={'1.1rem'} />
                ) : (
                  <IconFile size="1.1rem" />
                )
              }
            />
          )}
          {userPersonalInfo &&
            userPersonalInfo.nationality !== 'FR' &&
            hasPermission(
              [{ permission: UserPersonalInfoEdit, scope: ALL }],
              user
            ) && (
              <Stepper.Step
                icon={<IconFile size="1.1rem" />}
                label={checkMediaQuery ? 'Travailleur' : ''}
                description={checkMediaQuery ? 'Étranger' : ''}
                completedIcon={
                  request ? (
                    <IconCheck size={'1.1rem'} />
                  ) : (
                    <IconFile size="1.1rem" />
                  )
                }
              />
            )}
        </Stepper>
      </Grid.Col>
      <Grid.Col
        styles={{
          col: {
            overflowY: 'scroll',
            height: !edit ? (height * 3) / 5 : 'auto',
          },
        }}
        span={'auto'}
        pl={'xl'}
        pr={'100px'}
      >
        {renderComponentRequest()}
      </Grid.Col>
    </Grid>
  );
}
