import { ActionIcon, Badge, Group, Text, Title } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import { IconEye, IconRefresh } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import CustomTooltip from '../../../../../components/CustomTooltip';
import { useI18n } from '../../../../../contexts/I18nProvider';
import type {
  PayslipsMonthTreatment,
  PayslipsMonthTreatmentState,
} from '../../../../../types/types';
import PayslipsTreatmentAccordion from './PayslipsTreatmentAccordion';
import classes from './PayslipsTreatmentsBadge.module.sass';

type Props = {
  pendingTreatment: PayslipsMonthTreatment[];
  successTreatment: PayslipsMonthTreatment[];
  errorTreatment: PayslipsMonthTreatment[];
  companyId: string;
  setSelectedDate: React.Dispatch<
    React.SetStateAction<DateValue | DatesRangeValue | Date[]>
  >;
};

export default function PayslipsTreatmentsBadge({
  pendingTreatment,
  successTreatment,
  errorTreatment,
  companyId,
  setSelectedDate,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();

  function displayIconEye(state: PayslipsMonthTreatmentState) {
    return (
      <CustomTooltip label={t('w.see')} position={'bottom'}>
        <ActionIcon
          size={'sm'}
          variant={'subtle'}
          onClick={() => handleOpenTreatmentModal(state)}
        >
          <IconEye color={'white'} />
        </ActionIcon>
      </CustomTooltip>
    );
  }

  function displayIconRefresh() {
    return (
      <CustomTooltip label={t('w.relaunch')} position={'bottom'}>
        <ActionIcon
          size={'sm'}
          variant={'subtle'}
          onClick={handleRelanchPayslipsTreatment}
        >
          <IconRefresh color={'white'} />
        </ActionIcon>
      </CustomTooltip>
    );
  }

  function displayTreatmentTitleModal(state: PayslipsMonthTreatmentState) {
    switch (state) {
      case 'SUCCESS':
        return t('w.success');
      case 'ERROR':
        return t('w.error');
      default:
        return t('w.inProgress');
    }
  }

  function handleOpenTreatmentModal(state: PayslipsMonthTreatmentState) {
    modals.open({
      modalId: `${state.toLowerCase()}-treatments-payslips-modal`,
      title: (
        <Title size={'h3'} component="p">
          {displayTreatmentTitleModal(state)}
        </Title>
      ),
      children: (
        <PayslipsTreatmentAccordion
          state={state}
          pendingTreatment={pendingTreatment}
          successTreatment={successTreatment}
          errorTreatment={errorTreatment}
          setSelectedDate={setSelectedDate}
        />
      ),
      size: 'lg',
    });
  }

  function handleRelanchPayslipsTreatment() {
    queryClient.invalidateQueries({
      queryKey: ['PayslipService.getPayslipsTreatmentForCompanyId', companyId],
    });
  }

  return (
    <Group justify={'flex-end'}>
      <Text fw={'bold'}>{t('w.treatments')} :</Text>
      <Badge
        leftSection={
          successTreatment.length > 0 ? displayIconEye('SUCCESS') : undefined
        }
        rightSection={displayIconRefresh()}
        className={classes.label}
        size="lg"
        color="teal"
      >
        {t('w.success')} {successTreatment.length}
      </Badge>
      <Badge
        leftSection={
          pendingTreatment.length > 0 ? displayIconEye('PROCESSING') : undefined
        }
        rightSection={displayIconRefresh()}
        className={classes.label}
        size="lg"
      >
        {t('w.inProgress')} {pendingTreatment.length}
      </Badge>
      <Badge
        leftSection={
          errorTreatment.length > 0 ? displayIconEye('ERROR') : undefined
        }
        rightSection={displayIconRefresh()}
        className={classes.label}
        size="lg"
        color="red"
      >
        {t('w.error')} {errorTreatment.length}
      </Badge>
    </Group>
  );
}
