import { PermissionScoped, PermissionScopedBack } from '../types/types';
import { CODERKAINE } from '../variables/BuiltInRoles';

export function isAccountant(user: any, companyId: string): boolean {
  return !!user.myCompanies?.includes(companyId);
}

export function hasRole(roleName: string | string[], user?: any): boolean {
  if (!user?.role) {
    // no user or no role
    return false;
  }

  if (user.role.name === CODERKAINE) {
    return true;
  }

  if (!user.company?.id) {
    // not coderkaine and no company
    return false;
  }

  if (typeof roleName === 'string') {
    // have company and have role, return equality test
    return user.role.name === roleName;
  }

  if (Array.isArray(roleName)) {
    return roleName.includes(user.role.name);
  }

  return false;
}

export function hasPermission(
  permission: PermissionScoped | PermissionScoped[],
  user?: any
): boolean {
  if (!user?.role?.permissions) {
    return false;
  }

  if (Array.isArray(permission)) {
    let _hasPermission = false;
    for (const _permission of permission) {
      const toTest = user.role.permissions.find(
        (elt: PermissionScopedBack) =>
          elt.permission.key === _permission.permission &&
          elt.scope === _permission.scope
      );
      if (toTest) {
        _hasPermission = true;
        break;
      }
    }

    return _hasPermission;
  } else {
    return (
      user.role.permissions.find(
        (elt: PermissionScopedBack) =>
          elt.permission.key === permission.permission &&
          elt.scope === permission.scope
      ) != null
    );
  }
}
