import {
  ActionIcon,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Space,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowRight,
  IconCheck,
  IconCirclePlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import CustomTooltip from '../../../../../components/CustomTooltip';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import DailyWorkService, {
  WeeklyTemplateToUpdate,
} from '../../../../../services/DailyWorkService';
import {
  DailyTemplate,
  WeeklyPeriod,
  WeeklyTemplate,
} from '../../../../../types/types';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import { weeklyPeriods } from '../../../PlanningView';

type Props = {
  template: WeeklyTemplate;
  refetchWeeklyTemplatesData: () => void;
  onClose: () => void;
  isEditing?: boolean;
};

type FormValues = {
  name: string;
  weeklyPeriods: WeeklyPeriod[];
  shared?: boolean;
};

export default function WeeklyTemplateForm({
  template,
  refetchWeeklyTemplatesData,
  onClose,
  isEditing,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);

  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      weeklyPeriods: weeklyPeriods,
      shared: false,
    },
  });

  useEffect(() => {
    if (isEditing) {
      form.values.weeklyPeriods.forEach((item, index) => {
        const findTemplateDay = template.weeklyPeriods.find(
          (weeklyPeriod) => weeklyPeriod.day === item.day
        );
        if (findTemplateDay) {
          if (findTemplateDay.day === item.day) {
            form.setFieldValue(
              `weeklyPeriods.${index}.period`,
              findTemplateDay.period
            );
          }
        }
      });
    }
  }, [isEditing]);

  const {
    mutate: updateWeeklyTemplate,
    isLoading: isUpdateWeeklyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: WeeklyTemplateToUpdate) =>
      DailyWorkService.updateWeeklyTemplate(companyId, template.id, variables),
    onSuccess: (data: DailyTemplate) => {
      const { id, name } = data;
      refetchWeeklyTemplatesData();
      onClose();
      showNotification({
        id: `update-weekly-template-${id}-successful`,
        title: t('w.success'),
        message: `Modèle ${name} ${t('w.updated').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `update-weekly-template-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });
  const {
    mutate: createWeeklyTemplate,
    isLoading: isCreateWeeklyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: WeeklyTemplateToUpdate) =>
      DailyWorkService.createWeeklyTemplate(companyId, variables),
    onSuccess: (data: DailyTemplate) => {
      const { id, name } = data;
      refetchWeeklyTemplatesData();
      onClose();
      showNotification({
        id: `create-weekly-template-${id}-successful`,
        title: t('w.success'),
        message: `Modèle ${name} ${t('w.created').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `create-weekly-template-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleWeeklyTemplateSubmit(values: FormValues) {
    if (isEditing) {
      updateWeeklyTemplate({
        name: template.name,
        weeklyPeriods: values.weeklyPeriods,
      });
    } else {
      createWeeklyTemplate({
        name: values.name,
        weeklyPeriods: values.weeklyPeriods,
        shared: values.shared,
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleWeeklyTemplateSubmit(values))}
    >
      <LoadingOverlay
        visible={isUpdateWeeklyTemplateLoading || isCreateWeeklyTemplateLoading}
      />
      <Space h={'xl'} />
      <Stack gap={'xl'}>
        {!isEditing && (
          <Flex align={'flex-end'} justify={'space-between'}>
            <TextInput
              label={<LabelText text={'Nom du modèle'} />}
              required
              {...form.getInputProps('name')}
            />
            <Switch
              size="xs"
              labelPosition="left"
              label={<LabelText text={'Partager le modèle ?'} />}
              {...form.getInputProps('shared')}
            />
          </Flex>
        )}
        <Title size={'h4'} c={'dimmed'}>
          Périodes hébdomadaires
        </Title>
        <Space h={'xl'} />
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify={'space-around'}
          align={'flex-start'}
        >
          {form.values.weeklyPeriods.map((item, weeklyPeriodIndex) => (
            <Flex
              key={`${item.day}-${weeklyPeriodIndex}`}
              direction={'column'}
              gap={'md'}
              p={'xs'}
              styles={{
                root: {
                  backgroundColor: 'var(--mantine-color-hifivework-0)',
                  borderRadius: 8,
                },
              }}
            >
              <Group align={'center'} justify={'space-between'}>
                <Title size={'h5'} c={'hifivework.6'}>
                  {t(`daysEnum.${item.day}`)}
                </Title>
                <CustomTooltip label={t('w.add')}>
                  <ActionIcon
                    variant={'subtle'}
                    size={'sm'}
                    onClick={() =>
                      form.insertListItem(
                        `weeklyPeriods.${weeklyPeriodIndex}.period`,
                        {
                          startDate: '00:00',
                          endDate: '00:00',
                        }
                      )
                    }
                  >
                    <IconCirclePlus />
                  </ActionIcon>
                </CustomTooltip>
              </Group>
              {item.period.map((item, periodIndex) => (
                <Group
                  key={`group-${weeklyPeriodIndex}-${periodIndex}`}
                  gap={'xs'}
                >
                  <TimeInput
                    id={`start-${weeklyPeriodIndex}-${periodIndex}`}
                    label={
                      <Text fz={'sm'} fw={'bold'} c={'hifivework.3'}>
                        Début
                      </Text>
                    }
                    size="xs"
                    {...form.getInputProps(
                      `weeklyPeriods.${weeklyPeriodIndex}.period.${periodIndex}.startDate`
                    )}
                  />
                  <IconArrowRight
                    style={{ marginTop: 'var(--mantine-spacing-md)' }}
                    color={'var(--mantine-color-hifivework-5)'}
                    size={15}
                  />
                  <TimeInput
                    id={`end-${weeklyPeriodIndex}-${periodIndex}`}
                    label={
                      <Text fz={'sm'} fw={'bold'} c={'hifivework.3'}>
                        Fin
                      </Text>
                    }
                    size="xs"
                    {...form.getInputProps(
                      `weeklyPeriods.${weeklyPeriodIndex}.period.${periodIndex}.endDate`
                    )}
                  />
                  <ActionIcon
                    mt={'md'}
                    variant={'subtle'}
                    size={'xs'}
                    color={'red'}
                    onClick={() =>
                      form.removeListItem(
                        `weeklyPeriods.${weeklyPeriodIndex}.period`,
                        periodIndex
                      )
                    }
                  >
                    <IconTrash />
                  </ActionIcon>
                </Group>
              ))}
            </Flex>
          ))}
        </Flex>
        <Space h={'xl'} />
        <Flex justify={'flex-end'}>
          <Button type={'submit'}>
            {isEditing ? t('w.modify') : t('w.create')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}
