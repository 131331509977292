import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

export interface BlockingPeriodDate {
  companyId: string;
  name: string;
  description: string;
  periodStart: string;
  periodEnd: string;
  divisionIds: string[];
}

function getBlockingPeriodsList(companyId: string) {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/blockingPeriod/${companyId}/list`)
    .then((res) => res?.data);
}

function deleteBlockingPeriods(blockId: string, companyId: string) {
  return request
    .delete(
      `${MS_PLANNING_ENDPOINT}/v2/blockingPeriod/${companyId}/delete/${blockId}`
    )
    .then((res) => res?.data);
}

function createBlockingPeriod(companyId: string, data: BlockingPeriodDate) {
  return request
    .post(`${MS_PLANNING_ENDPOINT}/v2/blockingPeriod/${companyId}/create`, data)
    .then((res) => res?.data);
}

const BlockingPeriodsService = {
  getBlockingPeriodsList,
  deleteBlockingPeriods,
  createBlockingPeriod,
};

export default BlockingPeriodsService;
