import { Button, Group, LoadingOverlay, Space, Title } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowLeft,
  IconDeviceFloppy,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import ContractualStepper from '../../../../components/ContractualStepper';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import ProfileService from '../../../../services/ProfileService';
import type {
  ContractRegime,
  ContractType,
  IdentifiantCompany,
  User,
  UserContractualInfoType,
} from '../../../../types/types';

type Props = {
  onClose: () => void;
  addedEmployee: User | null;
  refetch: () => void;
  userContractualInfo?: UserContractualInfoType | null;
  setActive: (number: number) => void;
  active: number;
  setContractualInfo: (data: UserContractualInfoType) => void;
  refetchUserContractualInfo?: () => void;
};

interface FormValues {
  id?: string | null;
  userId?: string | null;
  professionalQualifications?: string;
  contractType?: ContractType;
  otherContractType?: string | null;
  payslipName?: string;
  regime?: ContractRegime;
  regimeLevel?: string;
  otherRegime?: string | null;
  weeklyHours?: number | string | null;
  monthlyHours?: number | string | null;
  yearlyHours?: number | string | null;
  grossPay?: number | null;
  entryDate?: Date | string | null;
  exitDate?: Date | string | null;
  employmentAuthorizationDate?: Date | string | null;
  layoffAuthorizationDate?: Date | string | null;
  comment?: string | null;
  file?: FileWithPath[];
  identifiantsCompany?: IdentifiantCompany[] | [];
  employment?: string;
}

export default function AddEmployeeWorkContractForm({
  onClose,
  addedEmployee,
  refetch,
  userContractualInfo,
  setActive,
  active,
  setContractualInfo,
  refetchUserContractualInfo,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const [file, setFile] = useState<FileWithPath[]>([]);

  const form = useForm<FormValues>({
    initialValues: {
      id:
        userContractualInfo !== null
          ? userContractualInfo?.id
            ? userContractualInfo.id
            : null
          : null,
      userId: addedEmployee?.id,
      professionalQualifications:
        userContractualInfo !== null
          ? userContractualInfo?.professionalQualifications
            ? userContractualInfo.professionalQualifications
            : ''
          : '',
      contractType:
        userContractualInfo !== null
          ? userContractualInfo?.contractType
            ? userContractualInfo.contractType
            : 'CDI'
          : 'CDI',
      otherContractType:
        userContractualInfo !== null
          ? userContractualInfo?.otherContractType
            ? userContractualInfo.otherContractType
            : null
          : null,
      payslipName:
        userContractualInfo !== null
          ? userContractualInfo?.payslipName
            ? userContractualInfo.payslipName
            : ''
          : '',
      regime:
        userContractualInfo !== null
          ? userContractualInfo?.regime
            ? userContractualInfo.regime
            : 'ETAM'
          : 'ETAM',
      regimeLevel:
        userContractualInfo !== null
          ? userContractualInfo?.regimeLevel
            ? userContractualInfo.regimeLevel
            : ''
          : '',
      otherRegime:
        userContractualInfo !== null
          ? userContractualInfo?.otherRegime
            ? userContractualInfo.otherRegime
            : null
          : null,
      weeklyHours:
        userContractualInfo !== null
          ? userContractualInfo?.weeklyHours
            ? userContractualInfo.weeklyHours
            : null
          : null,
      monthlyHours:
        userContractualInfo !== null
          ? userContractualInfo?.monthlyHours
            ? userContractualInfo.monthlyHours
            : null
          : null,
      yearlyHours:
        userContractualInfo !== null
          ? userContractualInfo?.yearlyHours
            ? userContractualInfo.yearlyHours
            : null
          : null,
      grossPay:
        userContractualInfo !== null
          ? userContractualInfo?.grossPay
            ? userContractualInfo.grossPay
            : null
          : null,
      entryDate:
        userContractualInfo !== null
          ? userContractualInfo?.entryDate
            ? dayjs(userContractualInfo.entryDate).toDate()
            : null
          : null,
      exitDate:
        userContractualInfo !== null
          ? userContractualInfo?.exitDate
            ? dayjs(userContractualInfo.exitDate).toDate()
            : null
          : null,
      employmentAuthorizationDate:
        userContractualInfo !== null
          ? userContractualInfo?.employmentAuthorizationDate
            ? dayjs(userContractualInfo.employmentAuthorizationDate).toDate()
            : null
          : null,
      layoffAuthorizationDate:
        userContractualInfo !== null
          ? userContractualInfo?.layoffAuthorizationDate
            ? dayjs(userContractualInfo.layoffAuthorizationDate).toDate()
            : null
          : null,
      comment:
        userContractualInfo !== null
          ? userContractualInfo?.comment
            ? userContractualInfo.comment
            : ''
          : '',
      file: [],
      identifiantsCompany:
        addedEmployee !== null
          ? addedEmployee?.identifiantsCompany
            ? addedEmployee.identifiantsCompany
            : []
          : [],
      employment: addedEmployee !== null ? addedEmployee.employment : '',
    },
  });

  const {
    mutate: createOrUpdateUserContractualInfo,
    isLoading: isLoadingCreateOrUpdateUserContractualInfo,
  } = useMutation({
    mutationFn: (variables: FormValues) =>
      ProfileService.createOrUpdateUserContractualInfo(user.id, variables),
    onSuccess: (data) => {
      if (file.length > 0) {
        updateContract();
      }
      showNotification({
        id: 'udpate-added-employee-work-contract-success',
        title: t('w.success'),
        message: t('success.contractualInfoUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setContractualInfo(data);
      if (refetchUserContractualInfo) {
        refetchUserContractualInfo();
      }
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserContractualInfo', addedEmployee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateEmployee, isLoading: isLoadingUpdateEmmployee } =
    useMutation({
      mutationFn: (variables: {
        identifiantsCompany: IdentifiantCompany[] | [] | undefined;
        employment: string | undefined;
      }) =>
        ProfileService.updateUserProfile(addedEmployee?.id, variables, null),
      onSuccess: () => {
        showNotification({
          id: 'udpate-added-employee-information-success',
          title: t('w.success'),
          message: t('success.informationUpdated'),
          color: 'green',
          icon: <IconCheck />,
        });
        refetch();
      },
      onError: () =>
        showNotification({
          id: 'update-employee-added-error',
          title: t('w.error'),
          message: t('error.updateUserProfile', addedEmployee?.firstname),
          color: 'red',
          icon: <IconX />,
        }),
    });

  const { mutate: updateContract } = useMutation({
    mutationFn: () => ProfileService.updateContract(user.id, file[0]),
    onSuccess: () => {
      showNotification({
        id: 'udpate-added-employee-work-contract-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserContractualInfo', addedEmployee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateAddedEmployeeWorkContract(values: FormValues) {
    if (values.id !== null) {
      createOrUpdateUserContractualInfo({
        id: values.id,
        userId: addedEmployee?.id ? addedEmployee.id : null,
        professionalQualifications: values.professionalQualifications,
        contractType: values.contractType,
        otherContractType:
          values.contractType === 'OTHER' ? values.otherContractType : null,
        payslipName: values.payslipName,
        regime: values.regime,
        regimeLevel: values.regimeLevel,
        otherRegime: values.regime === 'OTHER' ? values.otherRegime : null,
        weeklyHours: values.weeklyHours,
        monthlyHours: values.monthlyHours,
        yearlyHours: values.yearlyHours,
        grossPay: values.grossPay,
        entryDate: values.entryDate
          ? dayjs(values.entryDate).format('YYYY-MM-DD')
          : null,
        exitDate: values.exitDate
          ? dayjs(values.exitDate).format('YYYY-MM-DD')
          : null,
        employmentAuthorizationDate: values.employmentAuthorizationDate
          ? dayjs(values.employmentAuthorizationDate).format('YYYY-MM-DD')
          : null,
        layoffAuthorizationDate: values.layoffAuthorizationDate
          ? dayjs(values.layoffAuthorizationDate).format('YYYY-MM-DD')
          : null,
        comment: values.comment,
      });
    } else {
      createOrUpdateUserContractualInfo({
        userId: addedEmployee?.id ? addedEmployee.id : null,
        professionalQualifications: values.professionalQualifications,
        contractType: values.contractType,
        otherContractType:
          values.contractType === 'OTHER' ? values.otherContractType : null,
        payslipName: values.payslipName,
        regime: values.regime,
        regimeLevel: values.regimeLevel,
        otherRegime: values.regime === 'OTHER' ? values.otherRegime : null,
        weeklyHours: values.weeklyHours,
        monthlyHours: values.monthlyHours,
        yearlyHours: values.yearlyHours,
        grossPay: values.grossPay,
        entryDate: values.entryDate
          ? dayjs(values.entryDate).format('YYYY-MM-DD')
          : null,
        exitDate: values.exitDate
          ? dayjs(values.exitDate).format('YYYY-MM-DD')
          : null,
        employmentAuthorizationDate: values.employmentAuthorizationDate
          ? dayjs(values.employmentAuthorizationDate).format('YYYY-MM-DD')
          : null,
        layoffAuthorizationDate: values.layoffAuthorizationDate
          ? dayjs(values.layoffAuthorizationDate).format('YYYY-MM-DD')
          : null,
        comment: values.comment,
      });
    }
    if (values.file != null && values.file?.length > 0) {
      setFile(values.file);
    }

    updateEmployee({
      identifiantsCompany: values.identifiantsCompany,
      employment: values.employment,
    });
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateAddedEmployeeWorkContract(values)
      )}
    >
      <LoadingOverlay
        visible={
          isLoadingCreateOrUpdateUserContractualInfo || isLoadingUpdateEmmployee
        }
      />
      <Title order={3}>{t('w.workContract')}</Title>
      <Space h={'xl'} />
      <ContractualStepper form={form} request={true} employee={addedEmployee} />
      <Group justify={'flex-end'} mt={'51px'} gap={'30px'}>
        <Button variant={'subtle'} onClick={onClose}>
          {t('w.cancel')}
        </Button>
        <Button
          variant={'outline'}
          disabled={!Boolean(addedEmployee)}
          leftSection={<IconDeviceFloppy />}
          type={'submit'}
        >
          {t('w.save')}
        </Button>
        <Button
          onClick={() => setActive(active - 1)}
          leftSection={<IconCircleArrowLeft />}
        >
          {t('w.back')}
        </Button>
        <Button onClick={onClose}>{t('w.close')}</Button>
      </Group>
    </form>
  );
}
