import {
  Button,
  Chip,
  Divider,
  Group,
  LoadingOverlay,
  Stack,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCalendar,
  IconCalendarWeek,
  IconCheck,
  IconCirclePlus,
  IconClockEdit,
  IconClockX,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';

import CustomMenu from '../../components/CustomMenu';
import CustomTransition from '../../components/CustomTransition';
import { useAppBar } from '../../contexts/AppBarProvider';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useModule } from '../../contexts/ModuleProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import useCompany from '../../hooks/useCompany';
import useFetchDivisions from '../../hooks/useFetchDivisions';
import DailyWorkService from '../../services/DailyWorkService';
import type { DailyTemplate, WeeklyTemplate } from '../../types/types';
import { hasPermission } from '../../utils/authorization';
import handleErrorMessage from '../../utils/handleErrorMessage';
import {
  ALL,
  AuthorizedReadAllUsersInfo,
  AuthorizedReadPlanning,
} from '../../variables/BuiltInPermissions';
import { DailyWork } from '../../variables/ModulesV2';
import PlanningScheduler from './components/PlanningScheduler';
import DailyTemplateForm from './work-templates/components/daily/DailyTemplateForm';
import WeeklyTemplateForm from './work-templates/components/weekly/WeeklyTemplateForm';
import WorkTemplate from './work-templates/components/WorkTemplate';

const daily = 'daily';
const weekly = 'weekly';

export const weeklyPeriods = [
  { day: 'MONDAY', period: [] },
  { day: 'TUESDAY', period: [] },
  { day: 'WEDNESDAY', period: [] },
  { day: 'THURSDAY', period: [] },
  { day: 'FRIDAY', period: [] },
  { day: 'SATURDAY', period: [] },
  { day: 'SUNDAY', period: [] },
];

export default function PlanningView() {
  const planningId = 'planning-id-to-print';
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId, company } = useCompany(user);
  const { getModule } = useModule();
  const {
    appBarSelectedDivisions,
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
  } = useAppBar();
  const { divisions } = useFetchDivisions({
    companyId,
  });
  const queryClient = useQueryClient();
  const [openedTemplates, { toggle: handleOpenTemplatesToogle }] =
    useDisclosure(false);
  const [chipValue, setChipValue] = useState(daily);
  const [selectedTemplate, setSelectedTemplate] = useState<
    DailyTemplate | WeeklyTemplate | undefined
  >(undefined);

  const selectedDivisions: string[] = useMemo(() => {
    if (!divisions || !appBarSelectedDivisions?.length) return [];

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions]);

  const dailyWorkModule = getModule(DailyWork);

  const templateMenuItems = useMemo(
    () => [
      {
        label: t('w.daily'),
        icon: <IconCalendar />,
        onClick: () =>
          modals.open({
            id: 'add-daily-template-modal',
            title: (
              <Title size={'h3'} component="p">
                {t('w.daily')}
              </Title>
            ),
            children: (
              <DailyTemplateForm
                template={{ id: '', name: '', period: [] }}
                refetchDailyTemplatesData={refetchDailyTemplatesData}
                onClose={() => modals.closeAll()}
                isEditing={false}
              />
            ),
            size: 'lg',
          }),
      },
      {
        label: t('w.weekly'),
        icon: <IconCalendarWeek />,
        onClick: () =>
          modals.open({
            id: 'add-weekly-template-modal',
            title: (
              <Title size={'h3'} component="p">
                {t('w.weekly')}
              </Title>
            ),
            children: (
              <WeeklyTemplateForm
                template={{ id: '', name: '', weeklyPeriods: weeklyPeriods }}
                refetchWeeklyTemplatesData={refetchWeeklyTemplatesData}
                onClose={modals.closeAll}
                isEditing={false}
              />
            ),
            fullScreen: true,
          }),
      },
    ],
    []
  );

  useEffect(() => {
    setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, []);

  useEffect(() => {
    buildAppbarDivisions();
  }, [divisions]);

  function buildAppbarDivisions() {
    let _divisions = [...divisions];

    // managers only see divisions where they are n1, n2 or assistant
    if (
      !hasPermission(
        [
          { permission: AuthorizedReadPlanning, scope: ALL },
          { permission: AuthorizedReadAllUsersInfo, scope: ALL },
        ],
        user
      )
    ) {
      _divisions = _divisions.filter(
        (_div) =>
          (_div.idOfN1 && _div.idOfN1 === user.id) ||
          (_div.idOfN2 && _div.idOfN2 === user.id) ||
          (_div.idOfAssistant && _div.idOfAssistant === user.id) ||
          _div.id === user.division.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }

  const {
    mutate: deleteDailyTemplate,
    isLoading: isDeleteDailyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: string) =>
      DailyWorkService.deleteDailyTemplate(variables),
    onSuccess: () => {
      modals.closeAll();
      refetchDailyTemplatesData();
      showNotification({
        id: `delete-daily-template-successful`,
        title: t('w.success'),
        message: `${t('w.template')} ${t('w.deleted').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `delete-daily-template-successful-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: deleteWeeklyTemplate,
    isLoading: isDeleteWeeklyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: string) =>
      DailyWorkService.deleteWeeklyTemplate(variables),
    onSuccess: () => {
      modals.closeAll();
      refetchWeeklyTemplatesData();
      showNotification({
        id: `delete-weekly-template-successful`,
        title: t('w.success'),
        message: `${t('w.template')} ${t('w.deleted').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `delete-weekly-template-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['CkModuleManagerServiceMS.list', companyId],
    });
  }, []);

  const { data: dailyTemplatesData, refetch: refetchDailyTemplatesData } =
    useQuery({
      queryKey: ['DailyWorkService.getDailyTemplates', user.id],
      queryFn: () => DailyWorkService.getDailyTemplates(user.id),
    });

  const dailyTemplates: DailyTemplate[] = useMemo(() => {
    if (!dailyTemplatesData) {
      return [];
    }
    return dailyTemplatesData;
  }, [dailyTemplatesData]);

  const { data: weeklyTemplatesData, refetch: refetchWeeklyTemplatesData } =
    useQuery({
      queryKey: ['DailyWorkService.getWeeklyTemplates', user.id],
      queryFn: () => DailyWorkService.getWeeklyTemplates(user.id),
    });

  const weeklyTemplates: WeeklyTemplate[] = useMemo(() => {
    if (!weeklyTemplatesData) {
      return [];
    }
    return weeklyTemplatesData;
  }, [weeklyTemplatesData]);

  function handleOpenUpdateDailyTemplateModal(template: DailyTemplate): void {
    modals.open({
      id: `daily-template-${template.id}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {template.name}
        </Title>
      ),
      children: (
        <DailyTemplateForm
          template={template}
          refetchDailyTemplatesData={refetchDailyTemplatesData}
          isEditing
          onClose={() => modals.closeAll()}
        />
      ),
    });
  }

  function handleOpenDeleteDailyTemplateModal(id: string, name: string) {
    modals.openConfirmModal({
      id: `delete-daily-${id}-template-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t(
          'w.confirmDeletion'
        )} ${name}`}</Title>
      ),
      size: 'lg',
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      onConfirm: () => deleteDailyTemplate(id),
    });
  }

  function handleOpenUpdateWeeklyTemplateModal(template: WeeklyTemplate) {
    modals.open({
      id: `weekly-template-${template.id}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {template.name}
        </Title>
      ),
      children: (
        <WeeklyTemplateForm
          template={template}
          refetchWeeklyTemplatesData={refetchWeeklyTemplatesData}
          onClose={modals.closeAll}
          isEditing
        />
      ),
      fullScreen: true,
    });
  }

  function handleOpenDeleteWeeklyTemplateModal(id: string, name: string) {
    modals.openConfirmModal({
      id: `delete-weekly-${id}-template-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t(
          'w.confirmDeletion'
        )} ${name}`}</Title>
      ),
      size: 'lg',
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      onConfirm: () => deleteWeeklyTemplate(id),
    });
  }

  const actionButtons = useMemo(() => {
    if (!dailyWorkModule?.active) return undefined;

    return [
      <Button
        key={'daily-work-button'}
        disabled={!selectedDivisions.length}
        onClick={() => {
          handleOpenTemplatesToogle();
          setSelectedTemplate(undefined);
        }}
        leftSection={openedTemplates ? <IconClockX /> : <IconClockEdit />}
      >
        {openedTemplates ? t('w.closePresenceEntry') : t('w.presenceEntry')}
      </Button>,
    ];
  }, [selectedDivisions, dailyWorkModule, company, openedTemplates]);

  return (
    <ScreenWrapper title={'Planning'} actionButtons={actionButtons} date>
      <Stack mb={'md'} gap="md">
        <LoadingOverlay
          visible={
            isDeleteDailyTemplateLoading || isDeleteWeeklyTemplateLoading
          }
        />
        {selectedDivisions.length > 0 && dailyWorkModule?.active && (
          <CustomTransition
            opened={openedTemplates}
            transition={'slide-right'}
            duration={600}
            timingFunction={'ease'}
          >
            <Divider />
            <Group justify={'space-between'}>
              <Chip.Group
                multiple={false}
                value={chipValue}
                onChange={setChipValue}
              >
                <Group>
                  <Chip value={daily}>Journaliers</Chip>
                  <Chip value={weekly}>Hébdomadaires</Chip>
                </Group>
              </Chip.Group>
              {dailyWorkModule?.active && (
                <CustomMenu
                  buttonLabel={t('w.addTemplate')}
                  buttonVariant={'subtle'}
                  leftSection={<IconCirclePlus />}
                  menuLabel={t('w.presences')}
                  menuItems={templateMenuItems}
                />
              )}
            </Group>
          </CustomTransition>
        )}
        {selectedDivisions.length > 0 && dailyWorkModule?.active && (
          <CustomTransition
            opened={openedTemplates}
            transition={'slide-right'}
            duration={800}
            timingFunction={'ease'}
          >
            {chipValue === daily ? (
              <Group gap={'xl'}>
                {dailyTemplates.map((item) => (
                  <WorkTemplate
                    key={item.id}
                    template={item}
                    handleOpenTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenUpdateDailyTemplateModal(item);
                    }}
                    handleDeleteTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenDeleteDailyTemplateModal(item.id, item.name);
                    }}
                    setSelectedTemplate={
                      selectedTemplate
                        ? () => setSelectedTemplate(undefined)
                        : () => setSelectedTemplate(item)
                    }
                    selectedTemplate={selectedTemplate}
                  />
                ))}
              </Group>
            ) : (
              <Group gap={'xl'}>
                {weeklyTemplates.map((item) => (
                  <WorkTemplate
                    key={item.id}
                    template={item}
                    handleOpenTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenUpdateWeeklyTemplateModal(item);
                    }}
                    handleDeleteTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenDeleteWeeklyTemplateModal(item.id, item.name);
                    }}
                    setSelectedTemplate={
                      selectedTemplate
                        ? () => setSelectedTemplate(undefined)
                        : () => setSelectedTemplate(item)
                    }
                    selectedTemplate={selectedTemplate}
                  />
                ))}
              </Group>
            )}
            <Divider mt="xs" />
          </CustomTransition>
        )}
        {company && (
          <PlanningScheduler
            planningId={planningId}
            selectedDivisions={selectedDivisions}
            selectedTemplate={selectedTemplate}
            divisions={divisions}
            company={company}
          />
        )}
      </Stack>
    </ScreenWrapper>
  );
}
