import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import DivisionService from '../services/DivisionService';
import type { Division } from '../types/types';

export default function useFetchDivisions({
  companyId,
}: {
  companyId: string;
}) {
  const {
    data: divisionsData,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['DivisionService.list', companyId],
    queryFn: () => DivisionService.list(companyId),
  });

  const divisions: Division[] = useMemo(() => {
    if (!divisionsData) {
      return [];
    }
    return divisionsData;
  }, [divisionsData]);

  return {
    divisions,
    refetch,
    isLoading,
    isError,
  };
}
