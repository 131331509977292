import { Button, Group, LoadingOverlay, Space, Title } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowLeft,
  IconCircleArrowRight,
  IconDeviceFloppy,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';

import SensitiveInfoStepper from '../../../../components/SensitiveInfoStepper';
import { useI18n } from '../../../../contexts/I18nProvider';
import ProfileService from '../../../../services/ProfileService';
import type {
  User,
  UserPersonalInfo,
  UserPersonalSensitive,
} from '../../../../types/types';

type Props = {
  onClose: () => void;
  employee: User | null;
  refetch: () => void;
  userPersonalInfo: UserPersonalInfo | null;
  userSensitiveInfo?: UserPersonalSensitive | null;
  setActive: (number: number) => void;
  active: number;
  setSensitiveInfo: (data: UserPersonalSensitive) => void;
  refetchUserSensitiveInfo?: () => void;
};

interface FormValues {
  id?: string | null;
  userId?: string;
  identityCardNumber?: string;
  identityCardFiles?: FileWithPath[];
  sSNumber?: string;
  sSFiles?: FileWithPath[];
  handicappedWorker?: boolean;
  iban?: string;
  bic?: string;
  residencePermitNumber?: string;
  residencePermitFiles?: FileWithPath[];
}

export default function AddEmployeeSensitiveForm({
  employee,
  refetch,
  onClose,
  userPersonalInfo,
  userSensitiveInfo,
  setActive,
  active,
  setSensitiveInfo,
  refetchUserSensitiveInfo,
}: Props) {
  const { t } = useI18n();
  const [identityCardFile, setIdentityCardFile] = useState<FileWithPath[]>([]);
  const [sSFile, setSSFile] = useState<FileWithPath[]>([]);
  const [residencePermitFile, setResidencePermitFile] = useState<
    FileWithPath[]
  >([]);

  const form = useForm<FormValues>({
    initialValues: {
      id:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.id
            ? userSensitiveInfo.id
            : null
          : null,
      userId: employee?.id,
      identityCardNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.identityCardNumber
            ? userSensitiveInfo.identityCardNumber
            : ''
          : '',
      identityCardFiles: [],
      sSNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.sSNumber
            ? userSensitiveInfo.sSNumber
            : ''
          : '',
      sSFiles: [],
      handicappedWorker:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.handicappedWorker
            ? userSensitiveInfo.handicappedWorker
            : false
          : false,
      iban:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.iban
            ? userSensitiveInfo.iban
            : ''
          : '',
      bic:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.bic
            ? userSensitiveInfo.bic
            : ''
          : '',
      residencePermitNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.residencePermitNumber
            ? userSensitiveInfo.residencePermitNumber
            : ''
          : '',
      residencePermitFiles: [],
    },
  });

  const {
    mutate: createOrUpdateUserSensitiveInfo,
    isLoading: isLoadingCreateOrUpdateUserSensitiveInfo,
  } = useMutation({
    mutationFn: (variables: FormValues) =>
      ProfileService.createOrUpdateUserSensitiveInfo(employee?.id, variables),
    onSuccess: (data) => {
      if (residencePermitFile.length > 0) {
        updateResidentPermit();
      }
      if (sSFile.length > 0) {
        updateSocialSecurity();
      }
      if (identityCardFile.length > 0) {
        updateIdentityCard();
      }
      showNotification({
        id: 'udpate-added-employee-work-contract-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setSensitiveInfo(data);
      if (refetchUserSensitiveInfo) {
        refetchUserSensitiveInfo();
      }
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateResidentPermit } = useMutation({
    mutationFn: () =>
      ProfileService.updateResidencePermit(
        employee?.id,
        residencePermitFile[0]
      ),
    onSuccess: () => {
      showNotification({
        id: 'udpate-added-employee-resident-permit-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateSocialSecurity } = useMutation({
    mutationFn: () =>
      ProfileService.updateSecuritySocial(employee?.id, sSFile[0]),
    onSuccess: () => {
      showNotification({
        id: 'udpate-added-employee-security-social-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateIdentityCard } = useMutation({
    mutationFn: () =>
      ProfileService.updateIdentityCard(employee?.id, identityCardFile[0]),
    onSuccess: () => {
      showNotification({
        id: 'udpate-added-employee-indentity-card-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleSensitiveSubmitForm(values: FormValues) {
    if (values.id !== null) {
      createOrUpdateUserSensitiveInfo({
        id: values.id,
        userId: values.userId,
        identityCardNumber: values.identityCardNumber,
        sSNumber: values.sSNumber,
        handicappedWorker: values.handicappedWorker,
        iban: values.iban,
        bic: values.bic,
        residencePermitNumber: values.residencePermitNumber,
      });
    } else {
      createOrUpdateUserSensitiveInfo({
        userId: values.userId,
        identityCardNumber: values.identityCardNumber,
        sSNumber: values.sSNumber,
        handicappedWorker: values.handicappedWorker,
        iban: values.iban,
        bic: values.bic,
        residencePermitNumber: values.residencePermitNumber,
      });
    }

    if (
      values.identityCardFiles != null &&
      values.identityCardFiles?.length > 0
    ) {
      setIdentityCardFile(values.identityCardFiles);
    }
    if (values.sSFiles != null && values.sSFiles?.length > 0) {
      setSSFile(values.sSFiles);
    }
    if (
      values.residencePermitFiles != null &&
      values.residencePermitFiles?.length > 0
    ) {
      setResidencePermitFile(values.residencePermitFiles);
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleSensitiveSubmitForm(values))}
    >
      <LoadingOverlay visible={isLoadingCreateOrUpdateUserSensitiveInfo} />
      <Title order={3}>{t('w.administrativeInfo')}</Title>
      <Space h={'xl'} />
      <SensitiveInfoStepper
        form={form}
        request={true}
        edit={false}
        userPersonalInfo={userPersonalInfo}
      />
      <Group justify={'flex-end'} mt={'51px'} gap={'30px'}>
        <Button variant={'subtle'} onClick={onClose}>
          {t('w.cancel')}
        </Button>
        <Button
          variant={'outline'}
          disabled={!Boolean(employee)}
          leftSection={<IconDeviceFloppy />}
          type={'submit'}
        >
          {t('w.save')}
        </Button>
        <Button
          onClick={() => setActive(active - 1)}
          leftSection={<IconCircleArrowLeft />}
        >
          {t('w.back')}
        </Button>
        <Button
          onClick={() => setActive(active + 1)}
          leftSection={<IconCircleArrowRight />}
        >
          {t('w.next')}
        </Button>
        <Button onClick={onClose}>{t('w.close')}</Button>
      </Group>
    </form>
  );
}
