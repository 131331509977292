import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Badge, Title, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconEdit } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../../../../components/CustomTooltip';
import { useI18n } from '../../../../../../contexts/I18nProvider';
import EndOfMonthService from '../../../../../../services/EndOfMonthService';
import {
  OpenpayeUser,
  SynchroOpenpayeParams,
} from '../../../../../../types/types';
import { milliToFullDateString } from '../../../../../../utils/format';
import IdentifiantsCompanyForm from './IdentifiantsCompanyForm';
import MailAndUnitForm from './MailAndUnitForm';

type Props = {
  companyId: string;
  closeModal: () => void;
  moduleParams: SynchroOpenpayeParams;
};

export default function LastSynchroOpenpaye({ companyId }: Props) {
  const { t, lang } = useI18n();
  const theme = useMantineTheme();
  const [columns] = useState<DataTableColumn<OpenpayeUser>[]>([
    {
      accessor: 'dossierId',
      title: t('module.dossierOpenPaye'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'matricule_salarie',
      title: t('w.serialNumber'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'nom',
      title: t('w.name'),
      sortable: true,
      ellipsis: true,
      render: ({ nom, prenom }) => nom + ' ' + prenom,
    },
    {
      accessor: 'contrat_en_cours',
      title: t('com.feature.openPaye.activeOnOpenpaye'),
      sortable: true,
      ellipsis: true,
      render: ({ contrat_en_cours }) =>
        contrat_en_cours ? t('w.yes') : t('w.no'),
    },
    {
      accessor: 'status',
      title: t('w.status'),
      sortable: true,
      ellipsis: true,
      render: ({ status }) => (
        <CustomTooltip
          label={
            status.comment === ''
              ? t(`com.feature.openPaye.comment.HFW_CREATED`)
              : t(status.comment)
          }
        >
          <Badge
            autoContrast
            color={
              status.status === 'OP_IMPORTED' ||
              status.status === 'OP_UPDATED' ||
              status.status === 'HFW_IMPORTED'
                ? theme.colors.orange[2]
                : status.status === 'ERROR'
                ? theme.colors.red[5]
                : status.status === 'OP_CREATED'
                ? theme.colors.yellow[5]
                : theme.colors.green[5]
            }
          >
            {t(`com.feature.openPaye.${status.status}`)}
          </Badge>
        </CustomTooltip>
      ),
    },
    {
      accessor: 'created',
      title: t('com.feature.openPaye.creationDate'),
      sortable: true,
      ellipsis: true,
      render: ({ created }) =>
        created ? milliToFullDateString(created) : t('w.unknown'),
    },
    {
      accessor: 'updated',
      title: t('com.feature.openPaye.lastDateUpdated'),
      sortable: true,
      ellipsis: true,
      render: ({ updated }) =>
        updated ? milliToFullDateString(updated) : t('w.unknown'),
    },
    {
      accessor: 'actions',
      title: t('w.actions'),
      textAlign: 'right',
      sortable: false,
      width: 80,
      render: ({ id, status, nom, prenom, matricule_salarie }) =>
        status.status === 'OP_UPDATED' || status.status === 'OP_IMPORTED' ? (
          //mail
          <ActionIcon
            variant={'subtle'}
            size="sm"
            color="blue"
            onClick={() =>
              modals.open({
                modalId: `update-mail-and-unit-modal`,
                title: (
                  <Title size={'h3'} component="p">
                    {nom + ' ' + prenom + ' - ' + matricule_salarie}
                  </Title>
                ),
                size: '80%',
                children: <MailAndUnitForm companyId={companyId} id={id} />,
              })
            }
          >
            <IconEdit size={16} />
          </ActionIcon>
        ) : status.status === 'HFW_IMPORTED' ? (
          //matricule
          <ActionIcon
            variant={'subtle'}
            size="sm"
            color="blue"
            onClick={() =>
              modals.open({
                modalId: `update-mail-and-unit-modal`,
                title: (
                  <Title size={'h3'} component="p">
                    {nom + ' ' + prenom}
                  </Title>
                ),
                size: '80%',
                children: (
                  <IdentifiantsCompanyForm companyId={companyId} id={id} />
                ),
              })
            }
          >
            <IconEdit size={16} />
          </ActionIcon>
        ) : (
          ''
        ),
    },
  ]);

  const { data, isLoading } = useQuery({
    queryKey: [`EndMonthService.getSynchroOpenpaye`, companyId],
    queryFn: () => EndOfMonthService.getSynchroOpenpaye(companyId),
  });
  const lastSynchro = useMemo(() => {
    if (!data) {
      return [];
    }
    return data;
  }, [data]);

  const [options] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'nom',
      label: t('w.name'),
    },
  });

  return (
    <>
      <Table
        pinFirstColumn
        pinLastColumn
        rows={lastSynchro}
        options={options}
        columns={columns}
        lang={lang}
        fetching={isLoading}
        withTableBorder={false}
      />
    </>
  );
}
