import { type Dayjs } from 'dayjs';
import React from 'react';

import type { IconName } from '../features/company/period-types/components/IconSelector';
import {
  ALL,
  type BuiltInPermission,
  MINE,
  TEAM,
} from '../variables/BuiltInPermissions';
import type { ModuleNameType } from '../variables/ModulesV2';

export type GetTranslationProps = (
  key: string,
  ...args: (string | number)[]
) => string;

export type IdentifiantCompanyObject = {
  codeCompany?: string;
  matricule?: string;
};

export type AddIdentifiantCompanyOpenPaye = {
  userId: string;
  identifiantsCompany: IdentifiantCompanyObject;
};

export const ACCOUNTANT = 'ACCOUNTANT';
export const ACCOUNTANT_MODE = 'accountant';
export const COMPANY_MODE = 'company';

type PermissionScope = typeof ALL | typeof TEAM | typeof MINE;

export type Permission = {
  id: string;
  name: string;
  type: string;
  key: string;
  scope?: string | undefined;
};

export type PermissionScopedBack = {
  permission: Permission;
  scope: PermissionScope;
};

export type PermissionScoped = {
  permission: BuiltInPermission;
  scope: PermissionScope;
};

export type Accountant = {
  id: string;
  name: string;
  address: string;
  mail: string;
  phone: string;
};

export type AccountantUser = {
  id: string;
  firstname: string;
  lastname: string;
  fullname: string;
  companyName: string;
  mail: string;
};

export type DivisionAccountant = {
  id: string;
  name: string;
  accountantUser: AccountantUser[];
};

export type EndOfMonthCustomerStatus = {
  monthConcerned: string; // (example format: 01-2024);
  globalValidation: boolean;
  divisions: {
    validation: boolean;
    divisionId: string;
    date?: number;
  }[];
};

export type Accountants = {
  accountant: Accountant;
  paiementAccount: boolean;
  active: boolean;
  clientCompanyId: string;
  status: null | EndOfMonthCustomerStatus;
  customerCompany: Company;
  divisionAccountant: DivisionAccountant[];
  generalManagers: AccountantUser[];
  managedDivision:
    | {
        id: string;
        name: string;
      }[]
    | null;
};

export type InvitationAccountantHistory = {
  created: number;
  updated: number;
  who: {
    fullname: string;
    userId: string;
    mail: string;
    companyId: string;
    companyName: string;
  };
  toMail: string;
  toManage: {
    fullname: string;
    userId: string;
    mail: string;
    companyId: string;
    companyName: string;
  };
  requestType: 'linkClientToAccountant' | 'delete';
  status: 'accept' | 'processing' | 'reject' | 'send';
  id: string;
};

export type Employee = {
  securityRoleId: string;
  valid: boolean;
  id: string;
  fullname: string;
  mail: string;
};

export type Role = {
  id: string;
  name: string;
  companyId?: string;
  permissions: PermissionScopedBack[];
  updated?: number;
  created?: number;
};

export type Company = {
  created?: number;
  id: string;
  updated?: number;
  /**
   * @deprecated since version 2.0
   */
  acquisionRulePerMonth?: number;
  address?: Address;
  billingPlanId?: string;
  divisionLabel?: string;
  countryCode?: string;
  hasDefaultPayment?: boolean;
  /**
   * @deprecated since version 2.0
   */
  joursOuvres?: boolean;
  mail?: string;
  name?: string;
  phone?: string;
  recoveryActive?: boolean;
  rttActive?: boolean;
  useAssistant?: boolean;
  useN2?: boolean;
  logo?: File | null | undefined;
  siret?: string;
};

export type CompanyLeave = {
  created: number;
  id: string;
  updated: number;
  companyId: string;
  description: string;
  excludeDivisions: Division[];
  leaveTypeId: string;
  name: string;
  period: {
    end: string;
    endDate: string;
    start: string;
    startDate: string;
    valid: boolean;
  };
};

export type Address = {
  address1?: string;
  address2?: string;
  city?: string;
  zipCode?: string;
};

export type Division = {
  created: number;
  id: string;
  idOfN1: string;
  idOfN2: string;
  idOfAssistant: string;
  name: string;
  nameOfN1: string;
  nameOfN2: string;
  nameOfAssistant: string;
  nbUsers: number;
  numberLeavesOnDay: number;
  numberLeavesOnWeek: number;
  updated: number;
};

export type SecurityRoles = {
  created?: number;
  id: string;
  name: string;
  updated?: number;
};

export type LeaveType = {
  id?: string; // payload possibly null if create
  name: string;
  description?: string;
  order?: number;
  maxDays?: number;
  legalDelay?: number;
  securityRoles?: SecurityRoles[]; // payload string[]

  autoValid?: boolean;
  blockingPeriodAllowed?: boolean;
  cancelOthersLeaves?: boolean;
  color?: string;
  icon?: IconName;
  companyId?: string;
  companyMandatoryLeaveType?: boolean;
  created?: number;
  excludeEndMonth?: boolean;
  repeatable?: boolean;
  requiredAttachment?: boolean;
  requiredComment?: boolean;
  showLabel?: boolean;
  selected?: boolean;
  updated?: number;
  useAllDays?: boolean;
  codeQuadra?: string;
  codeDiapaie?: string;
  codeSilae?: string;
  codeIsapaye?: string;
  codeOpenpaye?: string;
  codeCegidExpert?: string;
  associateCounter?: AssociateCounter[];
  /**
   * @deprecated since version 2.0
   */
  tag?: string;
};

export interface LeaveTypeFormValues {
  name: string;
  description: string;
  order: number;
  maxDays: number;
  legalDelay: number;
  securityRoles: string[];
  autoValid: boolean;
  requiredComment: boolean;
  requiredAttachment: boolean;
  blockingPeriodAllowed: boolean;
  useAllDays: boolean;
  showLabel: boolean;
  excludeEndMonth: boolean;
  repeatable: boolean;
  cancelOthersLeaves: boolean;
  color: string;
  icon?: IconName;
  codeQuadra?: string;
  codeDiapaie?: string;
  codeSilae?: string;
  codeIsapaye?: string;
  codeOpenpaye?: string;
  codeCegidExpert?: string;
  associateCounter?: AssociateCounter[];
  status?: boolean;
}

export type LeaveTypePayload = Omit<LeaveTypeFormValues, 'status'>;

export type ImportLeaveTypeResultResponse = {
  nbCreated: number;
  nbErrors: number;
  fileName: string;
  nbUpdated: number;
  importReportList: ImportLeaveTypeReport[];
};

export type ImportLeaveTypeReport = {
  name: string;
  lineNb: number;
  message: string;
  status: 'OK' | 'NOK';
};

export type AssociateCounter = {
  order: number;
  counterTypeId: string;
};

export type LeavePeriod = {
  end: string;
  endDate: string;
  start: string;
  startDate: string;
  valid: boolean;
};

type Comment = {
  date: number;
  comment: string;
  username: string;
};

export type LeaveStatusState = 'VALID' | 'VALIDN1' | 'REJECTED' | 'WAITING';

export type LeaveStatus = {
  comments: Comment[];
  state: LeaveStatusState;
  stateDate: number;
  statusDateN1: number;
  statusDateN2: number;
  updated: number;
};

type PaidFeature = {
  active: boolean;
  name: string;
};

export type IdentifiantCompany = {
  matricule: string;
  codeCompany: string;
};

export type TypeOfContract =
  | 'Apprenti'
  | 'CDD'
  | 'CDI'
  | 'Temps partiel'
  | 'Contrat de professionnalisation'
  | 'Temporaire'
  | 'Stagiaire';

export type PersonalRegistry = {
  dateOfBirth?: number;
  gender?: boolean | string;
  dateOfEntry?: number;
  dateOfExit?: number;
  dateOfAuthorizationToHire?: number;
  dateAuthorizationForDismissal?: number;
  nationality: string;
  foreignWorker?: boolean;
  typeOfContract?: TypeOfContract;
  qualification?: string;
  residenceNumber?: string;
  lastNameAndFirstNameOfTheTutor?: string;
  placeOfPresence?: string;
  residencePermit?: string;
  residencePermitFile?: string;
};

export type User = {
  id: string;
  created: number;
  updated: number;
  fullname: string;
  mail: string;
  firstname: string;
  lastname: string;
  divisionId: string;
  companyId: string;
  valid: boolean;
  employment: string;
  securityRoleId: string;
  lastLoginDate?: number;
  identifiantsCompany?: IdentifiantCompany[];
  paidFeatures: PaidFeature[];
  fixedPriceManager: boolean;
  status: OnBoardingStatus | null;
  firstManager: string;
  secondManager: string;
  divisionN1: string;
  divisionN2: string;
  divisionName: string;
  division: Division;
  lang: 'fr' | 'en' | undefined;
  termsAcceptance: boolean;
  personalRegistry?: PersonalRegistry;
  company: Company;
  myCompanies?: string[];
  role: Role;
  payslipName: string;
  nameOfN1: string;
  nameOfN2: string;
  phone: string;
  gender: boolean;
};

export type UserPayload = {
  firstname?: string;
  lastname?: string;
  mail?: string;
  securityRoleId?: string;
  divisionId?: string;
  valid?: boolean;
  avatar?: File | null;
  filename?: string | null;
  firstManager?: string;
  secondManager?: string;
  identifiantsCompany?: IdentifiantCompany[] | undefined;
  fixedPriceManager?: boolean;
  employment?: string;
};

export type UserPersonalSensitive = {
  id?: string;
  userId: string;
  identityCardNumber: string;
  identityCardFile: string;
  sSNumber: string;
  sSFile: string;
  handicappedWorker: boolean;
  iban: string;
  bic: string;
  residencePermitNumber: string;
  residencePermitFile: string;
};

export type UserPersonalFamily = {
  id?: string;
  userId: string;
  familyState?: FamilyState;
  numberDependents?: number;
  numberDependentsChildren?: number;
};

export type FamilyState =
  | 'married'
  | 'single'
  | 'civil_union'
  | 'divorced'
  | 'separated'
  | 'widowed';

export type UserPersonalInfo = {
  id?: string;
  userId?: string;
  nationality?: string;
  gender?: Gender;
  phoneNumber?: string;
  birthday?: number;
  birthPlace?: string;
  birthDepartment?: string;
  address?: UserAddress;
};

export type Gender = 'MALE' | 'FEMALE' | 'UNKNOWN';

export type UserAddress = {
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
};

export type UserContractualInfoType = {
  id?: string;
  userId: string;
  payslipName: string;
  regime: ContractRegime;
  regimeLevel: string;
  otherRegime: string;
  professionalQualifications: string;
  contractType: ContractType;
  otherContractType: string;
  weeklyHours: number;
  monthlyHours: number;
  yearlyHours: number;
  grossPay: number;
  entryDate: number;
  exitDate: number;
  employmentAuthorizationDate: number;
  layoffAuthorizationDate: number;
  comment: string;
  contractFile: string;
};

export type ContractRegime = 'EXECUTIVE' | 'ETAM' | 'WORKER' | 'OTHER';
export type ContractType = 'CDI' | 'CDD' | 'CP' | 'CTT' | 'CA' | 'OTHER';

export type UserEmergencyContactType = {
  id?: string | null;
  userId?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  comment?: string;
};

export type UserWithFilteredFields = {
  id: string;
  created: number;
  updated: number;
  fullname?: string;
  mail?: string;
  firstname?: string;
  lastname?: string;
  divisionId?: string;
  companyId?: string;
  valid?: boolean;
  employment?: string;
  securityRoleId?: string;
  lastLoginDate?: number;
  identifiantsCompany?: IdentifiantCompany[];
  paidFeatures?: PaidFeature[];
  fixedPriceManager?: boolean;
  status?: OnBoardingStatus | null;
};

export type Leave = {
  comment: string;
  created: number;
  updated: number;
  division: Division;
  duration: number;
  endHalf: boolean;
  startHalf: boolean;
  fileName?: string;
  id: string;
  leaveType: LeaveType;
  period: LeavePeriod;
  status: LeaveStatus;
  statusDateN1: number;
  statusDateN2: number;
  validatorFullname?: string;
  who: User;
};

export type ExpenseStatusState =
  | 'WAITING'
  | 'VALID'
  | 'PROCESSED'
  | 'REFUNDED'
  | 'REJECTED';

export type ExpenseReportStatus = {
  state: ExpenseStatusState;
  comment?: Comment;
  stateDate?: string;
};

export type ExpenseReportLabel =
  | 'FUEL'
  | 'PLANE'
  | 'TRAIN'
  | 'TAXI'
  | 'TOLL'
  | 'PARKING'
  | 'CARRENTAL'
  | 'BIKERENTAL'
  | 'TRANSITPASS'
  | 'KILOMETRICALLOWANCE'
  | 'MEAL'
  | 'LODGING'
  | 'OTHER';

export type ExpenseReportType =
  | 'DISPLACEMENT'
  | 'MISSION'
  | 'OTHER'
  | 'RECEPTION';

export type ExpenseReportObject = {
  type: ExpenseReportType;
  comment: string;
  currency: string;
  status: ExpenseReportStatus;
  companyId: string;
  label: ExpenseReportLabel;
  creator: string;
  dateOfExpense: string;
  amount: number;
  additionVATList: number[];
  additionValueVATList: number[];
  id: string;
  created: number;
  updated: number;
  attachment?: string;
  external?: string;
  internal?: string;
  distance?: number;
  placeOfDeparture?: string;
  placeOfArrival?: string;
};

export type UserExpenseReportSummary = {
  waiting: number;
  waitingAmount: string;
  refundProcessing: number;
  refundProcessingAmount: string;
  refunded: number;
  refundedAmount: string;
  rejected: number;
  rejectedAmount: string;
  processed: number;
  processedAmount: string;
  details: UserExpenseReportSummaryDetail[];
};

export type UserExpenseReportSummaryDetail = {
  month: string;
  processed: number;
  processedAmount: string;
  refundProcessing: number;
  refundProcessingAmount: string;
  refunded: number;
  rejected: number;
  solde: string;
  totalTTC: string;
  waiting: number;
  values: ExpenseReportObject[];
};

export type Sublink = {
  label: string | React.ReactNode;
  link: string;
  icon: React.FC<any>;
  divider: boolean;
  isDisplayed?: boolean;
};

export type Link = {
  label: string | React.ReactNode;
  icon: React.FC<any>;
  link?: string;
  divider: boolean;
  links?: Sublink[];
  isDisplayed?: boolean;
};

export type OauthUserProfile = {
  id: string;
  phone: string;
  firstname: string;
  lastname: string;
  currentProfile: string;
  profileIds: string[];
  username: string;
};

export type PublicHoliday = {
  date: string;
  key: string;
  label: string;
  worked: boolean;
};

export type BreakDay = {
  end: string;
  start: string;
  valid: boolean;
};

export type BreakDays = {
  userId: string;
  color: string;
  breakDays: BreakDay[];
};

export type PaidFeatures = {
  name: string;
  active: boolean;
};

export type UserPersonalBreakDay = {
  morning: boolean;
  afternoon: boolean;
  day: string;
  repeat?: string;
  editable?: boolean;
};

export type ViewModule =
  | 'companyFeature'
  | 'companyMarketplace'
  | 'accountantMarketPlace';

export type PayslipGma = {
  id: string;
  idStr: string;
  gmaPayslipId: string;
  month: number;
  tmp: boolean;
  visible: boolean;
  year: number;
};

export type PayslipsFromGma = {
  accessToken: string;
  payslips: PayslipGma[];
  refreshToken: string;
  spaceId: string;
};

export type PayslipUser = {
  year: number;
  month: number;
  gmaPayslipId: string;
  id: string;
  created: number;
  updated: number;
};

export type PayslipMonthResponse = {
  id: string;
  created: number;
  updated: number;
  companyId: string;
  year: number;
  month: number;
  sourceKey: {
    date: number;
  }[];
  ok: UserPayslipResponse[];
  new: UserPayslipResponse[];
  notFound: UserPayslipResponse[];
};

export type UserPayslipResponse = {
  userId: string;
  siret: number;
  netAmount: number;
  grossAmount: number;
  visible: boolean;
  divisionId: string;
  lastname: string;
  firstname: string;
  identifiantsCompany: IdentifiantCompany[];
  fullname: string;
  status: 'OK' | 'NEW' | 'NOTFOUND';
  notification?: number;
  counter: {
    [name: string]: number;
  };
};

type PayslipsMonthTreatmentError = {
  msg: string;
  key: string;
};

export type PayslipsMonthTreatmentState = 'SUCCESS' | 'PROCESSING' | 'ERROR';

export type PayslipsMonthTreatment = {
  id: string;
  state: PayslipsMonthTreatmentState;
  year: number;
  month: number;
  companyId: string;
  created: number;
  updated: number;
  filename: string;
  error: {
    errors: PayslipsMonthTreatmentError[];
  };
};

export type Module = {
  name: string;
  active: boolean;
  configuration: ModuleConfiguration;
  description: ModuleDescription;
};

type ModuleDescription = {
  id: string;
  description: string;
  name: ModuleNameType;
  enabled: boolean;
  types: string[];
  url: string;
  views: string[];
  directUrl: boolean;
  imageUrl: string;
  activatable: boolean;
  paid: boolean;
  params: boolean;
};

export type ModuleConfiguration = {
  params?: ModuleConfigurationParams;
  active: boolean;
  clientId: string;
};

export type TimeRecorderParams = {
  linkToDailyWork?: boolean;
  delayPeriod?: null | number;
};

export type DailyWorkParams = {
  activeForAll?: boolean;
  variableIdOvertime?: string;
  variableIdWorkingTime?: string;
  variableIdWorkingDays?: string;
};

export type GMAParams = {
  safeBeingInitialized?: boolean;
  existsOnGma?: boolean;
};

export type SynchroOpenpayeParams = {
  username: string;
  password: string;
  dossiers?: DossierProps[];
  lastSynchroEmployee: number;
};

export type ResUpdateSynchroOpenpayeModule = {
  active: boolean;
  params: SynchroOpenpayeParams;
  clientId: string;
  descriptionId: string;
  id: string;
  created?: string;
  updated?: string;
};

export type PayloadAddMailProps = {
  divisionId: string;
  mail: string;
  userId: string;
};

export type DossierProps = {
  dossierId: string;
  status?: boolean;
  employeeCount?: number;
};

export type ShowMessageBeforePaidLeaveRequestParams = {
  message?: string;
};

export type ExportParams = {
  codesDossier?: string[];
  includeLeave?: boolean;
  includeVar?: boolean;
};

export type HfwAzureSyncParams = {
  login?: string;
  password?: string;
  tenant?: string;
  clientId?: string;
  clientSecret?: string;
  scope?: string;
};

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

type ColorIconType = 'BREAK_DAY' | 'PUBLIC_HOLIDAY' | 'BLOCKING_PERIOD';

export type ColorIcon = {
  color: string;
  icon: IconName;
  type: ColorIconType;
};

export type CompanyDaysWorkedParams = {
  joursOuvres?: boolean;
  nbMobileBreakDay?: number;
  validDayOfWeek?: DayOfWeek[];
  useHalfDay?: boolean;
  colorIcons: ColorIcon[];
};

export type ExpenseReportParams = {
  method: 'PAYROLL' | 'DIRECTLY';
};

export type VariablePayrollType =
  | 'EUROS'
  | 'DAYS'
  | 'HOURS'
  | 'NUMBERS'
  | 'TEXT'
  | 'FILE';

export type VariablePayroll = {
  id?: string;
  label: string;
  type: VariablePayrollType;
  editable: boolean;
  codeQuadra?: string;
  codeSilae?: string;
  codeDiapaie?: string;
  codeIsapaye?: string;
  codeOpenpaye?: string;
  codeCegidExpert?: string;
};

export type VariablePayrollParams = {
  variablesPayroll: VariablePayroll[];
};

export type ModuleConfigurationParams =
  | SynchroOpenpayeParams
  | TimeRecorderParams
  | GMAParams
  | ShowMessageBeforePaidLeaveRequestParams
  | ExportParams
  | HfwAzureSyncParams
  | CompanyDaysWorkedParams
  | ExpenseReportParams
  | VariablePayrollParams
  | DailyWorkParams;

export type PaymentCompany = {
  id: string;
  active: boolean;
  mail: string;
  name: string;
  createdBy?: any;
};

export type PaymentAuthorizedUser = {
  mail: string;
  secretToken?: string; // check if deprecated in back
  confirmationToken?: string; // check if deprecated in back
};

export type PaymentCard = {
  brand: string;
  country: string;
  expMonth: number;
  expYear: number;
  funding: 'credit' | 'debit';
  last4: string;
};

export type PaymentSepaDebit = {
  bankCode: string;
  country: string;
  last4: string;
};

export type PaymentMethod = {
  id: 'pm_1JZsYJGzublQIbMAAtiILtfL';
  name: 'es';
  card?: PaymentCard;
  sepaDebit?: PaymentSepaDebit;
};

export type PaymentAccount = {
  created: string;
  updated: string;
  companies: PaymentCompany[];
  active: boolean;
  label: string;
  firstBilling: string;
  billing: {
    customerId?: string;
    histories: any[];
  };
  asset: number;
  address: Address;
  siret: string;
  owner: PaymentAuthorizedUser;
  payment: {
    customerId?: string;
    defaultPaymentMethodId?: string;
  };
  users: PaymentAuthorizedUser[];
  billingPlanId: string;
  properties: {
    unitPrice: number;
    gma: boolean;
    whiteLabel: boolean;
  };
  id: string;
  defaultPayment?: PaymentMethod;
  nbActiveUsers: number;
  totalHT: number;
  nbDailyWorkActiveUsers: number;
  nbActiveUsersForGma: number;
};

export type BillingHistory = {
  created: string; // "2023-06-30T15:48:47.639",
  updated: string; // "2023-06-30T15:48:47.639",
  paymentAccountId: string;
  paymentIntentId: string;
  invoicePath: string;
  invoiceRef: string;
  status: BillingHistoryStatus;
  amountTTC: number;
  amountHT: number;
  paymentIntentDate: string; // "2020-03-01"
  lastPaymentErrorCode?: string;
  declineCode?: string;
  clientSecret?: string;
  lastPaymentErrorMethodId?: string;
  paymentHistories: PaymentHistory[];
  declineMessage?: string;
  id: string;
};

export type BillingHistoryStatus =
  | 'pending'
  | 'failed'
  | 'succeeded'
  | 'requires_payment_method';

export type PaymentHistory = {
  paymentIntentId: string;
  status: string;
  paymentIntentDate?: any; // "2020-03-01"
};

export type WhiteLabel = {
  id: string;
  paymentAccountId: string;
  dns?: string;
  logoPath?: string;
  primaryColor?: string;
  secondaryColor?: string;
  favicon?: string;
  name?: string;
  mail?: string;
  activeModules: string[];
};

export type Notification = {
  key: string;
  id: string;
  mail: {
    enabled: boolean;
    value: boolean;
  };
  app: {
    enabled: boolean;
    value: boolean;
  };
  mobile: {
    enabled: boolean;
    value: boolean;
  };
};

export type NotificationCompany = {
  myCompany: boolean;
  companyId: string;
  companyName: string;
  companyCustomDefinition: Notification[];
};

export type NotificationUser = {
  created: string;
  updated: string;
  userId: string;
  title: string;
  content: string;
  viewed: boolean;
  link: string | null;
  tag: string[];
  id: string;
};

export type CreateCompanyPaymentAccountPayload = {
  mail: string;
  companyName: string;
  address: string;
  zipCode: string;
  city: string;
  siret: string;
};

export type Provider = 'Google' | 'GMA' | 'Microsoft';

export type OauthProvider = {
  provider: Provider;
  mail: string;
};

export type Period = {
  start: Dayjs;
  end: Dayjs;
};

export type PlanningPeriod = {
  start: number;
  end: number;
};

export type PlanningDivision = {
  id: string;
  name: string;
  n1?: string;
  n2?: string;
  assistant?: string;
};

export type PlanningUser = {
  user: UserDetails;
  periods: LeaveDetails[];
};

export type PlanningObject = {
  blockingPeriods: LeaveDetails[];
  division: PlanningDivision;
  users: PlanningUser[];
};

export type BlockingPeriod = {
  companyId: string;
  id: string;
  name: string;
  description: string;
  period: PlanningPeriod;
  division: Division[];
};

export type PeriodTemplate = {
  endDate: string;
  startDate: string;
};

export type DailyTemplate = {
  id: string;
  name: string;
  period: PeriodTemplate[];
  shared?: boolean;
};

export type WeeklyPeriod = {
  day: string;
  period: PeriodTemplate[];
};

export type WeeklyTemplate = {
  id: string;
  name: string;
  weeklyPeriods: WeeklyPeriod[];
  shared?: boolean;
};

export type OnBoardingStatus =
  | 'ACTIVE'
  | 'ONBOARDING'
  | 'ONBOARDING_EMPLOYE'
  | 'ONBOARDING_VALIDATION';

export type AzureSyncResult = {
  newDivision: string[];
  userNoMail: string[];
  userNoOfficeLocation: string[];
  userCreate: string[];
  userUpdate: string[];
  userUnactive: string[];
};

export type EndOfMonthValidationLeave = {
  created: number;
  division: Division;
  duration: 1;
  endHalf: boolean;
  id: string;
  leaveType: LeaveType;
  period: LeavePeriod;
  startHalf: boolean;
  status: LeaveStatus;
  who: User;
  fileName?: string;
};

export type EndOfMonthVariablesPayroll = {
  id: string;
  value: string;
  editable: boolean;
};

export type PayslipStatus = 'TODO' | 'DONE';

export type EndOfMonthValidationData = {
  comment: string;
  employee: string;
  employeeObject: User;
  leaves: EndOfMonthValidationLeave[];
  expenseReports?: ExpenseReportObject[];
  variablesPayroll: EndOfMonthVariablesPayroll[];
  endOfMonthId: string;
  valid: boolean;
  payslipStatus?: PayslipStatus;
};

export type EndOfMonthValidation = {
  companyId: string;
  created: number;
  divisionId: string;
  id: string;
  monthConcerned: string;
  monthConcernedEnd: string;
  monthConcernedStart: string;
  updated: number;
  valid: boolean;
  data: EndOfMonthValidationData[];
};

export type FieldType =
  | 'STRING'
  | 'NUMBER'
  | 'BOOLEAN'
  | 'DATE'
  | 'DATETIME'
  | 'CALENDAR'
  | 'FILE'
  | 'USER'
  | 'SELECT'
  | 'MULTIPLESELECT';

export type ShuttleSheetTypeField = {
  required: boolean;
  editableBy: string[];
  name: string;
  type: FieldType;
  availableChoices: string[];
  value?: any;
};

export type ShuttleSheetType = {
  companyId: string;
  name: string;
  deletable: boolean;
  transmittedToAccountant: boolean;
  usableBy: string[] | null;
  fields: ShuttleSheetTypeField[];
  availableChoiceName?: string;
  id?: string;
  created?: string;
  updated?: string;
};

export type OpenpayeUser = {
  id: string;
  hfwUserId: string;
  created: number;
  updated: number;
  companyId: string;
  dossierId?: string;
  email: string;
  matricule_salarie?: string;
  openpayeId?: string;
  telephone?: string;
  contrat_en_cours: boolean;
  nom: string;
  prenom: string;
  commune_naissance?: string;
  departement?: string;
  nationalite?: string;
  date_naissance?: string;
  pays_naissance?: string;
  nbr_enfants_charge?: string;
  situation_familiale?: string;
  banque: Banque;
  adress?: Adresse;
  hfwUser: User;
  status: OpenpayeStatus;
};

export type Adresse = {
  code_distribution_etranger?: string;
  code_insee?: string;
  code_postal?: string;
  complement?: string;
  domicilie_fiscal_hors_france?: boolean;
  frontalier?: boolean;
  numero_voie?: string;
  pays?: string;
  ville?: string;
};

export type Banque = {
  code_bic: string;
  iban: string;
  virement: boolean;
};

export type OpenpayeStatus = {
  comment: string;
  status: string;
};

export type ShuttleSheetComment = {
  date: number;
  username: string;
  comment: string;
};

export type ShuttleSheetRequestStatus =
  | 'NEW'
  | 'WAITING'
  | 'INPROGRESS'
  | 'REJECTED'
  | 'VALID';

export type ShuttleSheetRequestStatusOwner =
  | 'EMPLOYEE'
  | 'MANAGER'
  | 'DIRECTION'
  | 'ADMINISTRATOR'
  | 'ACCOUNTANT';

export type ShuttleSheetRequestData = {
  field: ShuttleSheetTypeField;
  value: any;
};

export type ShuttleSheetRequest = {
  id?: string;
  comments?: ShuttleSheetComment[];
  companyId: string;
  creatorId: string;
  divisionId: string;
  created?: number;
  shuttleSheetType: ShuttleSheetType;
  status?: ShuttleSheetRequestStatus;
  statusOwner?: ShuttleSheetRequestStatusOwner;
  statusUpdatedDate?: number;
  updated?: number;
  data?: ShuttleSheetRequestData[];
};

export type ShuttleSheetRequestPayload = {
  divisionId: string;
  creatorId: string;
  comments?: ShuttleSheetComment[];
  shuttleSheetTypeId: string;
  status?: ShuttleSheetRequestStatus;
  statusOwner?: ShuttleSheetRequestStatusOwner;
  stringFields?: {
    field: ShuttleSheetTypeField;
    value: string;
  }[];
  numberFields?: {
    field: ShuttleSheetTypeField;
    value: number;
  }[];
  booleanFields?: {
    field: ShuttleSheetTypeField;
    value: boolean;
  }[];
  dateFields?: {
    field: ShuttleSheetTypeField;
    value: string; // (ex: '2022-03-10')
  }[];
  dateTimeFields?: {
    field: ShuttleSheetTypeField;
    value: string; // (ex: "2022-03-10T12:15:50")
  }[];
  calendarFields?: {
    field: ShuttleSheetTypeField;
    value: {
      label: string; // (ex: 'MONDAY')
      periods: [
        {
          start: string;
          end: string;
          valid: boolean;
        }
      ];
    }[];
  }[];
  fileFields?: {
    field: ShuttleSheetTypeField;
    value: string;
  }[];
  userFields?: {
    field: ShuttleSheetTypeField;
    value: string;
  }[];
  selectFields?: {
    field: ShuttleSheetTypeField;
    value: string;
  }[];
  multipleSelectFields?: {
    field: ShuttleSheetTypeField;
    value: string[];
  }[];
};

export type DocumentMeta = {
  title: string;
  comment: string;
};

export type DocumentPathType =
  | 'FOLDER'
  | 'PDF'
  | 'IMAGE'
  | 'DOC'
  | 'XLS'
  | 'UNKNOWN';

export type DocumentPathStorage = 'S3' | 'SAFE_SAE';

export type DocumentPath = {
  systemPath: string;
  appPath: string;
  parentId: string;
  fileSize: number;
  type: DocumentPathType;
  extension: string;
  storage: DocumentPathStorage;
};

export type DocumentPropertySpace = 'PERSONAL' | 'SHARED' | 'ACCOUNTANT';

export type DocumentProperty = {
  space: DocumentPropertySpace;
  creator: string;
  updater: string;
  personalUserId: string;
};

export type DocumentView = {
  id: string;
  created: string;
  updated: string;
  meta: DocumentMeta;
  path: DocumentPath;
  property: DocumentProperty;
  companyId: string;
};

export type ResUpdateDailyworkModule = {
  active: boolean;
  params: DailyWorkParams;
  clientId: string;
  descriptionId: string;
  id: string;
  created?: string;
  updated?: string;
};

export type CommonEntity = {
  id: string;
  created?: string;
  updated?: string;
};

export type CounterRule = 'DAY' | 'HOURS' | 'WORKING_DAY' | 'OPEN_DAY';

export type CounterType = CommonEntity & CounterTypePayload;

export type CounterTypePayload = {
  companyId: string;
  key: string;
  active: boolean;
  extractToPayslip: boolean;
  rule: CounterRule;
  useHalfDay: boolean;
  returnOnRestDay: boolean;
  update?: string;
};

export type CounterTypeResponse = {
  counterType: CounterType;
  associateType: AssociateType[];
};

export type AssociateType = {
  name: string;
  leaveTypeId: string;
  counterTypeId: string[];
};

export type CounterUser = {
  userId: string;
  companyId: string;
  counterTypeId: string;
  counter: CounterUserInfo;
};
export type CounterUserInfo = {
  date: string; // yyyy-MM-dd
  value?: number;
};

export type UpdateCounterUserPayload = {
  counterTypeId: string;
  value: number;
  date: string; // dd-MM-yyyy
};

export type CounterUserResponse = {
  userId: string;
  companyId: string;
  counterTypeId: string;
  date: string; // yyyy-MM-dd
  value: number;
  wait: number;
  consumes: number;
  solde: number;
};

export type ExtractLocation = {
  companyId: string;
  name?: PayslipLocation;
  periodImported?: PayslipLocation;
  matricule?: PayslipLocation;
  codeCompany?: PayslipLocation;
  siret?: PayslipLocation;
  grossAmount?: PayslipLocation;
  netAmount?: PayslipLocation;
  counter?: PayslipLocation[];
};

export type PayslipExtractLocation = CommonEntity & ExtractLocation;

export type PayslipExtractLocationPayload = ExtractLocation & {
  id?: string;
};

export type PayslipLocation = {
  key: string;
  counterTypeId?: string;
  location?: CkRectangle;
};

export type CkRectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ValidatePayslipPayload = {
  employees: UserPayslipRequest[];
};

export type UserPayslipRequest = {
  userId: string;
  counter: CounterStr[];
};

export type CounterStr = {
  counter: string; // counter value
  key: string;
  counterTypeId: string;
};

export type DailyWorkDetails = {
  id: string;
  date: number;
  divisionConcerned: string;
  leaves: LeaveDetails[];
  overtime: number;
  workingTimeDay: number;
  status: DailyWorkDetailsStatus;
  user: UserDetails;
  comment?: string;
};

export type LeaveDetails = {
  id?: string;
  period: PlanningPeriod;
  nbMinutes?: number;
  type?: LeaveType;
  leaveConflict?: LeaveDetails[];
  status?: LeaveStatus;
  validatorFullname?: string;
  divisionConcerned?: string;
  duration?: number;
};

export type UserDetails = {
  id: string;
  firstname: string;
  lastname: string;
  fullname: string;
  division: PlanningDivision;
  n1?: string;
  n2?: string;
  divisionN1?: string;
  divisionN2?: string;
  divisionAssistant?: string;
  fixedPriceManager: boolean;
  paidFeatures: PaidFeature[];
};

export type DailyWorkRequest = {
  id?: null | string;
  date?: string; // DD/MM/YYYY
  presence?: string; //"08:30-12:30,13:30-14:30,15:00-18:00"
  overtime?: number;
  comment?: string;
  status: DailyWorkDetailsStatus;
  divisionConcerned: string;
};

export type DailyWorkDetailsStatus = 'WAITING' | 'VALID' | string;

export type UserDailyWork = {
  userId: string;
  status: string;
  employeeName: string;
  dailyWorks: DailyWorkDetails[];
};
