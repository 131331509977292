import {
  AddIdentifiantCompanyOpenPaye,
  OpenpayeUser,
  PayloadAddMailProps,
} from '../types/types';
import { MS_ENDMONTH_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

export type EndOfMonthValidation = {
  monthConcerned: string; // ex : 01/01/2020
  divisionIds: string[];
};

function getEndOfMonthValidation(
  companyId: string,
  data: EndOfMonthValidation
) {
  return request
    .post(`${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/history`, data)
    .then((res) => res?.data);
}

export type EndOfMonthValidateLeave = {
  endOfMonthId: string;
  employeeId: string;
  leaveId: string;
  approved: boolean;
};

function validateUserLeaveRequest(
  companyId: string,
  data: EndOfMonthValidateLeave
) {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/validate-employee-leave-of-month`,
      data
    )
    .then((res) => res?.data);
}

export type EndOfMonthEmployeeCommentPayload = {
  endOfMonthId: string;
  employeeId: string;
  comment: string | null;
};

function updateEmployeeComment(
  companyId: string,
  data: EndOfMonthEmployeeCommentPayload
) {
  return request
    .patch(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/employee/comment`,
      data
    )
    .then((res) => res?.data);
}

export type ValidateEmployeesEndOfMonthPayload = {
  endOfMonthId: string;
  employeesIds: string[];
};

function validateEmployeesEndOfMonth(
  companyId: string,
  data: ValidateEmployeesEndOfMonthPayload
) {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/validate-list-employees-end-of-month`,
      data
    )
    .then((res) => res?.data);
}

function getEndOfMonthExport(
  companyId: string,
  type: string,
  data: EndOfMonthValidation
) {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/export/${companyId}/type/${type}`,
      data,
      {
        responseType: 'blob',
      }
    )
    .then((res) => res?.data);
}

function validateDivisionEndOfMonth(companyId: string, endOfMonthId: string) {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/validate-division-end-of-month/${endOfMonthId}`
    )
    .then((res) => res?.data);
}

function synchroEmployee(companyId: string) {
  return request
    .patch(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/employee`
    )
    .then((res) => res?.data);
}

function getSynchroOpenpaye(companyId: string): Promise<OpenpayeUser[]> {
  return request
    .get(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/employee`
    )
    .then((res) => res?.data);
}

function addMail(companyId: string, payload: PayloadAddMailProps) {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/employee/add-mail`,
      payload
    )
    .then((res) => res?.data);
}

function addIdentifiantsCompany(
  companyId: string,
  payload: AddIdentifiantCompanyOpenPaye
) {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/employee/identifiants-company`,
      payload
    )
    .then((res) => res?.data);
}

function synchroVariablePayroll(companyId: string) {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/variable-payroll`
    )
    .then((res) => res?.data);
}

const EndOfMonthService = {
  getEndOfMonthValidation,
  validateUserLeaveRequest,
  updateEmployeeComment,
  validateEmployeesEndOfMonth,
  getEndOfMonthExport,
  validateDivisionEndOfMonth,
  synchroEmployee,
  getSynchroOpenpaye,
  addMail,
  addIdentifiantsCompany,
  synchroVariablePayroll,
};

export default EndOfMonthService;
