import { ActionIcon, Badge } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { IconBuilding, IconCopy, IconUser } from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { AccountantUser } from '../../../../types/types';

type Props = {
  payrollOfficer: AccountantUser;
  isInvitationList?: boolean;
};

export default function PayrollOfficerBadge({
  payrollOfficer,
  isInvitationList = false,
}: Props) {
  const { t } = useI18n();
  const clipboard = useClipboard({ timeout: 2000 });

  return (
    <CustomTooltip label={payrollOfficer.mail}>
      <Badge
        size={'lg'}
        leftSection={
          isInvitationList ? (
            <CustomTooltip label={payrollOfficer.companyName} position={'left'}>
              <IconBuilding size={15} />
            </CustomTooltip>
          ) : (
            <IconUser size={15} />
          )
        }
        rightSection={
          <CustomTooltip
            label={
              clipboard.copied ? `${t('w.emailCopied')} !` : t('w.copyEmail')
            }
            position={'right'}
          >
            <ActionIcon
              variant={'subtle'}
              color="green"
              onClick={() => clipboard.copy(payrollOfficer.mail)}
            >
              <IconCopy size={15} />
            </ActionIcon>
          </CustomTooltip>
        }
        styles={{ label: { textTransform: 'none' } }}
        variant="outline"
      >
        {isInvitationList
          ? payrollOfficer.fullname
          : `${payrollOfficer.lastname} ${payrollOfficer.firstname}`}
      </Badge>
    </CustomTooltip>
  );
}
