import { Button, Tabs } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCirclePlus, IconInfoCircle, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import InvitationsHistoryPayrollOfficerTable from '../../../components/InvitationsHistoryPayrollOfficerTable';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CharteredAccountantService from '../../../services/CharteredAccountantService';
import PaymentAccountService from '../../../services/PaymentAccountService';
import { hasPermission } from '../../../utils/authorization';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { ALL, ConfigurePayment } from '../../../variables/BuiltInPermissions';
import AccountantCustomersTable from './component/AccountantCustomersTable';
import AddCustomerCompanyForm from './component/AddCustomerCompanyForm';

export default function AccountantCustomerManagement() {
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState<string | null>('customers');
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const {
    data: invitationAccountantHistoryData,
    isLoading: isInvitationAccountantHistoryLoading,
    refetch: refetchInvitationAccountantHistoryData,
    error,
  } = useQuery({
    queryKey: [
      'CharteredAccountantService.invitationAccountantHistory',
      companyId,
    ],
    queryFn: () =>
      CharteredAccountantService.invitationAccountantHistory(companyId),
    onError: (err: any) => {
      if (err.response.status !== 403) {
        showNotification({
          id: 'invitation-accountant-history-error',
          title: t('w.error'),
          message: handleErrorMessage(err, t),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  const { data: companyPaymentAccount, error: companyPaymentAccountError } =
    useQuery({
      queryKey: ['PaymentAccountService.getCompanyPaymentAccount', companyId],
      queryFn: () => PaymentAccountService.getCompanyPaymentAccount(companyId),
    });

  function getLabelError(errorDescription: string): string | undefined {
    switch (errorDescription) {
      case `Payment account ${companyId} has no payment account`:
        return t('w.yourCompanyBillingAccountIsNotConfigured');
      case `Company ${companyId} has active payment account but company not active`:
        return t(
          'w.yourCompanyBillingAccountIsActivatedButYourCompanyIsDeactivated'
        );
      default:
        return t('w.yourCompanyBillingAccountIsNotActivated');
    }
  }

  const actionButtons = useMemo(() => {
    const isActive =
      Boolean(companyPaymentAccount?.active) &&
      Boolean(
        companyPaymentAccount?.companies?.find(
          (paymentCompany) =>
            paymentCompany.id === companyId && paymentCompany.active
        )
      );

    return [
      <CustomTooltip
        key={'add-customer-company'}
        label={getLabelError(
          // @ts-ignore
          companyPaymentAccountError?.response?.data?.errorDescription
        )}
        position={'left'}
      >
        <span>
          <Button
            leftSection={isActive ? <IconCirclePlus /> : <IconInfoCircle />}
            onClick={() => {
              modals.open({
                modalId: 'add-customer-company',
                fullScreen: true,
                withCloseButton: true,
                children: <AddCustomerCompanyForm linkAsAccountant={true} />,
              });
            }}
            disabled={!isActive}
          >
            {`${t('w.add')} ${t('w.customers').slice(0, -1).toLowerCase()}`}
          </Button>
        </span>
      </CustomTooltip>,
    ];
  }, [companyPaymentAccount]);

  const tabs = useMemo(() => {
    // @ts-ignore
    if (error?.response.status === 403) {
      return [
        {
          value: 'customers',
          label: t('w.customers'),
          component: <AccountantCustomersTable />,
        },
      ];
    }
    return [
      {
        value: 'customers',
        label: t('w.customers'),
        component: <AccountantCustomersTable />,
      },
      {
        value: 'history',
        label: t('w.history'),
        component: (
          <InvitationsHistoryPayrollOfficerTable
            invitationAccountantHistoryData={invitationAccountantHistoryData}
            isInvitationAccountantHistoryLoading={
              isInvitationAccountantHistoryLoading
            }
            refetchInvitationAccountantHistoryData={
              refetchInvitationAccountantHistoryData
            }
          />
        ),
      },
    ];
  }, [error, isInvitationAccountantHistoryLoading]);

  function hasPermissionToAddCustomerCompany(): boolean {
    return hasPermission({ permission: ConfigurePayment, scope: ALL }, user);
  }

  return (
    <ScreenWrapper
      title={t('w.firmClients')}
      actionButtons={hasPermissionToAddCustomerCompany() ? actionButtons : []}
    >
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Tab key={`tab-${tab.value}-${index}`} value={tab.value}>
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {tabs.map((tab, index) => (
          <Tabs.Panel
            pt={'md'}
            key={`panel-${tab.value}-${index}`}
            value={tab.value}
          >
            {tab.component}
          </Tabs.Panel>
        ))}
      </Tabs>
    </ScreenWrapper>
  );
}
