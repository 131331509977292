import type { FileWithPath } from '@mantine/dropzone';
import dayjs, { type Dayjs } from 'dayjs';
import qs from 'qs';

import type {
  DailyWorkDetails,
  DailyWorkDetailsStatus,
  DailyWorkRequest,
  Period,
  PeriodTemplate,
  UserDailyWork,
  WeeklyPeriod,
} from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getDailyTemplates(userId: string) {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/dailyTemplates/user/${userId}`)
    .then((res) => res?.data);
}

export type DailyTemplateToUpdate = {
  name: string;
  period: PeriodTemplate[];
  shared?: boolean;
};

function updateDailyTemplate(
  companyId: string,
  id: string,
  template: DailyTemplateToUpdate
) {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/dailyTemplate/${companyId}/${id}`,
      template
    )
    .then((res) => res?.data);
}

function createDailyTemplate(
  companyId: string,
  template: DailyTemplateToUpdate
) {
  return request
    .post(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/dailyTemplate/${companyId}`,
      template
    )
    .then((res) => res?.data);
}

function deleteDailyTemplate(id: string) {
  return request
    .delete(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/dailyTemplate/${id}`)
    .then((res) => res?.data);
}

function getWeeklyTemplates(userId: string) {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/weeklyTemplates/user/${userId}`)
    .then((res) => res?.data);
}

export type WeeklyTemplateToUpdate = {
  name: string;
  weeklyPeriods: WeeklyPeriod[];
  shared?: boolean;
};

function updateWeeklyTemplate(
  companyId: string,
  id: string,
  template: WeeklyTemplateToUpdate
) {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/weeklyTemplate/${companyId}/${id}`,
      template
    )
    .then((res) => res?.data);
}

function createWeeklyTemplate(
  companyId: string,
  template: WeeklyTemplateToUpdate
) {
  return request
    .post(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/weeklyTemplate/${companyId}`,
      template
    )
    .then((res) => res?.data);
}

function deleteWeeklyTemplate(id: string) {
  return request
    .delete(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/weeklyTemplate/${id}`)
    .then((res) => res?.data);
}

export type ModuleDailyworkUsersUpdate = {
  ids: string[];
};

function activateUsers(companyId: string, data: ModuleDailyworkUsersUpdate) {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/module/${companyId}/activate-users`,
      data
    )
    .then((res) => res?.data);
}

function deactivateUsers(companyId: string, data: ModuleDailyworkUsersUpdate) {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/module/${companyId}/disable-users`,
      data
    )
    .then((res) => res?.data);
}

function getUserDailyWork(
  userId: string,
  month: number,
  year: number
): Promise<DailyWorkDetails[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/user/${userId}`, {
      params: { month, year },
    })
    .then((res) => res?.data);
}

function updateUserDailyWork(
  userId: string,
  data: DailyWorkRequest
): Promise<DailyWorkDetails> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/user/${userId}`,
      {
        ...data,
        presence: data.presence?.trim(),
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((res) => res?.data);
}

function getDailyWorks(
  divisionIds: string[] | undefined,
  month: Dayjs,
  period: Period | undefined
): Promise<UserDailyWork[]> {
  const divisionIdsInQuery = divisionIds!
    .filter((divisionId) => divisionId !== 'all')
    .join('&divisionId=');

  return request
    .get(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/divisionsId?divisionId=${divisionIdsInQuery}&month=${
        month.month() + 1
      }&year=${month.year()}`
    )
    .then((res) => {
      const theDaylies = res?.data.map((daylies: DailyWorkDetails[]) => {
        let selectedDaylies = [...daylies];
        if (period !== undefined) {
          selectedDaylies = daylies.filter(
            (dailyWork: DailyWorkDetails) =>
              dayjs(dailyWork.date).isSameOrAfter(period?.start) &&
              dayjs(dailyWork.date).isSameOrBefore(period?.end)
          );
        }
        return {
          userId: daylies[0].user.id,
          employeeName: `${daylies[0].user.firstname} ${daylies[0].user.lastname}`,
          status:
            daylies.find((dailyWork) => dailyWork.status === 'WAITING') !==
            undefined
              ? 'WAITING'
              : 'VALID',
          dailyWorks: selectedDaylies,
        };
      });
      return theDaylies;
    });
}

function getDailyWorksInPeriod(
  divisionIds: string[] | undefined,
  period: Period
): Promise<UserDailyWork[]> {
  const monthsOfPeriod: Map<Dayjs, Period> = new Map();
  for (
    let month: Dayjs = period.start.startOf('month');
    month.isSameOrBefore(period.end.startOf('month'));
    month = month.add(1, 'month')
  ) {
    monthsOfPeriod.set(month, {
      start: month.isBefore(period.start) ? period.start : month,
      end: month.endOf('month').isAfter(period.end)
        ? period.end
        : month.endOf('month'),
    });
  }

  const promises: Promise<UserDailyWork[]>[] = [];
  monthsOfPeriod.forEach((period, month) => {
    promises.push(getDailyWorks(divisionIds, month, period));
  });

  return Promise.all(promises).then((daylies) => {
    return daylies.reduce((wholeDaylies, monthDaylies) => {
      monthDaylies.forEach((monthDaily) => {
        const matchingDaily = wholeDaylies.find(
          (userDaily) => userDaily.userId === monthDaily.userId
        );

        if (matchingDaily) {
          matchingDaily!.dailyWorks = matchingDaily!.dailyWorks.concat(
            monthDaily.dailyWorks
          );
          matchingDaily.status =
            matchingDaily.status === 'WAITING'
              ? matchingDaily.status
              : monthDaily.status;
        } else {
          wholeDaylies.push(monthDaily);
        }
      });
      return wholeDaylies;
    }, []);
  });
}

function exportUserDailyWork(userId: string, month: number, year: number) {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/user/${userId}/export`, {
      responseType: 'blob',
      params: { month, year },
    })
    .then((res) => res?.data);
}

function importDailyWork(file: FileWithPath) {
  const formData = new FormData();
  formData.append('file', file);
  return request
    .patch(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res?.data);
}

function exportUserDailyWorkByDivisions(
  divisionIds: string[],
  month: number,
  year: number
) {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/divisionsId/export`, {
      responseType: 'blob',
      params: { month, year, divisionId: divisionIds },
      paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((res) => res?.data);
}

const DailyWorkService = {
  getDailyTemplates,
  updateDailyTemplate,
  createDailyTemplate,
  deleteDailyTemplate,
  getWeeklyTemplates,
  updateWeeklyTemplate,
  createWeeklyTemplate,
  deleteWeeklyTemplate,
  activateUsers,
  deactivateUsers,
  getUserDailyWork,
  updateUserDailyWork,
  getDailyWorksInPeriod,
  exportUserDailyWork,
  importDailyWork,
  exportUserDailyWorkByDivisions,
};

export default DailyWorkService;

const UserPresenceHelper = {
  toUserPresence: (
    dailyWork: DailyWorkDetails,
    newStatus?: DailyWorkDetailsStatus
  ) => {
    return {
      id: dailyWork.id,
      date: dayjs(dailyWork.date).format('DD/MM/YYYY'),
      presence: dailyWork.leaves
        .map(
          (leave) =>
            `${dayjs(leave.period.start).utc(false).format('HH:mm')}-${dayjs(
              leave.period.end
            )
              .utc(false)
              .format('HH:mm')}`
        )
        .join(','),
      overtime: dailyWork.overtime,
      comment: dailyWork.comment,
      status: newStatus ? newStatus : dailyWork.status,
      divisionConcerned: dailyWork.divisionConcerned
        ? dailyWork.divisionConcerned
        : dailyWork.user.division.id,
    };
  },
};

export { UserPresenceHelper };
