import type {
  CounterUser,
  CounterUserResponse,
  UpdateCounterUserPayload,
} from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getByUser(userId: string): Promise<CounterUserResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/counter/user/${userId}`)
    .then((res) => res?.data);
}

function update(
  userId: string,
  params: UpdateCounterUserPayload
): Promise<CounterUser> {
  return request
    .patch(`${MS_PLANNING_ENDPOINT}/v2/counter/user/${userId}`, {}, { params })
    .then((res) => res?.data);
}

function exportUsersCounters(companyId: string) {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/counter/user/${companyId}/export/XLSX`, {
      responseType: 'blob',
    })
    .then((res) => res?.data);
}

const CounterUserService = {
  getByUser,
  update,
  exportUsersCounters,
};

export default CounterUserService;
