import { SimpleGrid } from '@mantine/core';
import { IconBeach, IconUsers } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import DivisionService from '../../../../services/DivisionService';
import type { Company, Division } from '../../../../types/types';
import CompanyInfosWrapper, {
  CompanyInfosWrapperProps,
} from './CompanyInfosWrapper';
import ValidatorInfosWrapper, {
  ValidatorInfosWrapperProps,
} from './ValidatorInfosWrapper';
import classes from './WorkUnitsInfos.module.sass';

type Props = {
  selectedDivision: Division | null;
  company?: Company;
};

export default function WorkUnitsDashboard({
  selectedDivision,
  company,
}: Props) {
  const { t } = useI18n();

  const { data: divisionData, refetch } = useQuery({
    enabled: false,
    queryKey: ['DivisionService.getDivisionById', selectedDivision?.id],
    queryFn: () =>
      DivisionService.getDivisionById(
        selectedDivision?.id ? selectedDivision.id : ''
      ),
  });

  const division: Division | null = useMemo(() => {
    if (!divisionData) {
      return selectedDivision;
    }
    return divisionData;
  }, [divisionData]);

  useEffect(() => {
    if (selectedDivision) {
      refetch();
    }
  }, [selectedDivision]);

  const validators: ValidatorInfosWrapperProps[] = useMemo(() => {
    const result = [
      {
        validatorType: t('w.n1Validator'),
        userId: selectedDivision?.idOfN1 ? selectedDivision.idOfN1 : '',
        userFullName: selectedDivision?.nameOfN1
          ? selectedDivision.nameOfN1
          : t('w.none'),
      },
    ];

    if (company?.useN2) {
      result.push({
        validatorType: t('w.n2Validator'),
        userId: selectedDivision?.idOfN2 ? selectedDivision.idOfN2 : '',
        userFullName: selectedDivision?.nameOfN2
          ? selectedDivision.nameOfN2
          : t('w.none'),
      });
    }
    if (company?.useAssistant) {
      result.push({
        validatorType: t('w.assistant'),
        userId: selectedDivision?.idOfAssistant
          ? selectedDivision.idOfAssistant
          : '',
        userFullName: selectedDivision?.nameOfAssistant
          ? selectedDivision.nameOfAssistant
          : t('w.none'),
      });
    }

    return result;
  }, [selectedDivision, company]);

  const divisionOthersInfo: CompanyInfosWrapperProps[] = useMemo(
    () => [
      {
        title: t('w.employees'),
        icon: <IconUsers className={classes.icon} />,
        value: division?.nbUsers ? division.nbUsers : 0,
      },
      {
        title: t('w.numberLeavesOnDay'),
        icon: <IconBeach className={classes.icon} />,
        value: division?.numberLeavesOnDay ? division.numberLeavesOnDay : 0,
      },
      {
        title: t('w.numberLeavesOnWeek'),
        icon: <IconBeach className={classes.icon} />,
        value: division?.numberLeavesOnWeek ? division.numberLeavesOnWeek : 0,
      },
    ],
    [division]
  );

  return (
    <SimpleGrid cols={{ base: 1, sm: 3, lg: 6 }}>
      {validators.map((item) => (
        <ValidatorInfosWrapper
          key={item.validatorType}
          userId={item.userId}
          validatorType={item.validatorType}
          userFullName={item.userFullName}
        />
      ))}
      {divisionOthersInfo.map((item) => (
        <CompanyInfosWrapper
          key={item.title}
          icon={item.icon}
          title={item.title}
          value={item.value}
        />
      ))}
    </SimpleGrid>
  );
}
