import { Badge } from '@mantine/core';
import dayjs, { Dayjs } from 'dayjs';
import { cloneDeep } from 'lodash';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import type {
  DailyWorkDetails,
  LeaveDetails,
  LeaveStatusState,
  LeaveType,
} from '../../types/types';
import CustomTooltip from '../CustomTooltip';
import css from './HorizontalMonthTimeline.module.sass';

export declare type LeaveEvent = {
  id: string;
  startDate: Dayjs;
  endDate: Dayjs;
  bgColor: string;
  isHourly: boolean;
  leaveType: LeaveType;
  isEditable: boolean;
  division: string;
  status?: LeaveStatusState;
};

export declare type DayEventsCellProps = {
  dailyWork: DailyWorkDetails;
  day: Dayjs;
};

const isAContinuousPeriod = (events: LeaveEvent[]) => {
  return events[0].endDate.diff(events[0].startDate, 'day') + 1 > 1;
};

export default function DayEventsCell({ dailyWork, day }: DayEventsCellProps) {
  const { t } = useI18n();
  const events: LeaveEvent[] = dailyWork.leaves.map((leave: LeaveDetails) => {
    const leaveType = cloneDeep(leave).type as LeaveType;

    return {
      dailyWorkId: dailyWork.id,
      division: dailyWork.divisionConcerned
        ? dailyWork.divisionConcerned
        : dailyWork.user.division.id,
      bgColor:
        leave.status?.state === 'REJECTED'
          ? 'transparent'
          : leave.status?.state === 'WAITING'
          ? '#ADB5BD'
          : leaveType?.color
          ? leaveType.color
          : '#8ce99a',
      endDate: dayjs(leave.period.end),
      id: leave.id as string,
      isHourly:
        leave.nbMinutes !== undefined && !dailyWork.user.fixedPriceManager,
      isEditable: leave.status?.state === 'WAITING',
      startDate: dayjs(leave.period.start),
      leaveType,
      status: leave.status?.state,
    };
  });

  if (events.length === 0) {
    return <div className={css['timeline-content-row-cell-empty']}></div>;
  }

  const cellStyle = {
    backgroundColor: events[0].bgColor,
    '--timeline-event-duration':
      events[0].endDate.diff(events[0].startDate, 'day') + 1,
  };

  const dailyEventsDescription = events.map((event, index) => (
    <div key={`${event.startDate.get('date')}-${index}`}>
      {`${event.leaveType.name} ${
        event.status ? `[${t(`leave.status.state.${event.status}`)}] ` : ''
      }: `}
      {event.isHourly
        ? `${event.startDate.utc(false).format('HH:mm')} - ${event.endDate
            .utc(false)
            .format('HH:mm')}`
        : event.startDate.utc(false).format('LL')}
    </div>
  ));

  const periodEventDescription = [
    <div>
      {`${events[0].leaveType.name} ${
        events[0].status
          ? `[${t(`leave.status.state.${events[0].status}`)}] `
          : ''
      }: 
      ${t('Du')} ${events[0].startDate.utc(false).format('LL')} 
      ${t('au')} ${events[0].endDate.utc(false).format('LL')}`}
    </div>,
  ];

  const tooltipContent = (
    <div>
      {isAContinuousPeriod(events)
        ? periodEventDescription
        : dailyEventsDescription}
    </div>
  );
  return (
    <>
      <CustomTooltip label={tooltipContent} position="bottom">
        <div
          className={css['timeline-content-row-cell-scheduled']}
          style={cellStyle}
        >
          {events.length > 1 && !isAContinuousPeriod(events) && (
            <Badge circle autoContrast size="md" style={{ cursor: 'default' }}>
              {events.length - 1}+
            </Badge>
          )}
        </div>
      </CustomTooltip>
    </>
  );
}
