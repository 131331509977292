import {
  ActionIcon,
  Box,
  Flex,
  Group,
  SegmentedControl,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../components/Logo';
import { useAppBar } from '../../contexts/AppBarProvider';
import { useModule } from '../../contexts/ModuleProvider';
import { ACCOUNTANT_MODE, COMPANY_MODE } from '../../types/types';
import { getPersistedObject, persistData } from '../../utils/localStorage';
import { CharteredAccountant } from '../../variables/ModulesV2';
import AppBarChipGroupDivisions from './components/AppBarChipGroupDivisions';
import ContactSupport from './components/ContactSupport';
import TutorialsButton from './components/TutorialsButton';
import UserAppNotifications from './components/user-app-notifications/UserAppNotifications';

export default function Appbar() {
  const { getModule } = useModule();
  const {
    isAppBarUsed,
    appBarSelectedDivisions,
    setAppBarSelectedDivisions,
    divisionsToSelect,
  } = useAppBar();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const [value, setValue] = useState('');
  const isAccountantActiveModule = getModule(CharteredAccountant)?.active;

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  const data = useMemo(
    () => [
      { label: 'Mon entreprise', value: COMPANY_MODE },
      { label: 'Mode gestionnaire', value: ACCOUNTANT_MODE },
    ],
    []
  );

  useEffect(() => {
    if (getPersistedObject('mode')) {
      setValue(getPersistedObject('mode'));
    }
  }, [getPersistedObject('mode')]);

  useEffect(() => {
    if (
      pathname.includes('accept/link') ||
      pathname.includes('chartered-accountant/accept/customer/invitation')
    ) {
      setValue(ACCOUNTANT_MODE);
      persistData('mode', ACCOUNTANT_MODE);
    }
  }, [pathname]);

  function onModeChange(
    newMode: typeof COMPANY_MODE | typeof ACCOUNTANT_MODE | string
  ) {
    setValue(newMode);
    persistData('mode', newMode);
    if (newMode === ACCOUNTANT_MODE) {
      navigate('/chartered-accountant/market-place');
    } else {
      navigate('/home');
    }
  }

  return (
    <Box style={{ width: '100%' }} px={'lg'}>
      <Flex justify={'space-between'} align={'center'}>
        <Group gap={'80px'}>
          <Logo />
          {isAppBarUsed && (
            <AppBarChipGroupDivisions
              value={appBarSelectedDivisions}
              divisions={divisionsToSelect}
              onChange={setAppBarSelectedDivisions}
            />
          )}
        </Group>
        <Group gap="xs">
          {isAccountantActiveModule && (
            <SegmentedControl
              bg={'#ECEBF8'}
              value={value}
              onChange={onModeChange}
              data={data}
            />
          )}
          <ContactSupport />
          <TutorialsButton />
          <UserAppNotifications />
          <ActionIcon
            variant="default"
            onClick={() => toggleColorScheme()}
            size={30}
          >
            {colorScheme === 'dark' ? (
              <IconSun size={16} color={theme.colors.yellow[4]} />
            ) : (
              <IconMoonStars size={16} color={theme.colors.hifivework[5]} />
            )}
          </ActionIcon>
        </Group>
      </Flex>
    </Box>
  );
}
