import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCirclePlus, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import { Accountants, Company, Division } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import AccountantBadge from './AccountantBadge';
import AddAccountantToCustomerDivisionModal from './AddAccountantToCustomerDivisionModal';

type Props = {
  accountantsToConfigure: Accountants | null;
  accountantEmployees: { value: string; label: string }[];
  refetchAccountantCustomersData: () => void;
};

const componentName = 'AccountantDivisionsCustomerCompanyTable';
export default function AccountantDivisionsCustomerCompanyTable({
  accountantsToConfigure,
  accountantEmployees,
  refetchAccountantCustomersData,
}: Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [
    addAccountantToCustomerDivisionModalOpened,
    {
      open: handleAddAccountantToCustomerDivisionModalOpen,
      close: handleAddAccountantToCustomerDivisionModalClose,
    },
  ] = useDisclosure(false);
  const [customerDivisionCompany, setCustomerDivisionCompany] =
    useState<Company | null>(null);

  const {
    divisions: customerDivisionsData,
    isLoading: isCustomerDivisionsDataLoading,
    refetch: refetchCustomerDivisionsData,
  } = useFetchDivisions({
    companyId: accountantsToConfigure?.clientCompanyId as string,
  });

  const customerDivisions = useMemo(() => {
    if (!customerDivisionsData) {
      return [];
    } else {
      return customerDivisionsData.filter(
        (division: Division) => division.name !== NO_WORK_UNIT
      );
    }
  }, [customerDivisionsData]);

  const { mutate: unAssignAccountantToCustomerCompany } = useMutation({
    mutationFn: (variables: {
      userAccountantId: string;
      divisionId: string | null;
      generalManger: boolean;
    }) =>
      CharteredAccountantService.unAssignAccountantToCustomerCompany(
        accountantsToConfigure?.clientCompanyId,
        companyId,
        variables.userAccountantId,
        variables.divisionId,
        variables.generalManger
      ),
    onSuccess: (data) => {
      if (data) {
        showNotification({
          id: 'unassign-accountant-to-customer-division-company',
          title: t('w.success'),
          message: t('w.payrollOfficerHasBeenReassignedFromDivision'),
          color: 'green',
          icon: <IconCheck />,
        });
        refetchCustomerDivisionsData();
        refetchAccountantCustomersData();
      }
    },
    onError: (error) =>
      showNotification({
        id: 'unassign-accountant-to-customer-company-division-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function renderDivisionAccountantBadge(id: string) {
    const findDivisionAccountant =
      accountantsToConfigure?.divisionAccountant.find((item) => item.id === id);
    if (findDivisionAccountant) {
      return (
        <Group>
          {findDivisionAccountant.accountantUser.map((item) => (
            <AccountantBadge
              key={`${id}-${item.id}`}
              onClick={() =>
                unAssignAccountantToCustomerCompany({
                  userAccountantId: item.id,
                  divisionId: id,
                  generalManger: false,
                })
              }
              size={'lg'}
              userId={item.id}
            >{`${item.firstname} ${item.lastname}`}</AccountantBadge>
          ))}
        </Group>
      );
    }
  }

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'name',
        title: accountantsToConfigure?.customerCompany?.divisionLabel
          ? accountantsToConfigure.customerCompany.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'payroll',
        title: t('w.payrollOfficer'),
        sortable: true,
        ellipsis: true,
        render: ({ id }) => renderDivisionAccountantBadge(id),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 100,
        render: (row) => (
          <Group justify="end" wrap={'nowrap'}>
            <CustomTooltip
              label={`${t('w.add')} ${t('w.payrollOfficer').toLowerCase()}`}
              withinPortal
            >
              <ActionIcon
                onClick={() => {
                  handleAddAccountantToCustomerDivisionModalOpen();
                  setCustomerDivisionCompany(row);
                }}
                size="sm"
                color="blue"
                variant={'subtle'}
              >
                <IconCirclePlus size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    [customerDivisions, accountantsToConfigure]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <>
      <Table
        pinFirstColumn
        pinLastColumn
        options={options}
        columns={columns}
        rows={customerDivisions}
        fetching={isCustomerDivisionsDataLoading}
        lang={lang}
        height={'calc(100vh - 240px)'}
      />
      <AddAccountantToCustomerDivisionModal
        opened={addAccountantToCustomerDivisionModalOpened}
        onClose={handleAddAccountantToCustomerDivisionModalClose}
        accountantEmployees={accountantEmployees}
        customerDivisionCompany={customerDivisionCompany}
        refetchCustomerDivisionsData={refetchCustomerDivisionsData}
        accountantsToConfigure={accountantsToConfigure}
        refetchAccountantCustomersData={refetchAccountantCustomersData}
      />
    </>
  );
}
