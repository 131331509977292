import {
  Button,
  Center,
  Container,
  Flex,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Space,
  Stack,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import React, { useEffect, useState } from 'react';

import Logo from '../../components/Logo';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useQueryString } from '../../hooks/useQueryString';
import OauthServiceMS from '../../services/OauthServiceMS';
import { validatePassword } from '../../utils/validators';
import { PasswordStrength } from './components/PasswordStrength';

export default function ResetPassword() {
  const { logout } = useAuth();
  const { t } = useI18n();
  const queryString = useQueryString();
  const [tokenPath, setTokenPath] = useState<string | null>(null);
  const [_user, _setUser] = useState<any>(null);
  const form = useForm({
    initialValues: {
      firstPassword: '',
      secondPassword: '',
    },
    validate: {
      firstPassword: (value) =>
        validatePassword(value)
          ? null
          : t('w.yourPasswordMustContainAtLeast8characters'),
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (variables: {
      password: string;
      username?: string;
      temporary_token?: string | null;
    }) =>
      OauthServiceMS.resetPassword(
        variables.password,
        variables.username,
        variables.temporary_token
      ),
    onSuccess: () => {
      showNotification({
        id: 'passwordConfirmation',
        title: t('w.success'),
        message: t('w.passwordConfirmation'),
        color: 'green',
        icon: <IconCheck />,
      });
      setTimeout(() => logout(), 2000);
    },
    onError: (error: any) => {
      if (error?.response?.status === 400) {
        if (error?.response?.data?.error_key === 'password_invalid_format') {
          showNotification({
            id: 'password_invalid_format',
            title: t('w.error'),
            message: t(
              'w.passwordMustContainAtLeast8CharactersIncludingUupperCaseLowerCaseNumbersSpecialCharacters'
            ),
            color: 'red',
            icon: <IconX />,
          });
        }
        if (error?.response?.data?.error_key === 'temporary_token_invalid') {
          showNotification({
            id: 'temporary_token_invalid',
            title: t('w.error'),
            message: t('w.resetPasswordTokenExpired'),
            color: 'red',
            icon: <IconX />,
          });
        }
      }
      if (error?.status === 401) {
        showNotification({
          id: 'access_token_no_longer_valid',
          title: t('w.error'),
          message: t('w.accessTokenIsNoLongerValid'),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  useEffect(() => {
    setTokenPath(queryString.get('token'));
  }, [queryString]);

  useEffect(() => {
    if (tokenPath !== null) {
      _setUser(jwtDecode<JwtPayload>(tokenPath));
    }
  }, [tokenPath]);

  function onResetPasswordSubmit(
    values: ReturnType<
      (values: { secondPassword: string; firstPassword: string }) => {
        secondPassword: string;
        firstPassword: string;
      }
    >
  ) {
    if (values.firstPassword !== values.secondPassword) {
      showNotification({
        id: 'differentPasswords',
        title: t('w.warning'),
        message: t('w.passwordsMustBeIdentical'),
        color: 'orange',
        icon: <IconAlertTriangle />,
      });
    } else {
      mutate({
        password: values.firstPassword,
        username: _user.username,
        temporary_token: tokenPath,
      });
    }
  }

  return (
    <Container
      display={'grid'}
      size={'xs'}
      style={{ height: '100%', alignContent: 'center' }}
    >
      <Center>
        <Logo />
      </Center>
      <Space h="xl" />
      <Paper radius="md" p="xl" withBorder shadow={'md'} mx={'xl'}>
        <LoadingOverlay visible={isLoading} />
        <Stack>
          <Center>
            <Title order={3} size={'h3'} c={'hifivework.9'}>
              {t('w.resetYourPassword')}
            </Title>
          </Center>
        </Stack>
        <Space h="sm" />
        <form
          onSubmit={form.onSubmit((values) => onResetPasswordSubmit(values))}
        >
          <Stack>
            <PasswordInput
              id={'firstPassword'}
              required
              label={t('w.newPassword')}
              placeholder={t('w.yourNewPassword')}
              {...form.getInputProps('firstPassword')}
            />
            <PasswordStrength
              label={t('w.confirmNewPassword')}
              placeholder={t('w.yourNewPassword')}
              value={form.values.secondPassword}
              onChange={(event) =>
                form.setFieldValue('secondPassword', event.currentTarget.value)
              }
            />
            <Flex justify={'flex-end'} gap={'md'}>
              <Button onClick={() => logout()} variant={'outline'}>
                {t('w.backToApp')}
              </Button>
              <Button type={'submit'}>{t('w.validate')}</Button>
            </Flex>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}
