import './CustomModuleCardItem.module.sass';

import { Button, Card, Flex, Group, Switch, Text } from '@mantine/core';
import {
  IconExternalLink,
  IconInfoCircle,
  IconSearch,
  IconSettings,
  IconSquareRoundedCheck,
} from '@tabler/icons-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useI18n } from '../../contexts/I18nProvider';
import timeRecorderImage from '../../logos/features/time_recorder.png';
import {
  ACCOUNTANT_MODE,
  COMPANY_MODE,
  Module,
  ViewModule,
} from '../../types/types';
import { persistData, removePersistedObject } from '../../utils/localStorage';
import s from './CustomModuleCardItem.module.sass';

type Props = {
  module: Module;
  onActiveChangeModule: (event: any, moduleId: string) => void;
  setModuleToEdit: (module: Module) => void;
  view: ViewModule;
};

export default function CustomModuleCardItem({
  module,
  onActiveChangeModule,
  setModuleToEdit,
  view,
}: Props) {
  const { t } = useI18n();
  const navigate = useNavigate();

  function onAccessClick(url: string) {
    if (url.startsWith('/')) {
      removePersistedObject('mode');
      navigate(url);
      if (url.includes('chartered-accountant')) {
        persistData('mode', ACCOUNTANT_MODE);
      } else {
        persistData('mode', COMPANY_MODE);
      }
    } else {
      window.open(url, '_blank', 'noreferrer');
    }
  }

  return (
    <div className={s.flipCard}>
      <div className={s.flipCardInner}>
        <div className={s.flipCardFront}>
          <Card
            className={'flip-card'}
            shadow="sm"
            padding="sm"
            radius="md"
            withBorder
          >
            <Card.Section inheritPadding py="xs">
              <Group wrap="nowrap">
                {module.description.activatable && (
                  <IconSquareRoundedCheck
                    color={module.active ? 'teal' : 'gray'}
                  />
                )}
                <Text c={'hifivework.3'} fw={'bold'} truncate={'end'} fz={'md'}>
                  {t(`module.${module.name}`)}
                </Text>
              </Group>
            </Card.Section>
            <Card.Section
              h={150}
              p={'xs'}
              withBorder
              style={{
                backgroundSize: 150,
                backgroundImage:
                  module.name === 'TimeRecorder'
                    ? `url(${timeRecorderImage})`
                    : `url(${module.description.imageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
            <Card.Section p={'sm'}>
              <Text truncate={'end'} w={'95%'} c={'dimmed'} fz={'xs'}>
                {view === 'companyMarketplace' && module.name === 'vasa'
                  ? t('w.findTheBestMutualInsuranceForYourCompany')
                  : module.description.description}
              </Text>
            </Card.Section>
          </Card>
        </div>
        <div className={s.flipCardBack}>
          {module.description.activatable && (
            <Group justify={'space-between'} mb={'md'}>
              <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
                {t('w.activateFeature')}
              </Text>
              <Switch
                checked={module.active}
                onChange={(event) =>
                  onActiveChangeModule(event, module.description.id)
                }
              />
            </Group>
          )}
          <Text c={'dimmed'} fz={'xs'}>
            {module.description.description}
          </Text>
          <Flex
            mih={'100%'}
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
          >
            {module.description.directUrl && (
              <Button
                leftSection={<IconExternalLink />}
                onClick={() => onAccessClick(module.description.url)}
                variant={'subtle'}
              >
                {t('w.access')}
              </Button>
            )}
            {module.description.params && (
              <Button
                onClick={() => setModuleToEdit(module)}
                leftSection={<IconSettings />}
                variant={'outline'}
              >
                {t('w.settings')}
              </Button>
            )}
            {module.name === 'pappers' && (
              <Button
                onClick={() => setModuleToEdit(module)}
                leftSection={<IconSearch />}
                variant={'outline'}
              >
                {t('w.search')}
              </Button>
            )}
            {module.name === 'yoomonkeez' && (
              <Button
                onClick={() => setModuleToEdit(module)}
                leftSection={<IconInfoCircle />}
                variant={'outline'}
              >
                Informations
              </Button>
            )}
          </Flex>
        </div>
      </div>
    </div>
  );
}
