import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedCallback } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import EndOfMonthService, {
  EndOfMonthEmployeeCommentPayload,
} from '../../../../services/EndOfMonthService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  companyId: string;
  comment: string | null;
  endOfMonthId: string;
  employeeId: string;
  refetchAllData: () => void;
  disabled: boolean;
};

type FormValues = {
  comment: string | null;
};

export default function EndOfMonthCommentTextInput({
  companyId,
  comment,
  endOfMonthId,
  employeeId,
  refetchAllData,
  disabled,
}: Props) {
  const { t } = useI18n();
  const form = useForm<FormValues>({
    initialValues: { comment: comment || '' },
    onValuesChange: (values) => {
      handleAutoSave(values.comment);
    },
  });

  const { mutate: updateEmployeeComment } = useMutation({
    mutationFn: (variables: EndOfMonthEmployeeCommentPayload) =>
      EndOfMonthService.updateEmployeeComment(companyId, variables),
    onSuccess: () => {
      refetchAllData();
    },
    onError: (error: any) => {
      showNotification({
        id: 'mark-user-notification-as-viewed-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const handleAutoSave = useDebouncedCallback(
    async (comment: string | null) => {
      updateEmployeeComment({
        endOfMonthId: endOfMonthId,
        employeeId: employeeId,
        comment,
      });
    },
    1000
  );

  return (
    <TextInput
      disabled={disabled}
      size={'xs'}
      {...form.getInputProps('comment')}
    />
  );
}
