import { Stepper } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useMediaQuery } from '@mantine/hooks';
import { IconFileImport, IconListCheck } from '@tabler/icons-react';
import { useState } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import { Company, Division } from '../../../../types/types';
import {
  getPersistedObject,
  persistData,
} from '../../../../utils/localStorage';
import EndOfMonthPayslips from '../../../end-of-month/payslips/EndOfMonthPayslips';
import EndOfMonthValidationTable from '../../../end-of-month/validations/components/EndOfMonthValidationTable';

type Props = {
  selectedDate: DateValue | DatesRangeValue | Date[];
  divisions: Division[];
  company: Company;
  globalValidation: boolean;
};

export default function AccountantCustomersEndOfMonthStepper({
  selectedDate,
  divisions,
  company,
  globalValidation,
}: Props) {
  const { t } = useI18n();
  const matches = useMediaQuery('(min-width: 426px)');
  const [active, setActive] = useState<number>(
    parseInt(getPersistedObject('customerEndOfMonthStepper'))
  );

  function handleStepClick(stepIndex: number): void {
    persistData('customerEndOfMonthStepper', stepIndex.toString());
    setActive(stepIndex);
  }

  return (
    <Stepper
      active={active}
      onStepClick={handleStepClick}
      // allowNextStepsSelect={globalValidation}
    >
      <Stepper.Step
        label={
          matches
            ? globalValidation
              ? 'Préparation bulletins de paie'
              : t('w.endOfMonthValidation')
            : undefined
        }
        icon={<IconListCheck />}
      >
        <EndOfMonthValidationTable
          selectedDate={selectedDate}
          divisions={divisions}
          company={company}
          isAccountantEndOfMonth={true}
          globalValidation={globalValidation}
        />
      </Stepper.Step>
      <Stepper.Step
        // disabled={!globalValidation}
        // style={{ cursor: !globalValidation ? 'not-allowed' : 'pointer' }}
        label={
          matches
            ? `${t('w.import')} ${t('w.payslips').toLowerCase()}`
            : undefined
        }
        description={
          matches
            ? globalValidation
              ? ''
              : t('w.endOfMonthToValidate')
            : undefined
        }
        icon={<IconFileImport />}
      >
        <EndOfMonthPayslips
          customerCompanyId={company?.id}
          customerSelectedDate={selectedDate}
        />
      </Stepper.Step>
    </Stepper>
  );
}
