import {
  Button,
  Center,
  Grid,
  Group,
  Image,
  List,
  rem,
  SimpleGrid,
  Stepper,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBuilding,
  IconInfoCircle,
  IconUserQuestion,
} from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';

import { useI18n } from '../../../contexts/I18nProvider';
import { OauthUserProfile } from '../../../types/types';
import CompanyForm from '../../sign-up/components/CompanyForm';
import classes from '../../sign-up/SignUp.module.sass';

const myCompanyAlreadyUseHiFiveWork = 'myCompanyAlreadyUseHiFiveWork';
const myCompanyDoesNotYetUseApplicationHiFiveWork =
  'myCompanyDoesNotYetUseApplicationHiFiveWork';

type Props = {
  accessToken: string;
  oAuthUser: OauthUserProfile | undefined;
  refreshToken: string;
  onModalClose: () => void;
};

export default function LoginStepper({
  accessToken,
  oAuthUser,
  refreshToken,
  onModalClose,
}: Props) {
  const { t } = useI18n();
  const matches = useMediaQuery('(min-width: 426px)');
  const [active, setActive] = useState(0);
  const [choiceString, setChoiceString] = useState('');

  const orderedListText = useMemo(
    () => [
      t('w.contactHiFiveWorkAdminToInviteYou'),
      `Suite à l'invitation reçue par mail, vous pourrez lier votre compte ${oAuthUser?.username} à celui de votre entreprise en cliquant sur le bouton Lier votre compte HiFiveWork. (voir impression écran)`,
    ],
    []
  );

  function getSecondStepLabel() {
    switch (choiceString) {
      case myCompanyAlreadyUseHiFiveWork:
        return 'Informations importantes';
      case myCompanyDoesNotYetUseApplicationHiFiveWork:
        return 'Créer votre entreprise';
      default:
        return 'Etape suivante';
    }
  }

  return (
    <>
      <Stepper active={active} mt={'md'}>
        <Stepper.Step
          label={matches ? 'Choix' : ''}
          icon={
            <IconUserQuestion style={{ width: rem(18), height: rem(18) }} />
          }
        >
          <SimpleGrid cols={2} mt={'md'} spacing="xl" mx={'xl'}>
            <Group
              align={'flex-start'}
              className={classes.overviewWrapper}
              onClick={() => setChoiceString(myCompanyAlreadyUseHiFiveWork)}
              styles={{
                root: {
                  backgroundColor:
                    choiceString === myCompanyAlreadyUseHiFiveWork
                      ? 'var(--mantine-color-hifivework-0)'
                      : undefined,
                },
              }}
            >
              <Title size={'h5'} c={'hifivework.5'}>
                {t('w.myCompanyAlreadyUseHiFiveWork')}
              </Title>
            </Group>
            <Group
              align={'flex-start'}
              className={classes.overviewWrapper}
              onClick={() =>
                setChoiceString(myCompanyDoesNotYetUseApplicationHiFiveWork)
              }
              styles={{
                root: {
                  backgroundColor:
                    choiceString === myCompanyDoesNotYetUseApplicationHiFiveWork
                      ? 'var(--mantine-color-hifivework-0)'
                      : undefined,
                },
              }}
            >
              <Title size={'h5'} c={'hifivework.5'}>
                {t('w.myCompanyDoesNotYetUseApplicationHiFiveWork')}
              </Title>
            </Group>
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step
          label={matches ? getSecondStepLabel() : ''}
          icon={
            choiceString === myCompanyDoesNotYetUseApplicationHiFiveWork ? (
              <IconBuilding style={{ width: rem(18), height: rem(18) }} />
            ) : (
              <IconInfoCircle style={{ width: rem(18), height: rem(18) }} />
            )
          }
        >
          {choiceString === myCompanyAlreadyUseHiFiveWork ? (
            <Grid align={'flex-start'} mt={'md'}>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <List type={'ordered'} withPadding mt={'md'}>
                  {orderedListText.map((item, index) => (
                    <List.Item
                      fz={'sm'}
                      fw={'bold'}
                      key={`${item}-${index}`}
                      mb={'sm'}
                    >
                      {item}
                    </List.Item>
                  ))}
                </List>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Center>
                  <Image
                    h={300}
                    w={500}
                    src={
                      '/images/login/mail_template_invitation_user_par_admin.png'
                    }
                  />
                </Center>
              </Grid.Col>
            </Grid>
          ) : (
            <CompanyForm
              user={oAuthUser}
              userPhone={oAuthUser?.phone}
              userAccessToken={accessToken}
              userRefreshToken={refreshToken}
              companyPhone={oAuthUser?.phone}
              onCompanyPhoneChange={(value) => console.log(value)}
              handleBackStep={() => setActive(active - 1)}
              handleFinalStep={() => setActive(active + 1)}
              onModalClose={onModalClose}
            />
          )}
        </Stepper.Step>
      </Stepper>
      <Group justify="flex-end" mt="xl">
        {active > 0 && choiceString === myCompanyAlreadyUseHiFiveWork && (
          <Button variant="subtle" onClick={() => setActive(active - 1)}>
            {t('w.back')}
          </Button>
        )}
        {active === 0 && (
          <Button
            disabled={choiceString === ''}
            onClick={() => setActive(active + 1)}
          >
            {t('w.next')}
          </Button>
        )}
      </Group>
    </>
  );
}
