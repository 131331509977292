import { Button, Checkbox, Divider, Grid, List, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import React, { useState } from 'react';

import { useI18n } from '../../../contexts/I18nProvider';
import {
  DailyWorkRequest,
  Leave,
  Period,
  WeeklyTemplate,
} from '../../../types/types';
import { milliToHourMinuteStringUtc } from '../../../utils/format';

type userDailiesWorkInConflictsObject = {
  id: string;
  date: string;
  period: Period[];
  leaves: Leave[];
  templateChecked: boolean;
  userId: string;
  divisionId: string;
  divisionConcerned: string;
};

type Props = {
  userId: string;
  onClose: () => void;
  refresh: () => void;
  userDailyworksConflicted: any[];
  userWeeklyTemplate: WeeklyTemplate;
  updateUserPresence: (data: DailyWorkRequest) => void;
};

export default function DailyWorkConflictedModal({
  onClose,
  userDailyworksConflicted,
  userWeeklyTemplate,
  updateUserPresence,
}: Props) {
  const { t } = useI18n();
  const [periods, setPeriods] = useState<userDailiesWorkInConflictsObject[]>(
    userDailyworksConflicted
  );

  function convertPeriodToString(period: Period[]): string {
    const periodsToString: string[] = [];
    period.forEach((item) => {
      periodsToString.push(`Début : ${item.start} - Fin : ${item.end}`);
    });
    return periodsToString.join(' / ');
  }

  function convertLeavesToString(leaves: Leave[]): string {
    const leavesToString: string[] = [];
    leaves.forEach((item) => {
      leavesToString.push(
        `Début : ${milliToHourMinuteStringUtc(
          item.period.start
        )} - Fin : ${milliToHourMinuteStringUtc(item.period.end)}`
      );
    });
    return leavesToString.join(' / ');
  }

  function handleCheckToogle(checked: boolean, index: number): void {
    const _periods = [...periods];
    _periods[index].templateChecked = checked;

    setPeriods(_periods);
  }

  function getDisabled(): boolean {
    const templateCheckedFinded = periods.find((item) => item.templateChecked);
    return !templateCheckedFinded;
  }

  function templateToString(
    template: userDailiesWorkInConflictsObject
  ): string {
    const templatePeriodString: string[] = [];
    template.period.forEach((item) => {
      templatePeriodString.push(`${item.start}-${item.end}`);
    });
    return templatePeriodString.join(',');
  }

  function updateDailiesWorkWithTemplatePeriods(): void {
    showNotification({
      id: 'update-user-dailywork',
      message: t('w.updateInProgressPleaseWait'),
      icon: <IconCheck />,
      color: 'green',
    });
    periods
      .filter((item) => item.templateChecked)
      .forEach((item) => {
        const data = {
          id: item.id,
          date: item.date,
          presence: templateToString(item),
          divisionConcerned: item.divisionConcerned
            ? item.divisionConcerned
            : item.divisionId,
          status: 'WAITING',
        };
        updateUserPresence(data);
      });
  }

  return (
    <Grid grow gutter={45}>
      <Grid justify={'space-around'}>
        <Text variant={'h6'}>
          Période du modèle - {userWeeklyTemplate.name}
        </Text>
        <Text variant={'h6'}>Présences</Text>
      </Grid>
      <Grid justify={'space-around'}>
        <List>
          {periods.map((dailyWorkConflict, index) => (
            <List.Item key={dailyWorkConflict.id}>
              <Checkbox
                label={convertPeriodToString(dailyWorkConflict.period)}
                description={`Date : ${dailyWorkConflict.date}`}
                checked={dailyWorkConflict.templateChecked}
                onChange={(event) =>
                  handleCheckToogle(event.target.checked, index)
                }
              />
            </List.Item>
          ))}
        </List>
        <Divider orientation={'vertical'} variant={'fullWidth'} />
        <List>
          {periods.map((dailyWorkConflict) => (
            <List.Item key={dailyWorkConflict.id}>
              <Text>
                {`Date : ${dailyWorkConflict.date} `} +{' '}
                {convertLeavesToString(dailyWorkConflict.leaves)}
              </Text>
            </List.Item>
          ))}
        </List>
      </Grid>
      <Grid justify={'space-around'}>
        <Button
          size={'small'}
          variant={'contained'}
          color={'secondary'}
          disabled={getDisabled()}
          onClick={updateDailiesWorkWithTemplatePeriods}
        >
          Appliquer
        </Button>
        <Button
          size={'small'}
          variant={'contained'}
          color={'secondary'}
          onClick={() => onClose()}
        >
          Conserver
        </Button>
      </Grid>
    </Grid>
  );
}
