import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import { GOOGLE_CLIENT_ID } from '../../../../variables/GlobalVariables';
import type { ProviderProps } from '../../Login';
import useGoogleLogin from './hooks/useGoogleLogin';

export default function GoogleLogin({
  setOpenProfileChoice,
  setAuthInfo,
}: ProviderProps) {
  const { t } = useI18n();
  const { updateUser } = useAuth();
  const [googleAccessToken, setGoogleAccessToken] = useState<
    string | undefined
  >(undefined);
  const { login, error, loginResponse } = useGoogleLogin(googleAccessToken);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (googleAccessToken) {
      login();
    }
  }, [googleAccessToken]);

  useEffect(() => {
    if (loginResponse) {
      if (loginResponse.users?.length) {
        if (loginResponse.users.length > 1) {
          setOpenProfileChoice(true);
          setAuthInfo(loginResponse);
        } else {
          updateUser(
            loginResponse.users[0],
            loginResponse.access_token,
            loginResponse.refresh_token
          );
        }
      }
    }
  }, [loginResponse]);

  useEffect(() => {
    if (error.isError) {
      showNotification({
        id: 'microsoft-login-error',
        title: t('w.authentificationError'),
        message: error.details.response.data.error_description,
        color: 'red',
        icon: <IconX />,
      });
    }
  }, [error]);

  // @ts-ignore
  window.onGoogleResponse = function (response: any) {
    setGoogleAccessToken(response.credential);
  };

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={GOOGLE_CLIENT_ID}
        data-context="signin"
        data-ux_mode="popup"
        data-callback="onGoogleResponse"
        data-auto_prompt="false"
      />

      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="pill"
        data-theme="outline"
        data-text="signin_with"
        data-size="large"
        data-width="300"
        data-logo_alignment="center"
      />
    </>
  );
}
