import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Group, Skeleton, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCheck,
  IconCirclePlus,
  IconEdit,
  IconTrash,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import useFetchUsers from '../../../hooks/useFetchUsers';
import SecurityRoleService from '../../../services/SecurityRoleService';
import type { Role } from '../../../types/types';
import { milliToFullDateString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import { roleName } from '../../../utils/roleName';
import {
  ADMINISTRATOR,
  BUILT_IN_ROLES,
  DIRECTION,
  EMPLOYEE,
  MANAGER,
} from '../../../variables/BuiltInRoles';
import AssignUsersRolesTable from './components/AssignUsersRolesTable';
import CreateRoleForm from './components/CreateRoleForm';
import EditRole from './components/EditRole';

const componentName = 'Roles';
export default function Roles() {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { users, refetchUsers } = useFetchUsers(companyId);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
  });

  const actionButtons = useMemo(
    () => [
      <Button
        leftSection={<IconCirclePlus />}
        key={'add-role-button'}
        onClick={openCreate}
      >
        {`${t('w.add')} ${t('w.role').toLowerCase()}`}
      </Button>,
    ],
    []
  );

  const {
    data: roles,
    refetch: refetchRoles,
    isLoading,
  } = useQuery({
    queryKey: ['SecurityRoleService.rolesList', companyId],
    queryFn: () => SecurityRoleService.list(companyId),
  });

  const { mutate: deleteRole } = useMutation({
    mutationFn: (roleId: string) =>
      SecurityRoleService.deleteRole(companyId, roleId),
    onSuccess: (_data, roleId: string) => {
      refresh();
      showNotification({
        id: `delete-roleId-${roleId}-successful`,
        title: t('w.success'),
        message: `${t('w.role')} ${t('w.deleted').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error: any) => {
      if (error?.response) {
        if (error.response.status === 400) {
          showNotification({
            id: `delete-roleId-error`,
            title: t('w.error'),
            message: error.response.data.errorDescription,
            color: 'red',
            icon: <IconX />,
          });
        } else {
          showNotification({
            id: `delete-roleId-error`,
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        }
      }
    },
  });

  const columns: DataTableColumn<Role>[] = useMemo(
    () => [
      {
        accessor: 'name',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ name }) => roleName(t, name),
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
      {
        accessor: 'nbUsers',
        title: t('w.nbUsers'),
        sortable: true,
        ellipsis: true,
        render: ({ id }) => {
          if (!users?.length) return <Skeleton height={20} width="100%" />;

          return users.filter((item) => item.securityRoleId === id).length;
        },
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: (role) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip
              label={
                role.name === ADMINISTRATOR ? t('w.notEditable') : t('w.edit')
              }
            >
              <ActionIcon
                size="sm"
                color="blue"
                variant={'subtle'}
                onClick={() => openEdit(role)}
                disabled={role.name === ADMINISTRATOR}
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={t('division.message.assign', roleName(t, role.name))}
            >
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() => openAssign(role)}
              >
                <IconUsersGroup size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={
                BUILT_IN_ROLES.includes(role.name)
                  ? t('w.notDeletable')
                  : t('w.delete')
              }
            >
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="red"
                disabled={BUILT_IN_ROLES.includes(role.name)}
                onClick={() => openDelete(role)}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    [users, companyId]
  );

  function handleDeleteSelectedRoles() {
    const findNotDeletableRole = selectedRoles.find(
      (role: Role) =>
        role.name === ADMINISTRATOR ||
        role.name === DIRECTION ||
        role.name === MANAGER ||
        role.name === EMPLOYEE
    );
    if (findNotDeletableRole) {
      showNotification({
        id: 'impossible-to-delete-roles',
        title: t('w.warning'),
        message:
          "Il n'est pas possible de supprimer les rôles Administrateur / Direction / Manager / Employé",
        color: 'orange',
        icon: <IconAlertTriangle />,
      });
    }
    selectedRoles
      .filter(
        (role: Role) =>
          role.name !== ADMINISTRATOR &&
          role.name !== DIRECTION &&
          role.name !== MANAGER &&
          role.name !== EMPLOYEE
      )
      .forEach((role: Role) => deleteRole(role.id));
  }

  const rightCustomActions = useMemo(
    () => [
      <Button
        leftSection={<IconTrash />}
        key={'delete-selected-roles'}
        disabled={selectedRoles.length === 0}
        onClick={handleDeleteSelectedRoles}
      >
        {t('w.delete')}
      </Button>,
    ],
    [selectedRoles.length]
  );

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function openCreate() {
    modals.open({
      modalId: `create-role`,
      title: (
        <Title component="p">{`${t('w.add')} ${t(
          'w.role'
        ).toLowerCase()}`}</Title>
      ),
      size: 'xl',
      children: (
        <CreateRoleForm
          refresh={refresh}
          onClose={() => modals.close(`create-role`)}
          companyId={companyId}
        />
      ),
    });
  }

  function openEdit(role: Role) {
    modals.open({
      modalId: `edit-role-${role.id}`,
      title: (
        <Title component="p">
          {`${t('w.edit')} ${roleName(t, role.name)}`}
        </Title>
      ),
      fullScreen: true,
      children: (
        <EditRole
          role={role}
          refetchSecurityRolesData={refetchRoles}
          companyId={companyId}
        />
      ),
    });
  }

  function openDelete(role: Role) {
    modals.openConfirmModal({
      title: (
        <Title size={'h3'} component="p">
          {`${t('w.delete')} ${role.name}`}
        </Title>
      ),
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      size: 'xl',
      onConfirm: () => deleteRole(role.id),
    });
  }

  function openAssign(role: Role) {
    modals.open({
      modalId: `assign-role-${role.id}`,
      title: (
        <Title component="p">
          {t('division.message.assignRole', roleName(t, role.name))}
        </Title>
      ),
      fullScreen: true,
      children: (
        <AssignUsersRolesTable securityRoleId={role.id} companyId={companyId} />
      ),
    });
  }

  function refresh() {
    refetchRoles();
    refetchUsers();
  }

  return (
    <ScreenWrapper title={t('w.userRoles')} actionButtons={actionButtons} paper>
      <Table
        pinFirstColumn
        pinLastColumn
        onSelect={(_roles) => setSelectedRoles(_roles as Role[])}
        options={options}
        columns={columns}
        rows={roles ? roles : []}
        lang={lang}
        withTableBorder={false}
        fetching={isLoading}
        rightCustomActions={rightCustomActions}
      />
    </ScreenWrapper>
  );
}
