import {
  ActionIcon,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  rem,
  Stack,
  Switch,
  TextInput,
  Title,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowRight,
  IconCheck,
  IconCirclePlus,
  IconClock,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import CustomTooltip from '../../../../../components/CustomTooltip';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import DailyWorkService, {
  DailyTemplateToUpdate,
} from '../../../../../services/DailyWorkService';
import { DailyTemplate, PeriodTemplate } from '../../../../../types/types';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';

type Props = {
  template: DailyTemplate;
  refetchDailyTemplatesData: () => void;
  onClose: () => void;
  isEditing?: boolean;
};

type FormValues = {
  period: PeriodTemplate[];
  name: string;
  shared?: boolean;
};
export default function DailyTemplateForm({
  template,
  refetchDailyTemplatesData,
  onClose,
  isEditing = false,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);
  const form = useForm<FormValues>({
    initialValues: {
      period: [],
      name: '',
      shared: false,
    },
  });

  const startTimeInputRefs = useRef<any[]>([]);
  const endTimeInputRefs = useRef<any[]>([]);

  function pickerStartDateControl(index: number) {
    return (
      <ActionIcon
        variant="subtle"
        color="gray"
        onClick={() => startTimeInputRefs.current[index]?.showPicker()}
      >
        <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    );
  }
  function pickerEndDateControl(index: number) {
    return (
      <ActionIcon
        variant="subtle"
        color="gray"
        onClick={() => endTimeInputRefs.current[index]?.showPicker()}
      >
        <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    );
  }

  useEffect(() => {
    if (isEditing) {
      form.setInitialValues(template);
      form.setValues(template);
    }
  }, [isEditing]);

  const {
    mutate: updateDailyTemplate,
    isLoading: isUpdateDailyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: DailyTemplateToUpdate) =>
      DailyWorkService.updateDailyTemplate(companyId, template.id, variables),
    onSuccess: (data: DailyTemplate) => {
      const { id, name } = data;
      refetchDailyTemplatesData();
      onClose();
      showNotification({
        id: `update-daily-template-${id}-successful`,
        title: t('w.success'),
        message: `Modèle ${name} ${t('w.updated').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `update-daily-template-error`,
        title: t('w.success'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });
  const {
    mutate: createDailyTemplate,
    isLoading: isCreateDailyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: DailyTemplateToUpdate) =>
      DailyWorkService.createDailyTemplate(companyId, variables),
    onSuccess: (data: DailyTemplate) => {
      const { id, name } = data;
      refetchDailyTemplatesData();
      onClose();
      showNotification({
        id: `create-daily-template-${id}-successful`,
        title: t('w.success'),
        message: `Modèle ${name} ${t('w.created').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `create-daily-template-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleDailyTemplateSubmit(values: FormValues) {
    if (isEditing) {
      updateDailyTemplate({ name: template.name, period: values.period });
    } else {
      createDailyTemplate({
        name: values.name,
        period: values.period,
        shared: values.shared,
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleDailyTemplateSubmit(values))}
    >
      <LoadingOverlay
        visible={isUpdateDailyTemplateLoading || isCreateDailyTemplateLoading}
      />
      <Stack>
        {!isEditing && (
          <Flex align={'flex-end'} justify={'space-between'}>
            <TextInput
              label={<LabelText text={'Nom du modèle'} />}
              required
              {...form.getInputProps('name')}
            />
            <Switch
              size="xs"
              labelPosition="left"
              label={<LabelText text={'Partager le modèle ?'} />}
              {...form.getInputProps('shared')}
            />
          </Flex>
        )}
        <Group>
          <Title size={'h4'} c={'dimmed'}>
            Périodes journalières
          </Title>
          <CustomTooltip label={t('w.add')} position={'right'}>
            <ActionIcon
              variant={'subtle'}
              onClick={() =>
                form.insertListItem('period', {
                  startDate: '00:00',
                  endDate: '00:00',
                })
              }
            >
              <IconCirclePlus />
            </ActionIcon>
          </CustomTooltip>
        </Group>
        {form.values.period.map((item, index) => (
          <Group key={`group-${index}`} gap={'xl'}>
            <TimeInput
              id={`start-${index}`}
              label={<LabelText text={'Début'} />}
              {...form.getInputProps(`period.${index}.startDate`)}
              ref={(element) => (startTimeInputRefs.current[index] = element)}
              rightSection={pickerStartDateControl(index)}
            />
            <IconArrowRight
              style={{ marginTop: 'var(--mantine-spacing-md)' }}
              color={'var(--mantine-color-hifivework-5)'}
            />
            <TimeInput
              id={`end-${index}`}
              label={<LabelText text={'Fin'} />}
              {...form.getInputProps(`period.${index}.endDate`)}
              ref={(element) => (endTimeInputRefs.current[index] = element)}
              rightSection={pickerEndDateControl(index)}
            />
            <ActionIcon
              mt={'md'}
              variant={'subtle'}
              size={'sm'}
              color={'red'}
              onClick={() => form.removeListItem('period', index)}
            >
              <IconTrash />
            </ActionIcon>
          </Group>
        ))}
        <Flex justify={'flex-end'}>
          <Button type={'submit'}>
            {isEditing ? t('w.modify') : t('w.create')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}
