import { Group, Space, Text, Title } from '@mantine/core';

import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { Accountants, Company } from '../../../../types/types';
import PayrollOfficerBadge from './PayrollOfficerBadge';
import PayrollOfficerDivisionTable from './PayrollOfficerDivisionTable';

type Props = {
  opened: boolean;
  onClose: () => void;
  payrollOfficerToSee: Accountants | null;
  company: Company;
};

export default function PayrollOfficerToSeeModal({
  opened,
  onClose,
  payrollOfficerToSee,
  company,
}: Props) {
  const { t } = useI18n();

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      title={payrollOfficerToSee?.accountant.name}
      fullScreen
    >
      <Space h={'xs'} />
      <Title size={'h4'} c={'var(--mantine-color-hifivework-6)'}>
        Gestionnaires de paie qui interviennent sur votre entreprise :
      </Title>
      <Space h={'xs'} />
      <Group>
        {payrollOfficerToSee?.generalManagers &&
        payrollOfficerToSee.generalManagers.length > 0 ? (
          payrollOfficerToSee.generalManagers.map((manager) => (
            <PayrollOfficerBadge key={manager.id} payrollOfficer={manager} />
          ))
        ) : (
          <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
            {t('w.none')}
          </Text>
        )}
      </Group>
      <Space h={'lg'} />
      <Space h={'lg'} />
      <PayrollOfficerDivisionTable
        divisionAccountant={payrollOfficerToSee?.divisionAccountant}
        company={company}
      />
    </CustomModal>
  );
}
