import { Badge, Group, Title } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import { IconCheck, IconInfoCircle, IconProgress } from '@tabler/icons-react';
import React, { useMemo } from 'react';

import CustomCompanyAvatar from '../../../../components/CustomAvatar/CustomCompanyAvatar';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import { Company, EndOfMonthCustomerStatus } from '../../../../types/types';
import { persistData } from '../../../../utils/localStorage';
import AccountantCustomersEndOfMonthStepper from './AccountantCustomersEndOfMonthStepper';

type Props = {
  status: EndOfMonthCustomerStatus;
  clientCompanyId: string;
  customerCompany: Company;
  selectedDate: DateValue | DatesRangeValue | Date[];
  managedDivisionsIds: string[] | null;
};

export default function AccountantCustomersEndOfMonthStatusBadge({
  status,
  clientCompanyId,
  customerCompany,
  selectedDate,
  managedDivisionsIds,
}: Props) {
  const { t } = useI18n();
  const { divisions } = useFetchDivisions({ companyId: clientCompanyId });

  const isAllDivisionValidation = useMemo(() => {
    return status.divisions.every((division) => division.validation);
  }, []);

  function getStatus() {
    let badgeColor: string = '#FCF0E0';
    let badgeLabelColor: string = '#EDB464';
    let badgelabel: string = 'Attente informations client';
    let badgeIcon = <IconInfoCircle color={'#EDB464'} />;

    if (status.globalValidation && isAllDivisionValidation) {
      badgeColor = '#CEE9E6';
      badgeLabelColor = '#0A9182';
      badgelabel = 'Import bulletins de paie';
      badgeIcon = <IconCheck color={'#0A9182'} />;
    }

    if (status.globalValidation && !isAllDivisionValidation) {
      badgeColor = 'var(--mantine-color-hifivework-4)';
      badgeLabelColor = 'white';
      badgelabel = 'Préparation bulletins de paie';
      badgeIcon = <IconProgress />;
    }

    return {
      badgeColor: badgeColor,
      badgeLabelColor: badgeLabelColor,
      badgelabel: badgelabel,
      badgeIcon: badgeIcon,
    };
  }

  function getDivisions() {
    if (!managedDivisionsIds) return divisions;

    return divisions?.filter((item) => managedDivisionsIds.includes(item.id));
  }

  function handleOpenCustomerEndOfMonthClick() {
    persistData('customerEndOfMonthStepper', '0');
    modals.open({
      modalId: `customer-company-id-${clientCompanyId}-end-of-month-modal`,
      title: (
        <Group>
          <CustomCompanyAvatar companyId={clientCompanyId} size={'lg'} />
          <Title size={'h3'} component="p">{`${customerCompany.name} / ${
            getStatus().badgelabel === 'Attente informations client'
              ? t('w.endOfMonthValidation')
              : getStatus().badgelabel
          } [${status.monthConcerned}]`}</Title>
        </Group>
      ),
      fullScreen: true,
      children: (
        <AccountantCustomersEndOfMonthStepper
          selectedDate={selectedDate}
          divisions={getDivisions()}
          company={customerCompany}
          globalValidation={status.globalValidation}
        />
      ),
    });
  }

  return (
    <Badge
      size="lg"
      leftSection={getStatus().badgeIcon}
      color={getStatus().badgeColor}
      styles={{
        root: { textTransform: 'none', cursor: 'pointer' },
        label: { color: getStatus().badgeLabelColor },
      }}
      onClick={handleOpenCustomerEndOfMonthClick}
    >
      {getStatus().badgelabel}
    </Badge>
  );
}
