import {
  ActionIcon,
  Badge,
  Button,
  Fieldset,
  Flex,
  Grid,
  Group,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Stepper,
  Text,
  Title,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCheck,
  IconEdit,
  IconFileSettings,
  IconFileTypePdf,
  IconInfoCircle,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';

import CustomDropzone from '../../../../components/CustomDropzone/CustomDropzone';
import CustomTooltip from '../../../../components/CustomTooltip';
import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import { useModule } from '../../../../contexts/ModuleProvider';
import CompanyService from '../../../../services/CompanyService';
import CounterTypeService from '../../../../services/CounterTypeService';
import PayslipService from '../../../../services/PayslipService';
import type { PayslipExtractLocationPayload } from '../../../../types/types';
import { getTranslatedKey } from '../../../../utils/counterTypesFormatter';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  dataToFormData,
  formDataToPayload,
  getCegidExpertConfig,
  getCogilogConfig,
  getColorByKey,
  getDiapaieConfig,
  getFullConfig,
  getIsapayeConfig,
  getOpenpayeConfig,
  getQuadraConfig,
  getSageConfig,
  getSilaeConfig,
  type PayslipConfigurationFormObject,
  STATIC_FIELDS,
} from '../../../../utils/payslipConfigurations';
import {
  BulletinCogilog,
  ExportCegidExpert,
} from '../../../../variables/ModulesV2';
import ImportPayslipsInfoList from './ImportPayslipsInfoList';
import PayslipConfigShape from './PayslipConfigShape';

type FormValues = {
  files: FileWithPath[];
  payslipConfig: Map<string, PayslipConfigurationFormObject>;
  configOption: string;
};

type Props = {
  companyId: string;
  closeModal: () => void;
};

export default function ImportPayslipsFormStepper({
  companyId,
  closeModal,
}: Props) {
  const { t } = useI18n();
  const { getModule } = useModule();
  const cogilogModule = getModule(BulletinCogilog);
  const cegidExpertModule = getModule(ExportCegidExpert);
  const queryClient = useQueryClient();
  const matches = useMediaQuery('(max-width: 1023px)');
  const { data: users } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'valid',
        'identifiantsCompany',
      ]),
  });
  const form = useForm<FormValues>({
    initialValues: {
      files: [],
      payslipConfig: new Map(),
      configOption: t('w.savedConfiguration'),
    },
  });
  const [active, setActive] = useState(0);
  const { data: counterTypes } = useQuery({
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });
  const { refetch: refetchPayslipExtractorConfig } = useQuery({
    enabled: !!companyId,
    queryKey: ['PayslipService.getPayslipExtractorConfig', companyId],
    queryFn: () => PayslipService.getPayslipExtractorConfig(companyId),
    onSuccess: (data) => {
      form.setFieldValue('payslipConfig', dataToFormData(data));
    },
  });
  const { mutate: uploadPayslipMonth, isLoading: isUploadPayslipMonthLoading } =
    useMutation({
      mutationFn: (variables: { file: FileWithPath }) =>
        PayslipService.uploadPayslipMonth(
          companyId,
          false,
          true,
          variables.file
        ),
      onSuccess: () => {
        showNotification({
          id: 'upload-payslips-successful',
          title: t('w.success'),
          message: 'Import des bulletins de paie effectué !',
          color: 'green',
          icon: <IconCheck />,
        });
        closeModal();
        setTimeout(
          () =>
            queryClient.invalidateQueries({
              queryKey: [
                'PayslipService.getPayslipsTreatmentForCompanyId',
                companyId,
              ],
            }),
          300
        );
      },
      onError: (error) =>
        showNotification({
          id: 'upload-payslips-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });
  const {
    mutate: updatePayslipExtractorConfig,
    isLoading: isLoadingUpdatePayslipExtractorConfig,
  } = useMutation({
    mutationFn: (payload: PayslipExtractLocationPayload) => {
      return PayslipService.updatePayslipExtractorConfig(companyId, payload);
    },
    onSuccess: (_data, payload) => {
      if (!!payload.matricule?.location || !!payload.codeCompany?.location) {
        const usersWithoutIdentifiantsCompany = users?.filter(
          (item) => item.valid && !item.identifiantsCompany?.length
        );

        if (usersWithoutIdentifiantsCompany?.length) {
          modals.openConfirmModal({
            withCloseButton: false,
            title: (
              <Group>
                <Title size={'h3'} component="p">
                  {t('w.warning')}
                </Title>
                <IconAlertTriangle color={'orange'} />
              </Group>
            ),
            children: (
              <>
                <Text fz={'sm'} mb="md">
                  {t('payslips.import.usersWithoutIdentifiantsCompany')} :
                </Text>
                <Text fz={'sm'} fw="bold">
                  {usersWithoutIdentifiantsCompany
                    .map((item) => item.fullname)
                    .join(', ')}
                </Text>
              </>
            ),
            size: 'lg',
            labels: {
              confirm: t('payslips.import.continue'),
              cancel: t('w.cancel'),
            },
            onConfirm: () => uploadPayslipMonth({ file: form.values.files[0] }),
            onClose: () => modals.closeAll(),
          });
        } else {
          uploadPayslipMonth({ file: form.values.files[0] });
        }
      } else {
        uploadPayslipMonth({ file: form.values.files[0] });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'update-payslips-config-fieldPosition-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });
  const configOptions = useMemo(() => {
    const _configOptions: string[] = [
      t('w.savedConfiguration'),
      'Quadra',
      'Silae',
      'DiaPaie',
      'Full',
      'Sage',
      'Openpaye',
    ];
    if (cogilogModule?.active) {
      _configOptions.push('Cogilog');
    }
    if (cegidExpertModule?.active) {
      _configOptions.push('CegidExpert');
    }

    return _configOptions;
  }, [cogilogModule?.active, cegidExpertModule?.active]);
  useEffect(() => {
    switch (form.values.configOption) {
      case t('w.savedConfiguration'):
        refetchPayslipExtractorConfig();
        break;
      case 'Quadra':
        form.setFieldValue('payslipConfig', getQuadraConfig());
        break;
      case 'Silae':
        form.setFieldValue('payslipConfig', getSilaeConfig());
        break;
      case 'Isapaye':
        form.setFieldValue('payslipConfig', getIsapayeConfig());
        break;
      case 'Cogilog':
        form.setFieldValue('payslipConfig', getCogilogConfig());
        break;
      case 'DiaPaie':
        form.setFieldValue('payslipConfig', getDiapaieConfig());
        break;
      case 'Openpaye':
        form.setFieldValue('payslipConfig', getOpenpayeConfig());
        break;
      case 'Full':
        form.setFieldValue('payslipConfig', getFullConfig());
        break;
      case 'Sage':
        form.setFieldValue('payslipConfig', getSageConfig());
        break;
      case 'CegidExpert':
        form.setFieldValue('payslipConfig', getCegidExpertConfig());
        break;
    }
  }, [form.values.configOption]);
  const hasPdfFile = form.values.files.length > 0;

  const fieldsUsage: {
    used: PayslipConfigurationFormObject[];
    unused: PayslipConfigurationFormObject[];
  } = useMemo(() => {
    let used: PayslipConfigurationFormObject[] = [];
    let unused: PayslipConfigurationFormObject[] = [];

    if (!counterTypes) return { used, unused };

    used = Array.from(form.values.payslipConfig).map(([, value]) => value);

    const unusedCounterTypes = counterTypes
      .map((counterTypeResponse) => counterTypeResponse.counterType)
      .filter(
        (counterType) =>
          counterType.active &&
          counterType.extractToPayslip &&
          !Boolean(
            used.find(
              (payslipConfigurationFormObject) =>
                payslipConfigurationFormObject.id === counterType.key
            )
          )
      );

    unused = unusedCounterTypes.map((item, index) => ({
      id: item.key,
      color: getColorByKey(item.key, counterTypes),
    }));

    const usedKeys = used.map((item) => item.id);
    const unusedKeys = unused.map((item) => item.id);
    STATIC_FIELDS.forEach((key) => {
      if (!usedKeys.includes(key) && !unusedKeys.includes(key)) {
        unused.push({
          id: key,
          color: getColorByKey(key, counterTypes),
        });
      }
    });

    if (cogilogModule?.active) {
      console.warn(
        "Bulletin Cogilog actif, pas de champs 'acquisN', 'acquisN1', 'matricule', 'codeCompany'"
      );
      used = used.filter(
        (item) =>
          item.id !== 'acquisN' &&
          item.id !== 'acquisN1' &&
          item.id !== 'matricule' &&
          item.id !== 'codeCompany'
      );
      unused = unused.filter(
        (item) =>
          item.id !== 'acquisN' &&
          item.id !== 'acquisN1' &&
          item.id !== 'matricule' &&
          item.id !== 'codeCompany'
      );
    }

    return { used, unused };
  }, [form.values.payslipConfig, counterTypes]);

  function renderPayslipConfigFieldBadge(
    key: string,
    color: string,
    isUsed: boolean
  ) {
    return (
      <Group key={key}>
        <Badge
          styles={{ label: { textTransform: 'none' } }}
          color={color}
          leftSection={
            <ActionIcon
              size={'xs'}
              color={'white'}
              variant={'subtle'}
              onClick={() => handleConfigLocationClick(key, color)}
            >
              <IconEdit />
            </ActionIcon>
          }
          rightSection={
            isUsed &&
            key !== 'name' &&
            key !== 'periodImported' && (
              <ActionIcon
                size={'xs'}
                color={'white'}
                variant={'subtle'}
                onClick={() => handleDeleteLocation(key)}
              >
                <IconTrash />
              </ActionIcon>
            )
          }
        >
          {getTranslatedKey(t, key)}
        </Badge>
      </Group>
    );
  }

  function handleConfigLocationClick(key: string, color: string) {
    let cloneMap = new Map(form.values.payslipConfig);
    cloneMap.set(key, {
      x: 100,
      y: 100,
      width: 100,
      height: 100,
      id: key,
      color,
    });
    form.setFieldValue('payslipConfig', cloneMap);
  }

  function handleDeleteLocation(key: string) {
    const cloneMap = new Map(form.values.payslipConfig);
    cloneMap.delete(key);
    form.setFieldValue('payslipConfig', cloneMap);
  }

  function handleImportPayslipsSubmit(values: FormValues): void {
    if (!counterTypes?.length) return;

    if (values.payslipConfig.size === 0) {
      showNotification({
        id: 'no-field-detection',
        title: t('w.error'),
        message: t('w.noFieldDetection'),
        color: 'red',
        icon: <IconX />,
      });
    } else {
      updatePayslipExtractorConfig(
        formDataToPayload(companyId, counterTypes, values.payslipConfig)
      );
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleImportPayslipsSubmit)}>
      <Stack gap={'xl'}>
        <Stepper
          active={active}
          onStepClick={hasPdfFile ? setActive : undefined}
          styles={{ stepBody: { display: matches ? 'none' : undefined } }}
        >
          <Stepper.Step
            my={'lg'}
            label={
              <Group>
                <div>{t('w.uploadPdfFile')}</div>
                <CustomTooltip label={<ImportPayslipsInfoList />}>
                  <IconInfoCircle color={'var(--mantine-color-hifivework-3)'} />
                </CustomTooltip>
              </Group>
            }
            icon={<IconFileTypePdf />}
            description={'Format PDF'}
          >
            <CustomDropzone
              onDrop={(files) => form.setFieldValue('files', files)}
              files={form.values.files}
              mimeType={'pdf'}
              resetFile={() => form.setFieldValue('files', [])}
            />
          </Stepper.Step>
          <Stepper.Step
            my={'lg'}
            label={
              <Group>
                <div>Configurer les champs de détection</div>
                {!hasPdfFile && (
                  <CustomTooltip
                    label={`${t(
                      'w.uploadPdfFile'
                    )} avant de pouvoir configurer les champs de détections`}
                  >
                    <IconInfoCircle
                      color={'var(--mantine-color-hifivework-3)'}
                    />
                  </CustomTooltip>
                )}
              </Group>
            }
            description={'Si nécessaire'}
            icon={<IconFileSettings />}
          >
            <Grid justify={'center'}>
              <Grid.Col span={{ base: 12, lg: 8 }} order={{ base: 2, lg: 1 }}>
                {hasPdfFile && (
                  <PayslipConfigShape
                    pdfUrl={form.values.files[0]}
                    defaultPayslipConfig={form.values.payslipConfig}
                    setDefaultPayslipConfig={(rects) =>
                      form.setFieldValue('payslipConfig', rects)
                    }
                  />
                )}
              </Grid.Col>
              <Grid.Col span={{ base: 12, lg: 4 }} order={{ base: 1, lg: 2 }}>
                <Select
                  data={configOptions}
                  label={<LabelText text={'Modèle de configuration'} />}
                  {...form.getInputProps('configOption')}
                />
                <Space h={'md'} />
                <Fieldset legend={t('w.locations')}>
                  <SimpleGrid cols={2}>
                    <Paper shadow="xs" withBorder p="xs">
                      <Flex direction={'column'} gap={'md'}>
                        <Title size={'h4'}>{t('w.used')}</Title>
                        {fieldsUsage.used.map((field) =>
                          renderPayslipConfigFieldBadge(
                            field.id,
                            field.color as string,
                            true
                          )
                        )}
                      </Flex>
                    </Paper>
                    <Paper shadow="xs" withBorder p="xs">
                      <Flex direction={'column'} gap={'md'}>
                        <Title size={'h4'}>{t('w.unused')}</Title>
                        {fieldsUsage.unused.map((field) =>
                          renderPayslipConfigFieldBadge(
                            field.id,
                            field.color as string,
                            false
                          )
                        )}
                      </Flex>
                    </Paper>
                  </SimpleGrid>
                </Fieldset>
              </Grid.Col>
            </Grid>
          </Stepper.Step>
        </Stepper>
        <Group justify={'flex-end'}>
          {active > 0 && (
            <Button variant={'subtle'} onClick={() => setActive(active - 1)}>
              {t('w.back')}
            </Button>
          )}
          {active === 0 && (
            <Button
              disabled={active === 0 && form.values.files.length === 0}
              onClick={() => setActive(active + 1)}
            >
              {t('w.next')}
            </Button>
          )}
          {active === 1 && (
            <Button
              loading={
                isLoadingUpdatePayslipExtractorConfig ||
                isUploadPayslipMonthLoading
              }
              type={'submit'}
            >
              {t('w.import')}
            </Button>
          )}
        </Group>
      </Stack>
    </form>
  );
}
