import { Button, Flex, Group, Stack } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import ConfirmValidateEmployeesList from '../../../../../components/ConfirmValidateEmployeesList';
import { useI18n } from '../../../../../contexts/I18nProvider';
import CounterTypeService from '../../../../../services/CounterTypeService';
import PayslipService from '../../../../../services/PayslipService';
import type {
  CounterTypeResponse,
  UserPayslipRequest,
  UserPayslipResponse,
} from '../../../../../types/types';
import { CounterStr } from '../../../../../types/types';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import { getPersistedObject } from '../../../../../utils/localStorage';

type Props = {
  selectedEmployees: UserPayslipResponse[];
  selectedDate: DateValue | DatesRangeValue | Date[];
  companyId: string;
  closeModal: () => void;
};

export default function NotifyPayslipsEmployeesForm({
  selectedEmployees,
  selectedDate,
  companyId,
  closeModal,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const gma = getPersistedObject('gma');

  const { data: counterTypes } = useQuery({
    enabled: !!companyId,
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (_counterTypes: CounterTypeResponse[]) => {
      const employees: UserPayslipRequest[] = [];
      let counter: CounterStr[];
      selectedEmployees.forEach((item) => {
        counter = [];

        if (item.counter) {
          const counterKeys = Object.keys(item.counter);
          counterKeys.forEach((key) => {
            const counterTypeId = _counterTypes.find(
              (ct) => ct.counterType.key === key
            )?.counterType?.id;
            if (counterTypeId) {
              counter.push({
                counter:
                  item.counter[key] != null
                    ? item.counter[key].toString()
                    : '0',
                key,
                counterTypeId,
              });
            }
          });
        }

        employees.push({
          userId: item.userId,
          counter,
        });
      });
      return PayslipService.validateAndNotifyPayslips(
        companyId,
        // @ts-ignore
        dayjs(selectedDate).month() + 1,
        // @ts-ignore
        dayjs(selectedDate).year(),
        { employees },
        gma?.access_token,
        gma?.refresh_token
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'PayslipService.getPayslipsTreatmentForCompanyId',
          companyId,
        ],
      });
      showNotification({
        id: 'validate-payslips-and-notify-admin-successful',
        title: t('w.success'),
        message: t('w.payslipsHaveBeenSentToEmployees'),
        color: 'green',
        icon: <IconCheck />,
      });
      closeModal();
    },
    onError: (error) =>
      showNotification({
        id: 'validate-payslips-and-notify-admin-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function onNotifyClick() {
    if (!counterTypes) return;

    mutate(counterTypes);
  }

  return (
    <Stack gap={'xl'}>
      <Group>
        {selectedEmployees?.map((employee) => (
          <ConfirmValidateEmployeesList
            key={employee.userId}
            userId={employee.userId}
            userFullname={employee.fullname}
          />
        ))}
      </Group>
      <Flex justify={'flex-end'}>
        <Button onClick={onNotifyClick} loading={isLoading}>
          {t('w.notify')}
        </Button>
      </Flex>
    </Stack>
  );
}
