import { ActionIcon, Button, Flex, Group, Paper, Text } from '@mantine/core';
import {
  Dropzone,
  FileWithPath,
  IMAGE_MIME_TYPE,
  MIME_TYPES,
  PDF_MIME_TYPE,
} from '@mantine/dropzone';
import {
  IconCheck,
  IconCircleCheck,
  IconCloudUpload,
  IconX,
} from '@tabler/icons-react';
import React, { useRef } from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import s from './CustomDropzone.module.sass';

type Props = {
  onDrop: (files: FileWithPath[]) => void;
  files: FileWithPath[];
  mimeType: 'img' | 'pdf' | 'excel' | 'expense-report' | 'several';
  type?: 'simple' | 'multiple-files';
  resetFiles?: (index: number) => void;
  resetFile?: () => void;
  disabled?: boolean;
};

export default function CustomDropzone({
  onDrop,
  files,
  mimeType,
  type,
  resetFiles,
  resetFile,
  disabled,
}: Props) {
  const { t } = useI18n();
  const openRef = useRef<any>(null);

  function getAcceptedTypeFile() {
    switch (mimeType) {
      case 'pdf':
        return PDF_MIME_TYPE;
      case 'excel':
        return [MIME_TYPES.xls, MIME_TYPES.xlsx, MIME_TYPES.csv];
      case 'expense-report':
        return [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf];
      case 'several':
        return [
          MIME_TYPES.png,
          MIME_TYPES.gif,
          MIME_TYPES.jpeg,
          MIME_TYPES.svg,
          MIME_TYPES.webp,
          MIME_TYPES.avif,
          MIME_TYPES.mp4,
          MIME_TYPES.zip,
          MIME_TYPES.csv,
          MIME_TYPES.pdf,
          MIME_TYPES.doc,
          MIME_TYPES.docx,
          MIME_TYPES.xls,
          MIME_TYPES.xlsx,
          MIME_TYPES.ppt,
          MIME_TYPES.pptx,
          MIME_TYPES.exe,
        ];
      default:
        return IMAGE_MIME_TYPE.filter(
          (item) => item === 'image/png' || item === 'image/jpeg'
        );
    }
  }

  function renderDropzoneType() {
    switch (type) {
      case 'simple':
        return (
          <Flex direction={'column'} gap={'sm'}>
            <Dropzone
              openRef={openRef}
              onDrop={onDrop}
              activateOnClick={false}
              styles={{ inner: { pointerEvents: 'all' } }}
              accept={getAcceptedTypeFile()}
              disabled={disabled}
            >
              <Button
                size="xl"
                variant="transparent"
                onClick={() => {
                  if (openRef) {
                    openRef.current();
                  }
                }}
                disabled={disabled}
              >
                {t('w.selectFileOrDragNDrop')}
              </Button>
            </Dropzone>
            {files.length > 0 && (
              <Paper shadow="md" p="xs" withBorder radius={'md'}>
                <Group justify={'space-between'}>
                  <Group gap={'5px'}>
                    <IconCircleCheck size={20} className={s.iconCheck} />
                    <Text mb={-2} fz={'xs'}>
                      {files[0].path}
                    </Text>
                  </Group>
                  <ActionIcon onClick={resetFile} size={'sm'}>
                    <IconX />
                  </ActionIcon>
                </Group>
              </Paper>
            )}
          </Flex>
        );
      case 'multiple-files':
        return (
          <Flex direction={'column'} gap={'sm'}>
            <Dropzone
              onDrop={onDrop}
              accept={getAcceptedTypeFile()}
              h={'112px'}
              bg={'#f8f9fa'}
              disabled={disabled}
            >
              <Group justify={'center'}>
                <Dropzone.Accept>
                  <IconCheck size={60} className={s.iconSucces} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX size={60} stroke={1.5} className={s.iconError} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconCloudUpload size={60} className={s.icon} />
                </Dropzone.Idle>
                <Flex
                  direction={'column'}
                  align={'center'}
                  justify={'center'}
                  gap={'xs'}
                >
                  <Text fz={'md'} fw={'bold'} c={'hifivework.6'}>
                    {t('w.importFileGuide')}
                  </Text>
                  <Text fz={'xs'} c={'dimmed'}>
                    Ou cliquer pour parcourir vos fichiers
                  </Text>
                </Flex>
              </Group>
            </Dropzone>
            {files.length > 0 &&
              files.map((file, index) => (
                <Paper
                  shadow="md"
                  p="xs"
                  withBorder
                  radius={'md'}
                  key={`${file.name}-${index}`}
                >
                  <Group justify={'space-between'}>
                    <Group gap={'5px'}>
                      <IconCircleCheck size={20} className={s.iconCheck} />
                      <Text mb={-2} fz={'xs'}>
                        {file.path}
                      </Text>
                    </Group>
                    <ActionIcon onClick={resetFile} size={'sm'}>
                      <IconX />
                    </ActionIcon>
                  </Group>
                </Paper>
              ))}
          </Flex>
        );
      default:
        return (
          <Flex direction={'column'} gap={'sm'}>
            <Dropzone
              onDrop={onDrop}
              accept={getAcceptedTypeFile()}
              h={'112px'}
              bg={'#f8f9fa'}
              disabled={disabled}
            >
              <Group justify={'center'}>
                <Dropzone.Accept>
                  <IconCheck size={60} className={s.iconSucces} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX size={60} stroke={1.5} className={s.iconError} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconCloudUpload size={60} className={s.icon} />
                </Dropzone.Idle>
                <Flex
                  direction={'column'}
                  align={'center'}
                  justify={'center'}
                  gap={'xs'}
                >
                  <Text fz={'md'} fw={'bold'} c={'hifivework.6'}>
                    {t('w.importFileGuide')}
                  </Text>
                  <Text fz={'xs'} c={'dimmed'}>
                    Ou cliquer pour parcourir vos fichiers
                  </Text>
                </Flex>
              </Group>
            </Dropzone>
            {files.length > 0 && (
              <Paper shadow="md" p="xs" withBorder radius={'md'}>
                <Group justify={'space-between'}>
                  <Group gap={'5px'}>
                    <IconCircleCheck size={20} className={s.iconCheck} />
                    <Text mb={-2} fz={'xs'}>
                      {files[0].path}
                    </Text>
                  </Group>
                  <ActionIcon onClick={resetFile} size={'sm'}>
                    <IconX />
                  </ActionIcon>
                </Group>
              </Paper>
            )}
          </Flex>
        );
    }
  }

  return renderDropzoneType();
}
