import { ActionIcon, Text, Tooltip } from '@mantine/core';
import { useEventListener } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { IconChevronDown, IconChevronUp, IconTrash } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { RefObject, useCallback, useRef } from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import { LeaveEvent } from './DayEventsCell';
import DeleteEvent from './DeleteEvent';
import css from './MonthEventScheduler.module.sass';
dayjs.extend(utc);

export declare type EventCellInlineStyle = {
  backgroundColor: string;
  left: string;
  right: string;
  top: string;
  height: string;
};

export declare type SchedulerEventCellResizeEvent = {
  event: LeaveEvent;
  cellRef: RefObject<HTMLDivElement>;
};

export declare type SchedulerEventCellProps = {
  event: LeaveEvent;
  day: Dayjs;
  firstDayOfScheduler: Dayjs;
  onResizeWithTop: (
    cellResizeEvent: SchedulerEventCellResizeEvent | null
  ) => void;
  onResizeWithBottom: (
    cellResizeEvent: SchedulerEventCellResizeEvent | null
  ) => void;
  onStopResize: () => void;
  onEventDeleted: (event: LeaveEvent) => void;
  nbDaysDisplayed: number;
};

export default function SchedulerEventCell({
  event,
  day,
  firstDayOfScheduler,
  onResizeWithTop,
  onResizeWithBottom,
  onStopResize,
  onEventDeleted,
  nbDaysDisplayed,
}: SchedulerEventCellProps) {
  const { t } = useI18n();
  const cellRef = useRef<HTMLDivElement | null>(null);

  const triggerStopResizing = () => {
    onStopResize();
    window.removeEventListener('mouseup', triggerStopResizing);
  };

  const triggerResizeWithTop = useCallback(() => {
    onResizeWithBottom(null);
    onResizeWithTop({
      event,
      cellRef,
    });
    window.addEventListener('mouseup', triggerStopResizing);
  }, [event]);
  const startTopResizeHandlerRef = useEventListener(
    'mousedown',
    triggerResizeWithTop
  );

  const triggerResizeWithBottom = useCallback(() => {
    onResizeWithTop(null);
    onResizeWithBottom({
      event,
      cellRef,
    });
    window.addEventListener('mouseup', triggerStopResizing);
  }, [event]);

  const startBottomResizeHandlerRef = useEventListener(
    'mousedown',
    triggerResizeWithBottom
  );

  const triggerEventDeleted = () => {
    onEventDeleted(event);
  };

  const top = event.isHourly
    ? `calc(${event.startDate
        .utc()
        .get('hour')} * var(--scheduler-cell-height) + ${event.startDate
        .utc()
        .get('minute')} * var(--scheduler-cell-height) / 60)`
    : '0px';

  const diffStartEnd = event.endDate
    .utc()
    .diff(event.startDate.utc(), 'minute');
  const maxMinsInDay = 1440; // 60 * 24
  const height = event.isHourly
    ? `calc(${
        diffStartEnd > maxMinsInDay ? maxMinsInDay : diffStartEnd
      } * var(--scheduler-cell-height) / 60)`
    : '100%';

  const cellStyle: EventCellInlineStyle = {
    backgroundColor: event.bgColor,
    left: `calc(${day.diff(
      firstDayOfScheduler,
      'day'
    )} * var(--scheduler-cell-width))`,
    right: `calc(${
      nbDaysDisplayed - day.diff(firstDayOfScheduler, 'day') - 1
    } * var(--scheduler-cell-width))`,
    top: top,
    height: height,
  };

  function openDeleteEvent() {
    modals.open({
      modalId: 'DeleteEvent',
      title: t('dailyWork.event.delete'),
      children: (
        <DeleteEvent event={event} onEventDeleted={triggerEventDeleted} />
      ),
      size: 'lg',
      m: 'xl',
    });
  }

  return (
    <Tooltip.Floating
      label={event.status ? t(`leave.status.state.${event.status}`) : ''}
      disabled={!event.status}
    >
      <div
        className={css['scheduler-event-cell']}
        style={cellStyle}
        ref={cellRef}
      >
        {event.isEditable && event.isHourly && (
          <>
            <div
              className={css['scheduler-event-cell-resizable-handle-top']}
              ref={startTopResizeHandlerRef}
            >
              <IconChevronDown size={16} />
            </div>
            <div className={css['scheduler-event-cell-delete']}>
              <ActionIcon
                size={12}
                onClick={() => {
                  openDeleteEvent();
                }}
                variant={'subtle'}
                color={'black'}
              >
                <IconTrash size={18} />
              </ActionIcon>
            </div>
            <div
              className={css['scheduler-event-cell-resizable-handle-bottom']}
              ref={startBottomResizeHandlerRef}
            >
              <IconChevronUp size={16} />
            </div>
          </>
        )}
        <Text size="xs">{`${event.leaveType.name}`}</Text>
        <Text size="xs">
          {event.isHourly
            ? `${event.startDate.utc().format('HH:mm')} - ${event.endDate
                .utc()
                .format('HH:mm')}`
            : event.startDate.utc().format('LL')}
        </Text>
      </div>
    </Tooltip.Floating>
  );
}
