import {
  AppShell as MantineAppShell,
  Box,
  Burger,
  ScrollArea,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconBrandDaysCounter,
  IconBuildingStore,
  IconCalendarDue,
  IconCalendarEvent,
  IconCalendarTime,
  IconClipboardCheck,
  IconClipboardList,
  IconFileDescription,
  IconFileDollar,
  IconFolders,
  IconHome,
  IconInbox,
  IconListDetails,
  IconReceipt,
  IconReceiptTax,
  IconReport,
  IconSettings,
  IconUsers,
} from '@tabler/icons-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import IndicatorBadge from '../../../components/badges/IndicatorBadge';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import useDisplayTeamActivities from '../../../hooks/accessChecking/activities/useDisplayTeamActivities';
import useDisplayUserActivities from '../../../hooks/accessChecking/activities/useDisplayUserActivities';
import useCompany from '../../../hooks/useCompany';
import usePendingValidations from '../../../hooks/usePendingValidations';
import { ACCOUNTANT_MODE, type Link } from '../../../types/types';
import { hasPermission } from '../../../utils/authorization';
import { getPersistedObject } from '../../../utils/localStorage';
import {
  AccountantManageTeam,
  ALL,
  AuthorizedEditAccountantSpace,
  AuthorizedReadAllUsersInfo,
  AuthorizedReadCommonSpace,
  AuthorizedReadMyPersonalSpace,
  AuthorizedReadPlanning,
  ConfigureAccountant,
  ConfigureBlockingPeriods,
  ConfigureCompany,
  ConfigureEmployees,
  ConfigureFeatures,
  ConfigureLeaveTypes,
  ConfigurePermissions,
  ConfigurePublicHolidaysCompanyLeaves,
  ConfigureShuttleSheetTypes,
  ConfigureWorkUnit,
  MINE,
  TEAM,
  ValidateEndOfMonth,
  ValidateExpenseReports,
  ValidateHolidayCounter,
  ValidateLeavesAbsences,
  ValidatePayrollVariables,
  ValidatePayslips,
} from '../../../variables/BuiltInPermissions';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import { ExpenseReport, ShuttleSheet } from '../../../variables/ModulesV2';
import Appbar from '../Appbar';
import { DoubleNavbar } from '../DoubleNavBar/DoubleNavbar';
import NavbarNested from '../NavBarNested/NavBarNested';
import UserMenu from '../UserMenu/UserMenu';
import s from './AppShell.module.sass';

type Props = {
  children: ReactNode;
};

export default function AppShell({ children }: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { pathname } = useLocation();
  const { id: companyId } = useCompany(user);
  const { getModule } = useModule();
  const theme = useMantineTheme();
  const [opened, handlers] = useDisclosure(true);
  const [isDoubleNavBar, setIsDoubleNavBar] = useState<boolean>(false);
  const { pendingValidations: pendingValidationsCount } =
    usePendingValidations();

  const isExpenseReportModuleActive = Boolean(getModule(ExpenseReport)?.active);
  const isShuttleSheetModuleActive = Boolean(getModule(ShuttleSheet)?.active);

  const isDisplayMyActivities = useDisplayUserActivities();
  const isDisplayTeamActivities = useDisplayTeamActivities();

  const hasPermissionToAccessToAccountantNotificationConfig = hasPermission(
    [{ permission: AccountantManageTeam, scope: ALL }],
    user
  );

  const hasPermissionToReadMyPersonalSpace = hasPermission(
    { permission: AuthorizedReadMyPersonalSpace, scope: ALL },
    user
  );

  const hasPermissionToReadCommonSpace = hasPermission(
    { permission: AuthorizedReadCommonSpace, scope: ALL },
    user
  );

  const isAuthorizedEditAccountantSpace = hasPermission(
    { permission: AuthorizedEditAccountantSpace, scope: ALL },
    user
  );

  const companyLinks: Link[] = useMemo(
    () => [
      {
        label: t('w.home'),
        icon: IconHome,
        link: '/home',
        divider: false,
        isDisplayed: true,
      },
      {
        label: t('w.planning'),
        icon: IconCalendarEvent,
        link: '/planning',
        divider: false,
        isDisplayed: hasPermission(
          [
            { permission: AuthorizedReadPlanning, scope: ALL },
            { permission: AuthorizedReadPlanning, scope: TEAM },
            { permission: AuthorizedReadPlanning, scope: MINE },
            { permission: AuthorizedReadAllUsersInfo, scope: ALL },
          ],
          user
        ),
      },
      {
        label: user.company.divisionLabel
          ? user.company.divisionLabel
          : t('w.units'),
        icon: IconClipboardList,
        link: '/work-units',
        divider: false,
        isDisplayed: hasPermission(
          [
            { permission: AuthorizedReadAllUsersInfo, scope: ALL },
            { permission: AuthorizedReadAllUsersInfo, scope: TEAM },
            { permission: ConfigureEmployees, scope: ALL },
          ],
          user
        ),
      },
      {
        label: t('w.activities'),
        icon: IconCalendarTime,
        divider: false,
        isDisplayed: isDisplayTeamActivities,
        links: [
          {
            label: t('w.myActivities'),
            link: '/activities/user',
            divider: false,
            icon: IconReport,
            isDisplayed: isDisplayMyActivities,
          },
          {
            label: t('w.teamActivities'),
            link: '/activities/team',
            divider: false,
            icon: IconReport,
            isDisplayed: isDisplayTeamActivities,
          },
        ],
      },
      {
        label: (
          <Box component={'div'}>
            {t('w.validations')}
            {pendingValidationsCount.total > 0 && (
              <IndicatorBadge value={pendingValidationsCount.total} />
            )}
          </Box>
        ),
        icon: IconClipboardCheck,
        divider: false,
        isDisplayed:
          hasPermission(
            [{ permission: ValidateLeavesAbsences, scope: ALL }],
            user
          ) ||
          (getModule(ExpenseReport)?.active &&
            hasPermission(
              [{ permission: ValidateExpenseReports, scope: ALL }],
              user
            )),
        links: [
          {
            label: (
              <Box component={'div'}>
                {t('w.leavesAndAbsences')}
                {pendingValidationsCount.leaves > 0 && (
                  <IndicatorBadge value={pendingValidationsCount.leaves} />
                )}
              </Box>
            ),
            link: '/validation/leaves',
            divider: false,
            icon: IconReport,
            isDisplayed: hasPermission(
              [{ permission: ValidateLeavesAbsences, scope: ALL }],
              user
            ),
          },
          {
            label: (
              <Box component={'div'}>
                {t('w.expenseReports')}
                {pendingValidationsCount.expenseReports > 0 && (
                  <IndicatorBadge
                    value={pendingValidationsCount.expenseReports}
                  />
                )}
              </Box>
            ),
            link: '/validation/expense-reports',
            divider: false,
            icon: IconReport,
            isDisplayed:
              getModule(ExpenseReport)?.active &&
              hasPermission(
                [{ permission: ValidateExpenseReports, scope: ALL }],
                user
              ),
          },
        ],
      },
      {
        label: t('w.endOfMonth'),
        icon: IconCalendarDue,
        divider: true,
        isDisplayed: hasPermission(
          [
            { permission: ValidateLeavesAbsences, scope: ALL },
            { permission: ValidateExpenseReports, scope: ALL },
            { permission: ValidateEndOfMonth, scope: ALL },
            { permission: ValidatePayrollVariables, scope: ALL },
            { permission: ValidateHolidayCounter, scope: ALL },
            { permission: ValidatePayslips, scope: ALL },
          ],
          user
        ),
        links: [
          {
            label: t('w.validations'),
            link: '/end-of-month/validation',
            divider: false,
            icon: IconReport,
            isDisplayed: hasPermission(
              [
                { permission: ValidateLeavesAbsences, scope: ALL },
                { permission: ValidateEndOfMonth, scope: ALL },
                { permission: ValidatePayrollVariables, scope: ALL },
              ],
              user
            ),
          },
          {
            label: t('w.holidayCounters'),
            link: '/end-of-month/holiday-counter',
            divider: false,
            icon: IconBrandDaysCounter,
            isDisplayed: hasPermission(
              [{ permission: ValidateHolidayCounter, scope: ALL }],
              user
            ),
          },
          {
            label: t('w.payslips'),
            link: '/end-of-month/payslips',
            divider: false,
            icon: IconFileDollar,
            isDisplayed: hasPermission(
              [{ permission: ValidatePayslips, scope: ALL }],
              user
            ),
          },
          {
            label: t('w.expenseReports'),
            link: '/end-of-month/expense-reports',
            divider: false,
            icon: IconReceiptTax,
            isDisplayed:
              isExpenseReportModuleActive &&
              hasPermission(
                [{ permission: ValidateExpenseReports, scope: ALL }],
                user
              ),
          },
        ],
      },
      {
        label: 'Documents',
        icon: IconFolders,
        divider: false,
        isDisplayed:
          hasPermissionToReadMyPersonalSpace ||
          hasPermissionToReadCommonSpace ||
          isAuthorizedEditAccountantSpace,
        links: [
          {
            label: t('w.personal'),
            link: `/documents/personal`,
            divider: false,
            icon: IconReport,
            isDisplayed: hasPermissionToReadMyPersonalSpace,
          },
          {
            label: t('w.company'),
            link: `/documents/shared`,
            divider: false,
            icon: IconBrandDaysCounter,
            isDisplayed: hasPermissionToReadCommonSpace,
          },
          {
            label: t('w.payrollOfficers'),
            link: `/documents/accountant`,
            divider: false,
            icon: IconFileDollar,
            isDisplayed: isAuthorizedEditAccountantSpace,
          },
        ],
      },
      {
        label: t('w.myExpenseReports'),
        icon: IconReceipt,
        link: `/expense-reports/user/${user.id}`,
        divider: false,
        isDisplayed: isExpenseReportModuleActive,
      },
      {
        label: t('w.shuttleSheets'),
        icon: IconListDetails,
        link: `/shuttle-sheets/user/${user.id}`,
        divider: false,
        isDisplayed: isShuttleSheetModuleActive,
      },
      {
        label: t('w.myPayslips'),
        icon: IconFileDescription,
        link: `/payslips/user/${user.id}`,
        divider: true,
        isDisplayed: user.division?.name !== NO_WORK_UNIT,
      },
      {
        label: t('w.companySettings'),
        icon: IconSettings,
        link: `/company/${user.company.id}/home`,
        divider: false,
        isDisplayed: hasPermission(
          [
            { permission: ConfigureWorkUnit, scope: ALL },
            { permission: ConfigureWorkUnit, scope: TEAM },
            { permission: ConfigureEmployees, scope: ALL },
            { permission: ConfigureLeaveTypes, scope: ALL },
            { permission: ConfigureBlockingPeriods, scope: ALL },
            { permission: ConfigurePublicHolidaysCompanyLeaves, scope: ALL },
            { permission: ConfigureShuttleSheetTypes, scope: ALL },
            { permission: ConfigureAccountant, scope: ALL },
            { permission: ConfigureFeatures, scope: ALL },
            { permission: ConfigurePermissions, scope: ALL },
            { permission: ConfigureCompany, scope: ALL },
          ],
          user
        ),
      },
    ],
    [
      user,
      isExpenseReportModuleActive,
      isDisplayMyActivities,
      isDisplayTeamActivities,
      pendingValidationsCount,
      isShuttleSheetModuleActive,
      hasPermissionToReadMyPersonalSpace,
      hasPermissionToReadCommonSpace,
      isAuthorizedEditAccountantSpace,
    ]
  );

  const filteredCompanyLinks: Link[] = useMemo(
    () => companyLinks.filter((link) => link.isDisplayed),
    [companyLinks]
  );

  const accountantLinks: Link[] = useMemo(
    () => [
      {
        id: 'market-place',
        icon: IconBuildingStore,
        label: t('w.marketPlace'),
        link: '/chartered-accountant/market-place',
        divider: false,
        isDisplayed: true,
      },
      {
        id: 'customer-management',
        icon: IconUsers,
        label: t('w.customerManagement'),
        link: '/chartered-accountant/customer-management',
        divider: false,
        isDisplayed: true,
      },
      {
        id: 'customer-end-of-month',
        icon: IconCalendarDue,
        label: t('w.endOfMonth'),
        link: '/chartered-accountant/customer-end-of-month',
        divider: false,
        isDisplayed: true,
      },
      {
        id: 'customer-shuttle-sheet',
        icon: IconInbox,
        label: t('w.shuttleSheets'),
        link: '/chartered-accountant/customer-shuttle-sheet',
        divider: false,
        isDisplayed: true,
      },
      {
        id: 'accountant-configurations',
        icon: IconSettings,
        label: 'Configurations',
        link: '/chartered-accountant/configurations',
        divider: false,
        isDisplayed: hasPermissionToAccessToAccountantNotificationConfig,
      },
    ],
    [hasPermissionToAccessToAccountantNotificationConfig]
  );

  useEffect(() => {
    if (
      pathname === `/company/${companyId}/home` ||
      pathname === `/company/${companyId}/employees` ||
      pathname === `/company/${companyId}/work-units` ||
      pathname === `/company/${companyId}/counter-types` ||
      pathname === `/company/${companyId}/period-types` ||
      pathname === `/company/${companyId}/periods` ||
      pathname === `/company/${companyId}/public-holidays-and-leave` ||
      pathname === `/company/${companyId}/market-place` ||
      pathname === `/company/${companyId}/features` ||
      pathname === `/company/${companyId}/roles` ||
      pathname === `/company/${companyId}/settings` ||
      pathname === `/company/${companyId}/payroll-officer`
    ) {
      setIsDoubleNavBar(true);
    }
    if (
      pathname === '/home' ||
      pathname === '/planning' ||
      pathname === '/activities/user' ||
      pathname === '/activities/team' ||
      pathname === `/expense-reports/user/${user.id}` ||
      pathname === `/payslips/user/${user.id}` ||
      pathname === `/documents/user/${user.id}` ||
      pathname === `/presences/user/${user.id}` ||
      pathname === `/end-of-month/validations` ||
      pathname === `/end-of-month/holiday-counter` ||
      pathname === `/end-of-month/payslips` ||
      pathname === `/end-of-month/expense-reports` ||
      pathname === '/validation/leaves' ||
      pathname === '/validation/expense-reports' ||
      pathname === '/work-units' ||
      pathname === '/manage-payment-account' ||
      pathname === `/profile/user/${user.id}` ||
      pathname === `/chartered-accountant/market-place` ||
      pathname === `/account/user/${user.id}` ||
      pathname === '/chartered-accountant/customer-management' ||
      pathname === '/chartered-accountant/customer-end-of-month' ||
      pathname === '/chartered-accountant/customer-shuttle-sheet' ||
      pathname === '/chartered-accountant/configurations' ||
      pathname === '/end-of-month/validation' ||
      pathname === `/shuttle-sheets/user/${user.id}` ||
      pathname === `/documents/personal` ||
      pathname === `/documents/shared` ||
      pathname === `/documents/accountant`
    ) {
      setIsDoubleNavBar(false);
    }
  }, [pathname, companyId, user]);

  function checkIsNavBarNested(): boolean {
    return (
      !pathname.includes('accept/link') &&
      !pathname.includes('chartered-accountant/accept/customer/invitation') &&
      !isDoubleNavBar
    );
  }

  function checkIsDoubleNavBar(): boolean {
    return (
      !pathname.includes('accept/link') &&
      !pathname.includes('chartered-accountant/accept/customer/invitation') &&
      isDoubleNavBar
    );
  }

  return (
    <MantineAppShell
      navbar={{
        width: { md: 290 },
        breakpoint: 'md',
        collapsed: { mobile: !opened, desktop: !opened },
      }}
      header={{ height: { base: 50, md: 70 } }}
    >
      <MantineAppShell.Header withBorder={false}>
        <header className={s.boxShadow}>
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <Burger
              opened={opened}
              onClick={() => handlers.toggle()}
              size="sm"
              color={theme.colors.gray[6]}
              ml="md"
            />
            <Appbar />
          </div>
        </header>
      </MantineAppShell.Header>
      <MantineAppShell.Navbar className={s.appShellNavBar}>
        {checkIsNavBarNested() && (
          <MantineAppShell.Section grow component={ScrollArea}>
            <NavbarNested
              linksData={
                getPersistedObject('mode') === ACCOUNTANT_MODE
                  ? accountantLinks.filter((link) => link.isDisplayed)
                  : filteredCompanyLinks
              }
            />
          </MantineAppShell.Section>
        )}
        {checkIsDoubleNavBar() && (
          <MantineAppShell.Section grow>
            <DoubleNavbar
              linksData={
                companyId !== user.company.id
                  ? accountantLinks.filter((link) => link.isDisplayed)
                  : filteredCompanyLinks
              }
            />
          </MantineAppShell.Section>
        )}
        {!isDoubleNavBar && (
          <MantineAppShell.Section>
            <UserMenu isDoubleNavBar={false} />
          </MantineAppShell.Section>
        )}
      </MantineAppShell.Navbar>
      <MantineAppShell.Main>{children}</MantineAppShell.Main>
    </MantineAppShell>
  );
}
