import { Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconEdit, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo } from 'react';

import ExpenseStatusBadge from '../../../components/badges/ExpenseStatusBadge';
import CustomActionIconFile from '../../../components/CustomActionIconFile';
import CustomTooltip from '../../../components/CustomTooltip';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ExpenseReportService from '../../../services/ExpenseReportService';
import type { ExpenseReportObject, User } from '../../../types/types';
import { handleOpenExpenseReportAttachmentFile } from '../../../utils/attachmentFile';
import { getTotalHT, getTotalVAT } from '../../../utils/expenseReport';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import ExpenseReportRequestForm from './ExpenseReportRequestForm';

export type ExpenseReportByMonthViewProps = {
  month: string;
  refetch: () => void;
  userProfile: User;
};

export default function ExpenseReportByMonthView({
  month,
  refetch,
  userProfile,
}: ExpenseReportByMonthViewProps) {
  const { t, lang } = useI18n();
  const { access_token } = useAuth();

  const {
    data: userExpenseReportsByMonthData,
    refetch: refetchUserExpenseReportsByMonthData,
    isLoading: isExpenseReportByMonthDataLoading,
  } = useQuery({
    queryKey: ['ExpenseReportService.listByMonth', userProfile.id],
    queryFn: () =>
      ExpenseReportService.listByMonth(
        userProfile.id,
        dayjs(month).month() + 1,
        dayjs(month).year()
      ),
  });

  const expenseReportByMonth: ExpenseReportObject[] = useMemo(() => {
    if (!userExpenseReportsByMonthData) {
      return [];
    }
    return userExpenseReportsByMonthData;
  }, [userExpenseReportsByMonthData]);

  const columns: DataTableColumn<ExpenseReportObject>[] = useMemo(
    () => [
      {
        accessor: 'unit',
        title: t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: () =>
          userProfile.division.name === NO_WORK_UNIT
            ? t(NO_WORK_UNIT)
            : userProfile.division.name,
      },
      {
        accessor: 'dateOfExpense',
        title: t('expenseReport.dateOfExpense'),
        sortable: true,
        ellipsis: true,
        render: ({ dateOfExpense }) =>
          dayjs(dateOfExpense).format('DD/MM/YYYY'),
      },
      {
        accessor: 'fullname',
        title: t('w.fullname'),
        sortable: true,
        ellipsis: true,
        render: () => userProfile.fullname,
      },
      {
        accessor: 'type',
        title: t('w.type'),
        sortable: true,
        ellipsis: true,
        render: ({ type }) => t('expenseReport.type.' + type.toLowerCase()),
      },
      {
        accessor: 'label',
        title: t('w.wording'),
        sortable: true,
        ellipsis: true,
        render: ({ label }) => t('expenseReport.label.' + label),
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'attachment',
        title: t('expenseReport.receipt'),
        sortable: true,
        ellipsis: true,
        render: ({ companyId, id, attachment, status }) => {
          let extension = '';
          if (attachment) {
            const words = attachment.split('/');
            extension = words[words.length - 1]?.split('.')?.[1];
          }
          return (
            <Group>
              {attachment && (
                <CustomTooltip label={t('w.seeAttachment')}>
                  <CustomActionIconFile
                    fileName={attachment}
                    onClick={() =>
                      handleOpenExpenseReportAttachmentFile(
                        companyId,
                        id,
                        extension,
                        access_token,
                        t
                      )
                    }
                  />
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
      {
        accessor: 'amountHT',
        title: t('w.amountHT'),
        sortable: true,
        ellipsis: true,
        render: ({ additionValueVATList, amount }) =>
          getTotalHT(additionValueVATList, amount),
      },
      {
        accessor: 'totalVAT',
        title: t('w.totalVAT'),
        sortable: true,
        ellipsis: true,
        render: ({ additionValueVATList }) => getTotalVAT(additionValueVATList),
      },
      {
        accessor: 'amount',
        title: t('w.amountTTC'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'status.state',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => <ExpenseStatusBadge status={status} />,
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: ({ status, id, companyId }) => {
          return (
            <Group gap={4} justify="end" wrap={'nowrap'}>
              <CustomTooltip label={t('w.edit')}>
                <ActionIcon
                  disabled={status.state !== 'WAITING'}
                  variant={'subtle'}
                  size="sm"
                  color="blue"
                  onClick={() => handleOpenExpenseReportModalClick(id)}
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </CustomTooltip>
              <CustomTooltip label={t('w.delete')}>
                <ActionIcon
                  disabled={status.state !== 'WAITING'}
                  variant={'subtle'}
                  size="sm"
                  color="red"
                  onClick={() =>
                    mutateDelete({
                      companyId: companyId,
                      expenseReportId: id,
                    })
                  }
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </CustomTooltip>
            </Group>
          );
        },
      },
    ],
    [expenseReportByMonth]
  );

  function handleOpenExpenseReportModalClick(expenseReportId: string) {
    modals.open({
      modalId: `expense-report-${expenseReportId}-to-see-or-update-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t('w.expenseReport')} - ${
          userProfile.fullname
        }`}</Title>
      ),
      fullScreen: true,
      children: (
        <ExpenseReportRequestForm
          closeModal={() =>
            modals.close(
              `expense-report-${expenseReportId}-to-see-or-update-modal`
            )
          }
          expenseReport={expenseReportByMonth.find(
            (er: ExpenseReportObject) => er.id === expenseReportId
          )}
          edit={true}
          userProfile={userProfile}
        />
      ),
    });
  }

  const { mutate: mutateDelete } = useMutation({
    mutationFn: (variables: { companyId: string; expenseReportId: string }) =>
      ExpenseReportService.deleteExpenseReport(
        variables.companyId,
        variables.expenseReportId
      ),
    onSuccess: () => {
      showNotification({
        id: 'delete-expense-report-successful',
        title: t('w.success'),
        message: `${t('w.expenseReport')} ${t('w.deleted').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      refetchUserExpenseReportsByMonthData();
    },
    onError: (error) =>
      showNotification({
        id: 'delete-expense-report-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <Table
      pinFirstColumn
      pinLastColumn
      rows={expenseReportByMonth}
      columns={columns}
      lang={lang}
      fetching={isExpenseReportByMonthDataLoading}
      withTableBorder={false}
      height={'calc(100vh - 100px)'}
    />
  );
}
