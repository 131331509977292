import { LoadingOverlay, Space } from '@mantine/core';
import { IconBeach, IconBuildingStore, IconUsers } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import CustomStatsGrid from '../../../components/CustomStatsGrid/CustomStatsGrid';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CompanyService from '../../../services/CompanyService';
import { hasPermission } from '../../../utils/authorization';
import {
  ALL,
  ConfigureEmployees,
  ConfigureLeaveTypes,
  ConfigureWorkUnit,
  TEAM,
} from '../../../variables/BuiltInPermissions';

export default function CompanyHome() {
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { t } = useI18n();

  const { data, isLoading } = useQuery({
    queryKey: ['CompanyService.getCompanyHomeData', companyId],
    queryFn: () => CompanyService.getCompanyHomeData(companyId),
  });

  const companyHomeData = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        title: `${t('w.numberOf')} ${
          user.company?.divisionLabel
            ? user.company.divisionLabel
            : t('workUnit')
        }`,
        icon: IconBuildingStore,
        value: data[0],
        url: hasPermission(
          [
            { permission: ConfigureWorkUnit, scope: ALL },
            { permission: ConfigureWorkUnit, scope: TEAM },
          ],
          user
        )
          ? `/company/${companyId}/work-units`
          : undefined,
      },
      {
        title: t('w.numberOfEmployees'),
        icon: IconUsers,
        value: data[1],
        url: hasPermission(
          [
            { permission: ConfigureEmployees, scope: ALL },
            { permission: ConfigureEmployees, scope: TEAM },
          ],
          user
        )
          ? `/company/${companyId}/employees`
          : undefined,
      },
      {
        title: t('w.typesOfPeriod'),
        icon: IconBeach,
        value: data[2],
        url: hasPermission(
          [
            { permission: ConfigureLeaveTypes, scope: ALL },
            { permission: ConfigureLeaveTypes, scope: TEAM },
          ],
          user
        )
          ? `/company/${companyId}/period-types`
          : undefined,
      },
    ];
  }, [data]);

  return (
    <ScreenWrapper title={'Chiffres clés'}>
      <LoadingOverlay visible={isLoading} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <CustomStatsGrid data={companyHomeData} />
    </ScreenWrapper>
  );
}
