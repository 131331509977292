import {
  Button,
  Center,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  SimpleGrid,
  Space,
  Stepper,
  TextInput,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { isNotEmpty, useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import ButtonSave from '../../../../../components/ButtonSave';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import MandatoryLeaveService, {
  CompanyLeaveData,
} from '../../../../../services/MandatoryLeaveService';
import { Division, LeaveType } from '../../../../../types/types';
import { getExcludeDivisionIds } from '../../../../../utils/divisions';
import { milliToFullDateString } from '../../../../../utils/format';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';

type Props = {
  divisions: Division[];
  allCompanyLeaveTypesListData?: LeaveType[];
  refetchCompanyLeavesListData: () => void;
  onClose: () => void;
};

interface FormValues {
  name: string;
  description: string;
  dateRange: [Date, Date];
  excludeDivisions: string[];
  leaveTypeId: string;
  sessionStartDateSelected: string;
  sessionEndDateSelected: string;
}

export default function CompanyMandatoryLeavesStepper({
  divisions,
  allCompanyLeaveTypesListData,
  refetchCompanyLeavesListData,
  onClose,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const [active, setActive] = useState(0);
  const matches = useMediaQuery('(min-width: 1025px)');

  const morningLabel = t('leave.type.morning');
  const afternoonLabel = t('leave.type.afternoon');
  const wholeDayLabel = t('leave.type.wholeDay');

  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      description: '',
      dateRange: [new Date(), new Date()],
      excludeDivisions: [],
      leaveTypeId: '',
      sessionStartDateSelected: wholeDayLabel,
      sessionEndDateSelected: wholeDayLabel,
    },
    validate: {
      name: isNotEmpty(t('w.required')),
      leaveTypeId: isNotEmpty(t('w.required')),
    },
  });

  const isEndDateRangeAndSameDate = useMemo(() => {
    return (
      form.values.dateRange[1] &&
      dayjs(form.values.dateRange[0]).format('DD/MM/YYYY') !==
        dayjs(form.values.dateRange[1]).format('DD/MM/YYYY')
    );
  }, [form.values.dateRange[0], form.values.dateRange[1]]);

  const allCompanyLeaveTypesList = useMemo(() => {
    if (!allCompanyLeaveTypesListData) {
      return [];
    }
    return allCompanyLeaveTypesListData.map((item: LeaveType) => {
      return {
        value: item?.id as string,
        label: item?.name as string,
      };
    });
  }, [allCompanyLeaveTypesListData]);

  const divisionsSelect = useMemo(() => {
    if (!divisions) {
      return [];
    }
    return divisions.map((division: Division) => {
      return {
        value: division.id,
        label:
          division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name,
      };
    });
  }, [divisions]);

  function handleValidateFirstStepForm() {
    if (active === 0) {
      if (form.isValid('name') && form.isValid('leaveTypeId')) {
        form.clearFieldError('name');
        form.clearFieldError('leaveTypeId');
        setActive(active + 1);
      } else {
        form.validate();
      }
    }
  }

  useEffect(() => {
    if (allCompanyLeaveTypesList.length > 0) {
      form.setFieldValue('leaveTypeId', allCompanyLeaveTypesList[0].value);
    }
  }, [allCompanyLeaveTypesList.length]);

  useEffect(() => {
    if (active === 1) {
      if (form.values.sessionStartDateSelected.length > 0) {
        form.clearFieldError('sessionStartDateSelected');
      }
      if (
        form.values.sessionEndDateSelected &&
        form.values.sessionEndDateSelected.length > 0
      ) {
        form.clearFieldError('sessionEndDateSelected');
      }
    }
  }, [
    active,
    form.values.sessionStartDateSelected.length,
    form?.values?.sessionEndDateSelected?.length,
  ]);

  useEffect(() => {
    if (isEndDateRangeAndSameDate) {
      form.setFieldValue('sessionEndDateSelected', wholeDayLabel);
      form.setFieldValue('sessionStartDateSelected', wholeDayLabel);
    } else {
      form.setFieldValue('sessionEndDateSelected', wholeDayLabel);
      form.setFieldValue('sessionStartDateSelected', wholeDayLabel);
    }
  }, [isEndDateRangeAndSameDate]);

  function handleValidateSecondStepForm() {
    if (active === 1) {
      setActive(active + 1);
    }
  }

  const { mutate: createCompanyLeave, isLoading: isCreateCompanyLeaveLoading } =
    useMutation({
      mutationFn: (variables: CompanyLeaveData) =>
        MandatoryLeaveService.createCompanyLeave(companyId, variables),
      onSuccess: (data) => {
        refetchCompanyLeavesListData();
        onClose();
        const leaveName = data?.name;
        showNotification({
          id: `create-company-leave-${leaveName}-successful`,
          title: t('w.success'),
          message: t('success.companyLeave.created', leaveName),
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onError: (error: any) => {
        if (error?.response?.status === 403) {
          showNotification({
            id: `create-company-leave-error${error.response.data?.errorKey}`,
            title: t('w.error'),
            message: error.response.data.errorDescription,
            color: 'orange',
            icon: <IconAlertTriangle />,
          });
        } else {
          showNotification({
            id: 'create-company-leave-error',
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        }
      },
    });

  function getPeriodStart(values: FormValues) {
    if (values.sessionStartDateSelected === afternoonLabel) {
      return milliToFullDateString(
        dayjs(values.dateRange[0]).hour(12).minute(0).valueOf()
      );
    } else {
      return milliToFullDateString(
        dayjs(values.dateRange[0]).hour(0).minute(0).valueOf()
      );
    }
  }

  function getPeriodEnd(values: FormValues, index: number) {
    if (
      values.sessionEndDateSelected === morningLabel ||
      values.sessionStartDateSelected === morningLabel
    ) {
      return milliToFullDateString(
        dayjs(values.dateRange[index]).hour(11).minute(59).valueOf()
      );
    } else {
      return milliToFullDateString(
        dayjs(values.dateRange[index]).hour(23).minute(59).valueOf()
      );
    }
  }

  function handleCreateCompanyLeaveSubmit(values: FormValues) {
    if (values.excludeDivisions.length > 0) {
      form.clearFieldError('excludeDivisions');
      createCompanyLeave({
        companyId: companyId,
        name: values.name.trim(),
        periodStart: getPeriodStart(values),
        periodEnd: values.dateRange[1]
          ? getPeriodEnd(values, 1)
          : getPeriodEnd(values, 0),
        description: values.description,
        excludeDivisions: getExcludeDivisionIds(
          divisionsSelect.map((item: { value: string }) => item.value),
          values.excludeDivisions
        ),
        leaveTypeId: values.leaveTypeId,
        startHalf: values.sessionStartDateSelected !== wholeDayLabel,
        endHalf: values.sessionEndDateSelected !== wholeDayLabel,
      });
    } else {
      form.setFieldError('excludeDivisions', t('w.required'));
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleCreateCompanyLeaveSubmit(values)
      )}
    >
      <LoadingOverlay visible={isCreateCompanyLeaveLoading} />
      <Stepper active={active}>
        <Stepper.Step label={t('w.general')}>
          <SimpleGrid cols={{ base: 1, sm: 2 }}>
            <TextInput
              required
              label={<LabelText text={t('w.name')} />}
              placeholder={t('w.name')}
              {...form.getInputProps('name')}
              data-autofocus
            />
            <TextInput
              label={<LabelText text={'Description'} />}
              placeholder={'Description'}
              {...form.getInputProps('description')}
              data-autofocus
            />
            <Select
              required
              data={allCompanyLeaveTypesList}
              label={<LabelText text={t('w.typeOfPeriod')} />}
              placeholder={t('w.select')}
              searchable
              nothingFoundMessage={t('w.noValue')}
              {...form.getInputProps('leaveTypeId')}
            />
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step label="Dates">
          <SimpleGrid cols={{ base: 1, md: 2 }}>
            <Center>
              <DatePicker
                type="range"
                numberOfColumns={matches ? 2 : 1}
                allowSingleDateInRange
                {...form.getInputProps('dateRange')}
              />
            </Center>
            <SimpleGrid>
              {!isEndDateRangeAndSameDate && (
                <Select
                  data={[wholeDayLabel, morningLabel, afternoonLabel]}
                  label={
                    <LabelText
                      text={dayjs(form.values.dateRange[0]).format(
                        'DD/MM/YYYY'
                      )}
                    />
                  }
                  placeholder={t('w.select')}
                  {...form.getInputProps('sessionStartDateSelected')}
                />
              )}
              {isEndDateRangeAndSameDate && (
                <Select
                  data={[wholeDayLabel, afternoonLabel]}
                  label={
                    <LabelText
                      text={dayjs(form.values.dateRange[0]).format(
                        'DD/MM/YYYY'
                      )}
                    />
                  }
                  placeholder={t('w.select')}
                  {...form.getInputProps('sessionStartDateSelected')}
                />
              )}
              <Space h={'md'} />
              {isEndDateRangeAndSameDate && (
                <Select
                  data={[wholeDayLabel, morningLabel]}
                  label={
                    <LabelText
                      text={dayjs(form.values.dateRange[1]).format(
                        'DD/MM/YYYY'
                      )}
                    />
                  }
                  placeholder={t('w.select')}
                  {...form.getInputProps('sessionEndDateSelected')}
                />
              )}
            </SimpleGrid>
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step
          label={company?.divisionLabel ? company.divisionLabel : t('w.unit')}
        >
          <SimpleGrid cols={1}>
            <MultiSelect
              w={'50%'}
              data={divisionsSelect}
              label={
                <LabelText
                  text={
                    company?.divisionLabel ? company.divisionLabel : t('w.unit')
                  }
                />
              }
              placeholder={t('w.select')}
              searchable
              nothingFoundMessage={t('w.noValue')}
              {...form.getInputProps('excludeDivisions')}
            />
          </SimpleGrid>
        </Stepper.Step>
      </Stepper>
      <Group justify={'flex-end'} mt={'xl'}>
        {active > 0 && (
          <Button onClick={() => setActive(active - 1)}>{t('w.back')}</Button>
        )}
        {active < 2 ? (
          <Button
            onClick={() => {
              handleValidateFirstStepForm();
              handleValidateSecondStepForm();
            }}
          >
            {t('w.next')}
          </Button>
        ) : (
          <ButtonSave />
        )}
      </Group>
    </form>
  );
}
