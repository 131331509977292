import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import OauthServiceMS from '../services/OauthServiceMS';
import ProfileService from '../services/ProfileService';
import { OauthUserProfile } from '../types/types';

export type LoginResponse = {
  access_token: string;
  refresh_token: string;
  users: any[];
};

export type LoginError = {
  isError: boolean;
  details?: any;
};

export default function useHfwLogin(username: string, password: string) {
  const initialError: LoginError = {
    isError: false,
    details: undefined,
  };
  const [loginResponse, setLoginResponse] = useState<LoginResponse | undefined>(
    undefined
  );
  const [error, setError] = useState<LoginError>(initialError);
  const [accessToken, setAccessToken] = useState<string>('');
  const [refreshToken, setRefreshToken] = useState<string>('');
  const [oauthUserProfileResponse, setOauthUserProfileResponse] = useState<
    OauthUserProfile | undefined
  >(undefined);

  useQuery({
    enabled: !!accessToken,
    queryKey: ['OauthServiceMS.getProfileToOauthUser', accessToken],
    queryFn: () => OauthServiceMS.getProfileToOauthUser(accessToken),
    onSuccess: (data) => {
      setOauthUserProfileResponse(data);
      const profileIds = data.profileIds;
      if (profileIds.length === 1) {
        getCurrentUserProfile();
      } else if (profileIds.length > 1) {
        getUserHfwProfiles();
      }
    },
    onError: (error) => {
      setError({ isError: true, details: error });
    },
  });

  const { refetch: getUserHfwProfiles, isFetching: getUserHfwProfilesLoading } =
    useQuery({
      enabled: false,
      queryKey: ['ProfileService.getUserHfwProfiles', accessToken],
      queryFn: () => ProfileService.getUserHfwProfiles(accessToken),
      onSuccess: (data) => {
        setError(initialError);
        setLoginResponse({
          access_token: accessToken,
          refresh_token: refreshToken,
          users: data,
        });
      },
      onError: (error) => {
        setError({ isError: true, details: error });
      },
    });

  const {
    refetch: getCurrentUserProfile,
    isFetching: getCurrentUserProfileLoading,
  } = useQuery({
    enabled: false,
    queryKey: ['ProfileService.getCurrentUserProfile', accessToken],
    queryFn: () => {
      return ProfileService.getCurrentUserProfile(accessToken);
    },
    onSuccess: (data) => {
      setError(initialError);
      setLoginResponse({
        access_token: accessToken,
        refresh_token: refreshToken,
        users: [data],
      });
    },
    onError: (error) => {
      setError({ isError: true, details: error });
    },
  });

  const { mutate: mutateLogin, isLoading: loginLoading } = useMutation({
    mutationFn: () =>
      OauthServiceMS.login(
        'password',
        username,
        password,
        undefined,
        undefined
      ),
    onSuccess: (data) => {
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
    },
    onError: (error) => {
      setError({ isError: true, details: error });
    },
  });

  const login = useCallback(() => {
    if (username && password) {
      if (loginResponse) {
        setLoginResponse(undefined);
      }
      mutateLogin();
    }
  }, [username, password]);

  return {
    login,
    loading:
      loginLoading || getCurrentUserProfileLoading || getUserHfwProfilesLoading,
    error,
    loginResponse,
    oauthUserProfileResponse,
    accessToken,
    refreshToken,
  };
}
