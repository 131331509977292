export function typesRegime(t: any) {
  return [
    { value: '', label: t('w.noValue') },
    { value: 'EXECUTIVE', label: t('w.executive') },
    { value: 'ETAM', label: t('w.etam') },
    { value: 'WORKER', label: t('w.worker') },
    {
      value: 'OTHER',
      label: t('w.other'),
    },
  ];
}
