import { Button, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconTableDown,
  IconTableImport,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs, { type Dayjs } from 'dayjs';
import React, { useMemo, useState } from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import ScreenWrapper from '../../../../core/layouts/components/ScreenWrapper';
import useActivitiesSSE from '../../../../hooks/useActivitiesSSE';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import DailyWorkService from '../../../../services/DailyWorkService';
import type { Period, UserDailyWork } from '../../../../types/types';
import { hasPermission, isAccountant } from '../../../../utils/authorization';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import {
  ALL,
  AuthorizedUpsertDailyWork,
} from '../../../../variables/BuiltInPermissions';
import ImportActivitiesForm from '../../my-activities/components/ImportActivitiesForm';
import DailyWorkList from './DailyWorkList';

type Props = {
  companyId: string;
};

export default function TeamDailyWorks({ companyId }: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { lang } = useI18n();
  dayjs.locale(lang);
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(
    dayjs().startOf('month')
  );
  const [currentWeek, setCurrentWeek] = useState<Dayjs>(
    dayjs().startOf('week')
  );
  const [weekOrMonthPeriodShown, setWeekOrMonthPeriodShown] = useState('month');
  const [editedUserIds, setEditedUserIds] = useState<string[]>([]);
  const [expandedUserIds, setExpandedUserIds] = useState<string[]>([]);
  const { divisions: companyDivisions } = useFetchDivisions({ companyId });

  const period: Period = {
    start:
      weekOrMonthPeriodShown === 'week'
        ? currentWeek.startOf('week')
        : currentMonth,
    end:
      weekOrMonthPeriodShown === 'week'
        ? currentWeek.endOf('week')
        : currentMonth.endOf('month'),
  };

  const { usersActivities, refetch: refetchDailyWorkListOnPeriod } =
    useActivitiesSSE({
      divisionIds: companyDivisions?.map((division) => division.id),
      period,
    });

  const { mutate: exportUserDailyWorkByDivisions, isLoading: isLoadingExport } =
    useMutation({
      mutationFn: (variables: {
        divisionIds: string[];
        month: number;
        year: number;
      }) =>
        DailyWorkService.exportUserDailyWorkByDivisions(
          variables.divisionIds,
          variables.month,
          variables.year
        ),
      onSuccess: (data) => {
        saveDownloadedfile(data);
        showNotification({
          id: 'export-dailywork-success',
          message: t('w.success'),
          icon: <IconCheck />,
          color: 'green',
        });
      },
      onError: (error) =>
        showNotification({
          id: 'export-dailywork-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          icon: <IconX />,
          color: 'red',
        }),
    });

  const actionButtons = useMemo(
    () => [
      <Button
        key={'import-button'}
        disabled={
          (!hasPermission(
            { permission: AuthorizedUpsertDailyWork, scope: ALL },
            user
          ) &&
            !isAccountant(user, companyId)) ||
          !usersActivities?.length
        }
        leftSection={<IconTableDown />}
        bg={'white'}
        variant={'subtle'}
        onClick={() =>
          openImport(
            usersActivities,
            weekOrMonthPeriodShown === 'week' ? currentWeek : currentMonth
          )
        }
      >
        {t('w.import')}
      </Button>,
      <Button
        key={'export-button'}
        disabled={!usersActivities?.length || isLoadingExport}
        leftSection={<IconTableImport />}
        variant={'filled'}
        loading={isLoadingExport}
        onClick={() =>
          onExportClick(
            usersActivities,
            weekOrMonthPeriodShown === 'week' ? currentWeek : currentMonth
          )
        }
      >
        {t('w.export')}
      </Button>,
    ],
    [
      isLoadingExport,
      usersActivities,
      weekOrMonthPeriodShown,
      currentWeek,
      currentMonth,
    ]
  );

  function openImport(userDailyWorks: UserDailyWork[], date: Dayjs) {
    modals.open({
      modalId: `import-user-activities`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.import')}
        </Title>
      ),
      size: 'xl',
      children: (
        <ImportActivitiesForm
          exportClick={() => onExportClick(userDailyWorks, date)}
          refresh={refetchDailyWorkListOnPeriod}
        />
      ),
    });
  }

  function onExportClick(userDailyWorks: UserDailyWork[], date: Dayjs) {
    const divisionIds = userDailyWorks.map(
      (userDailyWork) => userDailyWork.dailyWorks[0].user.division.id
    );
    const uniqueDivisionIds = divisionIds.filter(
      (item: string, index: number) => divisionIds.indexOf(item) === index
    );
    exportUserDailyWorkByDivisions({
      divisionIds: uniqueDivisionIds,
      month: date.month() + 1,
      year: date.year(),
    });
  }

  return (
    <ScreenWrapper
      title={t('w.teamActivities')}
      paper
      actionButtons={actionButtons}
    >
      <DailyWorkList
        dailyWorkList={usersActivities!}
        refetchDailyWorkListOnPeriod={refetchDailyWorkListOnPeriod}
        currentMonth={currentMonth}
        onMonthChange={setCurrentMonth}
        currentWeek={currentWeek}
        onWeekChange={setCurrentWeek}
        weekOrMonthPeriodShown={weekOrMonthPeriodShown}
        onChangeWeekOrMonthPeriodShown={setWeekOrMonthPeriodShown}
        editedUserIds={editedUserIds}
        onChangeEditedUserIds={setEditedUserIds}
        expandedUserIds={expandedUserIds}
        onChangeExpandedUserIds={setExpandedUserIds}
        key={`${currentWeek.format('DD-MM')} ${currentMonth.format(
          'YYYY-MM-DD'
        )}`}
        companyId={companyId}
      />
    </ScreenWrapper>
  );
}
