import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconEdit,
  IconEye,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import MoreItemsBadge from '../../../../components/badges/MoreItemsBadge';
import YesNoBadge from '../../../../components/badges/YesNoBadge';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import ShuttleSheetService from '../../../../services/ShuttleSheetService';
import { Role, ShuttleSheetType } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import { roleName } from '../../../../utils/roleName';
import ShuttleSheetTypeForm from './ShuttleSheetTypeForm';

const componentName = 'ShuttleSheetTypeTable';

type Props = {
  rows: ShuttleSheetType[];
  isLoading: boolean;
  companyId: string;
  roles: Role[] | undefined;
  refetch: () => void;
};

export default function ShuttleSheetTypeTable({
  rows,
  isLoading,
  companyId,
  roles,
  refetch,
}: Props) {
  const { t, lang } = useI18n();

  function getUsableByRoles(usableBy: string[] | null): string[] {
    if (roles && usableBy) {
      const _roles: Role[] = [];
      usableBy.forEach((item) => {
        const findRole = roles.find((role) => role.id === item);
        if (findRole) {
          _roles.push(findRole);
        }
      });
      return _roles.map((role) => roleName(t, role.name));
    } else {
      return [];
    }
  }

  function handleOpenShuttleSheetTypeModal(
    shuttleSheetType: ShuttleSheetType,
    isEditable: boolean
  ): void {
    modals.open({
      modalId: `shuttle-sheet-type-${shuttleSheetType.id}-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t('w.shuttleSheetType')} : ${
          shuttleSheetType.name
        }`}</Title>
      ),
      size: '90%',
      children: (
        <ShuttleSheetTypeForm
          shuttleSheetType={shuttleSheetType}
          roles={roles}
          isEditable={isEditable}
          companyId={companyId}
          refetch={refetch}
        />
      ),
    });
  }

  const { mutate: deleteShuttleSheetType } = useMutation({
    mutationFn: (variables: string) =>
      ShuttleSheetService.deleteShuttleSheetType(companyId, variables),
    onSuccess: () => {
      showNotification({
        id: 'confirm-delete-shuttle-sheet-successful',
        title: t('w.success'),
        message: `${t('w.shuttleSheetType')} ${t('w.deleted')}`,
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: (error) =>
      showNotification({
        id: 'confirm-delete-shuttle-sheet-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleOpenConfirmDeleteShuttleSheetTypeModal(
    shuttleSheetType: ShuttleSheetType
  ) {
    modals.openConfirmModal({
      modalId: `confirm-delete-shuttle-sheet-type-${shuttleSheetType.id}-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t('w.confirmDeletion')} ${
          shuttleSheetType.name
        }`}</Title>
      ),
      size: 'xl',
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      onConfirm: () => deleteShuttleSheetType(shuttleSheetType.name),
    });
  }

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'name',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'transmittedToAccountant',
        title: t('w.forwardedToPayrollOfficer'),
        sortable: true,
        ellipsis: true,
        render: ({ transmittedToAccountant }) => (
          <YesNoBadge value={transmittedToAccountant} />
        ),
      },
      {
        accessor: 'usableBy',
        title: t('w.usableBy'),
        sortable: true,
        ellipsis: true,
        render: ({ usableBy }) =>
          usableBy ? (
            <MoreItemsBadge items={getUsableByRoles(usableBy)} />
          ) : (
            t('w.noValue')
          ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: (shuttleSheetType) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() =>
                  handleOpenShuttleSheetTypeModal(shuttleSheetType, false)
                }
              >
                <IconEye size={16} />
              </ActionIcon>
            </CustomTooltip>
            {shuttleSheetType.deletable && (
              <CustomTooltip label={t('w.edit')}>
                <ActionIcon
                  size="sm"
                  color="blue"
                  variant={'subtle'}
                  onClick={() =>
                    handleOpenShuttleSheetTypeModal(shuttleSheetType, true)
                  }
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </CustomTooltip>
            )}
            {shuttleSheetType.deletable && (
              <CustomTooltip label={t('w.delete')}>
                <ActionIcon
                  size="sm"
                  color="red"
                  variant={'subtle'}
                  onClick={() =>
                    handleOpenConfirmDeleteShuttleSheetTypeModal(
                      shuttleSheetType
                    )
                  }
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </CustomTooltip>
            )}
          </Group>
        ),
      },
    ],
    [roles]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'transmittedToAccountant',
          label: t('w.forwardedToPayrollOfficer'),
          choices: [
            { value: 'true', label: t('w.yes') },
            { value: 'false', label: t('w.no') },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      options={options}
      rows={rows}
      columns={columns}
      fetching={isLoading}
      withTableBorder={false}
      lang={lang}
    />
  );
}
