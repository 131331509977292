import { Group, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconAlertTriangle } from '@tabler/icons-react';
import { type NavigateFunction } from 'react-router/dist/lib/hooks';

import type { User } from '../types/types';
import {
  ALL,
  AuthorizedReadAllUsersInfo,
  AuthorizedReadEmployeesPayslips,
  ConfigureEmployees,
  ValidateLeavesAbsences,
} from '../variables/BuiltInPermissions';
import { ADMINISTRATOR } from '../variables/BuiltInRoles';
import { hasPermission, hasRole } from './authorization';

export default function goToUserProfile(
  currentUser: User,
  navigate: NavigateFunction,
  t: any,
  userId: string,
  userFullname: string
) {
  if (
    hasRole(ADMINISTRATOR, currentUser) ||
    hasPermission(
      { permission: AuthorizedReadAllUsersInfo, scope: ALL },
      currentUser
    ) ||
    hasPermission(
      [
        { permission: ConfigureEmployees, scope: ALL },
        { permission: ValidateLeavesAbsences, scope: ALL },
        { permission: AuthorizedReadEmployeesPayslips, scope: ALL },
      ],
      currentUser
    )
  ) {
    navigate(`/profile/user/${userId}`);
  } else {
    modals.open({
      id: 'alert-navigate-to-currentUser-profile-modal',
      title: (
        <Group>
          <Title size={'h3'} component="p">
            {t('w.warning')}
          </Title>
          <IconAlertTriangle color={'orange'} />
        </Group>
      ),
      children: (
        <Text fz={'sm'} fw={'bold'} c={'dimmed'}>{`${t(
          'w.YouAreNotAuthorisedToConsultTheProfileOf'
        )} ${userFullname}`}</Text>
      ),
    });
  }
}
