import { Button, Flex, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDeviceFloppy } from '@tabler/icons-react';
import React from 'react';

import type { ModuleToEditComponentProps } from '../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { ExpenseReportParams as ExpenseReportParamsType } from '../../../../types/types';

export default function ExpenseReportParams({
  module,
  updateModuleParams,
}: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const moduleParams = module?.configuration?.params as ExpenseReportParamsType;

  const form = useForm<ExpenseReportParamsType>({
    initialValues: {
      method: moduleParams?.method || 'DIRECTLY',
    },
  });

  const selectData = [
    { value: 'PAYROLL', label: t('w.payOnPayslip') },
    { value: 'DIRECTLY', label: t('w.payEmployeeDirectly') },
  ];

  function submit(values: ExpenseReportParamsType) {
    if (module) {
      updateModuleParams({
        apiUrl: module.description.url,
        payload: {
          active: true,
          ...values,
        },
      });
    }
  }

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Flex direction={'column'} gap={'xl'}>
        <Select
          label={t('w.expenseReportMethod')}
          data={selectData}
          allowDeselect={false}
          {...form.getInputProps('method')}
        />
        <Flex justify={'flex-end'} mt={'xl'}>
          <Button type={'submit'} leftSection={<IconDeviceFloppy />}>
            {t('w.saveAndActivate')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
