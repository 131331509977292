import { Box, Button, Group, Space, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import LabelText from '../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import LeaveService from '../../../../services/LeaveService';
import { Leave } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  leave: Leave;
  refetchLeavesList: () => void;
};

export default function DeclineLeave({
  leave: leaveToDecline,
  refetchLeavesList,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { company } = user;

  const leaveToDeclineForm = useForm({
    initialValues: {
      comment: '',
    },
  });

  const { mutate: declineLeave } = useMutation({
    mutationFn: (variables: { leaveId: string; comment: string }) =>
      LeaveService.declineRequest(
        company.id,
        variables.leaveId,
        variables.comment
      ),
    onSuccess: () => {
      refetchLeavesList();

      showNotification({
        id: 'confirm-leave-decline-success',
        title: t('w.success'),
        message: t('leave.request.confirmDecline.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      modals.close('DeclineLeave');
    },
    onError: (error) => handleErrorMessage(error, t),
  });

  return (
    <Box>
      <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
        {t(
          'leave.request.confirmDecline.askForComment',
          `${leaveToDecline?.who.fullname}`
        )}
      </Text>
      <Space h={'xl'} />
      <form
        onSubmit={leaveToDeclineForm.onSubmit((values) =>
          declineLeave({
            leaveId: leaveToDecline?.id!,
            comment: values.comment,
          })
        )}
      >
        <TextInput
          required
          label={<LabelText text={t('w.comment')} />}
          placeholder={t('w.required')}
          {...leaveToDeclineForm.getInputProps('comment')}
        />

        <Space h={'xl'} />
        <Group justify={'flex-end'}>
          <Button type={'submit'}>
            {t('leave.request.confirmDecline.declineRequest')}
          </Button>
        </Group>
      </form>
    </Box>
  );
}
