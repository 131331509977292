import React from 'react';

import type { User } from '../../../../types/types';
import DailyWorkMode from './DailyWorkMode';
import FixedPriceManagerMode from './FixedPriceManagerMode';

type Props = {
  userProfile: User;
};

export default function UserActivities({ userProfile }: Props) {
  if (userProfile.fixedPriceManager)
    return <FixedPriceManagerMode userProfile={userProfile} />;

  return <DailyWorkMode userProfile={userProfile} />;
}
