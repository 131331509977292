import { ActionIcon } from '@mantine/core';
import { IconHeadset } from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import { useI18n } from '../../../contexts/I18nProvider';
import { MS_WHITE_LABEL_ID } from '../../../variables/GlobalVariables';

export default function ContactSupport() {
  const { t } = useI18n();
  return (
    <CustomTooltip label={t('w.contactSupport')}>
      <ActionIcon
        variant="subtle"
        size={'lg'}
        color={'grey'}
        onClick={() =>
          window.open(
            MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
              ? 'https://africapaierh.com/contact'
              : 'https://hifivework.com/contacter-le-support/'
          )
        }
      >
        <IconHeadset />
      </ActionIcon>
    </CustomTooltip>
  );
}
