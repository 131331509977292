import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { Badge, Button, Group, Text, Title } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { upperFirst, useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCalendarCheck,
  IconCheck,
  IconChecks,
  IconFileExport,
  IconFileSpreadsheet,
  IconInfoCircle,
  IconTableExport,
  IconUserExclamation,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import UnitBadge from '../../../../components/badges/UnitBadge';
import CustomMenu from '../../../../components/CustomMenu';
import CustomModal from '../../../../components/CustomModal';
import CustomTooltip from '../../../../components/CustomTooltip';
import GoToUserButton from '../../../../components/GoToUserButton/GoToUserButton';
import ModuleToEditModal from '../../../../components/ModuleToEditModal';
import { useAppBar } from '../../../../contexts/AppBarProvider';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import CharteredAccountantService, {
  UpdatePayslipStatusPayload,
} from '../../../../services/CharteredAccountantService';
import CkModuleManagerServiceMS from '../../../../services/CkModuleManagerServiceMS';
import EndOfMonthService, {
  ValidateEmployeesEndOfMonthPayload,
} from '../../../../services/EndOfMonthService';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import ModuleWithParamsService from '../../../../services/ModuleWithParamsService';
import VariablePayrollService from '../../../../services/VariablePayrollService';
import {
  Company,
  Division,
  EndOfMonthValidation,
  EndOfMonthValidationData,
  EndOfMonthValidationLeave,
  EndOfMonthVariablesPayroll,
  ExpenseReportObject,
  ExpenseReportParams,
  IdentifiantCompany,
  Module,
  PayslipStatus,
  User,
  VariablePayroll,
} from '../../../../types/types';
import { hasPermission, isAccountant } from '../../../../utils/authorization';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import {
  ALL,
  ConfigureFeatures,
  ValidateEndOfMonth,
} from '../../../../variables/BuiltInPermissions';
import {
  ExpenseReport,
  ExportCegidExpert,
  ExportDIAPAIE,
  ExportISAPAYE,
  ExportQuadra,
  ExportSilae,
  ModuleNameType,
  SynchroOpenPaye,
  VariablePayroll as VariablePayrollModuleName,
} from '../../../../variables/ModulesV2';
import EditVariablePayrollFileInput from './EditVariablePayrollFileInput';
import EditVariablePayrollTextInput from './EditVariablePayrollTextInput';
import EndOfMonthCommentTextInput from './EndOfMonthCommentTextInput';
import EndOfMonthConfirmValidateEmployeesList from './EndOfMonthConfirmValidateEmployeesList';
import EndOfMonthDivisionsToValidate from './EndOfMonthDivisionsToValidate';
import EndOfMonthValidationExpenseReports from './EndOfMonthValidationExpenseReports';
import EndOfMonthValidationLeaveRequestsTable from './EndOfMonthValidationLeaveRequestsTable';

type Props = {
  selectedDate: DateValue | DatesRangeValue | Date[];
  divisions: Division[];
  company: Company;
  isAccountantEndOfMonth?: boolean;
  globalValidation?: boolean;
};

const componentName = 'EndOfMonthValidationTable';

type EndOfMonthValidationVariablePayroll = {
  employee: string;
  variable: EndOfMonthVariablesPayroll;
};

export default function EndOfMonthValidationTable({
  selectedDate,
  divisions,
  company,
  isAccountantEndOfMonth,
  globalValidation,
}: Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { appBarSelectedDivisions } = useAppBar();

  const { data: modules, refetch: refetchModules } = useQuery({
    queryKey: ['CkModuleManagerServiceMS.list', company.id],
    queryFn: () => CkModuleManagerServiceMS.list(company.id, 'companyFeature'),
  });

  function getModule(moduleName: ModuleNameType): Module | undefined {
    if (!modules?.length) return undefined;
    return modules.find((module: Module) => module.name === moduleName);
  }
  const [editVariablePayrollOpened, editVariablePayrollOpenedHandlers] =
    useDisclosure(false);
  const queryClient = useQueryClient();
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const expenseReportModule: Module | undefined = getModule(ExpenseReport);
  const isExportQuadraModuleActive = Boolean(getModule(ExportQuadra)?.active);
  const isExportSilaeModuleActive = Boolean(getModule(ExportSilae)?.active);
  const isExportDiapaieModuleActive = Boolean(getModule(ExportDIAPAIE)?.active);
  const isExportIsapayeModuleActive = Boolean(getModule(ExportISAPAYE)?.active);
  const isSyncroOpenPayeModuleActive = Boolean(
    getModule(SynchroOpenPaye)?.active
  );
  const isExportCegidExpertModuleActive = Boolean(
    getModule(ExportCegidExpert)?.active
  );
  const variablePayrollModule = getModule(VariablePayrollModuleName);
  const isVariablePayrollModuleActive = Boolean(variablePayrollModule?.active);
  const expenseReportModuleParams = expenseReportModule?.configuration
    ?.params as ExpenseReportParams;
  const isDisplayedExpenseReports =
    Boolean(expenseReportModule?.active) &&
    expenseReportModuleParams?.method !== 'DIRECTLY';
  const [expenseReportsModalUserId, setExpenseReportsModalUserId] = useState<
    string | null
  >(null);

  const divisionIds: string[] = useMemo(() => {
    if (!divisions) return [];

    if (!appBarSelectedDivisions?.length) {
      return divisions.map((item) => item.id);
    }

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions]);

  const {
    data: endOfMonthValidationData,
    isLoading: isEndOfMonthValidationDataLoading,
    refetch: refetchEndOfMonthValidationData,
  } = useQuery({
    queryKey: ['EndOfMonthService.getEndOfMonthValidation', company.id],
    queryFn: () =>
      EndOfMonthService.getEndOfMonthValidation(company.id, {
        // @ts-ignore
        monthConcerned: dayjs(selectedDate)
          .set('date', 15)
          .format('DD/MM/YYYY'),
        divisionIds,
      }),
  });

  const {
    data: variablesPayrollData,
    isLoading: isVariablesPayrollDataLoading,
    refetch: refetchVariablesPayrollData,
  } = useQuery({
    queryKey: ['VariableParollService.getVariables', company.id],
    queryFn: () => VariablePayrollService.getVariables(company.id),
  });

  const variablesPayroll: VariablePayroll[] = useMemo(() => {
    if (!variablesPayrollData?.variablesPayroll) {
      return [];
    }
    return variablesPayrollData.variablesPayroll;
  }, [variablesPayrollData]);

  const { data: expenseReportListData, refetch: refetchExpenseReportListData } =
    useQuery({
      enabled: isDisplayedExpenseReports,
      queryKey: [
        'ExpenseReportService.getExpenseReportsByDivisionsAndMonth',
        company.id,
      ],
      queryFn: () =>
        ExpenseReportService.getExpenseReportsByDivisionsAndMonth(company.id, {
          // @ts-ignore
          monthConcerned: dayjs(selectedDate)
            .set('date', 15)
            .format('DD/MM/YYYY'),
          divisionIds: divisionIds,
        }),
    });

  const { mutate: exportEndOfMonth, isLoading: isEndOfMonthExportLoading } =
    useMutation({
      mutationFn: (variables: string) =>
        EndOfMonthService.getEndOfMonthExport(company.id, variables, {
          // @ts-ignore
          monthConcerned: dayjs(selectedDate)
            .set('date', 15)
            .format('DD/MM/YYYY'),
          divisionIds: divisionIds,
        }),
      onSuccess: (data) => saveDownloadedfile(data),
      onError: (error) => {
        showNotification({
          id: 'export-end-of-month-error',
          title: t('w.error'),
          message:
            // @ts-ignore
            error.response.status === 400
              ? t('ms.endofmonth.error.exportcontroller.getExport.codeNotFound')
              : // @ts-ignore
              error.response.status === 404
              ? t(
                  'ms.endofmonth.error.exportcontroller.getExport.openpayeNotFound'
                )
              : handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        });
      },
    });

  function refetchAllData() {
    refetchEndOfMonthValidationData();
    refetchVariablesPayrollData();
  }

  useEffect(() => {
    if (selectedDate || divisionIds) {
      refetchAllData();
      if (isDisplayedExpenseReports) {
        refetchExpenseReportListData();
      }
    }
  }, [selectedDate, divisionIds, isDisplayedExpenseReports]);

  const endOfMonthValidation: EndOfMonthValidation[] = useMemo(() => {
    if (!endOfMonthValidationData) {
      return [];
    }
    return endOfMonthValidationData;
  }, [endOfMonthValidationData]);

  const expenseReportsList: ExpenseReportObject[] = useMemo(() => {
    const result: ExpenseReportObject[] = [];
    if (!expenseReportListData) {
      return [];
    } else {
      expenseReportListData.forEach((item) =>
        item.values.forEach((expenseReport) => result.push(expenseReport))
      );
      return result;
    }
  }, [expenseReportListData]);

  function convertVariablesPayrollToArray(
    variablesPayroll: any
  ): EndOfMonthVariablesPayroll[] {
    let array: any[] = [];
    Object.keys(variablesPayroll).forEach((key) => {
      const element = variablesPayroll[key];
      let obj = {
        id: key,
        value: element.value,
        editable: element.editable,
      };
      array.push(obj);
    });
    return array;
  }

  const rows: EndOfMonthValidationData[] = useMemo(() => {
    if (endOfMonthValidation || expenseReportsList) {
      const result: EndOfMonthValidationData[] = [];
      endOfMonthValidation.forEach((item) => {
        item.data.forEach((data) => {
          const _expenseReports = isDisplayedExpenseReports
            ? expenseReportsList.filter(
                (expenseReport) => expenseReport.creator === data.employee
              )
            : [];
          result.push({
            ...data,
            expenseReports: _expenseReports,
            variablesPayroll: convertVariablesPayrollToArray(
              data.variablesPayroll
            ),
            endOfMonthId: item.id,
          });
        });
      });
      return result;
    } else {
      return [];
    }
  }, [endOfMonthValidation, expenseReportsList, isDisplayedExpenseReports]);

  const modalData = useMemo(() => {
    if (!expenseReportsModalUserId) return undefined;
    return rows.find((item) => item.employee === expenseReportsModalUserId);
  }, [expenseReportsModalUserId, rows]);

  const endOfMonthVariablesPayroll:
    | EndOfMonthValidationVariablePayroll[]
    | undefined = useMemo(() => {
    if (rows) {
      const result: EndOfMonthValidationVariablePayroll[] = [];
      rows.forEach((row) =>
        row.variablesPayroll.forEach((variable) =>
          result.push({ employee: row.employee, variable: variable })
        )
      );
      return result;
    }
  }, [rows]);

  const isValidEndOfMonth = useMemo(() => {
    if (!Boolean(endOfMonthValidation)) {
      return false;
    } else {
      return endOfMonthValidation.every((item) => item.valid);
    }
  }, [endOfMonthValidation, selectedDate, divisionIds]);

  const isAllValidEmployees = useMemo(() => {
    if (Boolean(rows)) {
      return rows.every((row) => row.valid);
    } else {
      return false;
    }
  }, [rows, selectedDate, divisionIds]);

  const {
    mutate: validateEmployeesEndOfMonth,
    isSuccess: isValidateEmployeesEndOfMonthSuccess,
  } = useMutation({
    mutationFn: (variables: ValidateEmployeesEndOfMonthPayload) =>
      EndOfMonthService.validateEmployeesEndOfMonth(company.id, variables),
    onSuccess: () => {
      showNotification({
        id: `validate-employees-end-of-month-successful`,
        title: t('w.success'),
        message: t('w.endOfMonthValidatedForConcernedEmployee'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `validate-employees-end-of-month-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateModuleParams } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        company.id,
        variables.payload
      ),
    onSuccess: () => {
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchModules();
    },
    onError: (err) =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  useEffect(() => {
    if (isValidateEmployeesEndOfMonthSuccess) {
      refetchAllData();
    }
  }, [isValidateEmployeesEndOfMonthSuccess]);

  const {
    mutate: validateDivisionEndOfMonth,
    isLoading: isValidateDivisionEndOfMonthLoading,
  } = useMutation({
    mutationFn: (variables: string) =>
      EndOfMonthService.validateDivisionEndOfMonth(company.id, variables),
    onSuccess: (_data) => {
      showNotification({
        id: `validate-division-end-of-month-successful`,
        title: t('w.success'),
        message: t('w.endOfMonthValidated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchAllData();
      if (isAccountantEndOfMonth) {
        queryClient.invalidateQueries({
          queryKey: [
            'CharteredAccountantService.accountantCustomersList',
            company.id,
            // @ts-ignore
            dayjs(selectedDate).format('MM-YYYY'),
          ],
        });
        setTimeout(
          () =>
            modals.close(
              `customer-company-id-${company.id}-end-of-month-modal`
            ),
          2000
        );
      }
    },
    onError: (error) =>
      showNotification({
        id: 'validate-division-end-of-month-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateCustomerUsersPayslipStatusEndOfMonth } = useMutation({
    mutationFn: (variables: UpdatePayslipStatusPayload[]) =>
      CharteredAccountantService.updateCustomerUsersPayslipStatusEndOfMonth(
        company.id,
        variables
      ),
    onSuccess: () => {
      refetchAllData();
      showNotification({
        id: 'update-payslip-status-success',
        title: t('w.success'),
        message: `${t('w.status')} ${t('w.updated').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'update-payslip-status-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'green',
        icon: <IconCheck />,
      }),
  });

  function handleUpdateUsersPayslipStatus(payslipStatus: PayslipStatus) {
    const data: UpdatePayslipStatusPayload[] = selectedEmployees
      .map((employee: EndOfMonthValidationData) => ({
        endOfMonthValidationId: employee.endOfMonthId,
        employeeIds: selectedEmployees
          .filter(
            (_employee: EndOfMonthValidationData) =>
              _employee.endOfMonthId === employee.endOfMonthId
          )
          .map((_employee: EndOfMonthValidationData) => ({
            userId: _employee.employee,
            status: payslipStatus,
          })),
      }))
      .filter(
        (
          value: UpdatePayslipStatusPayload,
          index: number,
          self: UpdatePayslipStatusPayload[]
        ) =>
          index ===
          self.findIndex(
            (item) =>
              item.endOfMonthValidationId === value.endOfMonthValidationId
          )
      );
    updateCustomerUsersPayslipStatusEndOfMonth(data);
  }

  function handleValidateEmployeesEndOfMonth() {
    const employeesByDivisionToValidate: ValidateEmployeesEndOfMonthPayload[] =
      selectedEmployees
        .map((employee: EndOfMonthValidationData) => ({
          endOfMonthId: employee.endOfMonthId,
          employeesIds: selectedEmployees
            .filter(
              (_employee: EndOfMonthValidationData) =>
                _employee.endOfMonthId === employee.endOfMonthId
            )
            .map((item: EndOfMonthValidationData) => item.employee),
        }))
        .filter(
          (
            value: ValidateEmployeesEndOfMonthPayload,
            index: number,
            self: ValidateEmployeesEndOfMonthPayload[]
          ) =>
            index ===
            self.findIndex((item) => item.endOfMonthId === value.endOfMonthId)
        );

    employeesByDivisionToValidate.forEach((employeeToValidate) =>
      validateEmployeesEndOfMonth({
        endOfMonthId: employeeToValidate.endOfMonthId,
        employeesIds: employeeToValidate.employeesIds,
      })
    );
  }

  function handleOpenEmployeesToValidateModalClick(
    payslipStatus: PayslipStatus
  ) {
    modals.openConfirmModal({
      modalId: globalValidation
        ? 'mark-payslip-as-prepared-modal'
        : 'validate-employee-end-of-month-modal',
      title: (
        <Title size={'h3'} component="p">
          {globalValidation
            ? `${t('w.markAs')} ${t(`w.${payslipStatus}`)}`
            : selectedEmployees.length === 1
            ? t('w.validateFollowingCollaborator')
            : t('w.validateFollowingCollaborators')}
        </Title>
      ),
      size: 'lg',
      children: (
        <EndOfMonthConfirmValidateEmployeesList
          selectedEmployees={selectedEmployees}
        />
      ),
      withCloseButton: false,
      labels: { confirm: t('w.validate'), cancel: t('w.cancel') },
      onConfirm: () => {
        if (globalValidation) {
          handleUpdateUsersPayslipStatus(payslipStatus);
        } else {
          handleValidateEmployeesEndOfMonth();
        }
      },
    });
  }

  function handleValidateEndOfMonthClick() {
    modals.openConfirmModal({
      modalId: 'validate-end-of-month-modal',
      title: (
        <Title size={'h3'} component="p">{`${t('w.closeMonth')} - ${dayjs(
          // @ts-ignore
          selectedDate
        ).format('MMMM YYYY')} ?`}</Title>
      ),
      children: (
        <EndOfMonthDivisionsToValidate
          divisions={divisions}
          endOfMonthValidation={endOfMonthValidation}
        />
      ),
      size: 'lg',
      labels: {
        confirm: t('w.closeMonth').split(' ')[0],
        cancel: t('w.cancel'),
      },
      onConfirm: () =>
        divisionIds.forEach((divisionId) =>
          endOfMonthValidation
            .filter((endOfMonth) => endOfMonth.divisionId === divisionId)
            .forEach((endOfMonth) => validateDivisionEndOfMonth(endOfMonth.id))
        ),
    });
  }

  const leftCustomActions = useMemo(
    () => [
      globalValidation ? (
        <CustomTooltip label={t('w.markAs')} key={'mark-payslip-as-done'}>
          <Button
            leftSection={<IconChecks />}
            disabled={selectedEmployees.length === 0}
            onClick={() => handleOpenEmployeesToValidateModalClick('DONE')}
          >
            {upperFirst(t(`w.DONE`))}
          </Button>
        </CustomTooltip>
      ) : (
        <Button
          key={'validate-employees'}
          leftSection={<IconChecks />}
          disabled={selectedEmployees.length === 0}
          onClick={() => handleOpenEmployeesToValidateModalClick('DONE')}
        >
          {`${t('w.validate')} ${t('w.employees')}`}
        </Button>
      ),
      globalValidation ? (
        <CustomTooltip label={t('w.markAs')} key={'mark-payslip-as-to-do'}>
          <Button
            leftSection={<IconUserExclamation />}
            disabled={selectedEmployees.length === 0}
            onClick={() => handleOpenEmployeesToValidateModalClick('TODO')}
          >
            {upperFirst(t(`w.TODO`))}
          </Button>
        </CustomTooltip>
      ) : null,
      !!divisionIds.length &&
      isValidEndOfMonth &&
      !!endOfMonthValidationData?.length ? (
        <Badge
          key={'monthClosed'}
          leftSection={<IconCheck color={'#0A9182'} />}
          size="xl"
          radius="md"
          color={'#CEE9E6'}
          p={'lg'}
          styles={{ label: { textTransform: 'none', color: '#0A9182' } }}
        >
          {t(
            'w.monthClosedOn',
            dayjs(
              endOfMonthValidationData?.[0]?.validationDate,
              'YYYY-MM-DD'
            ).format('DD/MM/YYYY')
          )}
        </Badge>
      ) : null,
      !isValidEndOfMonth && !isAllValidEmployees ? (
        <Badge
          key={'thereAreStillEmployeesToBeValidated'}
          leftSection={<IconInfoCircle color={'#E70D4F'} />}
          size="xl"
          radius="md"
          color={'#E70D4F1A'}
          p={'lg'}
          styles={{ label: { textTransform: 'none', color: '#E70D4F' } }}
        >
          {t('w.thereAreStillEmployeesToBeValidated')}
        </Badge>
      ) : isValidEndOfMonth ? null : (
        <Button
          key={'closeMonth'}
          leftSection={<IconCalendarCheck />}
          variant={'outline'}
          onClick={handleValidateEndOfMonthClick}
          loading={isValidateDivisionEndOfMonthLoading}
        >
          {t('w.closeMonth')}
        </Button>
      ),
    ],
    [
      endOfMonthValidationData,
      selectedEmployees.length,
      isValidEndOfMonth,
      isAllValidEmployees,
      divisionIds,
      isValidateDivisionEndOfMonthLoading,
    ]
  );

  const exportMenuItems = useMemo(() => {
    const exports: { label: string; icon: ReactNode; onClick: () => void }[] = [
      {
        label: 'XLSX',
        icon: <IconFileSpreadsheet />,
        onClick: () => exportEndOfMonth('XLSX'),
      },
    ];
    if (isExportQuadraModuleActive) {
      exports.push({
        label: 'Quadra',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth('Quadra'),
      });
    }
    if (isExportSilaeModuleActive) {
      exports.push({
        label: 'Silae',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth('Silae'),
      });
    }
    if (isExportDiapaieModuleActive) {
      exports.push({
        label: 'DIAPAIE',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth('DIAPAIE'),
      });
    }
    if (isExportIsapayeModuleActive) {
      exports.push({
        label: 'ISAPAYE',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth('ISAPAYE'),
      });
    }
    if (isSyncroOpenPayeModuleActive) {
      exports.push({
        label: 'SynchroOpenPaye',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth('SynchroOpenPaye'),
      });
    }
    if (isExportCegidExpertModuleActive) {
      exports.push({
        label: 'Cegid Expext',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth(ExportCegidExpert),
      });
    }
    return exports;
  }, [
    isExportQuadraModuleActive,
    isExportSilaeModuleActive,
    isExportDiapaieModuleActive,
    isExportIsapayeModuleActive,
    isSyncroOpenPayeModuleActive,
    isExportCegidExpertModuleActive,
  ]);

  function openConfigureVariablePayroll() {
    editVariablePayrollOpenedHandlers.open();
  }

  function activateVariablePayrollFeature() {
    if (variablePayrollModule?.description?.url) {
      let payload: any;
      if (variablePayrollModule.configuration.params) {
        payload = {
          ...variablePayrollModule.configuration.params,
          active: true,
        };
      } else {
        payload = {
          active: true,
        };
      }
      updateModuleParams({
        apiUrl: variablePayrollModule.description.url,
        payload,
      });
    }
  }

  const rightCustomActions = useMemo(() => {
    const result = [
      <CustomMenu
        key={'end-of-month-export'}
        buttonLabel={t('w.export')}
        buttonVariant={'filled'}
        leftSection={<IconTableExport />}
        menuLabel={`${t('w.endOfMonth')} via :`}
        menuItems={exportMenuItems}
        buttonLoading={isEndOfMonthExportLoading}
        buttonDisabled={!divisionIds.length}
      />,
    ];

    if (
      isAccountant(user, company.id) ||
      hasPermission({ permission: ConfigureFeatures, scope: ALL }, user)
    ) {
      return [
        <Button
          key={'variable-payroll-module'}
          variant="light"
          onClick={
            isVariablePayrollModuleActive
              ? openConfigureVariablePayroll
              : activateVariablePayrollFeature
          }
        >
          {isVariablePayrollModuleActive
            ? t('w.configureVariablePayroll')
            : t('w.activateVariablePayrollFeature')}
        </Button>,
      ].concat(result);
    }

    return result;
  }, [
    isVariablePayrollModuleActive,
    exportMenuItems,
    isEndOfMonthExportLoading,
    divisionIds,
  ]);

  function displayUserWorkUnitName(userDivisionId: string) {
    const findDivision = divisions.find(
      (division) => division.id === userDivisionId
    );
    if (findDivision) {
      return <UnitBadge unit={findDivision.name} />;
    }

    return null;
  }

  function getDivisionName(divisionId: string | undefined): string | undefined {
    const findDivision = divisions.find(
      (division) => division.id === divisionId
    );
    if (findDivision) {
      return findDivision.name;
    }
  }

  function getVariableValue(
    employee: string,
    variablePayroll: VariablePayroll
  ): string {
    const findVariablePayroll = endOfMonthVariablesPayroll?.find(
      (item) =>
        item.employee === employee && item.variable.id === variablePayroll.id
    );
    if (!findVariablePayroll) {
      return '';
    }

    if (findVariablePayroll.variable.value) {
      return parseFloat(findVariablePayroll.variable.value).toFixed(2);
    }

    return findVariablePayroll.variable.value;
  }

  const columns: DataTableColumn<EndOfMonthValidationData>[] = useMemo(() => {
    const defaultColumns: DataTableColumn<EndOfMonthValidationData>[] = [
      {
        accessor: 'employeeObject.fullname',
        title: t('w.employee'),
        sortable: true,
        ellipsis: true,
        render: ({ employeeObject }) => (
          <GoToUserButton
            userId={employeeObject.id}
            userFullname={employeeObject.fullname}
          />
        ),
      },
      {
        accessor: 'employee',
        title: t('w.serialNumber'),
        sortable: true,
        ellipsis: true,
        render: ({ employeeObject }) =>
          employeeObject.identifiantsCompany
            ?.map((item: IdentifiantCompany) => item.matricule)
            ?.join(' - '),
      },
      {
        accessor: 'unit',
        title: company?.divisionLabel ? company.divisionLabel : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ employeeObject }) =>
          displayUserWorkUnitName(employeeObject.divisionId),
      },
      {
        accessor: 'leaveRequests',
        title: t('w.leaveRequests'),
        sortable: true,
        ellipsis: true,
        render: ({ leaves, employee, employeeObject }) => (
          <Badge
            onClick={() =>
              handleOpenEmployeeLeaveRequestsModal(
                leaves,
                employee,
                employeeObject.fullname,
                false
              )
            }
            color={
              leaves.every(
                (leave: EndOfMonthValidationLeave) =>
                  leave.status.state === 'VALID'
              )
                ? '#CEE9E6'
                : '#E70D4F1A'
            }
            styles={{
              label: {
                textTransform: 'none',
                color: leaves.every(
                  (leave: EndOfMonthValidationLeave) =>
                    leave.status.state === 'VALID'
                )
                  ? '#0A9182'
                  : '#E70D4F',
              },
              root: { cursor: leaves.length === 0 ? 'default' : 'pointer' },
            }}
          >
            {leaves.length === 0
              ? 'Pas de congé'
              : leaves.length === 1
              ? leaves.length + ' demande'
              : leaves.length + ' demandes'}
          </Badge>
        ),
      },
      {
        accessor: globalValidation ? 'payslipStatus' : 'valid',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ leaves, employee, employeeObject, valid, payslipStatus }) =>
          !globalValidation ? (
            displayUserStatusEndOfMonthBadge(
              leaves,
              employee,
              employeeObject,
              valid
            )
          ) : (
            <Badge
              leftSection={
                payslipStatus === 'TODO' ? (
                  <IconUserExclamation color={'#E70D4F'} size={15} />
                ) : (
                  <IconChecks color={'#0A9182'} size={15} />
                )
              }
              color={payslipStatus === 'TODO' ? '#FDE6ED' : '#CEE9E6'}
              styles={{
                label: {
                  textTransform: 'none',
                  color: payslipStatus === 'TODO' ? '#E70D4F' : '#0A9182',
                },
              }}
            >
              {upperFirst(t(`w.${payslipStatus}`))}
            </Badge>
          ),
      },
    ];
    if (isDisplayedExpenseReports) {
      defaultColumns.push({
        accessor: 'expenseReports',
        title: t('w.expenseReports'),
        sortable: true,
        ellipsis: true,
        render: ({ expenseReports, employee }) =>
          expenseReports && (
            <Badge
              p={'sm'}
              variant={expenseReports.length > 0 ? 'dot' : 'filled'}
              color={
                expenseReports.length === 0
                  ? '#CEE9E6'
                  : 'var(--mantine-color-hifivework-6)'
              }
              styles={{
                label: {
                  textTransform: 'none',
                  color:
                    expenseReports.length === 0
                      ? '#0A9182'
                      : 'var(--mantine-color-hifivework-6)',
                },
                root: {
                  cursor: expenseReports.length === 0 ? 'default' : 'pointer',
                },
              }}
              onClick={() => setExpenseReportsModalUserId(employee)}
            >
              {expenseReports.length === 0
                ? 'Pas de note de frais'
                : `${expenseReports.length} ${
                    expenseReports.length === 1 ? 'demande' : 'demandes'
                  }`}
            </Badge>
          ),
      });
    }

    variablesPayroll.forEach((variablePayroll) => {
      defaultColumns.push({
        accessor: variablePayroll?.id ? variablePayroll.id : '',
        title: `${variablePayroll.label} (${t(
          `addVariablePayroll.type.${variablePayroll.type}`
        )})`,
        sortable: true,
        ellipsis: true,
        render: ({ endOfMonthId, employee }) =>
          variablePayroll.editable ? (
            variablePayroll.type === 'FILE' ? (
              <EditVariablePayrollFileInput
                key={`${
                  variablePayroll.id
                }-${employee}-${selectedDate?.toString()}`}
                variablePayrollValue={getVariableValue(
                  employee,
                  variablePayroll
                )}
                disabled={isAccountantEndOfMonth ? false : isValidEndOfMonth}
                endOfMonthId={endOfMonthId}
                employeeId={employee}
                companyId={company.id}
                refetchAllData={refetchAllData}
                variablePayrollId={variablePayroll?.id as string}
              />
            ) : (
              <EditVariablePayrollTextInput
                key={`${
                  variablePayroll.id
                }-${employee}-${selectedDate?.toString()}`}
                variablePayrollValue={getVariableValue(
                  employee,
                  variablePayroll
                )}
                disabled={isAccountantEndOfMonth ? false : isValidEndOfMonth}
                endOfMonthId={endOfMonthId}
                employeeId={employee}
                companyId={company.id}
                refetchAllData={refetchAllData}
                variablePayrollId={variablePayroll?.id as string}
              />
            )
          ) : (
            <Group>
              {getVariableValue(employee, variablePayroll) !== '' &&
                variablePayroll.type === 'HOURS' && (
                  <CustomTooltip label={t('w.decimalHours')}>
                    <IconInfoCircle
                      size={18}
                      color={'var(--mantine-color-hifivework-5)'}
                    />
                  </CustomTooltip>
                )}
              <Text fz={'sm'} fw={'bold'}>
                {getVariableValue(employee, variablePayroll)}
              </Text>
            </Group>
          ),
      });
    });
    defaultColumns.push({
      accessor: 'comment',
      title: t('w.comment'),
      sortable: true,
      ellipsis: true,
      render: ({ comment, endOfMonthId, employee }) => (
        <EndOfMonthCommentTextInput
          key={`${endOfMonthId}-${employee}`}
          companyId={company.id}
          comment={comment}
          endOfMonthId={endOfMonthId}
          employeeId={employee}
          refetchAllData={refetchAllData}
          disabled={isAccountantEndOfMonth ? false : isValidEndOfMonth}
        />
      ),
    });

    return defaultColumns;
  }, [
    expenseReportModule?.active,
    expenseReportModuleParams?.method,
    endOfMonthVariablesPayroll,
    variablesPayroll,
    globalValidation,
  ]);

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'employeeObject.fullname',
      label: t('w.employee'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function handleOpenEmployeeLeaveRequestsModal(
    leaves: EndOfMonthValidationLeave[],
    employee: string,
    employeeFullname: string,
    isStatusEndOfMonth: boolean
  ): void {
    const waitingLeavesCount = leaves.filter(
      (leave: EndOfMonthValidationLeave) => leave.status.state === 'WAITING'
    ).length;

    if (!isStatusEndOfMonth) {
      if (leaves.length > 0) {
        modals.open({
          id: `employee-${employee}-leave-requests-modal`,
          title: (
            <Title size={'h3'} component="p">{`${t(
              'w.leavesAndAbsences'
            )} - ${employeeFullname}`}</Title>
          ),
          children: (
            <EndOfMonthValidationLeaveRequestsTable
              leaves={leaves}
              companyId={company.id}
              endOfMonthValidation={endOfMonthValidation}
              refetchEndOfMonthValidationData={refetchEndOfMonthValidationData}
            />
          ),
          size: '70%',
        });
      }
    } else {
      if (waitingLeavesCount > 0) {
        modals.open({
          id: `employee-${employee}-leave-requests-modal`,
          title: (
            <Title size={'h3'} component="p">{`${t(
              'w.leavesAndAbsences'
            )} - ${employeeFullname}`}</Title>
          ),
          children: (
            <EndOfMonthValidationLeaveRequestsTable
              leaves={leaves}
              companyId={company.id}
              endOfMonthValidation={endOfMonthValidation}
              refetchEndOfMonthValidationData={refetchEndOfMonthValidationData}
            />
          ),
          size: '70%',
        });
      }
    }
  }

  function displayUserStatusEndOfMonthBadge(
    leaves: EndOfMonthValidationLeave[],
    employee: string,
    employeeObject: User,
    valid: boolean
  ) {
    const waitingLeavesCount = leaves.filter(
      (leave: EndOfMonthValidationLeave) => leave.status.state === 'WAITING'
    ).length;

    return (
      <Badge
        variant={!valid && waitingLeavesCount !== 0 ? 'dot' : 'filled'}
        onClick={() =>
          handleOpenEmployeeLeaveRequestsModal(
            leaves,
            employee,
            employeeObject.fullname,
            true
          )
        }
        color={
          valid
            ? '#CEE9E6'
            : waitingLeavesCount !== 0
            ? 'hifivework.2'
            : '#FCF0E0'
        }
        styles={{
          label: {
            textTransform: 'none',
            color: valid
              ? '#0A9182'
              : waitingLeavesCount !== 0
              ? 'var(--mantine-color-hifivework-6)'
              : '#EDB464',
          },
          root: {
            cursor: valid || waitingLeavesCount === 0 ? 'default' : 'pointer',
          },
        }}
      >
        {valid
          ? t('w.endOfMonthValidated')
          : waitingLeavesCount > 0
          ? `${waitingLeavesCount} ${
              waitingLeavesCount === 1
                ? `${t('w.leave').slice(0, -1).toLowerCase()} ${t(
                    'w.toValidate'
                  )}`
                : `${t('w.leave').toLowerCase()} ${t('w.toValidate')}`
            }`
          : t('w.endOfMonthToValidate')}
      </Badge>
    );
  }

  return (
    <>
      <Table
        pinFirstColumn
        rows={rows}
        options={options}
        lang={lang}
        withTableBorder={false}
        columns={columns}
        fetching={
          isEndOfMonthValidationDataLoading || isVariablesPayrollDataLoading
        }
        onSelect={(employee) => setSelectedEmployees(employee)}
        leftCustomActions={
          isAccountant(user, company.id) ||
          hasPermission({ permission: ValidateEndOfMonth, scope: ALL }, user)
            ? leftCustomActions
            : []
        }
        rightCustomActions={rightCustomActions}
        idAccessor={'employee'}
        height={'calc(100vh - 210px)'}
      />
      {!!modalData && (
        <CustomModal
          fullScreen
          opened={!!modalData}
          onClose={() => setExpenseReportsModalUserId(null)}
          title={`${t('w.expenseReports')} - ${
            modalData.employeeObject.fullname
          } - ${
            company?.divisionLabel ? company.divisionLabel : t('w.unit')
          } : ${getDivisionName(modalData.employeeObject.divisionId)}`}
          zIndex={999}
        >
          <EndOfMonthValidationExpenseReports
            expenseReports={modalData.expenseReports as ExpenseReportObject[]}
            userFullname={modalData.employeeObject.fullname}
          />
        </CustomModal>
      )}

      {variablePayrollModule && (
        <ModuleToEditModal
          opened={editVariablePayrollOpened}
          onClose={() => {
            refetchVariablesPayrollData();
            editVariablePayrollOpenedHandlers.close();
          }}
          moduleToEdit={variablePayrollModule}
          updateModuleParams={updateModuleParams}
          companyId={company.id}
        />
      )}
    </>
  );
}
