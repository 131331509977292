import {
  Flex,
  LoadingOverlay,
  SimpleGrid,
  Space,
  TextInput,
} from '@mantine/core';
import { isEmail, matches, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import ButtonSave from '../../../../components/ButtonSave';
import LabelText from '../../../../components/LabelText/LabelText';
import PhoneField from '../../../../components/PhoneField/PhoneField';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import OauthServiceMS, { OauthUser } from '../../../../services/OauthServiceMS';
import { OauthUserProfile } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { internationalPhoneNumberRegex } from '../../../../utils/validators';

interface FormValues {
  username: string;
  lastname: string;
  firstname: string;
  phone: string | undefined;
}

type Props = {
  oAuthUser: OauthUserProfile | null;
};

export default function MyAccountSettingsForm({ oAuthUser }: Props) {
  const { access_token, updateUser, user } = useAuth();
  const { t } = useI18n();

  const form = useForm<FormValues>({
    validate: {
      username: isEmail(t('invalid.email')),
      phone: matches(internationalPhoneNumberRegex(), t('invalid.phone')),
    },
  });

  useEffect(() => {
    if (oAuthUser) {
      const values = {
        username: oAuthUser.username,
        lastname: oAuthUser.lastname,
        firstname: oAuthUser.firstname,
        phone: oAuthUser.phone,
      };
      form.setInitialValues(values);
      form.setValues(values);
    }
  }, [oAuthUser]);

  const { mutate: updateOauthUser, isLoading: isUpdateOauthUserLoading } =
    useMutation({
      mutationFn: (variables: OauthUser) =>
        OauthServiceMS.updateOauthUser(access_token, variables),
      onSuccess: (data) => {
        updateUser(user, data.access_token, data.refresh_token);
        showNotification({
          id: 'update-oauth-user-infos-successful',
          title: t('w.success'),
          message: 'Les informations du compte bien été mises à jour',
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onError: (error) =>
        showNotification({
          id: 'update-oauth-user-infos-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  function handleUpdateOauthUserSubmit(values: FormValues) {
    updateOauthUser({
      username: values.username,
      lastname: values.lastname,
      firstname: values.firstname,
      password: null,
      phone: values.phone,
    });
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleUpdateOauthUserSubmit(values))}
    >
      <LoadingOverlay visible={isUpdateOauthUserLoading} />
      <Space h={'xl'} />
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={'xl'}>
        <TextInput
          required
          label={<LabelText text={t('w.emailAddress')} />}
          {...form.getInputProps('username')}
        />
        <TextInput
          label={<LabelText text={t('w.name')} />}
          {...form.getInputProps('lastname')}
        />
        <TextInput
          label={<LabelText text={t('w.firstname')} />}
          {...form.getInputProps('firstname')}
        />
        <PhoneField
          value={form.values.phone}
          onChange={(value) => form.setFieldValue('phone', value)}
        />
      </SimpleGrid>
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Flex justify={'flex-end'}>
        <ButtonSave />
      </Flex>
    </form>
  );
}
