import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useEffect, useMemo, useState } from 'react';

import CustomMonthPickerInput from '../../../components/CustomMonthPickerInput';
import { useAppBar } from '../../../contexts/AppBarProvider';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import useFetchDivisions from '../../../hooks/useFetchDivisions';
import { hasRole } from '../../../utils/authorization';
import { initPickerDate } from '../../../utils/initPickerDate';
import { ADMINISTRATOR } from '../../../variables/BuiltInRoles';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import EndOfMonthValidationTable from './components/EndOfMonthValidationTable';

export default function EndOfMonthValidations() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { divisions, refetch: refetchDivisionsData } = useFetchDivisions({
    companyId,
  });
  const { setIsAppBarUsed, setDivisionsToSelect, setAppBarSelectedDivisions } =
    useAppBar();

  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[]
  >(initPickerDate());

  const actionButtons = useMemo(
    () => [
      <CustomMonthPickerInput
        key={'month-picker-end-of-month-validation'}
        value={selectedDate}
        onChange={setSelectedDate}
      />,
    ],
    [selectedDate]
  );

  useEffect(() => {
    setIsAppBarUsed(true);
    refetchDivisionsData();
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, [selectedDate]);

  useEffect(() => {
    buildAppbarDivisions();
  }, [hasRole(ADMINISTRATOR, user), divisions]);

  function buildAppbarDivisions() {
    let _divisions = divisions.filter(
      (division) => division.name !== NO_WORK_UNIT
    );
    if (!hasRole(ADMINISTRATOR, user)) {
      _divisions = _divisions.filter(
        (division) =>
          division.idOfN1 === user.id ||
          division.idOfN2 === user.id ||
          division.idOfAssistant === user.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }

  if (!company) return null;

  return (
    <ScreenWrapper
      title={t('w.endOfMonthValidation')}
      actionButtons={actionButtons}
      paper
    >
      <EndOfMonthValidationTable
        selectedDate={selectedDate}
        divisions={divisions}
        company={company}
      />
    </ScreenWrapper>
  );
}
