import { Center, Image, Text, Title } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import dayjs from 'dayjs';
import React from 'react';

import FileViewer from '../components/FileViewer/FileViewer';
import DocumentsService from '../services/DocumentsService';
import ExpenseReportService from '../services/ExpenseReportService';
import LeaveService from '../services/LeaveService';
import PayslipService from '../services/PayslipService';
import ProfileService from '../services/ProfileService';
import ShuttleSheetService from '../services/ShuttleSheetService';
import VariablePayrollService from '../services/VariablePayrollService';
import type { FieldType, PayslipMonthResponse } from '../types/types';
import { milliToFullDateString } from './format';
import { getPersistedObject } from './localStorage';

export function getMimeType(extension: string): string {
  const mimeTypes: { [key: string]: string } = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    txt: 'text/plain',
    html: 'text/html',
    css: 'text/css',
    js: 'text/javascript',
    json: 'application/json',
    xml: 'application/xml',
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    ogg: 'audio/ogg',
    mp4: 'video/mp4',
    avi: 'video/x-msvideo',
    mpeg: 'video/mpeg',
    zip: 'application/zip',
    rar: 'application/x-rar-compressed',
    tar: 'application/x-tar',
    gz: 'application/gzip',
    '7z': 'application/x-7z-compressed',
    // Ajoutez d'autres extensions et types MIME au besoin
  };

  return mimeTypes[extension.toLowerCase()] || 'application/octet-stream'; // Si l'extension n'est pas répertoriée, retourne un type MIME par défaut
}

export function handlePreviewExpenseReportAttachmentFile(
  id: string,
  expenseReportType: string,
  expenseReportLabel: string,
  fileName: string | undefined,
  userFullname: string | undefined,
  companyId: string,
  access_token: string,
  t: any
) {
  const fileExtension: string | 'jpg' | 'jpeg' | 'png' | 'pdf' | undefined =
    fileName?.split('.')[1];

  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  xhr.onload = function () {
    if (xhr.status === 200) {
      const newBlob = new Blob([this.response], {
        type: getMimeType(fileExtension ? fileExtension : ''),
      });
      const filePath = window.URL.createObjectURL(newBlob);

      let link = document.createElement('a');
      link.href = filePath;
      link.download = `${userFullname}-${t(
        `expenseReport.type.${expenseReportType.toLowerCase()}`
      )}-${t(`expenseReport.label.${expenseReportLabel}`)}.${fileExtension}`;
      link.target = '_blank';

      modals.openConfirmModal({
        id: `expense-report-${id}-attachment-modal`,
        title: (
          <Title size={'h3'} component="p">
            {`${t(
              `expenseReport.type.${expenseReportType.toLowerCase()}`
            )} - ${t(`expenseReport.label.${expenseReportLabel}`)}`}
          </Title>
        ),
        fullScreen: true,
        children: (
          <FileViewer
            id={`${expenseReportLabel}-${id}`}
            srcUrl={filePath}
            title={`${userFullname}-${expenseReportLabel}.${fileExtension}`}
          />
        ),
        labels: { cancel: t('w.cancel'), confirm: t('w.download') },
        onConfirm: () => link.click(),
      });
    }
  };
  xhr.open(
    'GET',
    ExpenseReportService.expenseReportAttachmentFile(companyId, id),
    true
  );
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}

export function loadExpenseReportAttachment(
  id: string,
  fileName: string | undefined,
  companyId: string,
  access_token: string,
  setFile: (file: string) => void
) {
  const fileExtension: string | 'jpg' | 'jpeg' | 'png' | 'pdf' | undefined =
    fileName?.split('.')[1];

  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  xhr.onload = function () {
    if (xhr.status === 200) {
      const newBlob = new Blob([this.response], {
        type: getMimeType(fileExtension ? fileExtension : ''),
      });
      const filePath = window.URL.createObjectURL(newBlob);
      setFile(filePath);
    }
  };
  xhr.open(
    'GET',
    ExpenseReportService.expenseReportAttachmentFile(companyId, id),
    true
  );
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}

export function handleOpenPayslipPdfFile(
  userId: string,
  fullname: string,
  selectedDate: DateValue | DatesRangeValue | Date[],
  payslipsMonthInfo: PayslipMonthResponse | undefined,
  access_token: string,
  t: any
) {
  const gma = getPersistedObject('gma');
  // @ts-ignore
  const month = dayjs(selectedDate).month() + 1;
  // @ts-ignore
  const year = dayjs(selectedDate).year();

  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      const newBlob = new Blob([this.response], {
        type: 'application/pdf',
      });
      const filePath = window.URL.createObjectURL(newBlob);

      let link = document.createElement('a');
      link.href = filePath;
      link.download = `${fullname}-${month}-${year}.pdf`;
      link.target = '_blank';

      // @ts-ignore
      const title = `${fullname} - ${dayjs(selectedDate).format('MMMM YYYY')}`;

      modals.openConfirmModal({
        id: `${fullname}-${month}-${year}-modal`,
        title: (
          <Title size={'h3'} component="p">
            {title}
          </Title>
        ),
        fullScreen: true,
        children: (
          <FileViewer
            id={`${fullname}-${month}-${year}`}
            srcUrl={filePath}
            title={`${fullname}-${month}-${year}.pdf`}
          />
        ),
        labels: { cancel: t('w.cancel'), confirm: t('w.download') },
        onConfirm: () => link.click(),
      });
    }
    // todo manage error response
  };
  xhr.open(
    'GET',
    PayslipService.getUserPayslipMonth(
      userId,
      year,
      month,
      payslipsMonthInfo?.companyId,
      gma?.access_token,
      gma?.refresh_token
    ),
    true
  );
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}

export function handleOpenPayslipPdfSourceFile(
  item: { date: number },
  index: number,
  payslipMonth: PayslipMonthResponse | undefined,
  access_token: string,
  t: any
) {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      const newBlob = new Blob([this.response], {
        type: 'application/pdf',
      });
      const filePath = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = filePath;
      link.download = `${item.date}.pdf`;
      link.target = '_blank';

      modals.openConfirmModal({
        id: `payslip-month-${payslipMonth?.id}-date-${item.date}-modal`,
        title: (
          <Title size={'h3'} component="p">
            {milliToFullDateString(item.date)}
          </Title>
        ),
        fullScreen: true,
        children: (
          <FileViewer
            id={`${payslipMonth?.id}-${item.date}`}
            srcUrl={filePath}
            title={`${payslipMonth?.id}-${item.date}.pdf`}
          />
        ),
        labels: { cancel: t('w.cancel'), confirm: t('w.download') },
        onConfirm: () => link.click(),
      });
    }
  };

  xhr.open(
    'GET',
    PayslipService.downloadPayslipSourceFile(
      payslipMonth ? payslipMonth.id : '',
      index,
      payslipMonth ? payslipMonth.companyId : ''
    ),
    true
  );
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}

export function handleOpenShuttleSheetFile(
  companyId: string | undefined,
  shuttleSheetId: string | undefined,
  shuttleSheetName: string | undefined,
  shuttleSheetType: FieldType,
  file: File,
  access_token: string,
  t: any
): void {
  const fileExtension: string | 'jpg' | 'jpeg' | 'png' | 'pdf' | undefined =
    file.name?.split('.')[1];

  if (shuttleSheetId) {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], {
          type: getMimeType(`${file}`.split('.')[1]),
        });
        const filePath = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = filePath;
        link.download = shuttleSheetName ? shuttleSheetName : '';
        link.target = '_blank';

        modals.openConfirmModal({
          modalId: `shuttle-sheet-${shuttleSheetId}-attachment-modal`,
          title: (
            <Title size={'h3'} component="p">
              {shuttleSheetName}
            </Title>
          ),
          fullScreen: true,
          children:
            getMimeType(`${file}`.split('.')[1]) === 'application/pdf' ? (
              <FileViewer
                id={`shuttle-sheet-${shuttleSheetId}`}
                srcUrl={filePath}
                title={shuttleSheetName ? shuttleSheetName : ''}
              />
            ) : (
              <Center>
                <Image w={'40%'} h={'40%'} src={filePath} />
              </Center>
            ),
          labels: { cancel: t('w.cancel'), confirm: t('w.download') },
          onConfirm: () => link.click(),
        });
      }
    };
    xhr.open(
      'GET',
      ShuttleSheetService.getShuttleSheetFile(
        companyId,
        shuttleSheetId,
        shuttleSheetName,
        shuttleSheetType
      ),
      true
    );
    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  } else {
    modals.open({
      modalId: `shuttle-sheet-new-attachment-modal`,
      title: (
        <Title size={'h3'} component="p">
          {file.name}
        </Title>
      ),
      fullScreen: true,
      children:
        getMimeType(fileExtension) === 'application/pdf' ? (
          <FileViewer
            id={`shuttle-sheet-new-file`}
            srcUrl={URL.createObjectURL(file)}
            title={file.name}
          />
        ) : (
          <Center>
            <Image w={'40%'} h={'40%'} src={URL.createObjectURL(file)} />
          </Center>
        ),
    });
  }
}

export function handleOpenDocumentFile(
  companyId: string,
  documentId: string,
  fileName: string,
  extension: string,
  access_token: string,
  t: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  xhr.onload = function () {
    if (xhr.status === 200) {
      const newBlob = new Blob([this.response], {
        type: getMimeType(extension),
      });
      const filePath = window.URL.createObjectURL(newBlob);

      let link = document.createElement('a');
      link.href = filePath;
      link.download = fileName;
      link.target = '_blank';

      modals.openConfirmModal({
        modalId: `document-${documentId}-attachment-modal`,
        title: (
          <Title size={'h3'} component="p">{`${fileName}.${extension}`}</Title>
        ),
        fullScreen:
          getMimeType(extension) === 'application/pdf' ||
          getMimeType(extension).includes('image'),
        size: 'xl',
        children:
          getMimeType(extension) === 'application/pdf' ? (
            <FileViewer
              id={`document-${documentId}`}
              srcUrl={filePath}
              title={fileName}
            />
          ) : (
            <Center>
              <Image w={'40%'} h={'40%'} src={filePath} />
            </Center>
          ),
        labels: { cancel: t('w.cancel'), confirm: t('w.download') },
        onConfirm: () => link.click(),
      });
    }
  };
  xhr.open(
    'GET',
    DocumentsService.downloadDocumentFile(companyId, documentId),
    true
  );
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}

export const getExtension = (str: string) => str.slice(str.lastIndexOf('.'));

export function handleOpenVariablePayrollFile(
  companyId: string,
  employeeId: string,
  endOfMonthId: string,
  variablePayrollId: string,
  variablePayrollValue: string,
  access_token: string,
  t: any
) {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  xhr.onload = function () {
    if (xhr.status === 200) {
      const mimeType = getMimeType(getExtension(variablePayrollValue));
      const newBlob = new Blob([this.response], {
        type: mimeType,
      });
      const filePath = window.URL.createObjectURL(newBlob);

      let link = document.createElement('a');
      link.href = filePath;
      link.download = `export${getExtension(variablePayrollValue)}`;
      link.target = '_blank';

      modals.openConfirmModal({
        modalId: `document-${variablePayrollId}-attachment-modal`,
        fullScreen:
          mimeType === 'application/pdf' || mimeType.includes('image'),
        size: 'xl',
        children:
          mimeType === 'application/pdf' ? (
            <FileViewer
              id={`document-${variablePayrollId}`}
              srcUrl={filePath}
              title={variablePayrollValue}
            />
          ) : (
            <Center>
              <Image w={'40%'} h={'40%'} src={filePath} />
            </Center>
          ),
        labels: { cancel: t('w.cancel'), confirm: t('w.download') },
        onConfirm: () => link.click(),
      });
    }
  };
  xhr.open(
    'GET',
    VariablePayrollService.getVariablePayrollFile(
      companyId,
      employeeId,
      endOfMonthId,
      variablePayrollId
    ),
    true
  );
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}

export function handleOpenExpenseReportAttachmentFile(
  companyId: string,
  expenseReportId: string,
  extension: string,
  access_token: string,
  t: any
) {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  xhr.onload = function () {
    if (xhr.status === 200) {
      const mimeType = getMimeType(extension);
      const newBlob = new Blob([this.response], {
        type: mimeType,
      });
      const filePath = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = filePath;
      link.download = `export.${extension}`;
      link.target = '_blank';

      modals.openConfirmModal({
        modalId: `expense-report-attachment-${expenseReportId}-attachment-modal`,
        fullScreen:
          mimeType === 'application/pdf' || mimeType.includes('image'),
        children:
          mimeType === 'application/pdf' ? (
            <FileViewer
              id={`expense-report-attachment-${expenseReportId}`}
              srcUrl={filePath}
              title={'todo'}
            />
          ) : (
            <Center>
              <Image w={'40%'} h={'40%'} src={filePath} />
            </Center>
          ),
        labels: { cancel: t('w.cancel'), confirm: t('w.download') },
        onConfirm: () => link.click(),
      });
    }
  };
  xhr.open(
    'GET',
    ExpenseReportService.getAttachment(companyId, expenseReportId),
    true
  );
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}

export function handleOpenLeaveAttachmentFile(
  id: string,
  leaveTypeName: string,
  userFullname: string,
  fileName: string,
  access_token: string,
  t: any
) {
  const fileExtension = fileName.split('.').pop();
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  function getBlobType() {
    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        return 'application/pdf';
    }
  }

  xhr.onload = function () {
    if (xhr.status === 200) {
      const newBlob = new Blob([this.response], { type: getBlobType() });
      const filePath = window.URL.createObjectURL(newBlob);

      let link = document.createElement('a');
      link.href = filePath;
      link.download = `${userFullname}-${leaveTypeName}.${fileExtension}`;
      link.target = '_blank';

      modals.openConfirmModal({
        id: `leave-${id}-attachment-modal`,
        title: (
          <Title size={'h3'} component="p">
            {leaveTypeName}
          </Title>
        ),
        fullScreen: true,
        children: (
          <FileViewer
            id={`${leaveTypeName}-${id}`}
            srcUrl={filePath}
            title={`${userFullname}-${leaveTypeName}.${fileExtension}`}
          />
        ),
        labels: { cancel: t('w.cancel'), confirm: t('w.download') },
        onConfirm: () => link.click(),
      });
    }
  };
  xhr.open('GET', LeaveService.attachmentUrl(id), true);
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}

export function handleOpenSensitiveDocument(
  access_token: string,
  t: any,
  type: string,
  userId: string
) {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  xhr.onload = function () {
    if (xhr.status === 200) {
      const newBlob = new Blob([this.response], { type: 'application/pdf' });
      const filePath = window.URL.createObjectURL(newBlob);

      let link = document.createElement('a');
      link.href = filePath;
      link.download = type;
      link.target = '_blank';

      modals.openConfirmModal({
        modalId: `user-sensitive-info-attachment-${userId}-modal`,
        fullScreen: true,
        children: (
          <FileViewer
            id={`user-sensitive-info-attachment-${userId}`}
            srcUrl={filePath}
            title={'todo'}
          />
        ),
        labels: { cancel: t('w.cancel'), confirm: t('w.download') },
        onConfirm: () => link.click(),
      });
    } else {
      modals.openConfirmModal({
        modalId: `user-sensitive-info-attachment-${userId}-modal`,
        fullScreen: false,
        children: (
          <Center>
            <Text>{t('w.noFile')}</Text>
          </Center>
        ),
        labels: { cancel: t('w.cancel'), confirm: t('w.close') },
      });
    }
  };
  if (type === 'securitySocial') {
    xhr.open('GET', ProfileService.getSecuritySocial(userId), true);
  } else if (type === 'residencePermit') {
    xhr.open('GET', ProfileService.getResidencePermit(userId), true);
  } else {
    xhr.open('GET', ProfileService.getIdentityCard(userId), true);
  }

  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}
