import {
  ActionIcon,
  Button,
  Group,
  rem,
  Select,
  SimpleGrid,
  Switch,
  TextInput,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCalendar,
  IconCheck,
  IconDeviceFloppy,
  IconInfoCircle,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect } from 'react';

import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import CounterTypeService from '../../../../services/CounterTypeService';
import type { CounterType, CounterTypePayload } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

dayjs.extend(customParseFormat);

type Props = {
  counterType?: CounterType | null;
  companyId: string;
};

export default function CreateEditForm({ counterType, companyId }: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();

  const { mutate: create, isLoading: createIsLoading } = useMutation({
    mutationFn: (payload: CounterTypePayload) =>
      CounterTypeService.create(companyId, payload),
    onSuccess,
    onError,
  });

  const { mutate: update, isLoading: updateIsLoading } = useMutation({
    mutationFn: (payload: CounterType) =>
      CounterTypeService.update(companyId, payload),
    onSuccess,
    onError,
  });

  const form = useForm<CounterTypePayload>({
    initialValues: {
      companyId,
      key: '',
      active: true,
      extractToPayslip: false,
      rule: 'DAY',
      useHalfDay: false,
      returnOnRestDay: false,
      update: undefined,
    },
  });

  useEffect(() => {
    if (counterType) {
      const _update = counterType.update
        ? dayjs(
            `${counterType.update}-${new Date().getFullYear()}`,
            'DD-MM-YYYY'
          )
        : undefined;
      // @ts-ignore
      form.setValues({ ...counterType, update: _update });
    }
  }, [counterType]);

  function onSuccess() {
    queryClient.invalidateQueries({
      queryKey: ['CounterTypeService.list', companyId],
    });
    showNotification({
      id: 'counter-type-modal-success',
      message: t('w.success'),
      color: 'green',
      icon: <IconCheck />,
    });
    modals.closeAll();
  }

  function onError(error: any) {
    showNotification({
      id: 'counter-type-modal-error',
      title: t('w.error'),
      message: handleErrorMessage(error, t),
      icon: <IconX />,
      color: 'red',
    });
  }

  function saveCounterType(_counterType: CounterTypePayload) {
    const _update = _counterType.update
      ? dayjs(_counterType.update).format('DD-MM')
      : undefined;

    if (counterType) {
      update({ ..._counterType, id: counterType.id, update: _update });
    } else {
      create({ ..._counterType, update: _update });
    }
  }

  function openRefDateInfoClick() {
    modals.open({
      title: (
        <Title size={'h3'} component="p">
          {t('w.referenceDate')}
        </Title>
      ),
      children: t('counterType.referenceDateMessage'),
      size: 'xl',
    });
  }

  return (
    <form onSubmit={form.onSubmit(saveCounterType)}>
      <TextInput
        label={<LabelText text={t('w.name')} />}
        {...form.getInputProps('key')}
        mb={'md'}
        disabled={!!counterType}
        required
      />

      <SimpleGrid cols={{ base: 1, md: 2 }} spacing={'md'} mb="md">
        <Select
          label={<LabelText text={t('w.type')} />}
          data={[
            { value: 'DAY', label: t('counterType.rule.DAY') },
            { value: 'WORKING_DAY', label: t('counterType.rule.WORKING_DAY') },
            { value: 'OPEN_DAY', label: t('counterType.rule.OPEN_DAY') },
            /*{
              value: 'HOURS',
              label: t('counterType.rule.HOURS'),
              disabled: true,
            },*/
          ]}
          {...form.getInputProps('rule')}
        />
        <DatePickerInput
          clearable
          leftSection={
            <IconCalendar
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          }
          leftSectionPointerEvents="none"
          label={
            <Group gap={'xs'}>
              <LabelText text={t('w.referenceDate')} />
              <ActionIcon
                size={'xs'}
                variant={'subtle'}
                onClick={openRefDateInfoClick}
              >
                <IconInfoCircle />
              </ActionIcon>
            </Group>
          }
          placeholder={t('w.referenceDate')}
          valueFormat="DD/MM"
          monthLabelFormat={(date: Date) => dayjs(date).format('MMMM')}
          yearLabelFormat={() => null}
          maxLevel={'year'}
          {...form.getInputProps('update')}
        />
        <Switch
          label={t('w.active')}
          {...form.getInputProps('active', { type: 'checkbox' })}
        />
        <Switch
          label={t('w.extractToPayslip')}
          {...form.getInputProps('extractToPayslip', { type: 'checkbox' })}
        />
        <Switch
          label={t('w.authorizeToUseHalfDay')}
          {...form.getInputProps('useHalfDay', { type: 'checkbox' })}
        />
        <Switch
          label={t('w.backOnRestDay')}
          {...form.getInputProps('returnOnRestDay', { type: 'checkbox' })}
        />
      </SimpleGrid>

      <Group justify={'flex-end'}>
        <Button
          type={'submit'}
          loading={createIsLoading || updateIsLoading}
          leftSection={<IconDeviceFloppy />}
        >
          {t('w.save')}
        </Button>
      </Group>
    </form>
  );
}
