import React, { useMemo, useRef } from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import useFetchDivisions from '../../hooks/useFetchDivisions';
import type { UserDailyWork } from '../../types/types';
import { NO_WORK_UNIT } from '../../variables/GlobalVariables';
import css from './HorizontalMonthDailyWorkInfo.module.sass';
import SchedulerDailyWorkHeadDayCell from './SchedulerDailyWorkHeadDayCell';
import SchedulerDailyWorkHeadInfoCell from './SchedulerDailyWorkHeadInfoCell';

export declare type HorizontalMonthDailyWorkInfoProps = {
  periodDailyWorks: UserDailyWork;
  refetchDailyWorkListOnPeriod: () => void;
  companyId: string;
};

interface TimelineInlineStyles {
  [Key: string]: number | string;
}

export default function HorizontalMonthDailyWorkInfo({
  periodDailyWorks,
  refetchDailyWorkListOnPeriod,
  companyId,
}: HorizontalMonthDailyWorkInfoProps) {
  const { t } = useI18n();
  const { divisions } = useFetchDivisions({ companyId });
  const divisionsOptions = useMemo(
    () =>
      divisions?.map((item) => ({
        value: item.id,
        label: item.name === NO_WORK_UNIT ? t(NO_WORK_UNIT) : item.name,
      })),
    [divisions]
  );
  const timelinePanel = useRef<HTMLDivElement>(null);
  const cellWidth =
    timelinePanel.current?.clientWidth! / periodDailyWorks.dailyWorks.length >
    117
      ? Math.round(
          timelinePanel.current?.clientWidth! /
            periodDailyWorks.dailyWorks.length
        )
      : 117;
  const timelineInlineStyles: TimelineInlineStyles = {
    '--timeline-header-row-items-count': periodDailyWorks.dailyWorks.length,
    '--timeline-cell-width': `${cellWidth}px`,
    '--scheduler-cell-width': `${cellWidth}px`,
  };

  return (
    <div
      className={`${css['timelineDailyWorkInfo']}`}
      style={timelineInlineStyles}
      ref={timelinePanel}
    >
      <div className={`${css['timelineDailyWorkInfo-header']}`}>
        <div className={`${css['timelineDailyWorkInfo-header-row']}`}>
          {periodDailyWorks.dailyWorks.map((dailyWork) => {
            return (
              <SchedulerDailyWorkHeadDayCell
                dailyWork={dailyWork}
                key={`dayCell_${dailyWork.date}`}
                refetchDailyWorksList={refetchDailyWorkListOnPeriod}
                divisionsOptions={divisionsOptions}
              />
            );
          })}
        </div>
      </div>
      <div className={`${css['timelineDailyWorkInfo-content']}`}>
        <div className={`${css['timelineDailyWorkInfo-content-row']}`}>
          {periodDailyWorks.dailyWorks.map((dailyWork) => {
            return (
              <SchedulerDailyWorkHeadInfoCell
                dailyWork={dailyWork}
                key={`dailyWorkHeadInfo_${dailyWork.date}`}
                refetchDailyWorksList={refetchDailyWorkListOnPeriod}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
