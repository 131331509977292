import { useAuth } from '../../../contexts/AuthProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import type { DailyWorkParams } from '../../../types/types';
import { hasPermission } from '../../../utils/authorization';
import {
  ALL,
  AuthorizedReadDailyWork,
  AuthorizedUpsertDailyWork,
} from '../../../variables/BuiltInPermissions';
import { DailyWork } from '../../../variables/ModulesV2';

export default function useDisplayUserActivities(): boolean {
  const { getModule } = useModule();
  const { user } = useAuth();
  const isModuleDailyWorkActiveForCompany = getModule(DailyWork)?.active;
  const isModuleDailyWorkActiveForAll = (
    getModule(DailyWork)?.configuration?.params as DailyWorkParams
  )?.activeForAll;
  const isActiveForUser = Boolean(
    user.paidFeatures?.find((item) => item.name === DailyWork && item.active)
  );
  const isUserAuthorized = hasPermission(
    [
      { permission: AuthorizedUpsertDailyWork, scope: ALL },
      { permission: AuthorizedReadDailyWork, scope: ALL },
    ],
    user
  );

  return Boolean(
    isModuleDailyWorkActiveForCompany &&
      isUserAuthorized &&
      (isActiveForUser || isModuleDailyWorkActiveForAll)
  );
}
