import {
  ActionIcon,
  Button,
  Chip,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import useCompany from '../../../hooks/useCompany';
import ProfileService from '../../../services/ProfileService';
import type { User, UserPersonalBreakDay } from '../../../types/types';
import { CompanyDaysWorkedParams } from '../../../types/types';
import { hasPermission, isAccountant } from '../../../utils/authorization';
import { ALL, ConfigureEmployees } from '../../../variables/BuiltInPermissions';
import { CompanyDaysWorked } from '../../../variables/ModulesV2';
import UserWeekRepartitionModal from './UserWeekRepartitionModal';

type Props = {
  userProfile: User;
};

interface FormValues {
  breakDays: UserPersonalBreakDay[];
}

type BreakDayToEdit = {
  opened: boolean;
  isMorning: boolean;
  breakDay: UserPersonalBreakDay | null;
};

export default function UserWeekRepartitionForm({ userProfile }: Props) {
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { t } = useI18n();
  const { getModule } = useModule();
  const [breakDayToEdit, setBreakDayToEdit] = useState<BreakDayToEdit>({
    opened: false,
    isMorning: true,
    breakDay: null,
  });

  const form = useForm<FormValues>({
    initialValues: {
      breakDays: [],
    },
  });

  function buildBreakDays(data: UserPersonalBreakDay[]): void {
    let _breakDays: UserPersonalBreakDay[] = [
      {
        day: 'MONDAY',
        morning: false,
        afternoon: false,
        editable: false,
      },
      {
        day: 'TUESDAY',
        morning: false,
        afternoon: false,
        editable: false,
      },
      {
        day: 'WEDNESDAY',
        morning: false,
        afternoon: false,
        editable: false,
      },
      {
        day: 'THURSDAY',
        morning: false,
        afternoon: false,
        editable: false,
      },
      {
        day: 'FRIDAY',
        morning: false,
        afternoon: false,
        editable: false,
      },
      {
        day: 'SATURDAY',
        morning: false,
        afternoon: false,
        editable: false,
      },
      {
        day: 'SUNDAY',
        morning: false,
        afternoon: false,
        editable: false,
      },
    ];

    data.forEach((_personalBreakDay: UserPersonalBreakDay) => {
      const _index = _breakDays.findIndex(
        (item) => _personalBreakDay.day === item.day
      );
      if (_index !== -1) {
        _breakDays[_index].morning = _personalBreakDay.morning;
        _breakDays[_index].afternoon = _personalBreakDay.afternoon;
        _breakDays[_index].repeat = _personalBreakDay.repeat;
      }
    });

    const companyDaysWorkedParams = getModule(CompanyDaysWorked)?.configuration
      ?.params as CompanyDaysWorkedParams;
    if (companyDaysWorkedParams?.validDayOfWeek) {
      companyDaysWorkedParams.validDayOfWeek.forEach((_workedDay) => {
        const _index = _breakDays.findIndex((item) => _workedDay === item.day);
        if (_index !== -1) {
          _breakDays[_index].editable = true;
        }
      });
    }
    form.setFieldValue('breakDays', _breakDays);
  }

  const { refetch: refetchUserBreakDays, isError: userBreakDaysIsError } =
    useQuery({
      queryKey: ['ProfileService.getUserPersonalBreakDay', userProfile.id],
      queryFn: () => ProfileService.getUserPersonalBreakDay(userProfile.id),
      onSuccess: (data: UserPersonalBreakDay[]) => buildBreakDays(data),
    });

  const {
    mutate: updateUserBreakDays,
    isLoading: updateUserBreakDaysIsLoading,
  } = useMutation({
    mutationFn: (variables: { userBreakDay: UserPersonalBreakDay[] }) =>
      ProfileService.updateUserPersonalBreakDay(
        userProfile.id,
        variables.userBreakDay
      ),
    onSuccess: () => {
      refetchUserBreakDays();
      showNotification({
        id: 'updateUserBreakDays-success',
        title: t('w.success'),
        message: t('breakDays.updatedSuccessful'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: 'updateUserBreakDays-error',
        title: t('w.error'),
        message: t('breakDays.updatedError'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function isAllowedToEdit(): boolean {
    return (
      hasPermission({ permission: ConfigureEmployees, scope: ALL }, user) ||
      isAccountant(user, companyId)
    );
  }

  function onEditBreakDayChange(
    index: number,
    breakDay: UserPersonalBreakDay,
    isMorning: boolean
  ): void {
    if (isAllowedToEdit() && breakDay.editable) {
      if (isMorning) {
        form.setFieldValue(`breakDays.${index}.morning`, !breakDay.morning);
      } else {
        form.setFieldValue(`breakDays.${index}.afternoon`, !breakDay.afternoon);
      }
    }
  }

  function onRepeatModeChange(
    value: string,
    breakDay: UserPersonalBreakDay | null
  ): void {
    const _breakDays = [...form.values.breakDays];
    const findBreakDayIndex = _breakDays.findIndex(
      (item) => item.day === breakDay?.day
    );
    if (findBreakDayIndex !== -1) {
      form.setFieldValue(`breakDays.${findBreakDayIndex}.repeat`, value);
    }
    if (breakDay) {
      setBreakDayToEdit({
        ...breakDayToEdit,
        breakDay: { ...breakDay, repeat: value },
      });
    }
  }

  function onUpdateUserBreakDaysFormSubmit(
    values: ReturnType<(values: FormValues) => FormValues>
  ): void {
    const _breakDays = values.breakDays.map((breakDay) => {
      delete breakDay.editable;

      return breakDay;
    });
    updateUserBreakDays({ userBreakDay: _breakDays });
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        onUpdateUserBreakDaysFormSubmit(values)
      )}
    >
      <LoadingOverlay visible={updateUserBreakDaysIsLoading} />
      {userBreakDaysIsError ? (
        <Text>{t('breakDays.error')}</Text>
      ) : (
        <Grid align={'center'} gutter={'md'}>
          <Grid.Col span={{ base: 12, lg: 9 }}>
            <Group>
              {form.values.breakDays.map((breakDay, index) => (
                <Flex
                  key={`${index + 1}-${breakDay.day}`}
                  align={'center'}
                  direction={'column'}
                  gap={'md'}
                >
                  <Text color={'hifivework.3'} fw={'bold'}>
                    {t(`daysEnum.${breakDay.day}`)}
                  </Text>
                  <CustomTooltip
                    label={
                      breakDay.editable && isAllowedToEdit()
                        ? t('w.edit')
                        : !breakDay.editable
                        ? t('w.rest')
                        : t('w.unauthorized')
                    }
                  >
                    <Group gap="xs">
                      <Chip
                        size="xs"
                        variant="filled"
                        checked={breakDay.morning}
                        onChange={() =>
                          onEditBreakDayChange(index, breakDay, true)
                        }
                      >
                        {t('leave.type.morning')}
                      </Chip>
                      {breakDay.editable && breakDay.morning && (
                        <ActionIcon
                          variant={'subtle'}
                          onClick={() =>
                            setBreakDayToEdit({
                              opened: true,
                              isMorning: true,
                              breakDay: breakDay,
                            })
                          }
                          size="sm"
                          color={'hifivework.6'}
                        >
                          <IconEdit />
                        </ActionIcon>
                      )}
                    </Group>
                  </CustomTooltip>
                  <CustomTooltip
                    label={
                      breakDay.editable && isAllowedToEdit()
                        ? t('w.edit')
                        : !breakDay.editable
                        ? t('w.rest')
                        : t('w.unauthorized')
                    }
                    position={'bottom'}
                  >
                    <Group gap="xs">
                      <Chip
                        size="xs"
                        variant="filled"
                        checked={breakDay.afternoon}
                        onChange={() =>
                          onEditBreakDayChange(index, breakDay, false)
                        }
                      >
                        {t('leave.type.afternoon')}
                      </Chip>
                      {breakDay.editable && breakDay.afternoon && (
                        <ActionIcon
                          variant={'subtle'}
                          onClick={() =>
                            setBreakDayToEdit({
                              opened: true,
                              isMorning: false,
                              breakDay: breakDay,
                            })
                          }
                          size="sm"
                          color={'hifivework.6'}
                        >
                          <IconEdit />
                        </ActionIcon>
                      )}
                    </Group>
                  </CustomTooltip>
                </Flex>
              ))}
            </Group>
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 3 }}>
            <Flex justify={'center'}>
              <CustomTooltip
                label={
                  !isAllowedToEdit()
                    ? t('w.youAreNotAuthorisedToChangeYourWeeklyAllocation')
                    : t('w.save')
                }
                position={'bottom'}
              >
                <span>
                  <Button type={'submit'} disabled={!isAllowedToEdit()}>
                    {t('w.save')}
                  </Button>
                </span>
              </CustomTooltip>
            </Flex>
          </Grid.Col>
        </Grid>
      )}
      <UserWeekRepartitionModal
        opened={breakDayToEdit.opened}
        onClose={() =>
          setBreakDayToEdit({
            opened: false,
            breakDay: null,
            isMorning: true,
          })
        }
        title={
          breakDayToEdit?.breakDay
            ? `${t(`daysEnum.${breakDayToEdit?.breakDay?.day}`)} - ${
                breakDayToEdit.isMorning
                  ? t('leave.type.morning')
                  : t('leave.type.afternoon')
              }`
            : ''
        }
        value={breakDayToEdit.breakDay?.repeat}
        onChange={(value) =>
          onRepeatModeChange(value, breakDayToEdit?.breakDay)
        }
        day={breakDayToEdit.breakDay?.day}
      />
    </form>
  );
}
