import type { DailyTemplate, WeeklyTemplate } from './types';

export function isDailyTemplate(
  template?: DailyTemplate | WeeklyTemplate
): template is DailyTemplate {
  return Boolean(template) && (template as DailyTemplate).period !== undefined;
}

export function isWeeklyTemplate(
  template?: DailyTemplate | WeeklyTemplate
): template is WeeklyTemplate {
  return (
    Boolean(template) &&
    (template as WeeklyTemplate).weeklyPeriods !== undefined
  );
}
