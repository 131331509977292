import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Space,
  Text,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconFileSpreadsheet, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { ReactNode, useEffect, useState } from 'react';

import { useI18n } from '../contexts/I18nProvider';
import { useModule } from '../contexts/ModuleProvider';
import CompanyService from '../services/CompanyService';
import LeaveTypeService from '../services/LeaveTypeService';
import handleErrorMessage from '../utils/handleErrorMessage';
import { PersonalRegister } from '../variables/ModulesV2';
import CustomDropzone from './CustomDropzone/CustomDropzone';
import CustomModal from './CustomModal';
import ImportReportModal from './ImportReportModal';

type Props = {
  opened: boolean;
  onClose: () => void;
  title: string;
  companyId: string;
  refetch: () => void;
  importType: 'employees' | 'divisions' | 'period-types';
};

interface FormValues {
  files: FileWithPath[];
}

type ImportReportList = {
  lineNb: number;
  mail: string;
  message: string;
  role: string;
  status: string;
};

export type ResultImportModal = {
  fileName: string;
  importReportList: ImportReportList[];
  nbCreated: number;
  nbErrors: number;
  nbUpdated: number;
};

export default function ImportModal({
  opened,
  onClose,
  title,
  companyId,
  refetch,
  importType,
}: Props) {
  const { t } = useI18n();
  const { getModule } = useModule();
  const isPersonalRegisterActive = getModule(PersonalRegister)?.active;

  const [resultImportModal, setResultImportModal] =
    useState<ResultImportModal | null>(null);

  const form = useForm<FormValues>({
    initialValues: {
      files: [],
    },
  });

  const { mutate: importEmployees, isLoading } = useMutation({
    mutationFn: (variables: { file: FileWithPath }) =>
      CompanyService.importEmployeesOrDivisions(
        importType,
        variables.file,
        companyId
      ),
    onSuccess: (data) => {
      setResultImportModal(data);
      refetch();
      showNotification({
        id: `import-${importType}-success`,
        title: t('w.success'),
        message:
          importType === 'employees'
            ? t('success.importEmployees')
            : t('w.success.importDivisions'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: `import-${importType}-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  const { mutate: importLeaveTypes } = useMutation({
    mutationFn: (variables: { file: FileWithPath }) =>
      LeaveTypeService.importLeaveTypes(companyId, variables.file),
    onSuccess: () => {
      refetch();
      onClose();
      showNotification({
        id: `import-leave-types-success`,
        title: t('w.success'),
        message: t('success.importLeaveTypes', 'XLSX'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: `import-leave-types-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  function resetFiles() {
    form.setFieldValue('files', []);
  }

  useEffect(() => {
    if (!opened) {
      resetFiles();
    }
  }, [opened]);

  function renderSubText(): ReactNode {
    let text;
    if (importType === 'employees') {
      text =
        'Prénom, Nom, Email, Rôle, Fonction, Unité de travail, Matricule, Code entreprise';
    } else {
      text = 'Nom unité, Email manager 1, Email manager 2';
    }
    if (importType === 'divisions') {
      text = 'Nom unité, Email manager 1, Email manager 2';
    }
    if (importType === 'period-types') {
      text = t('w.formattingOnlyXLSX');
    }
    if (importType === 'employees' && isPersonalRegisterActive) {
      text =
        'Prénom, Nom, Email, Rôle, Fonction, Unité de travail, Matricule, Code entreprise, Nationalité, Date de naissance, Le genre, Qualification, Type de contrat, Nom & prénom du tuteur, Lieu de présence, Date d’entrée, Date de sortie, Date autorisation de licenciement, Date autorisation d’embauche, Travailleur étranger, Numéro de séjour, Titre de séjour';
    }
    return (
      <Text fz={'xs'} c={'dimmed'}>
        {text}
      </Text>
    );
  }

  function handleImportFileSubmit(values: FormValues): void {
    if (importType !== 'period-types') {
      importEmployees({ file: values.files[0] });
    } else {
      importLeaveTypes({ file: values.files[0] });
    }
  }

  return (
    <CustomModal opened={opened} onClose={onClose} title={title} size={'xl'}>
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={form.onSubmit((values) => handleImportFileSubmit(values))}
      >
        <CustomDropzone
          files={form.values.files}
          onDrop={(files) => form.setFieldValue('files', files)}
          mimeType={'excel'}
          resetFiles={resetFiles}
        />
        <Space h={'md'} />
        {importType !== 'period-types' && (
          <Text>{t('w.formattingCondition')}</Text>
        )}
        {renderSubText()}
        {form.values.files.length > 0 && (
          <Flex mt={'md'} justify={'flex-end'}>
            <Button type={'submit'}>{t('w.import')}</Button>
          </Flex>
        )}
        <Space h={'md'} />
        {importType !== 'period-types' && (
          <Group>
            <Text fz={'sm'} fw={'bold'} color={'dimmed'}>
              {t('w.downloadTemplateFile')} :
            </Text>
            <Button
              variant={'subtle'}
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={
                importType === 'employees'
                  ? !isPersonalRegisterActive
                    ? '/exemple/Employes_modele_de_test.xlsx'
                    : '/exemple/Employes_with_personal_register_modele_de_test.xlsx'
                  : '/exemple/Divisions_modele_de_test.xlsx'
              }
              leftSection={<IconFileSpreadsheet />}
            >
              XLSX
            </Button>
            <Text fz={'sm'} fw={'bold'} color={'dimmed'}>
              {t('w.or')}
            </Text>
            <Button
              variant={'subtle'}
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={
                importType === 'employees'
                  ? !isPersonalRegisterActive
                    ? '/exemple/Employes_modele_de_test.csv'
                    : '/exemple/Employes_with_personal_register_modele_de_test.csv'
                  : '/exemple/Divisions_modele_de_test.csv'
              }
              leftSection={<IconFileSpreadsheet />}
            >
              CSV
            </Button>
          </Group>
        )}
      </form>
      <ImportReportModal
        opened={resultImportModal !== null}
        onClose={() => {
          setResultImportModal(null);
          onClose();
        }}
        resultImportModal={resultImportModal}
        importType={importType}
      />
    </CustomModal>
  );
}
