import {
  Accountant,
  Accountants,
  EndOfMonthValidation,
  PayslipStatus,
} from '../types/types';
import { MS_CHARTERED_ACCOUNTANT_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function accountantsList(companyId: string): Promise<Accountants[]> {
  return request
    .get(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/my-accountants`
    )
    .then((res) => res?.data);
}

function removeAccountantAccess(
  accountantCompanyId: string,
  companyId: string
) {
  return request
    .delete(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/remove/${accountantCompanyId}`
    )
    .then((res) => res?.data);
}

function inviteAccountantToAccess(companyId: string, mail: string) {
  return request
    .post(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/invite?mail=${encodeURIComponent(
        mail
      )}`
    )
    .then((res) => res?.data);
}

function invitationAccountantHistory(companyId: string) {
  return request
    .get(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/history`
    )
    .then((res) => res?.data);
}

function validateCustomerAccountantInvitation(
  companyId: string,
  validationId: string | undefined,
  status: boolean | null
) {
  if (status !== null) {
    return request
      .post(
        `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/validation/${validationId}?status=${status}`
      )
      .then((res) => res?.data);
  } else {
    return request
      .post(
        `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/validation/${validationId}`
      )
      .then((res) => res?.data);
  }
}

function accountantCustomersList(
  companyId: string,
  monthConcerned?: string // format MM-YYYY (ex: 01-2024)
): Promise<Accountants[]> {
  if (Boolean(monthConcerned)) {
    return request
      .get(
        `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/my-clients?monthConcerned=${monthConcerned}`
      )
      .then((res) => res?.data);
  } else {
    return request
      .get(
        `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/my-clients`
      )
      .then((res) => res?.data);
  }
}

function assignAccountantToCustomerCompany(
  clientCompanyId: string | undefined,
  companyId: string,
  userAccountantId: string,
  divisionId: string | null | undefined,
  generalManager: boolean
) {
  if (divisionId) {
    return request
      .post(
        `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/assign/${userAccountantId}/to/${clientCompanyId}?divisionId=${divisionId}&generalManager=${generalManager}`
      )
      .then((res) => res?.data);
  } else {
    return request
      .post(
        `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/assign/${userAccountantId}/to/${clientCompanyId}?generalManager=${generalManager}`
      )
      .then((res) => res?.data);
  }
}

function unAssignAccountantToCustomerCompany(
  clientCompanyId: string | undefined,
  companyId: string,
  userAccountantId: string,
  divisionId: string | null,
  generalManager: boolean
) {
  if (divisionId) {
    return request
      .delete(
        `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/unassign/${userAccountantId}/to/${clientCompanyId}?divisionId=${divisionId}&generalManager=${generalManager}`
      )
      .then((res) => res?.data);
  } else {
    return request
      .delete(
        `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/unassign/${userAccountantId}/to/${clientCompanyId}?generalManager=${generalManager}`
      )
      .then((res) => res?.data);
  }
}

function updateCustomerCompanyInformation(
  companyId: string,
  data: { accountant: Accountant; active: boolean; clientCompanyId: string }
) {
  return request.post(
    `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/client`,
    data
  );
}

type EmployeeId = {
  userId: string;
  status: PayslipStatus;
};

export type UpdatePayslipStatusPayload = {
  endOfMonthValidationId: string;
  employeeIds: EmployeeId[];
};

function updateCustomerUsersPayslipStatusEndOfMonth(
  companyId: string,
  data: UpdatePayslipStatusPayload[]
): Promise<EndOfMonthValidation[]> {
  return request
    .patch(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/endMonth/validation`,
      data
    )
    .then((res) => res?.data);
}

const CharteredAccountantService = {
  accountantsList,
  removeAccountantAccess,
  inviteAccountantToAccess,
  invitationAccountantHistory,
  validateCustomerAccountantInvitation,
  accountantCustomersList,
  assignAccountantToCustomerCompany,
  unAssignAccountantToCustomerCompany,
  updateCustomerCompanyInformation,
  updateCustomerUsersPayslipStatusEndOfMonth,
};

export default CharteredAccountantService;
