import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, LoadingOverlay, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconEye, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import MoreItemsBadge from '../../../../components/badges/MoreItemsBadge';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import useFetchAccountants from '../../../../hooks/useFetchAccountants';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import type { Accountants } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import PayrollOfficerToSeeModal from './PayrollOfficerToSeeModal';

const componentName = 'PayrollOfficerTable';

type Props = {
  isDocuments: boolean;
};
export default function PayrollOfficerTable({ isDocuments }: Props) {
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const {
    accountantsListData,
    refetchAccountantsListData,
    isAccountantsListDataLoading,
  } = useFetchAccountants();
  const { t, lang } = useI18n();
  const queryClient = useQueryClient();
  const [
    isOpenedPayrollOfficerToSee,
    { open: openPayrollOfficerToSee, close: closePayrollOfficerToSee },
  ] = useDisclosure(false);
  const [payrollOfficerToSee, setPayrollOfficerToSee] =
    useState<Accountants | null>(null);

  const accountantsList = useMemo(() => {
    if (!accountantsListData) {
      return [];
    }
    return accountantsListData;
  }, [accountantsListData]);

  const {
    mutate: removeAccountantAccess,
    isLoading: isRemoveAccountantAccessLoading,
  } = useMutation({
    mutationFn: (variables: { accountantCompanyId: string }) =>
      CharteredAccountantService.removeAccountantAccess(
        variables.accountantCompanyId,
        companyId
      ),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'CharteredAccountantService.invitationAccountantHistory',
          companyId,
        ],
      });
      showNotification({
        id: `remove-accountant-${variables.accountantCompanyId}-access-successful`,
        title: t('w.success'),
        message: `${t('w.PayrollOfficerHasBeenDeleted')} !`,
        color: 'green',
        icon: <IconCheck />,
      });
      refetchAccountantsListData();
    },
    onError: (error) =>
      showNotification({
        id: 'remove-accountant-access-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const columns: DataTableColumn<Accountants>[] = useMemo(
    () => [
      {
        accessor: 'accountant.name',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ accountant }) => (
          <Text fz={'xs'} fw={'bold'}>
            {accountant.name}
          </Text>
        ),
      },
      {
        accessor: 'accountant.mail',
        title: t('w.contactEmail'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'accountant.phone',
        title: t('w.contactPhone'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'divisionAccountant',
        title: company?.divisionLabel ? company.divisionLabel : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ divisionAccountant }) => (
          <MoreItemsBadge
            items={divisionAccountant.map((div) =>
              div.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : div.name
            )}
          />
        ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        render: (payrollOfficer) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() => {
                  setPayrollOfficerToSee(payrollOfficer);
                  openPayrollOfficerToSee();
                }}
              >
                <IconEye size={16} />
              </ActionIcon>
            </CustomTooltip>
            {!payrollOfficer.paiementAccount && (
              <CustomTooltip label={t('w.delete')}>
                <ActionIcon
                  variant={'subtle'}
                  size="sm"
                  color="red"
                  onClick={() =>
                    handleRemoveAccountantAccessClick(
                      payrollOfficer.accountant.id
                    )
                  }
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </CustomTooltip>
            )}
          </Group>
        ),
      },
    ],
    [isDocuments]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'accountant.name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function handleRemoveAccountantAccessClick(id: string) {
    removeAccountantAccess({
      accountantCompanyId: id,
    });
  }

  return (
    <>
      <LoadingOverlay visible={isRemoveAccountantAccessLoading} />
      <Table
        pinFirstColumn
        pinLastColumn
        idAccessor={'accountant.id'}
        options={options}
        columns={columns}
        rows={accountantsList}
        lang={lang}
        fetching={isAccountantsListDataLoading}
        height={'calc(100vh - 220px)'}
      />
      {company && (
        <PayrollOfficerToSeeModal
          opened={isOpenedPayrollOfficerToSee}
          onClose={closePayrollOfficerToSee}
          payrollOfficerToSee={payrollOfficerToSee}
          company={company}
        />
      )}
    </>
  );
}
