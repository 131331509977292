export const HfwAzureSync = 'hfwAzureSync';
export const CompanyDaysWorked = 'companyDaysWorked';
export const CompanyUseN2 = 'companyUseN2';
export const CompanyUseAssistant = 'companyUseAssistant';
export const ShuttleSheet = 'ShuttleSheet';
export const BulletinCogilog = 'BulletinCogilog';
export const BulletinIsapaye = 'BulletinIsapaye';
export const GererMesAffaires = 'GererMesAffaires';
export const TimeRecorder = 'TimeRecorder';
export const DailyWork = 'DailyWork';
export const ShowMessageBeforePaidLeaveRequest =
  'ShowMessageBeforePaidLeaveRequest';
export const ExpenseReport = 'ExpenseReport';
export const SynchroOpenPaye = 'SynchroOpenPaye';
export const ExportCegidExpert = 'ExportCegidExpert';
export const ExportQuadra = 'ExportQuadra';
export const ExportDIAPAIE = 'ExportDIAPAIE';
export const ExportISAPAYE = 'ExportISAPAYE';
export const ExportSilae = 'ExportSilae';
export const VariablePayroll = 'VariablePayroll';
export const CharteredAccountant = 'CharteredAccountant';
export const HfwAccountant = 'hfwAccountant';
export const HfwMyCompany = 'hfwMyCompany';
export const Pappers = 'pappers';
export const DpaeUrssaf = 'dpaeUrssaf';
export const IncomeSimulatorUrssaf = 'incomeSimulatorUrssaf';
export const Yoomonkeez = 'yoomonkeez';
export const Vasa = 'vasa';
export const MaitreDataLegiTempo = 'MaitreDataLegiTempo';
export const MaitreDataEasyBDESE = 'MaitreDataEasyBDESE';
export const MaitreDataLegiVision = 'MaitreDataLegiVision';

/**
 * @deprecated : TODO rechercher où c'est utilisé et fix car le module n'existe plus en V2
 */
export const PersonalRegister = 'Personal Register';

export type ModuleNameType =
  | typeof HfwAzureSync
  | typeof CompanyDaysWorked
  | typeof CompanyUseN2
  | typeof CompanyUseAssistant
  | typeof ShuttleSheet
  | typeof BulletinCogilog
  | typeof BulletinIsapaye
  | typeof GererMesAffaires
  | typeof TimeRecorder
  | typeof DailyWork
  | typeof ShowMessageBeforePaidLeaveRequest
  | typeof ExpenseReport
  | typeof SynchroOpenPaye
  | typeof ExportCegidExpert
  | typeof ExportQuadra
  | typeof ExportDIAPAIE
  | typeof ExportISAPAYE
  | typeof ExportSilae
  | typeof VariablePayroll
  | typeof CharteredAccountant
  | typeof HfwAccountant
  | typeof HfwMyCompany
  | typeof Pappers
  | typeof DpaeUrssaf
  | typeof IncomeSimulatorUrssaf
  | typeof Yoomonkeez
  | typeof Vasa
  | typeof MaitreDataLegiTempo
  | typeof MaitreDataEasyBDESE
  | typeof MaitreDataLegiVision
  | typeof PersonalRegister;

export enum ModulesNames {
  HfwAzureSync = 'hfwAzureSync',
  CompanyDaysWorked = 'companyDaysWorked',
  CompanyUseN2 = 'companyUseN2',
  CompanyUseAssistant = 'companyUseAssistant',
  ShuttleSheet = 'ShuttleSheet',
  BulletinCogilog = 'BulletinCogilog',
  BulletinIsapaye = 'BulletinIsapaye',
  GererMesAffaires = 'GererMesAffaires',
  TimeRecorder = 'TimeRecorder',
  DailyWork = 'DailyWork',
  ShowMessageBeforePaidLeaveRequest = 'ShowMessageBeforePaidLeaveRequest',
  ExpenseReport = 'ExpenseReport',
  SynchroOpenPaye = 'SynchroOpenPaye',
  ExportCegidExpert = 'ExportCegidExpert',
  ExportQuadra = 'ExportQuadra',
  ExportDIAPAIE = 'ExportDIAPAIE',
  ExportISAPAYE = 'ExportISAPAYE',
  ExportSilae = 'ExportSilae',
  VariablePayroll = 'VariablePayroll',
  CharteredAccountant = 'CharteredAccountant',
  HfwAccountant = 'hfwAccountant',
  HfwMyCompany = 'hfwMyCompany',
  Pappers = 'pappers',
  DpaeUrssaf = 'dpaeUrssaf',
  IncomeSimulatorUrssaf = 'incomeSimulatorUrssaf',
  Yoomonkeez = 'yoomonkeez',
  Vasa = 'vasa',
  MaitreDataLegiTempo = 'MaitreDataLegiTempo',
  MaitreDataEasyBDESE = 'MaitreDataEasyBDESE',
  MaitreDataLegiVision = 'MaitreDataLegiVision',
}
