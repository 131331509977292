import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useModule } from '../contexts/ModuleProvider';
import ExpenseReportService from '../services/ExpenseReportService';
import LeaveService from '../services/LeaveService';
import { hasPermission } from '../utils/authorization';
import {
  ALL,
  ValidateExpenseReports,
  ValidateLeavesAbsences,
} from '../variables/BuiltInPermissions';
import { ExpenseReport } from '../variables/ModulesV2';
import useCompany from './useCompany';

export default function usePendingValidations() {
  const { user } = useAuth();
  const { getModule } = useModule();
  const { id: companyId } = useCompany(user);

  const {
    data: leavesPendingValidation,
    isLoading: leavesPendingValidationIsLoading,
  } = useQuery({
    queryKey: ['LeaveService.getLeavesPendingValidation', companyId],
    queryFn: () => LeaveService.getLeavesPendingValidation(companyId),
    enabled: hasPermission(
      [{ permission: ValidateLeavesAbsences, scope: ALL }],
      user
    ),
  });

  const {
    data: expenseReportPendingValidation,
    isLoading: expenseReportPendingValidationIsLoading,
  } = useQuery({
    queryKey: ['ExpenseReportService.getPendingValidations', companyId],
    queryFn: () => ExpenseReportService.getPendingValidations(companyId),
    enabled:
      getModule(ExpenseReport)?.active &&
      hasPermission([{ permission: ValidateExpenseReports, scope: ALL }]),
  });

  const pendingValidations: PendingValidationsCount = useMemo(() => {
    return {
      leaves: leavesPendingValidation?.length || 0,
      expenseReports: expenseReportPendingValidation?.length || 0,
      total:
        (leavesPendingValidation?.length || 0) +
        (expenseReportPendingValidation?.length || 0),
    };
  }, [leavesPendingValidation, expenseReportPendingValidation]);

  const loading: boolean =
    expenseReportPendingValidationIsLoading || leavesPendingValidationIsLoading;

  return { pendingValidations, loading };
}

type PendingValidationsCount = {
  leaves: number;
  expenseReports: number;
  total: number;
};
