import {
  Card,
  Chip,
  Divider,
  Flex,
  Group,
  Indicator,
  LoadingOverlay,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconBellRinging, IconStarFilled, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Fragment, useMemo, useState } from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import s from '../../features/user-account/component/notifications/Notifications.module.sass';
import useCompany from '../../hooks/useCompany';
import NotificationService, {
  AccountantNotificationConfig,
  NotificationConfig,
} from '../../services/NotificationService';
import { Notification, NotificationCompany } from '../../types/types';
import handleErrorMessage from '../../utils/handleErrorMessage';
import CustomCompanyAvatar from '../CustomAvatar/CustomCompanyAvatar';
import CustomTooltip from '../CustomTooltip';
import classes from './NotificationCard.module.sass';

type Props = {
  userId?: string;
  type: 'user' | 'company' | 'accountant';
};

type FormValues = {
  notifications: Notification[];
};

export function NotificationCard({ userId, type }: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);
  const form = useForm<FormValues>({
    initialValues: {
      notifications: [],
    },
  });
  const [allNotificationsForMyCompany, setAllNotificationsForMyCompany] =
    useState<NotificationCompany[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>(
    user.company.name || ''
  );

  const {
    isLoading: isAllUserNotificationLoading,
    refetch: refetchUserNotification,
  } = useQuery({
    enabled: type === 'user' && !!userId,
    queryKey: ['Notifications.allList', userId],
    queryFn: () => NotificationService.allList(userId as string),
    onSuccess: (data) => {
      form.setInitialValues({ notifications: data });
      form.setValues({ notifications: data });
    },
  });

  const {
    isLoading: isAllCompanyNotificationLoading,
    refetch: refetchAllNotificationsForMyCompany,
  } = useQuery({
    enabled: type === 'company' && !!userId,
    queryKey: ['Notifications.allListForMyCompany', userId],
    queryFn: () => NotificationService.allListForMyCompany(userId as string),
    onSuccess: (data) => {
      setAllNotificationsForMyCompany(data);
    },
    onError: () => {
      setAllNotificationsForMyCompany([]);
    },
  });

  const {
    data: allAccountantNotificationData,
    isLoading: isAllAccountantNotificationLoading,
    refetch: refetchAllAccountantNotification,
  } = useQuery({
    enabled: type === 'accountant',
    queryKey: ['NotificationService.allAccountantNotification', companyId],
    queryFn: () => NotificationService.allAccountantNotification(companyId),
  });

  const allAccountantNotification: NotificationCompany = useMemo(() => {
    if (!allAccountantNotificationData) {
      return null;
    }
    return allAccountantNotificationData;
  }, [allAccountantNotificationData]);

  const { mutate: updateUserNotificationConfig } = useMutation({
    mutationKey: ['NotificationService.updateUserNotificationConfig'],
    mutationFn: (variables: NotificationConfig) =>
      NotificationService.updateUserNotificationConfig(userId, variables),
    onSuccess: () => {
      refetchUserNotification();
    },
    onError: (error) =>
      showNotification({
        id: `update-user-notification-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateCompanyNotificationConfig } = useMutation({
    mutationKey: ['NotificationService.updateCompanyNotificationConfig'],
    mutationFn: (variables: NotificationConfig) =>
      NotificationService.updateCompanyNotificationConfig(userId, variables),
    onSuccess: () => {
      refetchAllNotificationsForMyCompany();
    },
    onError: (error) =>
      showNotification({
        id: `update-company-notification-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateAccountantNotificationConfig } = useMutation({
    mutationKey: ['NotificationService.updateUserNotificationConfig'],
    mutationFn: (variables: AccountantNotificationConfig) =>
      NotificationService.updateAccountantNotification(companyId, variables),
    onSuccess: () => {
      refetchAllAccountantNotification();
    },
    onError: (error) =>
      showNotification({
        id: `update-accountant-notification-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function getCompanyId() {
    const findCompany = allNotificationsForMyCompany.find(
      (notification) => notification.companyName === selectedCompany
    );
    if (findCompany) {
      return findCompany.companyId;
    }
  }

  function updateNotificationMailConfig(item: Notification, checked: boolean) {
    if (type === 'user') {
      updateUserNotificationConfig({
        notificationDefinitionId: item.id,
        userId: userId,
        mail: checked,
        app: item.app.value,
        mobile: item.mobile.value,
      });
    }
    if (type === 'company') {
      updateCompanyNotificationConfig({
        notificationDefinitionId: item.id,
        userId: userId,
        companyId: getCompanyId(),
        mail: checked,
        app: item.app.value,
        mobile: item.mobile.value,
      });
    }
    if (type === 'accountant') {
      updateAccountantNotificationConfig({
        accountantCompanyId: companyId,
        notificationDefinitionId: item.id,
        mail: checked,
        app: item.app.value,
        mobile: item.mobile.value,
      });
    }
  }

  function updateNotificationAppConfig(item: Notification, checked: boolean) {
    if (type === 'user') {
      updateUserNotificationConfig({
        notificationDefinitionId: item.id,
        userId: userId,
        mail: item.mail.value,
        app: checked,
        mobile: item.mobile.value,
      });
    }
    if (type === 'company') {
      updateCompanyNotificationConfig({
        notificationDefinitionId: item.id,
        userId: userId,
        companyId: getCompanyId(),
        mail: item.mail.value,
        app: checked,
        mobile: item.mobile.value,
      });
    }
    if (type === 'accountant') {
      updateAccountantNotificationConfig({
        accountantCompanyId: companyId,
        notificationDefinitionId: item.id,
        mail: item.mail.value,
        app: checked,
        mobile: item.mobile.value,
      });
    }
  }

  function updateNotificationMobileConfig(
    item: Notification,
    checked: boolean
  ) {
    if (type === 'user') {
      updateUserNotificationConfig({
        notificationDefinitionId: item.id,
        userId: userId,
        mail: item.mail.value,
        app: item.app.value,
        mobile: checked,
      });
    }
    if (type === 'company') {
      updateCompanyNotificationConfig({
        notificationDefinitionId: item.id,
        userId: userId,
        companyId: getCompanyId(),
        mail: item.mail.value,
        app: item.app.value,
        mobile: checked,
      });
    }
    if (type === 'accountant') {
      updateAccountantNotificationConfig({
        accountantCompanyId: companyId,
        notificationDefinitionId: item.id,
        mail: item.mail.value,
        app: item.app.value,
        mobile: checked,
      });
    }
  }

  function ChipNotificationsWrapper(item: Notification) {
    return (
      <Fragment key={item.id}>
        <Flex
          justify="space-between"
          align={'center'}
          className={classes.item}
          wrap="nowrap"
          mb={'xs'}
        >
          <Text fz={'sm'} fw={'bold'} c={'hifivework.4'}>
            {t(`notification.${item.key}`)}
          </Text>
          <Group gap={'xs'}>
            {item.mail.enabled ? (
              <Chip
                checked={item.mail.value}
                onChange={(checked) =>
                  updateNotificationMailConfig(item, checked)
                }
                size={'xs'}
              >
                Mail
              </Chip>
            ) : (
              <CustomTooltip label={t('w.unavailable')}>
                <span>
                  <Chip
                    disabled={!item.mail.enabled}
                    checked={item.mail.value}
                    onChange={(checked) =>
                      updateNotificationMailConfig(item, checked)
                    }
                    size={'xs'}
                  >
                    Mail
                  </Chip>
                </span>
              </CustomTooltip>
            )}
            {item.app.enabled ? (
              <Chip
                checked={item.app.value}
                onChange={(checked) =>
                  updateNotificationAppConfig(item, checked)
                }
                size={'xs'}
              >
                Application
              </Chip>
            ) : (
              <CustomTooltip label={t('w.unavailable')}>
                <span>
                  <Chip
                    disabled={!item.app.enabled}
                    checked={item.app.value}
                    onChange={(checked) =>
                      updateNotificationAppConfig(item, checked)
                    }
                    size={'xs'}
                  >
                    Application
                  </Chip>
                </span>
              </CustomTooltip>
            )}
            {item.mobile.enabled ? (
              <Chip
                checked={item.mobile.value}
                onChange={(checked) =>
                  updateNotificationMobileConfig(item, checked)
                }
                size={'xs'}
              >
                Mobile
              </Chip>
            ) : (
              <CustomTooltip label={t('w.unavailable')}>
                <span>
                  <Chip
                    disabled={!item.mobile.enabled}
                    checked={item.mobile.value}
                    onChange={(checked) =>
                      updateNotificationMobileConfig(item, checked)
                    }
                    size={'xs'}
                  >
                    Mobile
                  </Chip>
                </span>
              </CustomTooltip>
            )}
          </Group>
        </Flex>
        <Divider />
      </Fragment>
    );
  }

  const userNotifications = form.values?.notifications?.map((item) =>
    ChipNotificationsWrapper(item)
  );

  const companyNotifications = allNotificationsForMyCompany
    .find((item) => item.companyName === selectedCompany)
    ?.companyCustomDefinition?.map((item) => ChipNotificationsWrapper(item));

  const accountantNotifications =
    allAccountantNotification?.companyCustomDefinition?.map((item) =>
      ChipNotificationsWrapper(item)
    );

  function handleSelectCompanyClick(companyName: string) {
    setSelectedCompany(companyName);
  }

  return (
    <>
      {type === 'company' && allNotificationsForMyCompany?.length > 0 ? (
        <Group mb={'md'} gap={'sm'}>
          {allNotificationsForMyCompany.map((item) => (
            <Indicator
              color={'var(--mantine-color-hifivework-2)'}
              key={item.companyId}
              disabled={!item.myCompany}
              inline
              processing
              label={<IconStarFilled size={10} />}
              size={20}
              styles={{
                indicator: {
                  top: 13,
                  right: 13,
                },
              }}
            >
              <CustomTooltip
                label={
                  item.myCompany
                    ? t('module.hfwMyCompany')
                    : t('w.customerCompany')
                }
                position={'bottom'}
              >
                <Group
                  gap={'xs'}
                  className={s.overviewWrapper}
                  styles={{
                    root: {
                      backgroundColor:
                        item.companyName === selectedCompany
                          ? 'var(--mantine-color-hifivework-0)'
                          : 'var(--mantine-color-body)',
                      cursor: 'pointer',
                      border: `1px solid ${
                        item.companyName === selectedCompany
                          ? 'var(--mantine-color-hifivework-2)'
                          : 'var(--mantine-primary-color-light)'
                      }`,
                    },
                  }}
                  onClick={() => handleSelectCompanyClick(item.companyName)}
                >
                  <CustomCompanyAvatar companyId={item.companyId} size={60} />
                  <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
                    {item.companyName}
                  </Text>
                </Group>
              </CustomTooltip>
            </Indicator>
          ))}
        </Group>
      ) : (
        type === 'company' && (
          <Text mb={'md'} fz={'sm'} fw={'bold'} c={'dimmed'}>
            Aucune entreprise
          </Text>
        )
      )}
      <Card withBorder radius="md" p="xl" className={classes.card}>
        {type === 'user' ? (
          <LoadingOverlay visible={isAllUserNotificationLoading} />
        ) : type === 'company' ? (
          <LoadingOverlay visible={isAllCompanyNotificationLoading} />
        ) : (
          <LoadingOverlay visible={isAllAccountantNotificationLoading} />
        )}
        <Group>
          <Text fz="lg" className={classes.title} fw={'bold'}>
            {`${t('w.configure')} notifications`}
          </Text>
          <IconBellRinging
            size={20}
            color={'var(--mantine-color-hifivework-3)'}
          />
        </Group>
        <Text fz="xs" fw={'bold'} c="dimmed" mt={3} mb="xl">
          {t('w.chooseWhatNotificationsYouWantToReceive')}
        </Text>
        {type === 'user'
          ? userNotifications
          : type === 'company'
          ? companyNotifications
          : accountantNotifications}
      </Card>
    </>
  );
}
