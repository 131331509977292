import { GetTranslationProps } from '../types/types';

export default function handleErrorMessage(
  err: any,
  t?: GetTranslationProps
): string {
  let msg = '';
  if (!t) {
    t = function t(key) {
      return key;
    };
  }

  if (err?.response?.status) {
    switch (err.response.status) {
      case 401:
      case 403:
      case 406:
        if (err?.response?.data?.errorKey) {
          if (t(err.response.data.errorKey) === err.response.data.errorKey) {
            msg = t(err.response.data.errorDescription);
          } else {
            msg = t(err.response.data.errorKey);
          }
        }
        if (!msg && err?.response?.data?.errors?.[0]?.key) {
          msg = t(err.response.data.errors[0].key);
        }
        if (!msg) {
          msg = t('w.unauthorized');
        }
        break;
      case 413:
        msg = t('w.fileTooLarge');
        break;
      case 422:
        msg = t(`error.${err?.response?.data?.errorKey}`);
        break;
      default:
        if (err?.response?.data?.errorKey) {
          if (t(err.response.data.errorKey) === err.response.data.errorKey) {
            msg = t(err.response.data.errorDescription);
          } else {
            msg = t(err.response.data.errorKey);
          }
        }

        if (!msg && err?.response?.data?.errors?.[0]?.key) {
          msg = t(err.response.data.errors[0].key);
        }
    }
  }

  if (msg === '') {
    msg = t('w.error');
  }
  return msg;
}
