import { Divider, Grid, LoadingOverlay, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import OauthServiceMS from '../../../../services/OauthServiceMS';
import { OauthProvider, Provider } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import OauthProviderItem from './OauthProviderItem';
import OauthUpdatePasswordForm from './OauthUpdatePasswordForm';

export default function OauthUserSecurity() {
  const { t } = useI18n();

  const { data: providersListData, refetch: refetchProvidersListData } =
    useQuery({
      queryKey: ['OauthServiceMS.providersList'],
      queryFn: () => OauthServiceMS.providersList(),
    });

  const providersList: OauthProvider[] = useMemo(() => {
    if (!providersListData) {
      return [];
    }
    return providersListData;
  }, [providersListData]);

  const providers = useMemo(
    () => [
      {
        label: 'Google',
        connected: Boolean(
          providersList.find((item) => item.provider === 'Google')
        ),
        logoUrl: '/images/google/google.png',
        mail: providersList.find((item) => item.provider === 'Google')?.mail,
      },
      {
        label: 'GérerMesAffaires',
        connected: Boolean(
          providersList.find((item) => item.provider === 'GMA')
        ),
        logoUrl: '/images/features/gerer_mes_affaires.png',
        mail: providersList.find((item) => item.provider === 'GMA')?.mail,
      },
      {
        label: 'Microsoft',
        connected: Boolean(
          providersList.find((item) => item.provider === 'Microsoft')
        ),
        logoUrl: '/images/features/microsoft_authentication.png',
        mail: providersList.find((item) => item.provider === 'Microsoft')?.mail,
      },
    ],
    [providersList]
  );

  const {
    mutate: removeProviderToLoggedOauth,
    isLoading: isRemoveProviderToLoggedOauthLoading,
  } = useMutation({
    mutationFn: (variables: Provider) =>
      OauthServiceMS.removeProviderToLoggedOauth(variables),
    onSuccess: (data, variables) => {
      refetchProvidersListData();
      showNotification({
        id: `delete-provider-${variables}-successful`,
        title: t('w.success'),
        message: t('w.disconnected').toLowerCase(),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'delete-provider-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleAddOrRemoveProviderClick(
    isConnected: boolean,
    providerLabel: string
  ) {
    if (isConnected) {
      switch (providerLabel) {
        case 'Google':
          removeProviderToLoggedOauth('Google');
          break;
        case 'Microsoft':
          removeProviderToLoggedOauth('Microsoft');
          break;
        default:
          removeProviderToLoggedOauth('GMA');
      }
    } else {
      // TODO connect provider
    }
  }

  return (
    <Grid justify={'space-around'} gutter={'xl'} mt={'md'}>
      <Grid.Col span={{ base: 12, sm: 5 }}>
        <OauthUpdatePasswordForm />
      </Grid.Col>
      <Divider orientation="vertical" />
      <Grid.Col span={{ base: 12, sm: 5 }}>
        <Stack gap={'lg'}>
          <LoadingOverlay visible={isRemoveProviderToLoggedOauthLoading} />
          {providers.map((provider, index) => (
            <OauthProviderItem
              key={`${provider.label}-${index}`}
              mail={provider.mail}
              logoUrl={provider.logoUrl}
              connected={provider.connected}
              onClick={() =>
                handleAddOrRemoveProviderClick(
                  provider.connected,
                  provider.label
                )
              }
            />
          ))}
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
