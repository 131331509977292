import { List, rem, Stack, ThemeIcon, Title } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import { IconBuilding } from '@tabler/icons-react';

import { useI18n } from '../../../../contexts/I18nProvider';
import { Division, EndOfMonthValidation } from '../../../../types/types';

type Props = {
  divisions: Division[];
  endOfMonthValidation: EndOfMonthValidation[];
};

export default function EndOfMonthDivisionsToValidate({
  divisions,
  endOfMonthValidation,
}: Props) {
  const { t } = useI18n();

  const endOfMonthsNotValidated = endOfMonthValidation.filter(
    (endOfMonth) => !endOfMonth.valid
  );

  function getDivisionName(divisionId: string): string | undefined {
    const findDivision = divisions.find(
      (division) => division.id === divisionId
    );
    if (findDivision) {
      return findDivision.name;
    }
  }

  function renderList(list: EndOfMonthValidation[]) {
    return (
      <List spacing="xs" size="sm" fw={'bold'}>
        {list.map((item) => (
          <List.Item
            key={item.divisionId}
            icon={
              <ThemeIcon color="hifivework.2" size={24} radius="xl">
                <IconBuilding style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
            }
          >
            {getDivisionName(item.divisionId)}
          </List.Item>
        ))}
      </List>
    );
  }

  return (
    <Stack>
      <Title size={'h5'} c={'hifivework.4'}>
        {upperFirst(t('w.toValidate'))}
      </Title>
      {renderList(endOfMonthsNotValidated)}
    </Stack>
  );
}
