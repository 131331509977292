import { Role } from '../types/types';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string): Promise<Role[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${companyId}`)
    .then((res) => res?.data);
}

function deleteRole(companyId: string, roleId: string) {
  return request
    .delete(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${roleId}/company/${companyId}`
    )
    .then((res) => res?.data);
}

function createRole(companyId: string, roleName: string): Promise<Role> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${roleName}/company/${companyId}`
    )
    .then((res) => res?.data);
}

export interface PermissionData {
  permissionId: string;
  scope: string | undefined;
}

function update(
  companyId: string,
  roleId: string | undefined,
  data: { permissionScoped: PermissionData[] | undefined }
): Promise<Role> {
  return request
    .patch(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${companyId}/${roleId}`,
      data
    )
    .then((res) => res?.data);
}

const SecurityRoleService = {
  list,
  deleteRole,
  createRole,
  update,
};

export default SecurityRoleService;
