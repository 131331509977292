import { Tabs, useMantineColorScheme } from '@mantine/core';
import React, { useMemo } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import type {
  BlockingPeriod,
  Company,
  Division,
  LeaveType,
} from '../../../../types/types';
import BlockingPeriodsTable from './blocking-periods/BlockingPeriodsTable';
import CompanyMandatoryLeavesTable from './company-mandatory-leaves/CompanyMandatoryLeavesTable';

type Props = {
  activeTab: string | null;
  setActiveTab: (value: string | null) => void;
  divisions: Division[];
  companyBlockingPeriodsData: BlockingPeriod[];
  isCompanyBlockingPeriodsListLoading: boolean;
  refetchCompanyBlockingPeriodsData: () => void;
  allCompanyLeaveTypesListData?: LeaveType[];
  companyLeavesListData: any;
  isCompanyLeavesListDataLoading: boolean;
  refetchCompanyLeavesListData: () => void;
  company: Company;
};

export default function PeriodsTabs({
  activeTab,
  setActiveTab,
  divisions,
  companyBlockingPeriodsData,
  isCompanyBlockingPeriodsListLoading,
  refetchCompanyBlockingPeriodsData,
  allCompanyLeaveTypesListData,
  companyLeavesListData,
  isCompanyLeavesListDataLoading,
  refetchCompanyLeavesListData,
  company,
}: Props) {
  const { colorScheme } = useMantineColorScheme();
  const { t } = useI18n();

  const tabs = useMemo(
    () => [
      {
        value: 'blocking-periods',
        label: t('w.blockingPeriods'),
        disabled: false,
      },
      {
        value: 'leaves',
        label: t('w.companyLeaves'),
        disabled: false,
      },
    ],
    []
  );

  return (
    <Tabs value={activeTab} onChange={setActiveTab}>
      <Tabs.List>
        {tabs.map((tab) => (
          <Tabs.Tab
            key={tab.value}
            bg={
              activeTab === tab.value && colorScheme === 'light'
                ? 'white'
                : 'transparent'
            }
            value={tab.value}
            disabled={tab.disabled}
          >
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      <Tabs.Panel
        bg={colorScheme === 'light' ? 'white' : 'transparent'}
        value="blocking-periods"
        pt="xs"
      >
        <BlockingPeriodsTable
          company={company}
          companyBlockingPeriodsData={companyBlockingPeriodsData}
          isCompanyBlockingPeriodsListLoading={
            isCompanyBlockingPeriodsListLoading
          }
          refetchCompanyBlockingPeriodsData={refetchCompanyBlockingPeriodsData}
        />
      </Tabs.Panel>
      <Tabs.Panel
        bg={colorScheme === 'light' ? 'white' : 'transparent'}
        value="leaves"
        pt="xs"
      >
        <CompanyMandatoryLeavesTable
          company={company}
          companyLeavesListData={companyLeavesListData}
          allCompanyLeaveTypesListData={allCompanyLeaveTypesListData}
          isCompanyLeavesListDataLoading={isCompanyLeavesListDataLoading}
          divisions={divisions}
          refetchCompanyLeavesListData={refetchCompanyLeavesListData}
        />
      </Tabs.Panel>
    </Tabs>
  );
}
