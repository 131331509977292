import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import MoreItemsBadge from '../../../../../components/badges/MoreItemsBadge';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { useI18n } from '../../../../../contexts/I18nProvider';
import BlockingPeriodsService from '../../../../../services/BlockingPeriodsService';
import { BlockingPeriod, Company, Division } from '../../../../../types/types';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';

type Props = {
  companyBlockingPeriodsData: BlockingPeriod[];
  isCompanyBlockingPeriodsListLoading: boolean;
  refetchCompanyBlockingPeriodsData: () => void;
  company: Company;
};

const componentName = 'BlockingPeriodsTable';
export default function BlockingPeriodsTable({
  companyBlockingPeriodsData,
  isCompanyBlockingPeriodsListLoading,
  refetchCompanyBlockingPeriodsData,
  company,
}: Props) {
  const { t, lang } = useI18n();
  const [selectedBlockingPeriods, setSelectedBlockingPeriods] = useState<any>(
    []
  );
  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
  });

  const { mutate: deleteBlockingPeriods } = useMutation({
    mutationFn: (variables: { blockId: string }) =>
      BlockingPeriodsService.deleteBlockingPeriods(
        variables.blockId,
        company.id
      ),
    onSuccess: () => {
      refetchCompanyBlockingPeriodsData();
      showNotification({
        id: `delete-blocking-periods-successful`,
        title: t('w.success'),
        message: t('w.deleted'),
        color: 'green',
        icon: <IconCheck />,
      });
      setSelectedBlockingPeriods([]);
    },
    onError: (error) =>
      showNotification({
        id: 'delete-blocking-periods-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const blockingPeriodsList = useMemo(() => {
    if (!companyBlockingPeriodsData) {
      return [];
    }
    return companyBlockingPeriodsData;
  }, [companyBlockingPeriodsData]);

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'name',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'description',
        title: 'Description',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'period.start',
        title: t('w.startDate'),
        sortable: true,
        ellipsis: true,
        render: ({ period }) => dayjs(period.start).utc().format('DD/MM/YYYY'),
      },
      {
        accessor: 'period.end',
        title: t('w.endDate'),
        sortable: true,
        ellipsis: true,
        render: ({ period }) => dayjs(period.end).utc().format('DD/MM/YYYY'),
      },
      {
        accessor: 'divisions',
        title: company?.divisionLabel ? company.divisionLabel : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => (
          <MoreItemsBadge
            items={division.map((div: Division) =>
              div.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : div.name
            )}
          />
        ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        render: ({ id }) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.delete')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="red"
                onClick={() => deleteBlockingPeriods({ blockId: id })}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    [company?.divisionLabel]
  );

  const rightCustomActions = useMemo(
    () => [
      <Button
        leftSection={<IconTrash />}
        disabled={selectedBlockingPeriods.length === 0}
        key={'delete-several-blocking-periods'}
        onClick={handleDeleteSelectedBlockingPeriodsClick}
      >
        {t('w.delete')}
      </Button>,
    ],
    [selectedBlockingPeriods.length]
  );

  function handleDeleteSelectedBlockingPeriodsClick() {
    selectedBlockingPeriods.forEach((item: BlockingPeriod) =>
      deleteBlockingPeriods({ blockId: item.id })
    );
  }

  return (
    <Table
      height={'calc(100vh - 220px)'}
      pinFirstColumn
      pinLastColumn
      onSelect={(blockingPeriod) => setSelectedBlockingPeriods(blockingPeriod)}
      rows={blockingPeriodsList}
      fetching={isCompanyBlockingPeriodsListLoading}
      options={options}
      columns={columns}
      lang={lang}
      rightCustomActions={rightCustomActions}
    />
  );
}
