import { Group, Paper, SimpleGrid, Text } from '@mantine/core';
import {
  IconDatabase,
  IconDatabaseImport,
  IconExclamationCircle,
} from '@tabler/icons-react';
import { useMemo } from 'react';

import { ResultImportModal } from '../../../../../components/ImportModal';
import { useI18n } from '../../../../../contexts/I18nProvider';
import s from './ImportEmployeesReportStats.module.sass';

type Props = {
  resultImportModal: ResultImportModal | null;
};

export default function ImportEmployeesReportStats({
  resultImportModal,
}: Props) {
  const { t } = useI18n();

  const icons: any = {
    dataUpdated: IconDatabase,
    dataCreated: IconDatabaseImport,
    errors: IconExclamationCircle,
  };

  const data = useMemo(
    () => [
      {
        title: t('w.dataCreated'),
        icon: 'dataCreated',
        value: resultImportModal?.nbCreated,
      },
      {
        title: t('w.dataUpdated'),
        icon: 'dataUpdated',
        value: resultImportModal?.nbUpdated,
      },
      {
        title: `${t('w.error')}s`,
        icon: 'errors',
        value: resultImportModal?.nbErrors,
      },
    ],
    [resultImportModal]
  );

  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];

    return (
      <Paper withBorder p="md" radius="md" key={stat.title}>
        <Group justify="space-between">
          <Text fz="sm" c="hifivework.2" className={s.title}>
            {stat.title}
          </Text>
          <Icon size="1.4rem" stroke={1.5} />
        </Group>
        <Group align="flex-end" gap="xs" mt={25}>
          <Text fz={'lg'} fw={700} className={s.value}>
            {stat.value}
          </Text>
        </Group>
      </Paper>
    );
  });

  return (
    <SimpleGrid spacing={'xl'} cols={3}>
      {stats}
    </SimpleGrid>
  );
}
