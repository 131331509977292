import React from 'react';

import CustomUserAvatar from '../../../../../components/CustomAvatar/CustomUserAvatar';
import CustomTooltip from '../../../../../components/CustomTooltip';
import type { Division } from '../../../../../types/types';

type Props = { name: string; id: string; division: Division };

export default function AvatarTooltip({ name, id, division }: Props) {
  return (
    <CustomTooltip
      label={`${name} : ${name === division.nameOfN2 ? 'N+2' : 'N+1'}`}
    >
      <div>
        <CustomUserAvatar userId={id} />
      </div>
    </CustomTooltip>
  );
}
