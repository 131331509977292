import { Button, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCalendarPlus,
  IconCheck,
  IconCirclePlus,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useModule } from '../../contexts/ModuleProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import ProfileService from '../../services/ProfileService';
import { hasPermission } from '../../utils/authorization';
import {
  ALL,
  ValidateExpenseReports,
} from '../../variables/BuiltInPermissions';
import { ExpenseReport } from '../../variables/ModulesV2';
import ExpenseReportRequestForm from '../expense-reports/components/ExpenseReportRequestForm';
import UserProfileLeaves from '../user-profile/components/UserProfileLeaves';
import LeavesRequestFormModal from './components/leaves/LeavesRequestFormModal';
import PrivacyPolicyModal from './components/PrivacyPolicyModal';
import WarningPrivacyPolicyModal from './components/WarningPrivacyPolicyModal';

export default function Home() {
  const { user, access_token, refresh_token, updateUser } = useAuth();
  const { t } = useI18n();
  const [opened, { close, open }] = useDisclosure(false);
  const [warningPrivacyPolicyModalOpened, setWarningPrivacyPolicyModalOpened] =
    useState<boolean>(false);
  const { getModule } = useModule();
  const isExpenseReportModuleActive = Boolean(getModule(ExpenseReport)?.active);

  const actionButtons = useMemo(() => {
    if (
      isExpenseReportModuleActive &&
      hasPermission([{ permission: ValidateExpenseReports, scope: ALL }], user)
    ) {
      return [
        <Button
          key={'expenseReport-button'}
          leftSection={<IconCirclePlus />}
          bg={'white'}
          variant={'subtle'}
          onClick={() =>
            modals.open({
              modalId: `add-new-expense-report-modal`,
              title: (
                <Title size={'h3'} component="p">
                  {t('w.expenseReport')}
                </Title>
              ),
              size: '90%',
              children: (
                <ExpenseReportRequestForm
                  closeModal={() =>
                    modals.close('add-new-expense-report-modal')
                  }
                  userProfile={user}
                />
              ),
            })
          }
        >
          {t('w.expenseReport')}
        </Button>,
        <Button
          key={'requestAbsence-button'}
          leftSection={<IconCalendarPlus />}
          onClick={openLeaveRequestForm}
        >
          {t('w.requestAbsence')}
        </Button>,
      ];
    } else {
      return [
        <Button
          key={'requestAbsence-button'}
          leftSection={<IconCalendarPlus />}
          onClick={openLeaveRequestForm}
        >
          {t('w.requestAbsence')}
        </Button>,
      ];
    }
  }, [isExpenseReportModuleActive]);

  useEffect(() => {
    if (!user.termsAcceptance) {
      open();
    }
  }, [user]);

  const { mutate: updateUserTermsAcceptance } = useMutation({
    mutationFn: (payload: { access_token: string; user: any }) =>
      ProfileService.updateUserTermsAcceptance(
        payload.access_token,
        payload.user.id
      ),
    onSuccess: (_data, payload) => {
      updateUser(_data, payload.access_token, refresh_token);
      close();
      setWarningPrivacyPolicyModalOpened(false);
      showNotification({
        id: 'userTermsAcceptance',
        title: t('w.success'),
        message: `${t('w.privacyPolicy')[0].toUpperCase()}${t(
          'w.privacyPolicy'
        ).slice(1)} ${t('w.accepted')}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
  });

  function handleAgreeTermsClick() {
    updateUserTermsAcceptance({ access_token: access_token, user: user });
  }

  function handleModalClose() {
    showNotification({
      id: 'close-privacy-policy-modal',
      title: t('w.warning'),
      message: t('w.privacyPolicyTextPart4'),
      color: 'orange',
      icon: <IconAlertTriangle />,
    });
    setWarningPrivacyPolicyModalOpened(true);
  }

  function openLeaveRequestForm() {
    modals.open({
      modalId: 'leave-request-form',
      title: (
        <Title size={'h3'} component="p">
          {t('w.requestForLeaveOrAbsence', user.fullname)}
        </Title>
      ),
      size: '90%',
      children: (
        <LeavesRequestFormModal
          onClose={() => modals.close('leave-request-form')}
          user={user}
        />
      ),
    });
  }

  return (
    <>
      {user?.company?.id && (
        <ScreenWrapper
          title={t('w.hello', user.firstname)}
          date
          actionButtons={actionButtons}
          fixedPriceManager={user.fixedPriceManager}
        >
          <UserProfileLeaves userProfile={user} />
        </ScreenWrapper>
      )}

      <PrivacyPolicyModal
        opened={opened}
        onClose={handleModalClose}
        onClick={handleAgreeTermsClick}
      />
      <WarningPrivacyPolicyModal
        opened={warningPrivacyPolicyModalOpened}
        onClose={() => setWarningPrivacyPolicyModalOpened(false)}
        onClick={handleAgreeTermsClick}
      />
    </>
  );
}
