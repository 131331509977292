import { LoadingOverlay } from '@mantine/core';
import { isInRange, isNotEmpty, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import LeaveTypeService from '../../../../services/LeaveTypeService';
import type {
  LeaveType,
  LeaveTypeFormValues,
  LeaveTypePayload,
} from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import PeriodTypeStepper from './PeriodTypeStepper';

type Props = {
  leaveTypeToEdit: LeaveType | null;
  refetch: () => void;
  onClose: () => void;
  companyId: string;
};

export default function PeriodTypeForm({
  leaveTypeToEdit,
  refetch,
  onClose,
  companyId,
}: Props) {
  const { t } = useI18n();

  const form = useForm<LeaveTypeFormValues>({
    initialValues: {
      name: leaveTypeToEdit?.name ? leaveTypeToEdit.name : '',
      description: leaveTypeToEdit?.description
        ? leaveTypeToEdit.description
        : '',
      order: leaveTypeToEdit?.order ? leaveTypeToEdit.order : 0,
      maxDays: leaveTypeToEdit?.maxDays ? leaveTypeToEdit.maxDays : 0,
      legalDelay: leaveTypeToEdit?.legalDelay ? leaveTypeToEdit.legalDelay : 0,
      securityRoles: leaveTypeToEdit?.securityRoles
        ? leaveTypeToEdit.securityRoles.map((role) => role.id)
        : [],
      autoValid: leaveTypeToEdit?.autoValid ? leaveTypeToEdit.autoValid : false,
      requiredComment: leaveTypeToEdit?.requiredComment
        ? leaveTypeToEdit.requiredComment
        : false,
      requiredAttachment: leaveTypeToEdit?.requiredAttachment
        ? leaveTypeToEdit.requiredAttachment
        : false,
      blockingPeriodAllowed: leaveTypeToEdit?.blockingPeriodAllowed
        ? leaveTypeToEdit.blockingPeriodAllowed
        : false,
      useAllDays: leaveTypeToEdit?.useAllDays
        ? leaveTypeToEdit.useAllDays
        : false,
      showLabel: leaveTypeToEdit?.showLabel ? leaveTypeToEdit.showLabel : false,
      excludeEndMonth: leaveTypeToEdit?.excludeEndMonth
        ? leaveTypeToEdit.excludeEndMonth
        : false,
      repeatable: leaveTypeToEdit?.repeatable
        ? leaveTypeToEdit.repeatable
        : false,
      cancelOthersLeaves: leaveTypeToEdit?.cancelOthersLeaves
        ? leaveTypeToEdit.cancelOthersLeaves
        : false,
      color: leaveTypeToEdit?.color ? leaveTypeToEdit.color : '',
      icon: leaveTypeToEdit?.icon ? leaveTypeToEdit.icon : 'beach',
      codeQuadra: leaveTypeToEdit?.codeQuadra ? leaveTypeToEdit.codeQuadra : '',
      codeDiapaie: leaveTypeToEdit?.codeDiapaie
        ? leaveTypeToEdit.codeDiapaie
        : '',
      codeSilae: leaveTypeToEdit?.codeSilae ? leaveTypeToEdit.codeSilae : '',
      codeIsapaye: leaveTypeToEdit?.codeIsapaye
        ? leaveTypeToEdit.codeIsapaye
        : '',
      codeOpenpaye: leaveTypeToEdit?.codeOpenpaye
        ? leaveTypeToEdit.codeOpenpaye
        : '',
      codeCegidExpert: leaveTypeToEdit?.codeCegidExpert
        ? leaveTypeToEdit.codeCegidExpert
        : '',
      associateCounter: leaveTypeToEdit?.associateCounter?.length
        ? leaveTypeToEdit.associateCounter.sort((a, b) => a.order - b.order)
        : undefined,
      status: leaveTypeToEdit?.securityRoles
        ? leaveTypeToEdit.securityRoles.length > 0
        : true,
    },
    validate: {
      name: isNotEmpty(t('w.required')),
      maxDays: isInRange({ min: 0 }, t('w.maxDaysMustBeGrtOrEqZero')),
      legalDelay: isInRange({ min: 0 }, t('w.legalDelayMustBeGrtOrEqZero')),
    },
  });

  useEffect(() => {
    if (!form.values.status) {
      form.setFieldValue('securityRoles', []);
    } else {
      form.setFieldValue(
        'securityRoles',
        leaveTypeToEdit?.securityRoles
          ? leaveTypeToEdit?.securityRoles.map((role) => role.id)
          : []
      );
    }
  }, [form.values.status]);

  const { mutate: updateLeaveType, isLoading: isUpdateLeaveTypeLoading } =
    useMutation({
      mutationFn: (variables: LeaveTypePayload) =>
        LeaveTypeService.updateLeaveType(
          companyId,
          leaveTypeToEdit?.id,
          variables
        ),
      onSuccess: (data) => {
        refetch();
        onClose();
        showNotification({
          id: `update-leave-type-${data?.id}-successful`,
          title: t('w.success'),
          message: t('success.leaveType.updated', data?.name),
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onError: (error) =>
        showNotification({
          id: `update-leave-type-error`,
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  const { mutate: createLeaveType, isLoading: isCreateLeaveTypeLoading } =
    useMutation({
      mutationFn: (variables: LeaveTypePayload) =>
        LeaveTypeService.createLeaveType(companyId, variables),
      onSuccess: (data) => {
        refetch();
        onClose();
        showNotification({
          id: `create-leave-type-${data?.id}-successful`,
          title: t('w.success'),
          message: t('success.leaveType.created', data?.name),
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onError: (error) =>
        showNotification({
          id: `create-leave-type-error`,
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  function handleUpdateLeaveTypeFormSubmit(values: LeaveTypeFormValues) {
    delete values.status;
    const data = {
      ...values,
      companyId: companyId,
    };
    if (leaveTypeToEdit !== null) {
      updateLeaveType(data);
    } else {
      createLeaveType(data);
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateLeaveTypeFormSubmit(values)
      )}
    >
      <LoadingOverlay
        visible={isUpdateLeaveTypeLoading || isCreateLeaveTypeLoading}
      />
      <PeriodTypeStepper form={form} isEdit={!!leaveTypeToEdit} />
    </form>
  );
}
