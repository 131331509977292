import en from '../i18n/en.json';
import fr from '../i18n/fr.json';

/**
 * Translate a file according to a key and a lang
 * Works with subtree (object)
 * @param lang the lang to translate, default 'fr'
 * @param key the key to translate
 * @param {optional} args other arguments if variables need to be pass
 */
export default function translate(
  lang: string,
  key: string,
  ...args: (string | number)[]
) {
  let translationFile;
  switch (lang) {
    case 'en':
      translationFile = en;
      break;
    default:
      translationFile = fr;
  }
  // get the value corresponding to the key from the Json file
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let result = translationFile[key];
  // if no result as a string, check if it's a value in an object
  if (!result) {
    result = _accessObjectfromString('.', key, translationFile);
  }
  // if nothing, return the key
  if (!result) {
    return key;
  }

  // case when others arguments for variables
  if (Array.isArray(args) && args.length) {
    return result.replace(
      /{(\d+)}/g,
      (_: any, n: string | number) => args[+n - 1]
    );
  }

  return result;
}

/**
 * Access an object from a string with a separator
 * Example : string "a.b.c" in obj => return obj.a.b.c
 * @param separation the separation in the string of the elementToAccess
 * @param elementToAccess the string element to access in the object
 * @param object the object where the string want to access
 */
function _accessObjectfromString(
  separation: string,
  elementToAccess: string,
  object: any
) {
  return elementToAccess
    .split(separation)
    .reduce((o, i) => (o ? o[i] : o), object);
}
