import { Stack } from '@mantine/core';

import ConfirmValidateEmployeesList from '../../../../components/ConfirmValidateEmployeesList';
import { EndOfMonthValidationData } from '../../../../types/types';

type Props = {
  selectedEmployees: EndOfMonthValidationData[];
};

export default function EndOfMonthConfirmValidateEmployeesList({
  selectedEmployees,
}: Props) {
  return (
    <Stack>
      {selectedEmployees.map((item) => (
        <ConfirmValidateEmployeesList
          key={item.employee}
          userId={item.employee}
          userFullname={item.employeeObject.fullname}
        />
      ))}
    </Stack>
  );
}
