import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useStripe } from '@stripe/react-stripe-js';
import { IconCreditCardPay, IconDownload } from '@tabler/icons-react';
import dayjs from 'dayjs';
import type { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import PaymentAccountService from '../../../../services/PaymentAccountService';
import type { BillingHistory } from '../../../../types/types';
import { PaymentAccount } from '../../../../types/types';
import download from '../../../../utils/download';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';

type Props = {
  invoices: BillingHistory[];
  paymentAccount: PaymentAccount;
  variant?: 'default' | 'small';
};

const componentName = 'Invoices';
export default function Invoices({
  invoices,
  paymentAccount,
  variant = 'default',
}: Props) {
  const { access_token } = useAuth();
  const { t, formatNumber } = useI18n();
  const stripe = useStripe();

  const columns: DataTableColumn<BillingHistory>[] = useMemo(
    () => [
      {
        accessor: 'paymentIntentDate',
        title: 'Date',
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
        render: ({ paymentIntentDate }: BillingHistory) =>
          paymentIntentDate
            ? dayjs(paymentIntentDate, 'YYYY-MM-DD').format('L')
            : '-',
      },
      {
        accessor: 'invoiceRef',
        title: t('paymentAccount.invoice.invoiceRef'),
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'amountHT',
        title: t('w.amountHT'),
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
        render: ({ amountHT }: BillingHistory) => formatNumber(amountHT),
      },
      {
        accessor: 'amountTTC',
        title: t('w.amountTTC'),
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
        render: ({ amountTTC }: BillingHistory) => formatNumber(amountTTC),
      },
      {
        accessor: 'declineMessage',
        title: t('paymentAccount.invoice.declineMessage'),
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'declineCode',
        title: t('paymentAccount.invoice.declineCode'),
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'lastPaymentErrorCode',
        title: t('paymentAccount.invoice.lastPaymentErrorCode'),
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'status',
        title: t('w.status'),
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
        render: ({ status }: BillingHistory) => t(`w.${status}`),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        draggable: true,
        resizable: true,
        sortable: true,
        ellipsis: true,
        textAlign: 'right',
        render: (invoice: BillingHistory) => renderActionCell(invoice),
      },
    ],
    [stripe]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'paymentIntentDate', direction: 'desc' },
    search: {
      accessor: 'invoiceRef',
      label: t('paymentAccount.invoice.invoiceRef'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [
        'amountTTC',
        'declineMessage',
        'declineCode',
        'lastPaymentErrorCode',
      ],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status',
          label: t('w.status'),
          choices: [
            { value: 'pending', label: t('w.pending') },
            { value: 'failed', label: t('w.failed') },
            { value: 'succeeded', label: t('w.succeeded') },
            {
              value: 'requires_payment_method',
              label: t('w.noPaymentMethod'),
            },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function downloadClick(invoice: BillingHistory) {
    download(
      `Facture n°${invoice.invoiceRef}`,
      PaymentAccountService.getInvoiceUrl(paymentAccount.id, invoice.id),
      access_token
    );
  }

  function payClick(invoice: BillingHistory) {
    // TODO
  }

  function continuePayProcessClick(invoice: BillingHistory) {
    // TODO
  }

  function renderActionCell(invoice: BillingHistory) {
    switch (invoice.status) {
      case 'succeeded':
        return (
          <Tooltip label={t('w.download')} withArrow withinPortal>
            <ActionIcon
              size="sm"
              variant={'subtle'}
              onClick={() => downloadClick(invoice)}
            >
              <IconDownload size={16} />
            </ActionIcon>
          </Tooltip>
        );
      case 'failed':
        return (
          <Tooltip label={t('w.pay')} withArrow withinPortal>
            <ActionIcon
              size="sm"
              variant={'subtle'}
              onClick={() => payClick(invoice)}
            >
              <IconCreditCardPay size={16} />
            </ActionIcon>
          </Tooltip>
        );
      default:
        // include status === "pending"
        return (
          <Tooltip label={t('w.continuePayment')} withArrow withinPortal>
            <ActionIcon
              size="sm"
              variant={'subtle'}
              onClick={() => continuePayProcessClick(invoice)}
            >
              <IconCreditCardPay size={16} />
            </ActionIcon>
          </Tooltip>
        );
    }
  }

  return (
    <Table
      withTableBorder={false}
      pinFirstColumn
      pinLastColumn
      height={variant === 'default' ? undefined : 'auto'}
      options={variant === 'default' ? options : undefined}
      withPagination={variant === 'default'}
      columns={columns}
      rows={invoices}
    />
  );
}
