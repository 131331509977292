import {
  Center,
  Grid,
  Group,
  Image,
  NumberInput,
  Select,
  Stepper,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import {
  IconBuildingBank,
  IconCalendarTime,
  IconCheck,
  IconClockHour5,
  IconFile,
  IconUser,
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import s from '../features/company/employees/components/AddEmployeeInformationForm/AddEmployeeInformationForm.module.sass';
import UserIdentifiantsCompany from '../features/user-profile/components/UserIdentifiantsCompany';
import UserIdentifiantsCompanyButton from '../features/user-profile/components/UserIdentifiantsCompanyButton';
import useUserCompanyModules from '../hooks/useUserCompanyModules';
import ProfileService from '../services/ProfileService';
import { DailyWorkParams, IdentifiantCompany, User } from '../types/types';
import { hasPermission } from '../utils/authorization';
import { typesContract } from '../utils/contracts';
import { typesRegime } from '../utils/regimes';
import {
  ALL,
  AuthorizedReadDailyWork,
  AuthorizedUpsertDailyWork,
  ValidateLeavesAbsences,
} from '../variables/BuiltInPermissions';
import { DailyWork } from '../variables/ModulesV2';
import CustomDropzone from './CustomDropzone/CustomDropzone';
import DailyworkTypeMode from './DailyworkTypeMode';
import FileViewer from './FileViewer/FileViewer';
import LabelText from './LabelText/LabelText';

type Props = {
  form: any;
  request: boolean;
  employee?: User | null;
};

export default function ContractualStepper({ form, request, employee }: Props) {
  const { t } = useI18n();
  const checkMediaQuery = useMediaQuery('(min-width: 1025px)');
  const [activeStepper, setActiveStepper] = useState(0);
  const { height } = useViewportSize();
  const { userId } = useParams();
  const { getModule } = useUserCompanyModules(userId as string);
  const { access_token, user } = useAuth();
  const [file, setFile] = useState({
    opened: false,
    path: '',
    type: '',
    loading: false,
  });
  const isModuleDailyWorkActiveForCompany = getModule(DailyWork)?.active;
  const isModuleDailyWorkActiveForAll = (
    getModule(DailyWork)?.configuration?.params as DailyWorkParams
  )?.activeForAll;
  const isActiveForUser = Boolean(
    employee?.paidFeatures?.find((item) => item.active)
  );

  useEffect(() => {
    if (activeStepper === 4) {
      openImage();
    }
  }, [activeStepper]);

  function openImage() {
    setFile({ ...file, loading: true });
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], {
          type: 'application/pdf',
        });
        const filePath = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = filePath;
        link.download = `exportpdf`;
        link.target = '_blank';
        setFile({
          path: filePath,
          type: newBlob.type,
          opened: true,
          loading: false,
        });
      }
    };
    xhr.open('GET', ProfileService.getUserContract(form.userId), true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  function renderComponent() {
    switch (activeStepper) {
      case 1:
        return (
          <Grid gutter="md">
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.dateOfAuthorizationToHire')}
                </Text>
                <Text fz={'sm'} fw={'600'}>
                  {form.employmentAuthorizationDate}
                </Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.dateOfEntry')}
                </Text>

                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {form.entryDate}
                </Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.dateAuthorizationForDismissal')}
                </Text>

                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {form.layoffAuthorizationDate}
                </Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.dateOfExit')}
                </Text>
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {form.exitDate}
                </Text>
              </Group>
            </Grid.Col>
          </Grid>
        );
      case 2:
        return (
          <Grid gutter={'md'}>
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.grossMonthlyRemuneration')}
                </Text>
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {form.grossPay}
                </Text>
              </Group>
            </Grid.Col>
          </Grid>
        );
      case 3:
        return (
          <>
            <Grid gutter="md">
              <Grid.Col span={4}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.weeklyHours')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.weeklyHours === '' ? '-' : form.weeklyHours}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={4}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.monthlyHours')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.monthlyHours === '' ? '-' : form.monthlyHours}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={4}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.yearlyHours')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.yearlyHours === '' ? '-' : form.yearlyHours}
                  </Text>
                </Group>
              </Grid.Col>
            </Grid>
          </>
        );
      case 4:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.workContract')}
                </Text>
                {file ? (
                  <Center>
                    {file.type === 'application/pdf' ? (
                      <FileViewer
                        id={`contract-${form.userId}`}
                        srcUrl={file.path}
                        title={`contract.pdf`}
                      />
                    ) : (
                      <Center>
                        <Image w={'40%'} h={'40%'} src={file.path} />
                      </Center>
                    )}
                  </Center>
                ) : (
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {t('w.noWorkContract')}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
          </>
        );
      case 5:
        return (
          <Grid gutter="xl">
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.nameOnPayslip')}
                </Text>
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {form.payslipName}
                </Text>
              </Group>
            </Grid.Col>
          </Grid>
        );
      case 6:
        return (
          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                {t('w.timeManagement')}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              {employee && (
                <DailyworkTypeMode
                  t={t}
                  id={employee.id}
                  companyId={employee.companyId}
                  fixedPriceManager={employee.fixedPriceManager}
                  paidFeatures={employee.paidFeatures}
                />
              )}
            </Grid.Col>
          </Grid>
        );
      default:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.typeOfContract')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.contractType}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.regimeType')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.regime}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.regimeLevel')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.regimeLevel}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.professionalQualification')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.professionalQualifications}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.jobType')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.employment}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.comment')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {form.comment}
                  </Text>
                </Group>
              </Grid.Col>
            </Grid>
          </>
        );
    }
  }

  function renderComponentRequest() {
    switch (activeStepper) {
      case 1:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={<LabelText text={t('w.dateOfAuthorizationToHire')} />}
                  valueFormat={'D MMMM YYYY'}
                  placeholder={t('w.noValue')}
                  clearable
                  {...form.getInputProps('employmentAuthorizationDate')}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={<LabelText text={t('w.dateOfEntry')} />}
                  valueFormat={'D MMMM YYYY'}
                  placeholder={t('w.noValue')}
                  clearable
                  {...form.getInputProps('entryDate')}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={
                    <LabelText text={t('w.dateAuthorizationForDismissal')} />
                  }
                  valueFormat={'D MMMM YYYY'}
                  placeholder={t('w.noValue')}
                  clearable
                  {...form.getInputProps('layoffAuthorizationDate')}
                  disabled={form.values.exitDate != null}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={<LabelText text={t('w.dateOfExit')} />}
                  valueFormat={'D MMMM YYYY'}
                  placeholder={t('w.noValue')}
                  clearable
                  {...form.getInputProps('exitDate')}
                />
              </Grid.Col>
            </Grid>
          </>
        );
      case 2:
        return (
          <Grid gutter={'xl'}>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <NumberInput
                min={0}
                label={<LabelText text={t('w.grossMonthlyRemuneration')} />}
                {...form.getInputProps('grossPay')}
              />
            </Grid.Col>
          </Grid>
        );
      case 3:
        return (
          <>
            <Grid grow gutter="xl">
              <Grid.Col span={{ base: 12, md: 4 }}>
                <NumberInput
                  min={0}
                  label={<LabelText text={t('w.weeklyHours')} />}
                  placeholder={
                    !form.values.weeklyHours
                      ? form.values.monthlyHours
                        ? ((form.values.monthlyHours * 12) / 52).toFixed(2)
                        : form.values.yearlyHours
                        ? (form.values.yearlyHours / 45.4).toFixed(2)
                        : null
                      : null
                  }
                  {...form.getInputProps('weeklyHours')}
                  disabled={
                    (form.values.monthlyHours !== '' &&
                      form.values.monthlyHours !== null) ||
                    (form.values.yearlyHours !== '' &&
                      form.values.yearlyHours !== null)
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4 }}>
                <NumberInput
                  min={0}
                  label={<LabelText text={t('w.monthlyHours')} />}
                  {...form.getInputProps('monthlyHours')}
                  placeholder={
                    !form.values.monthlyHours
                      ? form.values.weeklyHours
                        ? ((form.values.weeklyHours * 52) / 12).toFixed(2)
                        : form.values.yearlyHours
                        ? (
                            ((form.values.yearlyHours / 45.4) * 52) /
                            12
                          ).toFixed(2)
                        : null
                      : null
                  }
                  disabled={
                    (form.values.weeklyHours !== '' &&
                      form.values.weeklyHours !== null) ||
                    (form.values.yearlyHours !== '' &&
                      form.values.yearlyHours !== null)
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4 }}>
                <NumberInput
                  min={0}
                  label={<LabelText text={t('w.yearlyHours')} />}
                  {...form.getInputProps('yearlyHours')}
                  placeholder={
                    !form.values.yearlyHours
                      ? form.values.weeklyHours
                        ? (form.values.weeklyHours * 45.4).toFixed(2)
                        : form.values.monthlyHours
                        ? (
                            ((form.values.monthlyHours * 12) / 52) *
                            45.4
                          ).toFixed(2)
                        : null
                      : null
                  }
                  disabled={
                    (form.values.monthlyHours !== '' &&
                      form.values.monthlyHours !== null) ||
                    (form.values.weeklyHours !== '' &&
                      form.values.weeklyHours !== null)
                  }
                />
              </Grid.Col>
            </Grid>
          </>
        );
      case 4:
        return (
          <>
            <Grid gutter="lg">
              <Grid.Col span={{ base: 10, md: 6 }}>
                <Title order={3}>{t('w.workContract')}</Title>
                <Text fz={'sm'}>
                  sélectionner un fichier ou déplacer ou glisser déposer
                  plusieurs fichiers ici.
                </Text>

                <CustomDropzone
                  onDrop={(files) => form.setFieldValue('file', files)}
                  files={form.values.file}
                  mimeType={'pdf'}
                  type={'simple'}
                  resetFile={() => form.setFieldValue('file', [])}
                />
              </Grid.Col>
            </Grid>

            {form.values?.file.length > 0 ? (
              <Center>
                {form.values.file[0].type === 'application/pdf' ? (
                  <FileViewer
                    id={`document-${form.values.id}`}
                    srcUrl={URL.createObjectURL(form.values.file[0])}
                    title={'contract.pdf'}
                  />
                ) : (
                  <Center>
                    <Image
                      w={'40%'}
                      h={'40%'}
                      src={URL.createObjectURL(form.values.file[0])}
                    />
                  </Center>
                )}
              </Center>
            ) : (
              <Text fz={'sm'} fw={'600'} c={undefined}>
                {t('w.noWorkContract')}
              </Text>
            )}
          </>
        );
      case 5:
        return (
          <Grid grow gutter="xl">
            <Grid.Col span={12}>
              <TextInput
                label={<LabelText text={t('w.nameOnPayslip')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('payslipName')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              {form?.values?.identifiantsCompany &&
                form.values.identifiantsCompany.length > 0 &&
                form.values.identifiantsCompany.map(
                  (item: IdentifiantCompany, index: number) => (
                    <UserIdentifiantsCompany
                      key={index}
                      index={index}
                      form={form}
                    />
                  )
                )}
            </Grid.Col>
            <Grid.Col>
              <UserIdentifiantsCompanyButton
                onClick={() =>
                  form.insertListItem('identifiantsCompany', {
                    matricule: '',
                    codeCompany: '',
                  })
                }
              />
            </Grid.Col>
          </Grid>
        );
      case 6:
        return (
          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text>{t('w.timeManagement')}</Text>
            </Grid.Col>
            <Grid.Col span={3}>
              {employee && (
                <DailyworkTypeMode
                  t={t}
                  id={employee.id}
                  companyId={employee.companyId}
                  fixedPriceManager={employee.fixedPriceManager}
                  paidFeatures={employee.paidFeatures}
                />
              )}
            </Grid.Col>
          </Grid>
        );
      default:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Select
                  label={<LabelText text={t('w.typeOfContract')} />}
                  data={typesContract(t)}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('contractType')}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                {form.values.contractType === 'OTHER' && (
                  <TextInput
                    label={<LabelText text={t('w.otherContractType')} />}
                    placeholder={t('w.other')}
                    {...form.getInputProps('otherContractType')}
                    disabled={form.values.contractType !== 'OTHER'}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Select
                  label={<LabelText text={t('w.regimeType')} />}
                  data={typesRegime(t)}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('regime')}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                {form.values.regime === 'OTHER' && (
                  <TextInput
                    label={<LabelText text={t('w.regimeOther')} />}
                    placeholder={t('w.other')}
                    {...form.getInputProps('otherRegime')}
                    disabled={form.values.regime !== 'OTHER'}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.regimeLevel')} />}
                  placeholder={t('w.regimeLevel')}
                  {...form.getInputProps('regimeLevel')}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.professionalQualification')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('professionalQualifications')}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.jobType')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('employment')}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Textarea
                  resize={'vertical'}
                  placeholder={t('w.noValue')}
                  label={<LabelText text={t('w.comment')} />}
                  {...form.getInputProps('comment')}
                />
              </Grid.Col>
            </Grid>
          </>
        );
    }
  }

  return (
    <Grid
      gutter="lg"
      styles={{
        root: {
          position: 'sticky',
          top: 120,
        },
      }}
    >
      <Grid.Col span={2}>
        <Stepper
          className={checkMediaQuery ? s.stepper : undefined}
          active={activeStepper}
          orientation={'vertical'}
          onStepClick={setActiveStepper}
          miw={'180px'}
        >
          <Stepper.Step
            icon={<IconUser size="1.1rem" />}
            label={checkMediaQuery ? 'Informations' : ''}
            description={checkMediaQuery ? 'Principales' : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconUser size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconCalendarTime size="1.1rem" />}
            label={checkMediaQuery ? 'Dates' : ''}
            description={checkMediaQuery ? 'Embauche et sortie' : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconCalendarTime size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconBuildingBank size={'1.1rem'} />}
            label={checkMediaQuery ? t('w.remuneration') : ''}
            description={checkMediaQuery ? 'Salaire' : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconBuildingBank size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconClockHour5 size={'1.1rem'} />}
            label={checkMediaQuery ? 'Horaires' : ''}
            description={checkMediaQuery ? 'Temps de travail' : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconClockHour5 size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconFile size="1.1rem" />}
            label={checkMediaQuery ? 'Contrat de travail' : ''}
            description={checkMediaQuery ? 'Pièce jointe' : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconFile size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconFile size="1.1rem" />}
            label={checkMediaQuery ? 'Informations' : ''}
            description={checkMediaQuery ? 'Paie' : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconFile size="1.1rem" />
              )
            }
          />
          {isModuleDailyWorkActiveForCompany &&
            hasPermission(
              [
                { permission: ValidateLeavesAbsences, scope: ALL },
                { permission: AuthorizedUpsertDailyWork, scope: ALL },
                { permission: AuthorizedReadDailyWork, scope: ALL },
              ],
              user
            ) &&
            (isModuleDailyWorkActiveForAll || isActiveForUser) && (
              <Stepper.Step
                icon={<IconClockHour5 size={'1.1rem'} />}
                label={checkMediaQuery ? 'Gestion' : ''}
                description={checkMediaQuery ? 'Des temps' : ''}
                completedIcon={
                  request ? (
                    <IconCheck size={'1.1rem'} />
                  ) : (
                    <IconClockHour5 size="1.1rem" />
                  )
                }
              />
            )}
        </Stepper>
      </Grid.Col>
      <Grid.Col
        styles={{
          col: {
            overflowY: 'scroll',
            height: (height * 3) / 5,
          },
        }}
        span={'auto'}
        pl={'xl'}
        pr={'100px'}
      >
        {request ? renderComponentRequest() : renderComponent()}
      </Grid.Col>
    </Grid>
  );
}
