import { Stack, Tabs } from '@mantine/core';
import {
  IconBuilding,
  IconChevronDown,
  IconClipboardList,
} from '@tabler/icons-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import CustomMenu from '../../../components/CustomMenu';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import type { Company, Division } from '../../../types/types';
import { hasPermission, hasRole } from '../../../utils/authorization';
import {
  ALL,
  AuthorizedReadAllUsersInfo,
} from '../../../variables/BuiltInPermissions';
import { ADMINISTRATOR } from '../../../variables/BuiltInRoles';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import { DailyWork } from '../../../variables/ModulesV2';
import Employees from '../../company/employees/Employees';
import PlanningScheduler from '../../planning/components/PlanningScheduler';
import LeavesAndAbsencesPendingRequests from '../../validations/leaves/LeavesAndAbsencesPendingRequests';
import WorkUnitsDashboard from './work-units-infos/WorkUnitsDashboard';
import WorkUnitsFixedPriceManagerTable from './WorkUnitsFixedPriceManagerTable';

type Props = {
  divisionConsultedId?: string;
  company: Company;
  divisions: Division[];
};

export default function WorkUnitDetails({
  divisionConsultedId,
  company,
  divisions,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { getModule } = useModule();
  const [selectedDivision, setSelectedDivision] = useState<Division | null>(
    null
  );
  const title = user.company.divisionLabel
    ? user.company.divisionLabel
    : t('w.units');
  const dailyWorkModule = getModule(DailyWork);

  useEffect(() => {
    if (divisionConsultedId) {
      const findDivisionConsulted = divisions.find(
        (division) => division.id === divisionConsultedId
      );
      if (findDivisionConsulted) {
        setSelectedDivision(findDivisionConsulted);
      }
    } else {
      if (user.division) {
        const userWorkUnitIndex = divisions.findIndex(
          (div) => div.id === user.division.id
        );
        if (userWorkUnitIndex !== -1) {
          setSelectedDivision(divisions[userWorkUnitIndex]);
        }
      } else {
        if (divisions.length) {
          setSelectedDivision(divisions[0]);
        }
      }
    }
  }, [user, divisions, divisionConsultedId]);

  function getLabel(divisionName: string) {
    return divisionName === NO_WORK_UNIT ? t('NO_WORK_UNIT') : divisionName;
  }

  const workUnits: { label: string; icon: ReactNode; onClick: () => void }[] =
    useMemo(() => {
      if (divisionConsultedId) {
        return divisions
          .filter(
            (division) =>
              divisionConsultedId && divisionConsultedId === division.id
          )
          .map((division) => ({
            label: getLabel(division.name),
            icon: <IconBuilding />,
            onClick: () => setSelectedDivision(division),
          }));
      } else {
        if (
          !hasRole([ADMINISTRATOR], user) &&
          !hasPermission(
            { permission: AuthorizedReadAllUsersInfo, scope: ALL },
            user
          )
        ) {
          return divisions
            .filter(
              (_div) =>
                (_div.idOfN1 && _div.idOfN1 === user.id) ||
                (_div.idOfN2 && _div.idOfN2 === user.id) ||
                (_div.idOfAssistant && _div.idOfAssistant === user.id) ||
                _div.id === user.division.id
            )
            .map((division) => ({
              label: getLabel(division.name),
              icon: <IconBuilding />,
              onClick: () => setSelectedDivision(division),
            }));
        } else {
          return divisions.map((division) => ({
            label: getLabel(division.name),
            icon: <IconBuilding />,
            onClick: () => setSelectedDivision(division),
          }));
        }
      }
    }, [
      hasRole([ADMINISTRATOR], user),
      hasPermission(
        { permission: AuthorizedReadAllUsersInfo, scope: ALL },
        user
      ),
      divisions,
      divisionConsultedId,
    ]);

  const actionButtons = useMemo(
    () => [
      <CustomMenu
        key={'divisions-menu'}
        buttonLabel={getLabel(
          selectedDivision?.name ? selectedDivision?.name : title
        )}
        buttonVariant={'filled'}
        leftSection={<IconClipboardList />}
        rightSection={<IconChevronDown />}
        menuLabel={title}
        menuItems={workUnits}
        width={250}
      />,
    ],
    [selectedDivision, workUnits]
  );

  const tabs = useMemo(
    () => [
      {
        value: 'planning',
        label: 'Planning',
        component: selectedDivision ? (
          <PlanningScheduler
            planningId="planning-id-to-print"
            selectedDivisions={[selectedDivision.id]}
            selectedTemplate={undefined}
            divisions={divisions}
            company={company}
          />
        ) : null,
        isDisplayed: true,
      },
      {
        value: 'employees',
        label: t('w.employees'),
        component: (
          <Employees
            divisionId={selectedDivision?.id ? selectedDivision.id : ''}
            company={company}
          />
        ),
        isDisplayed: true,
      },
      {
        value: 'fixedPriceManager',
        label: t('w.fixedPriceManager'),
        component: (
          <WorkUnitsFixedPriceManagerTable
            companyId={company.id}
            selectedDivisions={[
              selectedDivision?.id ? selectedDivision.id : '',
            ]}
          />
        ),
        isDisplayed: dailyWorkModule?.active,
      },
      {
        value: 'leaves-and-absences',
        label: t('w.leavesAndAbsences'),
        component: (
          <LeavesAndAbsencesPendingRequests
            divisionId={selectedDivision?.id ? selectedDivision.id : ''}
            company={company}
          />
        ),
        isDisplayed: true,
      },
    ],
    [selectedDivision, dailyWorkModule?.active, divisions, company]
  );

  return (
    <ScreenWrapper
      title={divisionConsultedId ? '' : title}
      actionButtons={actionButtons}
    >
      <Stack gap={'48px'}>
        <WorkUnitsDashboard
          selectedDivision={selectedDivision}
          company={company}
        />
        <Tabs defaultValue={'planning'}>
          <Tabs.List>
            {tabs
              .filter((tab) => tab.isDisplayed)
              .map((tab, index) => (
                <Tabs.Tab key={`tab-${tab.value}-${index}`} value={tab.value}>
                  {tab.label}
                </Tabs.Tab>
              ))}
          </Tabs.List>
          {tabs
            .filter((tab) => tab.isDisplayed)
            .map((tab, index) => (
              <Tabs.Panel
                p={'md'}
                key={`panel-${tab.value}-${index}`}
                value={tab.value}
              >
                {tab.component}
              </Tabs.Panel>
            ))}
        </Tabs>
      </Stack>
    </ScreenWrapper>
  );
}
