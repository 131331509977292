import { Button, Flex, Switch, TagsInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDeviceFloppy } from '@tabler/icons-react';
import React from 'react';

import type { ModuleToEditComponentProps } from '../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { ExportParams } from '../../../../types/types';

export default function ExportSilaeParams({
  module,
  updateModuleParams,
}: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const moduleParams = module?.configuration?.params as ExportParams;

  const form = useForm<ExportParams>({
    initialValues: {
      includeLeave: moduleParams?.includeLeave || false,
      includeVar: moduleParams?.includeVar || false,
      codesDossier: moduleParams?.codesDossier || [],
    },
  });

  function submit(values: ExportParams) {
    if (module) {
      updateModuleParams({
        apiUrl: module.description.url,
        payload: {
          active: true,
          ...values,
        },
      });
    }
  }

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Flex direction={'column'} gap={'xl'}>
        <Flex justify={'space-between'} gap={'md'}>
          <Switch
            labelPosition="left"
            label={t('w.includeLeave')}
            checked={form.values.includeLeave}
            {...form.getInputProps('includeLeave')}
          />
          <Switch
            labelPosition="left"
            label={t('w.includeVar')}
            checked={form.values.includeVar}
            {...form.getInputProps('includeVar')}
          />
        </Flex>
        <TagsInput
          label={t('w.codesDossiers')}
          placeholder={t('w.enterCodeDossier')}
          data={form.values.codesDossier}
          {...form.getInputProps('codesDossier')}
        />
        <Flex justify={'flex-end'} mt={'xl'}>
          <Button type={'submit'} leftSection={<IconDeviceFloppy />}>
            {t('w.saveAndActivate')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
