import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import CkModuleManagerServiceMS from '../services/CkModuleManagerServiceMS';
import ProfileService from '../services/ProfileService';
import type { Module } from '../types/types';
import type { ModuleNameType } from '../variables/ModulesV2';

export default function useUserCompanyModules(userId: string) {
  const { user, access_token } = useAuth();
  const [companyId, setCompanyId] = useState<string | undefined>(undefined);

  const { data: userProfile } = useQuery({
    enabled: userId !== user.id,
    queryKey: ['ProfileService.getUserProfile', userId],
    queryFn: () => ProfileService.getUserProfile(access_token, userId),
  });

  const { data: modules } = useQuery({
    enabled: !!companyId,
    queryKey: ['CkModuleManagerServiceMS.list', companyId],
    queryFn: () =>
      CkModuleManagerServiceMS.list(companyId as string, 'companyFeature'),
  });

  useEffect(() => {
    if (userId === user.id) {
      setCompanyId(user.company.id);
    }
  }, [userId]);

  useEffect(() => {
    if (userProfile) {
      setCompanyId(userProfile.company.id);
    }
  }, [userProfile]);

  function getModule(moduleName: ModuleNameType): Module | undefined {
    if (!modules?.length) return undefined;

    return modules.find((module: Module) => module.name === moduleName);
  }

  return { modules, getModule };
}
