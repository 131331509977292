import { type FileWithPath } from '@mantine/dropzone';

import type {
  ImportLeaveTypeResultResponse,
  LeaveType,
  LeaveTypePayload,
} from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getLeaveTypes(
  companyId: string,
  userId?: string
): Promise<LeaveType[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/leave-type`, {
      params: { companyId, userId },
    })
    .then((res) => res?.data);
}

function createLeaveType(
  companyId: string,
  data: LeaveTypePayload
): Promise<LeaveType> {
  return request
    .post(`${MS_PLANNING_ENDPOINT}/v2/leave-type/${companyId}`, data)
    .then((res) => res?.data);
}

function updateLeaveType(
  companyId: string,
  leaveTypeId: string | undefined,
  data: LeaveTypePayload
): Promise<LeaveType> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/leave-type/${companyId}/${leaveTypeId}`,
      data
    )
    .then((res) => res?.data);
}

function exportLeaveTypes(companyId: string, type: 'XLSX' | 'CSV') {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/leave-type/${companyId}/export/${type}`, {
      responseType: 'blob',
    })
    .then((res) => res?.data);
}

function importLeaveTypes(
  companyId: string,
  file: FileWithPath
): Promise<ImportLeaveTypeResultResponse> {
  return request
    .post(
      `${MS_PLANNING_ENDPOINT}/v2/leave-type/${companyId}/import`,
      {
        file: file,
        filename: file.name.split('.')[0],
        extension: file.name.split('.')[1],
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

const LeaveTypeService = {
  createLeaveType,
  updateLeaveType,
  exportLeaveTypes,
  importLeaveTypes,
  getLeaveTypes,
};

export default LeaveTypeService;
