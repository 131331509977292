import {
  Button,
  ColorPicker,
  Fieldset,
  Flex,
  MultiSelect,
  SimpleGrid,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDeviceFloppy } from '@tabler/icons-react';
import React from 'react';

import type { ModuleToEditComponentProps } from '../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { CompanyDaysWorkedParams as CompanyDaysWorkedParamsType } from '../../../../types/types';
import { DayOfWeek } from '../../../../types/types';
import IconSelector from '../../period-types/components/IconSelector';

export default function CompanyDaysWorkedParams({
  module,
  updateModuleParams,
}: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const moduleParams = module?.configuration
    ?.params as CompanyDaysWorkedParamsType;
  const form = useForm<CompanyDaysWorkedParamsType>({
    initialValues: { ...moduleParams },
    validate: {
      validDayOfWeek: (value) =>
        value?.length && value.length >= 5
          ? null
          : t('w.pleaseSelectAtLeast5WorkedDaysWorked'),
    },
  });
  const ALL_DAYS_OF_WEEK = Object.values(DayOfWeek).map((dayOfWeek) => ({
    value: dayOfWeek,
    label: t(`daysEnum.${dayOfWeek}`),
  }));

  function submit(values: CompanyDaysWorkedParamsType) {
    if (module) {
      const nbMobileBreakDay =
        values.validDayOfWeek?.length === 7
          ? 2
          : values.validDayOfWeek?.length === 6
          ? 1
          : 0;
      updateModuleParams({
        apiUrl: module.description.url,
        payload: {
          active: true,
          ...values,
          nbMobileBreakDay,
        },
      });
    }
  }

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Flex direction={'column'} gap={'md'}>
        <MultiSelect
          label={t('w.workedDays')}
          data={ALL_DAYS_OF_WEEK}
          searchable
          {...form.getInputProps('validDayOfWeek')}
        />

        <Fieldset legend={t('theme.configuration.breakDay')}>
          <SimpleGrid
            cols={{ base: 1, sm: 2 }}
            spacing={{ base: 'sm', sm: 'md' }}
          >
            <ColorPicker {...form.getInputProps('colorIcons.0.color')} />
            <IconSelector
              selected={form.values.colorIcons[0].icon}
              onClick={(newIconName) =>
                form.setFieldValue('colorIcons.0.icon', newIconName)
              }
            />
          </SimpleGrid>
        </Fieldset>

        <Fieldset legend={t('theme.configuration.publicHoliday')}>
          <SimpleGrid
            cols={{ base: 1, sm: 2 }}
            spacing={{ base: 'sm', sm: 'md' }}
          >
            <ColorPicker {...form.getInputProps('colorIcons.1.color')} />
            <IconSelector
              selected={form.values.colorIcons[1].icon}
              onClick={(newIconName) =>
                form.setFieldValue('colorIcons.1.icon', newIconName)
              }
            />
          </SimpleGrid>
        </Fieldset>

        <Fieldset legend={t('theme.configuration.blockingPeriod')}>
          <SimpleGrid
            cols={{ base: 1, sm: 2 }}
            spacing={{ base: 'sm', sm: 'md' }}
          >
            <ColorPicker {...form.getInputProps('colorIcons.2.color')} />
            <IconSelector
              selected={form.values.colorIcons[2].icon}
              onClick={(newIconName) =>
                form.setFieldValue('colorIcons.2.icon', newIconName)
              }
            />
          </SimpleGrid>
        </Fieldset>

        <Flex justify={'flex-end'} mt={'xl'}>
          <Button type={'submit'} leftSection={<IconDeviceFloppy />}>
            {t('w.save')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
