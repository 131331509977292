import { Box } from '@mantine/core';
import { IconAlarm, IconClockHour4 } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

import type { DailyWorkDetails } from '../../types/types';
import css from './HorizontalMonthTimeline.module.sass';

export declare type DailyWorkInfoCellProps = {
  dailyWork: DailyWorkDetails;
  day: Dayjs;
};

export default function DailyWorkInfoCell({
  dailyWork,
  day,
}: DailyWorkInfoCellProps) {
  return (
    <div
      className={css['timeline-content-row-cell-empty']}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box style={{ display: 'flex' }} fz={'xs'}>
        {dailyWork.workingTimeDay > 0 && (
          <>
            <IconClockHour4 size="xs" height={21} />
            {dayjs
              .duration(dailyWork.workingTimeDay, 'minutes')
              .format('HH:mm')}
          </>
        )}
      </Box>
      <Box style={{ display: 'flex' }} fz={'xs'}>
        {dailyWork.overtime > 0 && (
          <>
            <IconAlarm size="xs" color={'red'} height={21} />
            {dayjs.duration(dailyWork.overtime, 'minutes').format('HH:mm')}
          </>
        )}
      </Box>
    </div>
  );
}
