import { ActionIcon, Button, Flex, Group, Select, Text } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleMinus,
  IconCirclePlus,
  IconUserShare,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';

import CustomModal from '../../../../components/CustomModal';
import CustomTooltip from '../../../../components/CustomTooltip';
import CustomTransition from '../../../../components/CustomTransition';
import LabelText from '../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import type { Accountants, AccountantUser } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import AccountantBadge from './AccountantBadge';

type Props = {
  accountantsToConfigure: Accountants | null;
  accountantEmployees: { value: string; label: string }[];
  refetchAccountantCustomersData: () => void;
};

interface FormValues {
  generalManager: string;
}
export default function AssignGeneralManagersForm({
  accountantsToConfigure,
  accountantEmployees,
  refetchAccountantCustomersData,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [accountant, setAccountant] = useState<Accountants | null>(
    accountantsToConfigure
  );
  const [isAddAccountant, { toggle }] = useDisclosure(false);
  const [
    confirmUnassignAccountantModalOpened,
    {
      open: handleConfirmUnassignAccountantModalOpen,
      close: handleConfirmUnassignAccountantModalClose,
    },
  ] = useDisclosure(false);
  const [accountantToUnAssign, setAccountantToUnAssign] =
    useState<AccountantUser | null>(null);

  const form = useForm<FormValues>({
    initialValues: {
      generalManager: '',
    },
    validate: {
      generalManager: isNotEmpty(t('w.required')),
    },
  });

  function showErrorResponse<TError>(error: TError, text: string) {
    return showNotification({
      id: text,
      title: t('w.error'),
      message: handleErrorMessage(error, t),
      color: 'red',
      icon: <IconX />,
    });
  }

  const { mutate: assignAccountantToCustomerCompany } = useMutation({
    mutationFn: (variables: {
      userAccountantId: string;
      divisionId: string | null;
      generalManger: boolean;
    }) =>
      CharteredAccountantService.assignAccountantToCustomerCompany(
        accountantsToConfigure?.clientCompanyId,
        companyId,
        variables.userAccountantId,
        variables.divisionId,
        variables.generalManger
      ),
    onSuccess: (data) => {
      if (data) {
        if (accountant) {
          setAccountant({
            ...accountant,
            generalManagers: data[0]?.generalManagers,
          });
        }
        toggle();
        refetchAccountantCustomersData();
        showNotification({
          id: 'assign-accountant-to-customer-company',
          title: t('w.success'),
          message: t(
            'w.payrollOfficerHasBeenAssignedToCompany',
            accountant?.customerCompany?.name
          ),
          color: 'green',
          icon: <IconCheck />,
        });
      }
    },
    onError: (error) => showErrorResponse(error, 'assign-accountant-error'),
  });

  const { mutate: unAssignAccountantToCustomerCompany } = useMutation({
    mutationFn: (variables: {
      userAccountantId: string;
      divisionId: string | null;
      generalManger: boolean;
    }) =>
      CharteredAccountantService.unAssignAccountantToCustomerCompany(
        accountantsToConfigure?.clientCompanyId,
        companyId,
        variables.userAccountantId,
        variables.divisionId,
        variables.generalManger
      ),
    onSuccess: (data) => {
      if (data) {
        if (accountant) {
          setAccountant({
            ...accountant,
            generalManagers: data[0]?.generalManagers,
          });
        }
        refetchAccountantCustomersData();
        showNotification({
          id: 'unAssign-accountant-to-customer-company',
          title: t('w.success'),
          message: t(
            'w.payrollOfficerHasBeenReassignedFromCompany',
            accountant?.customerCompany?.name
          ),
          color: 'green',
          icon: <IconCheck />,
        });
        handleConfirmUnassignAccountantModalClose();
      }
    },
    onError: (error) => showErrorResponse(error, 'unassign-accountant-error'),
  });

  function handleAssignGeneralManagerSubmit(values: FormValues) {
    assignAccountantToCustomerCompany({
      userAccountantId: values.generalManager,
      divisionId: null,
      generalManger: true,
    });
  }

  function handleUnassignAccountantClick() {
    if (accountantToUnAssign) {
      unAssignAccountantToCustomerCompany({
        userAccountantId: accountantToUnAssign.id,
        divisionId: null,
        generalManger: true,
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleAssignGeneralManagerSubmit(values)
      )}
    >
      <Text fz={'xl'} fw={'bold'} c={'dimmed'} mb="md">
        {t('w.payrollManagersWhoManageClientCompany')} :
      </Text>
      <Flex gap={'xl'} align={'flex-end'}>
        <Group>
          {accountant?.generalManagers.map((manager) => (
            <AccountantBadge
              key={manager.id}
              onClick={() => {
                handleConfirmUnassignAccountantModalOpen();
                setAccountantToUnAssign(manager);
              }}
              size={'lg'}
              userId={manager.id}
            >{`${manager.firstname} ${manager.lastname}`}</AccountantBadge>
          ))}
          <CustomTooltip
            label={
              isAddAccountant
                ? t('w.reduce')
                : `${t('w.add')} ${t('w.payrollOfficer').toLowerCase()}`
            }
            position={'bottom'}
          >
            <ActionIcon
              color={'var(--mantine-color-hifivework-2)'}
              variant={'subtle'}
              onClick={toggle}
            >
              {isAddAccountant ? <IconCircleMinus /> : <IconCirclePlus />}
            </ActionIcon>
          </CustomTooltip>
        </Group>
        <CustomTransition
          opened={isAddAccountant}
          transition={'slide-left'}
          duration={400}
          timingFunction={'ease'}
        >
          <Flex align={'flex-end'} gap={'md'}>
            <Select
              required
              w={300}
              label={<LabelText text={t('w.payrollOfficers')} />}
              data={accountantEmployees}
              placeholder={`${t('w.select')} ${t('w.payrollOfficer')}`}
              {...form.getInputProps('generalManager')}
            />
            <CustomTooltip label={'Assigner'} position={'right'}>
              <ActionIcon size={'lg'} variant={'subtle'} type={'submit'}>
                <IconUserShare
                  size={20}
                  color={'var(--mantine-color-hifivework-2)'}
                />
              </ActionIcon>
            </CustomTooltip>
          </Flex>
        </CustomTransition>
      </Flex>
      <CustomModal
        opened={confirmUnassignAccountantModalOpened}
        onClose={handleConfirmUnassignAccountantModalClose}
        title={`${t('w.withdraw')} ${accountantToUnAssign?.firstname} ${
          accountantToUnAssign?.lastname
        }`}
        size={'lg'}
      >
        <Flex justify={'flex-end'} mt={'xl'}>
          <Button onClick={handleUnassignAccountantClick}>
            {t('w.withdraw')}
          </Button>
        </Flex>
      </CustomModal>
    </form>
  );
}
