import {
  ADMINISTRATOR,
  DIRECTION,
  EMPLOYEE,
  MANAGER,
} from '../variables/BuiltInRoles';

export function roleName(t: any, name: string) {
  if (
    name === ADMINISTRATOR ||
    name === MANAGER ||
    name === DIRECTION ||
    name === EMPLOYEE
  ) {
    return t(`role.${name}`);
  } else {
    return name;
  }
}
