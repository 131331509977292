import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCirclePlus, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import useFetchDivisions from '../../../hooks/useFetchDivisions';
import BlockingPeriodsService from '../../../services/BlockingPeriodsService';
import LeaveTypeService from '../../../services/LeaveTypeService';
import MandatoryLeaveService from '../../../services/MandatoryLeaveService';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import PeriodsModal from './components/PeriodsModal';
import PeriodsTabs from './components/PeriodsTabs';

export default function Periods() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const [activeTab, setActiveTab] = useState<string | null>('blocking-periods');
  const [opened, { open, close }] = useDisclosure(false);

  const {
    data: companyBlockingPeriodsData,
    isLoading: isCompanyBlockingPeriodsListLoading,
    refetch: refetchCompanyBlockingPeriodsData,
  } = useQuery({
    queryKey: ['BlockingPeriodsService.getBlockingPeriodsList', companyId],
    queryFn: () => BlockingPeriodsService.getBlockingPeriodsList(companyId),
    onError: (err) =>
      showNotification({
        id: 'blocking-period-list-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    data: companyLeavesListData,
    isLoading: isCompanyLeavesListDataLoading,
    refetch: refetchCompanyLeavesListData,
  } = useQuery({
    enabled: activeTab !== 'blocking-periods',
    queryKey: ['MandatoryLeaveService.getCompanyLeaves', companyId],
    queryFn: () => MandatoryLeaveService.getCompanyLeaves(companyId),
    onError: (err) =>
      showNotification({
        id: 'company-mandatory-leaves-list-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { divisions } = useFetchDivisions({ companyId });

  const { data: allCompanyLeaveTypesListData } = useQuery({
    queryKey: ['LeaveTypeService.getLeaveTypes', companyId],
    queryFn: () => LeaveTypeService.getLeaveTypes(companyId),
    onError: (err) =>
      showNotification({
        id: 'get-all-leaves-types-list-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  if (!company) return null;

  return (
    <ScreenWrapper
      title={t('w.periods')}
      actionButtons={[
        <Button
          key={'periods-button'}
          leftSection={<IconCirclePlus />}
          onClick={open}
        >
          {activeTab === 'blocking-periods'
            ? t('w.addBlockingPeriod')
            : t('w.addLeave')}
        </Button>,
      ]}
    >
      <PeriodsTabs
        company={company}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        divisions={divisions ? divisions : []}
        companyBlockingPeriodsData={companyBlockingPeriodsData}
        isCompanyBlockingPeriodsListLoading={
          isCompanyBlockingPeriodsListLoading
        }
        refetchCompanyBlockingPeriodsData={refetchCompanyBlockingPeriodsData}
        allCompanyLeaveTypesListData={allCompanyLeaveTypesListData}
        companyLeavesListData={companyLeavesListData}
        isCompanyLeavesListDataLoading={isCompanyLeavesListDataLoading}
        refetchCompanyLeavesListData={refetchCompanyLeavesListData}
      />
      <PeriodsModal
        opened={opened}
        onClose={close}
        activeTab={activeTab}
        divisions={divisions ? divisions : []}
        allCompanyLeaveTypesListData={allCompanyLeaveTypesListData}
        refetchCompanyLeavesListData={refetchCompanyLeavesListData}
        refetchCompanyBlockingPeriodsData={refetchCompanyBlockingPeriodsData}
      />
    </ScreenWrapper>
  );
}
