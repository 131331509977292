import type { Division, User } from '../types/types';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string | undefined): Promise<Division[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division?companyId=${companyId}`)
    .then((res) => res?.data);
}

type Data = {
  name: string;
  managerN1: string;
  managerN2: string;
  assistant: string;
};

function create(companyId: string | undefined, data: Data): Promise<Division> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/create/${companyId}`,
      data
    )
    .then((res) => res?.data);
}

function getDivisionById(divisionId: string | null): Promise<Division> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/${divisionId}`)
    .then((res) => res?.data);
}

function deleteDivision(divisionId: string | null): Promise<Division> {
  return request
    .delete(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/${divisionId}`)
    .then((res) => res?.data);
}

function update(divisionId: string | null, data: Data): Promise<Division> {
  return request
    .patch(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/${divisionId}`, data)
    .then((res) => res?.data);
}

function usersListFromDivision(
  divisionId: string,
  show: string[]
): Promise<User[]> {
  if (show.length > 0) {
    return request
      .get(
        `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/division/${divisionId}/users?${show
          .map((item) => `show=${item}`)
          .join('&')}`
      )
      .then((res) => res?.data);
  } else {
    return request
      .get(
        `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/division/${divisionId}/users`
      )
      .then((res) => res?.data);
  }
}

function exportDivisions(companyId: string, type: 'XLSX' | 'CSV') {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/${companyId}/export/${type}`,
      {
        responseType: 'blob',
      }
    )
    .then((res) => res?.data);
}

const DivisionService = {
  list,
  create,
  getDivisionById,
  deleteDivision,
  update,
  usersListFromDivision,
  exportDivisions,
};

export default DivisionService;
