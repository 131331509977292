import { Divider, Group, Text } from '@mantine/core';
import React from 'react';

type Props = {
  item: {
    firstLabel: string;
    hidden: boolean;
    secondColor: string | undefined;
    secondLabel: string | undefined | any;
    firstColor: string;
    detail: boolean;
    divider: boolean;
  };
};

export default function InfosContainer({ item }: Props) {
  return (
    <div>
      <Group justify={'space-between'}>
        <Text fz={'sm'} fw={'600'} c={item.firstColor}>
          {item.firstLabel}
        </Text>
        {item.detail ? (
          <Group>{item.secondLabel}</Group>
        ) : (
          <Text fz={'sm'} fw={'600'} c={item.secondColor}>
            {item.secondLabel}
          </Text>
        )}
      </Group>
      {item.divider && <Divider my="xs" />}
    </div>
  );
}
