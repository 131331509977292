import { Container, Flex, Tabs, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';

import { UpdateModuleParamsFunction } from '../../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../../contexts/I18nProvider';
import { Module } from '../../../../../types/types';
import SynchroOpenpayefeatureConnectionForm from './SynchroOpenpayefeatureConnectionForm';
import SynchroOpenpayeFeatureDossierForm from './SynchroOpenpayeFeatureDossierForm';

type Props = {
  module: Module | null;
  updateModuleParams: UpdateModuleParamsFunction;
  companyId: string;
};

export default function FeatureSynchroOpenpayeParams({
  module,
  updateModuleParams,
  companyId,
}: Props) {
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState<string | null>('connexion');
  const tabs = useMemo(
    () => [
      { value: 'connexion', label: t('w.connectionOpenpaye') },
      { value: 'dossiers', label: t('w.dossier') },
    ],
    []
  );

  const contents = useMemo(
    () => [
      {
        value: 'connexion',
        component: (
          <SynchroOpenpayefeatureConnectionForm
            module={module}
            companyId={companyId}
          />
        ),
      },
      {
        value: 'dossiers',
        component: (
          <SynchroOpenpayeFeatureDossierForm
            module={module}
            companyId={companyId}
          />
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <Flex
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
        gap="xs"
      >
        <Text size={'sm'}>
          {t('com.feature.openPaye.knowOpenpayeIdentifiants') + ' :'}
        </Text>
        <Text
          component={'a'}
          target="_blank"
          href={'https://app.openpaye.co/MyApp/0/APIAccess/Edit'}
          size={'xs'}
          td="underline"
        >
          https://app.openpaye.co/MyApp/0/APIAccess/Edit
        </Text>
      </Flex>
      <Tabs value={activeTab} onChange={setActiveTab} mt={'md'}>
        <Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Tab key={`${tab.value}-${index}`} value={tab.value}>
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {contents.map((content) => (
          <Tabs.Panel key={content.value} value={content.value} p={'md'}>
            {content.component}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Container>
  );
}
