import { Button, Flex, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDeviceFloppy } from '@tabler/icons-react';
import React from 'react';

import type { ModuleToEditComponentProps } from '../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { ShowMessageBeforePaidLeaveRequestParams as ShowMessageBeforePaidLeaveRequestParamsType } from '../../../../types/types';

export default function ShowMessageBeforePaidLeaveRequestParams({
  module,
  updateModuleParams,
}: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const moduleParams = module.configuration
    ?.params as ShowMessageBeforePaidLeaveRequestParamsType;

  const form = useForm<ShowMessageBeforePaidLeaveRequestParamsType>({
    initialValues: {
      message: moduleParams?.message || '',
    },
  });

  function submit(values: ShowMessageBeforePaidLeaveRequestParamsType) {
    updateModuleParams({
      apiUrl: module.description.url,
      payload: {
        active: true,
        ...values,
      },
    });
  }

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Flex direction={'column'} gap={'xl'}>
        <Textarea
          placeholder={t("En cliquant sur j'accepte ...")}
          label={t('w.message')}
          {...form.getInputProps('message')}
          autosize
          minRows={3}
        />
        <Flex justify={'flex-end'} mt={'xl'}>
          <Button type={'submit'} leftSection={<IconDeviceFloppy />}>
            {t('w.saveAndActivate')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
