import { Flex } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CompanyService from '../../../services/CompanyService';
import CompanyCountry from './components/CompanyCountry';
import CompanyLogoForm from './components/CompanyLogoForm';
import CompanySettingsForm from './components/CompanySettingsForm';

export default function CompanySettings() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const { data: company } = useQuery({
    queryKey: ['CompanyService.getCompany', companyId],
    queryFn: () => CompanyService.getCompany(companyId),
  });

  return (
    <ScreenWrapper title={t('w.settings')}>
      <Flex justify={'space-between'}>
        <CompanyLogoForm companyId={companyId} />
        {company && <CompanyCountry company={company} />}
      </Flex>
      {company && <CompanySettingsForm company={company} />}
    </ScreenWrapper>
  );
}
