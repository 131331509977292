import { Group, Space, Text } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { NotificationCard } from '../../../../components/NotificationCard/NotificationCard';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { User as UserType } from '../../../../types/types';
import { hasPermission } from '../../../../utils/authorization';
import {
  ALL,
  AuthorizedNotificationReceive,
} from '../../../../variables/BuiltInPermissions';
import UserProfilesNotifications from './UserProfilesNotifications';

export type User = {
  id: string;
  fullname: string;
  companyName: string;
  selected: boolean;
  hasPermissionToReceiveCompanyNotifications: boolean;
};

type Props = {
  type: 'user' | 'company' | 'accountant';
};
export default function Notifications({ type }: Props) {
  const { t } = useI18n();
  const { userId } = useParams();
  const { loginResponse } = useAuth();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (!loginResponse) {
      setUsers([]);
    }
    const result = loginResponse?.users?.map((user: UserType) => {
      return {
        id: user.id,
        fullname: user.fullname,
        companyName: user.company.name,
        companyId: user.company.id,
        selected: userId === user.id,
        hasPermissionToReceiveCompanyNotifications: hasPermission(
          [{ permission: AuthorizedNotificationReceive, scope: ALL }],
          user
        ),
      };
    });
    setUsers(result);
  }, [loginResponse]);

  const selectedUserId = useMemo(() => {
    if (users) {
      const findSelectedUser = users.find((user) => user.selected);
      if (findSelectedUser) {
        return findSelectedUser.id;
      }
    }
  }, [users]);

  function handleSelectUserClick(index: number) {
    const _users = [...users];
    _users[index].selected = !_users[index].selected;
    _users.forEach((user, indexUser) => {
      user.selected = index === indexUser;
    });
    setUsers(_users);
  }

  return (
    <>
      {type === 'company' && (
        <Group gap={'xs'} mb={'md'}>
          <IconAlertTriangle color={'red'} />
          <Text fw={'bold'} fz={'xs'} c="red">
            {t('w.companyNotificationsWarningMessage')}
          </Text>
        </Group>
      )}
      {type === 'company' && (
        <UserProfilesNotifications
          users={users?.filter(
            (user) => user.hasPermissionToReceiveCompanyNotifications
          )}
          onClick={(index) => handleSelectUserClick(index)}
        />
      )}
      <Space h={'md'} />
      <NotificationCard userId={selectedUserId} type={type} />
    </>
  );
}
