import { Fieldset, Select, Switch, TextInput } from '@mantine/core';
import type { DateValue } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useDebouncedCallback } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import DailyWorkService from '../../../../services/DailyWorkService';
import type { DailyWorkDetails } from '../../../../types/types';
import { DailyWorkRequest } from '../../../../types/types';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';

type FormValues = {
  comment: string | null;
  worked: boolean;
  divisionConcerned: string;
};

type Props = {
  selectedDate: DateValue;
  data: DailyWorkDetails;
  refresh: () => void;
  companyId: string;
};

export default function EditWorkingDay({
  selectedDate,
  data,
  refresh,
  companyId,
}: Props) {
  const { t } = useI18n();
  const { divisions } = useFetchDivisions({ companyId });
  const divisionsOptions = useMemo(
    () =>
      divisions?.map((item) => ({
        value: item.id,
        label: item.name === NO_WORK_UNIT ? t(NO_WORK_UNIT) : item.name,
      })),
    [divisions]
  );

  const form = useForm<FormValues>({
    initialValues: {
      comment: data.comment || '',
      worked: data.workingTimeDay !== 0,
      divisionConcerned: data.divisionConcerned
        ? data.divisionConcerned
        : data.user.division.id,
    },
    onValuesChange: (values) => {
      handleAutoSave(values);
    },
  });

  const { mutate } = useMutation({
    mutationFn: (payload: DailyWorkRequest) =>
      DailyWorkService.updateUserDailyWork(data.user.id, payload),
    onSuccess: () => {
      refresh();
      showNotification({
        id: `update-fixed-price-manager-worked-day-${data.id}-${data.user.id}`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
  });

  const handleAutoSave = useDebouncedCallback(async (values: FormValues) => {
    mutate({
      id: data.id,
      date: dayjs(selectedDate).format('DD/MM/YYYY'),
      presence: values.worked ? '00:00-23:59' : '00:00-00:00',
      overtime: 0,
      comment: values.comment || '',
      status: 'VALID',
      divisionConcerned: values.divisionConcerned,
    });
  }, 1000);

  return (
    <Fieldset
      legend={`${t('w.date')} : ${dayjs(selectedDate).format('DD/MM/YYYY')}`}
    >
      <Switch
        label={<LabelText text={t('w.worked')} />}
        {...form.getInputProps('worked', { type: 'checkbox' })}
        mb="md"
        mt="md"
      />
      <TextInput
        label={<LabelText text={t('w.comment')} />}
        {...form.getInputProps('comment')}
        mb="md"
      />

      <Select
        data={divisionsOptions}
        label={<LabelText text={t('w.divisionConcerned')} />}
        placeholder={t('w.select')}
        {...form.getInputProps('divisionConcerned')}
      />
    </Fieldset>
  );
}
