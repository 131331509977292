import {
  Button,
  Flex,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  rem,
  Select,
  Switch,
  TextInput,
  Title,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { isEmail, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowRight,
  IconDeviceFloppy,
  IconHelpHexagon,
  IconMail,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef } from 'react';

import CustomUserAvatar from '../../../../../components/CustomAvatar/CustomUserAvatar';
import CustomDropzone from '../../../../../components/CustomDropzone/CustomDropzone';
import CustomTooltip from '../../../../../components/CustomTooltip';
import CustomTransition from '../../../../../components/CustomTransition';
import ActionDeleteIcon from '../../../../../components/icons/ActionDeleteIcon';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import useFetchManagers from '../../../../../hooks/useFetchManagers';
import CompanyService from '../../../../../services/CompanyService';
import ProfileService from '../../../../../services/ProfileService';
import type {
  Division,
  Role,
  User,
  UserPayload,
} from '../../../../../types/types';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import { roleName } from '../../../../../utils/roleName';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';
import AddEmployeeGroupRadio from '../AddEmployeeGroupRadio';
import s from './AddEmployeeProfileForm.module.sass';

type Props = {
  divisions: Division[];
  roles: Role[];
  onClose: () => void;
  creationTypeValue: string;
  setCreationTypeValue: (value: string) => void;
  onBoardingTypeValue: string;
  setOnBoardingTypeValue: (value: string) => void;
  active: number;
  setActive: (number: number) => void;
  refetch: () => void;
  setAddedEmployee: (data: User) => void;
  employee: User | null;
};

interface FormValues {
  firstname: string;
  lastname: string;
  mail: string;
  securityRoleId: string;
  divisionId: string;
  valid: boolean;
  avatar: FileWithPath[];
  filename?: string | null;
  firstManager: string;
  secondManager: string;
}

export default function AddEmployeeProfileForm({
  roles,
  divisions,
  onClose,
  creationTypeValue,
  setCreationTypeValue,
  onBoardingTypeValue,
  setOnBoardingTypeValue,
  active,
  setActive,
  refetch,
  setAddedEmployee,
  employee,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { managers } = useFetchManagers();

  const form = useForm<FormValues>({
    initialValues: {
      firstname: employee ? employee?.firstname : '',
      lastname: employee ? employee?.lastname : '',
      mail: employee ? employee?.mail : '',
      securityRoleId: employee ? employee?.securityRoleId : '',
      divisionId: employee ? employee?.division.id : '',
      valid: employee ? (employee?.valid ? employee.valid : false) : true,
      avatar: [],
      filename: null,
      firstManager: employee ? employee?.firstManager : '',
      secondManager: employee ? employee.secondManager : '',
    },
    validate: {
      mail: isEmail(t('invalid.email')),
    },
  });
  const resetFileRef = useRef<() => void>(null);

  const {
    mutate: createNewUserOnboarding,
    isLoading: isCreateNewUserOnboardingLoading,
  } = useMutation({
    mutationFn: (variables: {
      firstname: string;
      lastname: string;
      mail: string;
      securityRoleId: string;
      divisionId: string;
      valid: boolean;
      firstManager: string;
      secondManager: string;
    }) =>
      CompanyService.createNewUserOnboarding(
        companyId,
        'ONBOARDING',
        variables
      ),
    onSuccess: (data) => {
      showNotification({
        id: 'add-employee-profile-success',
        title: t('w.success'),
        message: `${data.firstname} a bien été ajouté à la liste des employés`,
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setActive(active + 1);
      setAddedEmployee(data);
    },
    onError: (error) => handleErrorMessage(error, t),
  });

  const {
    mutate: updateEmployeeAdded,
    isLoading: isLoadingUpdateEmmployeeAdded,
  } = useMutation({
    mutationFn: (variables: UserPayload) =>
      ProfileService.updateUserProfile(
        employee?.id,
        variables,
        onBoardingTypeValue !== 'ONBOARDING' ? null : 'ONBOARDING'
      ),
    onSuccess: (data) => {
      showNotification({
        id: 'udpate-added-employee-information-success',
        title: t('w.success'),
        message: t('success.profileUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setAddedEmployee(data);
      onClose();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const divisionsSelect = useMemo(() => {
    if (!divisions) {
      return [];
    }
    return divisions.map((division: Division) => {
      return {
        value: division.id,
        label:
          division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name,
      };
    });
  }, [divisions]);

  const rolesSelect = useMemo(() => {
    if (!roles) {
      return [];
    }
    return roles.map((role: Role) => {
      return {
        value: role.id,
        label: roleName(t, role.name),
      };
    });
  }, [roles]);

  useEffect(() => {
    if (!employee) {
      if (
        creationTypeValue === 'create-employee' ||
        onBoardingTypeValue === 'fill-infos'
      ) {
        form.setFieldValue('valid', true);
      } else {
        form.setFieldValue('valid', false);
      }
    }
  }, [creationTypeValue]);

  function handleAddEmployeeProfileFormSubmit(
    values: ReturnType<(values: FormValues) => FormValues>
  ) {
    if (Boolean(employee)) {
      setActive(active + 1);
    } else {
      createNewUserOnboarding({
        firstname: values.firstname,
        lastname: values.lastname,
        mail: values.mail,
        securityRoleId: values.securityRoleId,
        divisionId: values.divisionId,
        valid: values.valid,
        firstManager: values.firstManager,
        secondManager: values.secondManager,
      });
    }
  }

  function handleCreateOrUpdateEmployee(values: FormValues) {
    if (!Boolean(employee)) {
      createNewUserOnboarding({
        firstname: values.firstname,
        lastname: values.lastname,
        mail: values.mail,
        securityRoleId: values.securityRoleId,
        divisionId: values.divisionId,
        valid: values.valid,
        firstManager: values.firstManager,
        secondManager: values.secondManager,
      });
    } else {
      let data;
      if (employee?.mail !== values.mail) {
        data = {
          firstname: values.firstname,
          lastname: values.lastname,
          mail: values.mail,
          securityRoleId: values.securityRoleId,
          divisionId: values.divisionId,
          valid: values.valid,
          firstManager: values.firstManager,
          secondManager: values.secondManager,
          avatar: form.values.avatar?.length > 0 ? form.values.avatar[0] : null,
          filename:
            form.values.avatar.length > 0 ? form.values.avatar[0].name : null,
        };
      } else {
        data = {
          firstname: values.firstname,
          lastname: values.lastname,
          securityRoleId: values.securityRoleId,
          divisionId: values.divisionId,
          valid: values.valid,
          firstManager: values.firstManager,
          secondManager: values.secondManager,
          avatar: form.values.avatar?.length > 0 ? form.values.avatar[0] : null,
          filename:
            form.values.avatar.length > 0 ? form.values.avatar[0].name : null,
        };
      }
      updateEmployeeAdded(data);
    }
  }

  function getImageUrl() {
    if (form.values.avatar.length > 0) {
      return URL.createObjectURL(form.values.avatar[0]);
    }
  }

  function clearFile() {
    form.setFieldValue('avatar', []);
    resetFileRef.current?.();
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleAddEmployeeProfileFormSubmit(values)
      )}
    >
      <LoadingOverlay
        visible={
          isCreateNewUserOnboardingLoading || isLoadingUpdateEmmployeeAdded
        }
      />
      <Flex justify={'space-between'}>
        <Title order={3}>{t('w.profile')}</Title>
        <Switch
          label={<LabelText text={t('w.active')} />}
          labelPosition={'left'}
          checked={form.values.valid}
          {...form.getInputProps('valid')}
        />
      </Flex>
      <Grid grow gutter="xl" mt={'md'}>
        {employee && (
          <>
            <Grid.Col span={{ base: 12, lg: 3 }} style={{ minHeight: rem(80) }}>
              <CustomUserAvatar userId={employee.id} size={120} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 9 }} style={{ minHeight: rem(80) }}>
              <Group justify={'space-between'}>
                <Group>
                  <CustomDropzone
                    onDrop={(files) => form.setFieldValue('avatar', files)}
                    files={form.values.avatar}
                    mimeType={'img'}
                    type={'simple'}
                    resetFiles={(index) => form.removeListItem('avatar', index)}
                  />
                  {form.values.avatar.length > 0 && (
                    <Image height={60} width={60} src={getImageUrl()} />
                  )}
                  {form.values.avatar.length > 0 && (
                    <ActionDeleteIcon
                      label={t('w.deletePhoto')}
                      onClick={clearFile}
                    />
                  )}
                </Group>
              </Group>
            </Grid.Col>
          </>
        )}
        <Grid.Col span={{ base: 12, md: 4 }}>
          <TextInput
            required
            label={<LabelText text={t('w.firstname')} />}
            placeholder={t('w.noValue')}
            {...form.getInputProps('firstname')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <TextInput
            required
            label={<LabelText text={t('w.name')} />}
            placeholder={t('w.noValue')}
            {...form.getInputProps('lastname')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <TextInput
            required
            label={<LabelText text={t('w.emailAddress')} />}
            leftSection={
              <CustomTooltip
                label={t('w.temporaryEmailThatCanBeChangedLater')}
                position={'bottom'}
              >
                <IconHelpHexagon className={s.icon} />
              </CustomTooltip>
            }
            rightSection={<IconMail className={s.icon} />}
            placeholder={t('w.noValue')}
            {...form.getInputProps('mail')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Select
            required
            data={rolesSelect}
            label={<LabelText text={t('w.role')} />}
            placeholder={t('w.selectRole')}
            {...form.getInputProps('securityRoleId')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Select
            required
            data={divisionsSelect}
            label={
              <LabelText
                text={
                  user.company.divisionLabel
                    ? user.company.divisionLabel
                    : t('w.unit')
                }
              />
            }
            placeholder={t('w.select')}
            {...form.getInputProps('divisionId')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            required
            data={managers}
            label={<LabelText text={t('w.n1Validator')} />}
            placeholder={t('w.select')}
            {...form.getInputProps('firstManager')}
          />
        </Grid.Col>
        {company?.useN2 && (
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              required
              data={managers}
              label={<LabelText text={t('w.n2Validator')} />}
              placeholder={t('w.select')}
              {...form.getInputProps('secondManager')}
            />
          </Grid.Col>
        )}
      </Grid>
      {employee === null && (
        <AddEmployeeGroupRadio
          value={creationTypeValue}
          onChange={setCreationTypeValue}
          radioItems={[
            { value: 'create-employee', label: t('w.addEmployee') },
            {
              value: 'onBoarding',
              label: `onBoarding (${t('w.availableSoon')})`,
              disabled: true,
            },
          ]}
          name={'creationType'}
          label={t('w.methodForAddingAnEmployee')}
          description={t('w.directlyInTheListOfEmployeesOrViaOnBoarding')}
        />
      )}
      <CustomTransition
        opened={creationTypeValue === 'onBoarding'}
        transition={'slide-left'}
        duration={600}
        timingFunction={'ease'}
      >
        <AddEmployeeGroupRadio
          value={onBoardingTypeValue}
          onChange={setOnBoardingTypeValue}
          radioItems={[
            {
              value: 'fill-infos',
              label: t('w.fillInTheInformationByCompany'),
            },
            {
              value: 'choose-onBoarding',
              label: t('w.chooseSelfOnBoarding'),
            },
          ]}
          name={'onBoardingTypeChoice'}
          label={'OnBoarding'}
          description={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          }
        />
      </CustomTransition>
      <Group
        styles={{
          root: {
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'var(--mantine-color-body)',
          },
        }}
        mt={'51px'}
        gap={'30px'}
      >
        <Button variant={'subtle'} onClick={onClose}>
          {t('w.cancel')}
        </Button>
        <Button
          variant={'outline'}
          disabled={!form.isValid()}
          leftSection={<IconDeviceFloppy />}
          onClick={() => handleCreateOrUpdateEmployee(form.values)}
        >
          {t('w.save')}
        </Button>
        {employee === null && (
          <Button
            leftSection={<IconCircleArrowRight />}
            disabled={!form.isValid()}
            type={'submit'}
          >
            {creationTypeValue === 'create-employee'
              ? t('w.next')
              : onBoardingTypeValue === 'choose-onBoarding'
              ? 'Envoyer l’invitation'
              : t('w.next')}
          </Button>
        )}
      </Group>
    </form>
  );
}
