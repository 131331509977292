import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import useCompany from '../../hooks/useCompany';
import { hasPermission } from '../../utils/authorization';
import {
  ALL,
  AuthorizedEditMyPersonalSpace,
} from '../../variables/BuiltInPermissions';
import DocumentsTable from './components/DocumentsTable';

export default function DocumentsPersonal() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  return (
    <ScreenWrapper title={t('w.personal')}>
      <DocumentsTable
        hasPermissionToEditSpace={hasPermission(
          { permission: AuthorizedEditMyPersonalSpace, scope: ALL },
          user
        )}
        space={'PERSONAL'}
        userId={user.id}
        companyId={companyId}
        accountantCompanyId={null}
      />
    </ScreenWrapper>
  );
}
