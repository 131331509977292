import { Box, Button, Group, Space, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { Dayjs } from 'dayjs';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import LabelText from '../LabelText/LabelText';

type Props = {
  day: Dayjs;
  onCommentCreated: (comment: string) => void;
  comment?: string;
};

export default function AddComment({ day, onCommentCreated, comment }: Props) {
  const { t } = useI18n();

  const commentCreationForm = useForm({
    initialValues: {
      comment: comment ? comment : '',
    },
  });

  function createComment(comment: string) {
    onCommentCreated(comment);
    modals.close('AddComment');
  }

  return (
    <Box>
      <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
        {t('dailyWork.addComment.intro')}
      </Text>
      <Space h={'xl'} />
      <form
        onSubmit={commentCreationForm.onSubmit((values) =>
          createComment(values.comment)
        )}
      >
        <TextInput
          label={<LabelText text={t('w.comment')} />}
          {...commentCreationForm.getInputProps('comment')}
        />
        <Space h={'xl'} />
        <Group justify={'flex-end'}>
          <Button type={'submit'}>{t('w.validate')}</Button>
        </Group>
      </form>
    </Box>
  );
}
