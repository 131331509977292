import { useQuery } from '@tanstack/react-query';
import React, { createContext, ReactNode, useContext } from 'react';

import useCompany from '../hooks/useCompany';
import CkModuleManagerServiceMS from '../services/CkModuleManagerServiceMS';
import type { Module } from '../types/types';
import type { ModuleNameType } from '../variables/ModulesV2';
import { useAuth } from './AuthProvider';

type ModuleContextType = {
  getModule: (moduleName: ModuleNameType) => Module | undefined;
  modules: Module[] | undefined;
};

export const ModuleContext = createContext<ModuleContextType>(
  {} as ModuleContextType
);

export const useModule = () => useContext(ModuleContext);

type ModuleProviderProps = {
  children: ReactNode;
};

export default function ModuleProvider({ children }: ModuleProviderProps) {
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const { data: modules } = useQuery({
    enabled: !!companyId,
    queryKey: ['CkModuleManagerServiceMS.list', companyId],
    queryFn: () => CkModuleManagerServiceMS.list(companyId, 'companyFeature'),
  });

  function getModule(moduleName: ModuleNameType): Module | undefined {
    if (!modules?.length) return undefined;
    return modules.find((module: Module) => module.name === moduleName);
  }

  return (
    <ModuleContext.Provider value={{ getModule, modules }}>
      {children}
    </ModuleContext.Provider>
  );
}
