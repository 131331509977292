import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Flex, Group, Text, Title } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconEdit,
  IconFolders,
  IconHomeCog,
  IconUserHexagon,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveInactiveBadge from '../../../../components/badges/ActiveInactiveBadge';
import CustomModal from '../../../../components/CustomModal';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import { Accountant, Accountants, Company } from '../../../../types/types';
import { milliToFullDateString } from '../../../../utils/format';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import DocumentsTable from '../../../documents/components/DocumentsTable';
import CompanyInfoWrapper from '../../components/CompanyInfoWrapper';
import AccountantConfigModal from './AccountantConfigModal';
import CustomerEditForm from './CustomerEditForm';

const componentName = 'AccountantCustomersTable';
export default function AccountantCustomersTable() {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const navigate = useNavigate();

  const [
    accountantConfigModalOpened,
    {
      open: handleAccountantConfigModalOpen,
      close: handleAccountantConfigClose,
    },
  ] = useDisclosure(false);
  const [accountantsToConfigure, setAccountantsToConfigure] =
    useState<Accountants | null>(null);
  const [customerCompany, setCustomerCompany] = useState<Company | null>(null);
  const matches = useMediaQuery('(min-width: 1441px)');

  const {
    data: accountantCustomersData,
    isLoading: isAccountantCustomersDataLoading,
    refetch: refetchAccountantCustomersData,
  } = useQuery({
    queryKey: ['CharteredAccountantService.accountantCustomersList', companyId],
    queryFn: () =>
      CharteredAccountantService.accountantCustomersList(companyId),
  });

  const rows: Accountants[] = useMemo(() => {
    if (!accountantCustomersData) {
      return [];
    }

    if (accountantsToConfigure?.clientCompanyId) {
      const _updatedAccountantsToConfigure = accountantCustomersData.find(
        (item) =>
          item.clientCompanyId === accountantsToConfigure.clientCompanyId
      );

      if (_updatedAccountantsToConfigure) {
        setAccountantsToConfigure(_updatedAccountantsToConfigure);
      }
    }

    return accountantCustomersData;
  }, [accountantCustomersData]);

  const { mutate: updateCustomerCompany } = useMutation({
    mutationFn: (variables: {
      accountant: Accountant;
      active: boolean;
      clientCompanyId: string;
    }) =>
      CharteredAccountantService.updateCustomerCompanyInformation(
        companyId,
        variables
      ),
    onSuccess: () => {
      refetchAccountantCustomersData();
      showNotification({
        id: 'update-customer-company-status-successful',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      modals.closeAll();
    },
    onError: (error) =>
      showNotification({
        id: 'update-customer-company-status-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const columns: DataTableColumn<Accountants>[] = useMemo(
    () => [
      {
        accessor: 'customerCompany.name',
        title: t('w.companyName'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) => (
          <CompanyInfoWrapper
            companyId={customerCompany.id}
            companyName={customerCompany.name}
          />
        ),
      },
      {
        accessor: 'active',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ active }) => <ActiveInactiveBadge value={active} />,
      },
      {
        accessor: 'customerCompany.address',
        title: t('w.companyAddress'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) => (
          <Flex direction={'column'}>
            <Group gap={4}>
              <Text fz={'xs'} fw={'bold'}>
                {customerCompany?.address?.address1}
              </Text>
              {customerCompany?.address?.address2 && (
                <Text fz={'xs'} fw={'bold'}>
                  {' '}
                  - {customerCompany.address.address2}
                </Text>
              )}
            </Group>
            <Group gap={4}>
              <Text c={'dimmed'} fz={'xs'}>
                {customerCompany?.address?.zipCode}
              </Text>
              <Text c={'dimmed'} fz={'xs'}>
                {customerCompany?.address?.city}
              </Text>
            </Group>
          </Flex>
        ),
      },
      {
        accessor: 'accountant',
        title: t('w.infoAccountantShowToClient'),
        sortable: false,
        ellipsis: true,
        render: ({ accountant }) => (
          <Flex direction={'column'}>
            <Text fz={'xs'} fw={'bold'}>
              {accountant.name} - {accountant.address}
            </Text>
            <Group gap={4}>
              <Text c={'dimmed'} fz={'xs'}>
                {accountant.mail}
              </Text>
              {accountant.phone && (
                <Text c={'dimmed'} fz={'xs'}>
                  {` / ${accountant.phone}`}
                </Text>
              )}
            </Group>
          </Flex>
        ),
      },
      {
        accessor: 'customerCompany.mail',
        title: t('w.companyMail'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'customerCompany.phone',
        title: t('w.phone'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'customerCompany.created',
        title: t('w.created'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) =>
          customerCompany.created
            ? milliToFullDateString(customerCompany.created)
            : '',
      },
      {
        accessor: 'customerCompany.updated',
        title: t('w.updated'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) =>
          customerCompany.updated
            ? milliToFullDateString(customerCompany.updated)
            : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        render: (row) => (
          <Group gap={6} justify="end" wrap={'nowrap'}>
            <CustomTooltip
              label={`${t('w.configure')} ${t(
                'w.payrollOfficers'
              ).toLowerCase()}`}
            >
              <ActionIcon
                onClick={() => {
                  handleAccountantConfigModalOpen();
                  setAccountantsToConfigure(row);
                }}
                size="sm"
                color="green"
                variant={'subtle'}
              >
                <IconUserHexagon size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={`${t('w.configure')} ${t(
                'w.customerCompany'
              ).toLowerCase()}`}
            >
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() => navigate(`/company/${row.clientCompanyId}/home`)}
              >
                <IconHomeCog size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.sharedDocuments')}>
              <ActionIcon
                size="sm"
                color="blue"
                variant={'subtle'}
                onClick={() => setCustomerCompany(row.customerCompany)}
              >
                <IconFolders size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.edit')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() => openEditModal(row)}
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    [matches]
  );

  function openEditModal(accountants: Accountants) {
    modals.open({
      modalId: `customer-${accountants.clientCompanyId}-edit-modal`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.edit')}
        </Title>
      ),
      size: '90%',
      children: (
        <CustomerEditForm
          accountants={accountants}
          updateCustomerCompanyStatus={updateCustomerCompany}
        />
      ),
    });
  }

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'customerCompany.name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'active',
          label: t('w.status'),
          choices: [
            { value: 'true', label: t('w.active') },
            { value: 'false', label: t('w.inactive') },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <>
      <Table
        pinFirstColumn
        pinLastColumn
        idAccessor={'customerCompany.id'}
        options={options}
        columns={columns}
        rows={rows}
        lang={lang}
        fetching={isAccountantCustomersDataLoading}
        height={'calc(100vh - 220px)'}
      />
      <AccountantConfigModal
        opened={accountantConfigModalOpened}
        onClose={handleAccountantConfigClose}
        accountantsToConfigure={accountantsToConfigure}
        refetchAccountantCustomersData={refetchAccountantCustomersData}
      />
      <CustomModal
        opened={customerCompany !== null}
        onClose={() => setCustomerCompany(null)}
        title={`${t('w.sharedDocuments')} - ${customerCompany?.name}`}
        fullScreen
      >
        {customerCompany !== null && (
          <DocumentsTable
            hasPermissionToEditSpace={true}
            space={'ACCOUNTANT'}
            userId={user.id}
            companyId={customerCompany.id}
            accountantCompanyId={companyId}
          />
        )}
      </CustomModal>
    </>
  );
}
