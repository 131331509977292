import { Image, LoadingOverlay, Stack, Title } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { upperFirst } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import CustomMonthPickerInput from '../../../components/CustomMonthPickerInput';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import PayslipService from '../../../services/PayslipService';
import type { PayslipMonthResponse } from '../../../types/types';
import { initPickerDate } from '../../../utils/initPickerDate';
import ImportPayslipsButton from './components/ImportPayslipsButton';
import PayslipsStatusTabs from './components/PayslipsStatusTabs';
import PayslipsTreatmentsBadge from './components/treatments/PayslipsTreatmentsBadge';

type Props = {
  customerCompanyId?: string;
  customerSelectedDate?: DateValue | DatesRangeValue | Date[];
};

export default function EndOfMonthPayslips({
  customerCompanyId,
  customerSelectedDate,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[]
  >(customerSelectedDate ? customerSelectedDate : initPickerDate());

  const {
    data: payslipsMonthInfoData,
    refetch: refetchPayslipsMonthInfoData,
    isLoading,
    isFetching,
    isSuccess,
  } = useQuery({
    enabled: false,
    queryKey: [
      'PayslipService.getPayslipsMonthInfo',
      customerCompanyId ? customerCompanyId : companyId,
    ],
    queryFn: () =>
      PayslipService.getPayslipsMonthInfo(
        customerCompanyId ? customerCompanyId : companyId,
        // @ts-ignore
        dayjs(selectedDate).month() + 1,
        // @ts-ignore
        dayjs(selectedDate).year()
      ),
  });

  const { data: payslipTreatmentsData, refetch: refetchPayslipTreatmentsData } =
    useQuery({
      queryKey: [
        'PayslipService.getPayslipsTreatmentForCompanyId',
        customerCompanyId ? customerCompanyId : companyId,
      ],
      queryFn: () =>
        PayslipService.getPayslipsTreatmentForCompanyId(
          customerCompanyId ? customerCompanyId : companyId
        ),
      onSuccess: () => refetchPayslipsMonthInfoData(),
    });

  const pendingTreatment = useMemo(() => {
    if (!payslipTreatmentsData) {
      return [];
    }
    return payslipTreatmentsData.filter(
      (treatment) => treatment.state === 'PROCESSING'
    );
  }, [payslipTreatmentsData]);

  const successTreatment = useMemo(() => {
    if (!payslipTreatmentsData) {
      return [];
    }
    return payslipTreatmentsData.filter(
      (treatment) => treatment.state === 'SUCCESS'
    );
  }, [payslipTreatmentsData]);

  const errorTreatment = useMemo(() => {
    if (!payslipTreatmentsData) {
      return [];
    }
    return payslipTreatmentsData.filter(
      (treatment) => treatment.state === 'ERROR'
    );
  }, [payslipTreatmentsData]);

  const actionButtons = useMemo(
    () => [
      <CustomMonthPickerInput
        key={'month-picker-end-of-month-payslips'}
        value={selectedDate}
        onChange={setSelectedDate}
      />,
      <ImportPayslipsButton
        key={'import-payslips-button'}
        companyId={customerCompanyId ? customerCompanyId : companyId}
      />,
    ],
    [selectedDate]
  );

  const payslipsMonthInfo: PayslipMonthResponse | undefined = useMemo(() => {
    if (!payslipsMonthInfoData) {
      return undefined;
    }
    return payslipsMonthInfoData;
  }, [payslipsMonthInfoData]);

  useEffect(() => {
    refetchPayslipTreatmentsData();
  }, [selectedDate]);

  function displayMonthAndYear(): string {
    // @ts-ignore
    return upperFirst(dayjs(selectedDate).format('MMMM YYYY'));
  }

  return (
    <ScreenWrapper title={t('w.payslips')} actionButtons={actionButtons}>
      <LoadingOverlay visible={isLoading || isFetching} />
      <Stack gap={'xl'} mt={'md'}>
        <PayslipsTreatmentsBadge
          pendingTreatment={pendingTreatment}
          successTreatment={successTreatment}
          errorTreatment={errorTreatment}
          companyId={customerCompanyId ? customerCompanyId : companyId}
          setSelectedDate={setSelectedDate}
        />
        {!isFetching && !isLoading && isSuccess ? (
          <PayslipsStatusTabs
            companyId={customerCompanyId ? customerCompanyId : companyId}
            payslipsMonthInfo={payslipsMonthInfo}
            selectedDate={selectedDate}
          />
        ) : (
          <Stack align={'center'} mt={'xl'} gap={'md'}>
            <Title size={'h3'} c={'hifivework.3'}>
              {t('w.noPayslipsImported')}
            </Title>
            <Title size={'h4'} c={'hifivework.2'}>
              {displayMonthAndYear()}
            </Title>
            <Image src={'/images/payslips/add-files.png'} h={'40%'} w={'40%'} />
          </Stack>
        )}
      </Stack>
    </ScreenWrapper>
  );
}
