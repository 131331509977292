import {
  FilterOption,
  FilterOptionChoice,
  Options,
  Table,
} from '@ckprivate/ckf-ui';
import { ActionIcon, Badge, Button, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconBrandPocket,
  IconCheck,
  IconCirclePlus,
  IconEdit,
  IconFolders,
  IconMailForward,
  IconSend,
  IconStatusChange,
  IconTableDown,
  IconTableImport,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveInactiveBadge from '../../../components/badges/ActiveInactiveBadge';
import RoleBadge from '../../../components/badges/RoleBadge';
import UnitBadge from '../../../components/badges/UnitBadge';
import CustomMenu from '../../../components/CustomMenu';
import CustomModal from '../../../components/CustomModal';
import CustomTooltip from '../../../components/CustomTooltip';
import GoToUserButton from '../../../components/GoToUserButton/GoToUserButton';
import ImportModal from '../../../components/ImportModal';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import useFetchUsers from '../../../hooks/useFetchUsers';
import CompanyService from '../../../services/CompanyService';
import ProfileService from '../../../services/ProfileService';
import {
  ACCOUNTANT_MODE,
  type Company,
  type IdentifiantCompanyObject,
  type OnBoardingStatus,
  type User,
} from '../../../types/types';
import { hasPermission } from '../../../utils/authorization';
import { milliToFullDateString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { isWhiteLabelGma } from '../../../utils/isWhiteLabelGma';
import { getPersistedObject } from '../../../utils/localStorage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../utils/saveDownloadedFile';
import {
  ALL,
  AuthorizedEditEmployeesPersonalSpace,
  AuthorizedReadEmployeesPersonalSpace,
  ConfigureEmployees,
} from '../../../variables/BuiltInPermissions';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import { HfwAzureSync } from '../../../variables/ModulesV2';
import DocumentsTable from '../../documents/components/DocumentsTable';
import AddEmployeeModal from './components/AddEmployeeModal';

const componentName = 'Employees';

type Props = {
  divisionId?: string;
  company?: Company;
};

export default function Employees({ divisionId, company }: Props) {
  const { getModule } = useModule();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, lang } = useI18n();
  const { id: companyId } = useCompany(user);
  const {
    users,
    divisions,
    roles,
    loading: isUsersLoading,
    refetchUsers,
  } = useFetchUsers(company ? company.id : companyId);
  const [importEmployeeModal, setImportEmployeeModal] = useState({
    opened: false,
    title: '',
  });
  const [opened, handlers] = useDisclosure(false);
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const [employeePersonalDocuments, setEmployeePersonalDocuments] =
    useState<User | null>(null);

  const isHfwAzureSyncModuleActive = getModule(HfwAzureSync)?.active;

  const hasPermissionToReadEmployeesPersonalSpace: boolean = hasPermission(
    {
      permission: AuthorizedReadEmployeesPersonalSpace,
      scope: ALL,
    },
    user
  );

  const hasPermissionToEditEmployeesPersonalSpace = hasPermission(
    { permission: AuthorizedEditEmployeesPersonalSpace, scope: ALL },
    user
  );

  function handleNavigateToUserProfile(companyId: string, id: string): void {
    if (getPersistedObject('mode') === ACCOUNTANT_MODE) {
      navigate(`/company/${companyId}/profile/user/${id}`);
    } else {
      navigate(`/profile/user/${id}`);
    }
  }

  function getOnboardingStatusLabel(status: OnBoardingStatus): ReactNode {
    switch (status) {
      case 'ONBOARDING':
        return 'Onboarding en cours de validation';
      case 'ONBOARDING_EMPLOYE':
        return 'Onboarding collaborateur en cours... ';
      default:
        return 'Onboarding validé';
    }
  }

  const columns: DataTableColumn<User>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ id, fullname }) => (
          <GoToUserButton userId={id} userFullname={fullname} />
        ),
      },
      {
        accessor: 'mail',
        title: 'Email',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'employment',
        title: t('w.jobType'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'role.name',
        title: t('w.role'),
        sortable: true,
        ellipsis: true,
        render: ({ role }) => role && <RoleBadge role={role.name} />,
      },
      {
        accessor: 'division.name',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => <UnitBadge unit={division?.name} />,
      },
      {
        accessor: 'identifiantsCompany',
        title: 'Matricule',
        sortable: true,
        ellipsis: true,
        render: ({ identifiantsCompany }) => {
          const _matricule = identifiantsCompany || [];
          return (
            <Text fz={'xs'} fw={'bold'}>
              {_matricule
                .reverse()
                .map(
                  (item: IdentifiantCompanyObject) =>
                    `${
                      item.codeCompany
                        ? `${item.codeCompany} - ${item.matricule}`
                        : item.matricule
                    }`
                )
                .join(' / ')}
            </Text>
          );
        },
      },
      {
        accessor: 'valid',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ valid, status }) =>
          !status ? (
            <ActiveInactiveBadge value={valid} />
          ) : (
            <CustomTooltip label={getOnboardingStatusLabel(status)}>
              <Badge
                color={
                  valid && status === 'ACTIVE'
                    ? '#CEE9E6'
                    : status === 'ONBOARDING' ||
                      'ONBOARDING_EMPLOYE' ||
                      'ONBOARDING_VALIDATION'
                    ? '#F8F9FA'
                    : '#E2E2E2'
                }
                styles={{
                  label: {
                    textTransform: 'none',
                    color:
                      valid && status === 'ACTIVE'
                        ? '#0A9182'
                        : status === 'ONBOARDING'
                        ? '#EDB464'
                        : status === 'ONBOARDING_EMPLOYE'
                        ? '#10069F'
                        : status === 'ONBOARDING_VALIDATION'
                        ? '#0A9182'
                        : '#707070',
                  },
                }}
                variant="filled"
              >
                {status === 'ACTIVE' && valid ? t('w.active') : 'Onboarding'}
              </Badge>
            </CustomTooltip>
          ),
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: (employee) => {
          return (
            <Group gap={4} justify="end" wrap={'nowrap'}>
              <CustomTooltip label={t('w.edit')}>
                <ActionIcon
                  size="sm"
                  color="green"
                  variant={'subtle'}
                  onClick={() =>
                    handleNavigateToUserProfile(employee.companyId, employee.id)
                  }
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </CustomTooltip>
              {hasPermissionToReadEmployeesPersonalSpace && (
                <CustomTooltip label={t('w.personalDocuments')}>
                  <ActionIcon
                    size="sm"
                    color="blue"
                    variant={'subtle'}
                    onClick={() => setEmployeePersonalDocuments(employee)}
                  >
                    <IconFolders size={16} />
                  </ActionIcon>
                </CustomTooltip>
              )}
              {!isWhiteLabelGma() && (
                <CustomTooltip label={t('w.invite')}>
                  <ActionIcon
                    size="sm"
                    variant={'subtle'}
                    color="blue"
                    onClick={() =>
                      inviteUser({
                        companyId: employee.companyId,
                        id: employee.id,
                        mail: employee.mail,
                      })
                    }
                  >
                    <IconMailForward size={16} />
                  </ActionIcon>
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
    ],
    []
  );

  const rightCustomActions: ReactNode[] = useMemo(
    () => [
      <CustomMenu
        key={'custom-menu'}
        buttonLabel={t('w.import')}
        buttonVariant={'white'}
        leftSection={<IconTableDown />}
        menuLabel={`${t('w.employees')} via :`}
        menuItems={[
          {
            label: 'Silae',
            icon: <IconBrandPocket size={18} />,
            onClick: () => handleOpenImportEmployeeModalClick('Silae'),
          },
          {
            label: 'Quadra',
            icon: <IconBrandPocket size={18} />,
            onClick: () => handleOpenImportEmployeeModalClick('Quadra'),
          },
          {
            label: 'HiFiveWork',
            icon: <IconBrandPocket size={18} />,
            onClick: () => handleOpenImportEmployeeModalClick('HiFiveWork'),
          },
        ]}
      />,
      <CustomMenu
        key={'export-employees'}
        buttonLabel={t('w.export')}
        buttonVariant={'filled'}
        leftSection={<IconTableImport />}
        menuLabel={`${t('w.employees')} via :`}
        menuItems={[
          {
            label: 'XLSX',
            icon: <IconBrandPocket size={18} />,
            onClick: () => onExportEmployeesClick('XLSX'),
          },
          {
            label: 'CSV',
            icon: <IconBrandPocket size={18} />,
            onClick: () => onExportEmployeesClick('CSV'),
          },
        ]}
      />,
    ],
    [selectedEmployees.length]
  );

  const leftCustomActions: ReactNode[] = useMemo(
    () => [
      <Button
        key={'changingStatus-button'}
        leftSection={<IconStatusChange size={'1.2rem'} />}
        disabled={selectedEmployees.length === 0}
        onClick={onChangeSelectedEmployeesStatusClick}
      >
        {t('w.changingStatus')}
      </Button>,
      <Button
        key={'invite-button'}
        leftSection={<IconSend size={'1.2rem'} />}
        disabled={selectedEmployees.length === 0}
        onClick={onInviteSelectedEmployeesClick}
      >
        {t('w.invite')}
      </Button>,
    ],
    [selectedEmployees.length]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: ['updated'],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'valid',
          label: t('w.status'),
          choices: [
            { value: 'true', label: t('w.active') },
            { value: 'false', label: t('w.inactive') },
          ],
          selectedChoices: [],
        },
        {
          accessor: 'division.name',
          label: user.company?.divisionLabel
            ? user.company.divisionLabel
            : t('w.unit'),
          choices: [],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const { mutate: inviteUser } = useMutation({
    mutationFn: (variables: { companyId: string; id: string; mail: string }) =>
      ProfileService.inviteUser(variables.companyId, variables.id),
    onSuccess: (_data, variables) => {
      showNotification({
        id: `success-invite-user-${variables.id}`,
        title: t('w.success'),
        message: t('w.mailSent', variables.mail),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchUsers();
    },
    onError: (error, variables) => {
      showNotification({
        id: `error-invite-user-${variables.id}`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const { mutate: changeUserStatus, isError: isChangeStatusError } =
    useMutation({
      mutationFn: (variables: { userId: string; valid: boolean }) =>
        ProfileService.updateUserProfile(variables.userId, {
          valid: variables.valid,
        }),
      onSuccess: (data) => {
        showNotification({
          id: `change-status-userId-${data?.id}-successful`,
          title: t('w.success'),
          message: t('success.updateUserProfile', data?.fullname),
          color: 'green',
          icon: <IconCheck />,
        });
        refetchUsers();
        const _selectedEmployees = [...selectedEmployees];
        _selectedEmployees.forEach((employee: any) => {
          employee.valid = data?.valid;
        });
        setSelectedEmployees(_selectedEmployees);
      },
    });

  useEffect(() => {
    if (isChangeStatusError) {
      showNotification({
        id: 'update-user-profile-error',
        title: t('w.error'),
        message: t('error.updateUserStatus'),
        color: 'red',
        icon: <IconX />,
      });
    }
  }, [isChangeStatusError]);

  const { mutate: exportEmployees } = useMutation({
    mutationFn: (variables: { type: string }) =>
      CompanyService.exportEmployees(
        company ? company.id : companyId,
        variables.type
      ),
    onSuccess: (data, variables) => {
      saveDownloadedfile(data);
      showNotification({
        id: 'export-employees-success',
        title: t('w.success'),
        message: t('success.exportEmployees', variables.type),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-employees-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      }),
  });

  function onChangeSelectedEmployeesStatusClick() {
    selectedEmployees.forEach((employee: { id: string; valid: boolean }) => {
      changeUserStatus({
        userId: employee.id,
        valid: !employee.valid,
      });
    });
  }

  function onInviteSelectedEmployeesClick() {
    selectedEmployees.forEach(
      (employee: { companyId: string; id: string; mail: string }) => {
        inviteUser({
          companyId: employee.companyId,
          id: employee.id,
          mail: employee.mail,
        });
      }
    );
  }

  function handleOpenImportEmployeeModalClick(title: string): void {
    if (!isHfwAzureSyncModuleActive) {
      setImportEmployeeModal({ opened: true, title: title });
    } else {
      showNotification({
        id: 'azureUserSync-module-active',
        title: t('w.warning'),
        message: t('module.azureUserSyncImportantMessage'),
        icon: <IconAlertTriangle />,
        color: 'orange',
      });
    }
  }

  function handleCloseImportEmployeeModalClick(): void {
    setImportEmployeeModal({ opened: false, title: '' });
  }

  function onExportEmployeesClick(type: string): void {
    if (hasPermission({ permission: ConfigureEmployees, scope: ALL }, user)) {
      exportEmployees({ type: type });
    } else {
      showNotification({
        id: 'no-permission-to-configure-employees',
        title: t('w.warning'),
        message: t('permission.noConfigureEmployees'),
        icon: <IconAlertTriangle />,
        color: 'orange',
      });
    }
  }

  const actionButtons = useMemo(
    () => [
      <Button
        key={'button'}
        leftSection={<IconCirclePlus />}
        onClick={handlers.open}
      >
        {t('w.addEmployee')}
      </Button>,
    ],
    []
  );

  useEffect(() => {
    if (divisions?.length) {
      const _unitNames: FilterOptionChoice[] = [];
      divisions.forEach((item: any) => {
        if (item.name === NO_WORK_UNIT) {
          _unitNames.push({ value: item.name, label: t('NO_WORK_UNIT') });
        } else {
          _unitNames.push({ value: item.name, label: item.name });
        }
      });
      const _options: Options = { ...options };
      if (_options?.filter?.[1].choices) {
        _options.filter[1].choices = _unitNames;
      }
      setOptions(_options);
    }
  }, [divisions]);

  function filterUsers() {
    if (divisionId) {
      return users.filter((user: User) => user.divisionId === divisionId);
    }
    return users;
  }

  return (
    <ScreenWrapper
      title={t('w.employees')}
      actionButtons={!isWhiteLabelGma() ? actionButtons : []}
      paper
    >
      <Table
        pinFirstColumn
        pinLastColumn
        onSelect={(employee) => setSelectedEmployees(employee)}
        rows={filterUsers()}
        options={options}
        columns={columns}
        lang={lang}
        fetching={isUsersLoading}
        withTableBorder={false}
        rightCustomActions={rightCustomActions}
        leftCustomActions={leftCustomActions}
      />
      <ImportModal
        opened={importEmployeeModal.opened}
        onClose={handleCloseImportEmployeeModalClick}
        title={importEmployeeModal.title}
        companyId={company ? company.id : companyId}
        refetch={refetchUsers}
        importType={'employees'}
      />
      <AddEmployeeModal
        opened={opened}
        onClose={handlers.close}
        divisions={divisions ? divisions : []}
        roles={roles ? roles : []}
        refetch={refetchUsers}
      />
      <CustomModal
        opened={employeePersonalDocuments !== null}
        onClose={() => setEmployeePersonalDocuments(null)}
        title={`${t('w.personalDocuments')} - ${
          employeePersonalDocuments?.fullname
        }`}
        fullScreen
      >
        {employeePersonalDocuments && (
          <DocumentsTable
            hasPermissionToEditSpace={hasPermissionToEditEmployeesPersonalSpace}
            space={'PERSONAL'}
            userId={employeePersonalDocuments.id}
            companyId={employeePersonalDocuments.companyId}
            accountantCompanyId={null}
          />
        )}
      </CustomModal>
    </ScreenWrapper>
  );
}
