import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Group, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCalendar,
  IconCheck,
  IconCirclePlus,
  IconClockHour5,
  IconCoins,
  IconEdit,
  IconFile,
  IconNumbers,
  IconRefresh,
  IconTextWrap,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import MoreItemsBadge from '../../../../../../../components/badges/MoreItemsBadge';
import CustomTooltip from '../../../../../../../components/CustomTooltip';
import { useI18n } from '../../../../../../../contexts/I18nProvider';
import { useModule } from '../../../../../../../contexts/ModuleProvider';
import VariablePayrollService from '../../../../../../../services/VariablePayrollService';
import {
  VariablePayroll,
  VariablePayrollType,
} from '../../../../../../../types/types';
import handleErrorMessage from '../../../../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../../../../utils/optionsPreferences';
import {
  ExportCegidExpert,
  ExportDIAPAIE,
  ExportISAPAYE,
  ExportQuadra,
  ExportSilae,
  SynchroOpenPaye,
} from '../../../../../../../variables/ModulesV2';
import VariablePayrollForm from '../VariablePayrollForm';
import classes from './VariablesPayrollTable.module.sass';

type Props = {
  companyId: string;
};

const componentName = 'VariablesPayrollTable';

export default function VariablesPayrollTable({ companyId }: Props) {
  const { t, lang } = useI18n();
  const { getModule } = useModule();
  const matches = useMediaQuery('(min-width: 426px)');
  const [selectedVariablesPayroll, setSelectedVariablesPayroll] = useState<any>(
    []
  );
  const isCegidExpertModuleActive = getModule(ExportCegidExpert)?.active;
  const isOpenpayeModuleActive = getModule(SynchroOpenPaye)?.active;
  const isIsapayeModuleActive = getModule(ExportISAPAYE)?.active;
  const isDiapaieModuleActive = getModule(ExportDIAPAIE)?.active;
  const isSilaeModuleActive = getModule(ExportSilae)?.active;
  const isQuadraModuleActive = getModule(ExportQuadra)?.active;

  const {
    data: variablesPayrollsListData,
    isLoading: isVariablesPayrollsListDataLoading,
    refetch,
  } = useQuery({
    queryKey: ['VariablePayrollService.getVariables', companyId],
    queryFn: () => VariablePayrollService.getVariables(companyId),
  });

  const variablesPayrollsList: VariablePayroll[] = useMemo(() => {
    if (!variablesPayrollsListData?.variablesPayroll) {
      return [];
    }
    return variablesPayrollsListData.variablesPayroll;
  }, [variablesPayrollsListData]);

  const {
    mutate: synchroVariablesPayrollOpenPaye,
    isLoading: isSynchroVariablesPayrollOpenPayeLoading,
  } = useMutation({
    mutationFn: () =>
      VariablePayrollService.synchroVariablesPayrollOpenPaye(companyId),
    onSuccess: () => refetch(),
    onError: (error) =>
      showNotification({
        id: `syncro-variable-payroll-openpaye-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const rightCustomActions = useMemo(
    () => [
      <Button
        leftSection={<IconTrash />}
        key={'delete-several-variables-payroll-button'}
        disabled={selectedVariablesPayroll.length === 0}
        onClick={handleOpenConfirmDeleteSeveralVariablePayrollModal}
      >
        {t('w.delete')}
      </Button>,
      <Button
        leftSection={<IconCirclePlus />}
        key={'add-variable-payroll-button'}
        onClick={handleOpenAddVariablePayrollModal}
      >
        {t('w.add')}
      </Button>,
      isOpenpayeModuleActive ? (
        <Button
          leftSection={<IconRefresh />}
          key={'syncro-variables-payroll-openpaye-button'}
          onClick={() => synchroVariablesPayrollOpenPaye()}
        >
          {t('w.syncOpenpayePayrollVariables')}
        </Button>
      ) : null,
    ],
    [selectedVariablesPayroll, isOpenpayeModuleActive]
  );

  function getVariablePayrollIcon(type: VariablePayrollType) {
    switch (type) {
      case 'FILE':
        return <IconFile className={classes.icon} />;
      case 'DAYS':
        return <IconCalendar className={classes.icon} />;
      case 'NUMBERS':
        return <IconNumbers className={classes.icon} />;
      case 'TEXT':
        return <IconTextWrap className={classes.icon} />;
      case 'HOURS':
        return <IconClockHour5 className={classes.icon} />;
      case 'EUROS':
        return <IconCoins className={classes.icon} />;
    }
  }

  const moduleExportsColumns: DataTableColumn<VariablePayroll>[] =
    useMemo(() => {
      const columns: DataTableColumn<VariablePayroll>[] = [];

      if (isCegidExpertModuleActive) {
        columns.push({
          accessor: 'codeCegidExpert',
          title: 'Code Cegid Expert',
          sortable: true,
          ellipsis: true,
        });
      }

      if (isOpenpayeModuleActive) {
        columns.push({
          accessor: 'codeOpenpaye',
          title: 'Code Openpaye',
          sortable: true,
          ellipsis: true,
        });
      }

      if (isIsapayeModuleActive) {
        columns.push({
          accessor: 'codeIsapaye',
          title: 'Code Isapaye',
          sortable: true,
          ellipsis: true,
        });
      }

      if (isDiapaieModuleActive) {
        columns.push({
          accessor: 'codeDiapaie',
          title: 'Code Diapaie',
          sortable: true,
          ellipsis: true,
        });
      }

      if (isSilaeModuleActive) {
        columns.push({
          accessor: 'codeSilae',
          title: 'Code Silae',
          sortable: true,
          ellipsis: true,
        });
      }

      if (isQuadraModuleActive) {
        columns.push({
          accessor: 'codeQuadra',
          title: 'Code Quadra',
          sortable: true,
          ellipsis: true,
        });
      }

      return columns;
    }, [
      isCegidExpertModuleActive,
      isOpenpayeModuleActive,
      isIsapayeModuleActive,
      isDiapaieModuleActive,
      isSilaeModuleActive,
      isQuadraModuleActive,
    ]);

  const columns: DataTableColumn<VariablePayroll>[] = useMemo(
    () => [
      {
        accessor: 'label',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'type',
        title: 'Type',
        sortable: true,
        ellipsis: true,
        render: ({ type }) =>
          matches ? (
            <Group>
              {getVariablePayrollIcon(type)}
              <Text fz={'sm'}>{t(`addVariablePayroll.type.${type}`)}</Text>
            </Group>
          ) : (
            <CustomTooltip label={t(`addVariablePayroll.type.${type}`)}>
              {getVariablePayrollIcon(type)}
            </CustomTooltip>
          ),
      },
    ],
    [matches]
  );

  const actionColumns: DataTableColumn<VariablePayroll>[] = useMemo(
    () => [
      {
        accessor: 'actions',
        title: t('w.actions'),
        sortable: true,
        ellipsis: true,
        render: (variable) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.edit')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() => handleOpenEditVariablePayrollModal(variable)}
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.delete')}>
              <ActionIcon
                size="sm"
                color="red"
                variant={'subtle'}
                onClick={() =>
                  handleOpenConfirmDeleteVariablePayrollModal(variable)
                }
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'label',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'type',
          label: 'Type',
          choices: [
            { value: 'EUROS', label: t('addVariablePayroll.type.EUROS') },
            { value: 'DAYS', label: t('addVariablePayroll.type.DAYS') },
            { value: 'HOURS', label: t('addVariablePayroll.type.HOURS') },
            { value: 'NUMBERS', label: t('addVariablePayroll.type.NUMBERS') },
            { value: 'TEXT', label: t('addVariablePayroll.type.TEXT') },
            { value: 'FILE', label: t('addVariablePayroll.type.FILE') },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const {
    mutate: deleteVariablePayroll,
    isLoading: isDeleteVariablePayrollLoading,
  } = useMutation({
    mutationFn: (variables: string) =>
      VariablePayrollService.deleteVariablePayroll(companyId, variables),
    onSuccess: () => {
      showNotification({
        id: `delete-variable-payroll-successful`,
        title: t('w.success'),
        message: t('w.deleted').toLowerCase(),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: (error) =>
      showNotification({
        id: `delete-variable-payroll-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleConfirmDeleteSeveralVariablesClick() {
    selectedVariablesPayroll.forEach((item: VariablePayroll) =>
      deleteVariablePayroll(item.id ? item.id : '')
    );
  }

  function handleOpenConfirmDeleteSeveralVariablePayrollModal() {
    modals.openConfirmModal({
      modalId: 'confirm-delete-several-variable-payroll-modal',
      title: (
        <Title size={'h3'} component="p">
          {t('w.confirmDeletion')}
        </Title>
      ),
      size: 'xl',
      children: (
        <MoreItemsBadge
          items={selectedVariablesPayroll.map(
            (item: VariablePayroll) => item.label
          )}
        />
      ),
      labels: { cancel: t('w.cancel'), confirm: t('w.delete') },
      onConfirm: handleConfirmDeleteSeveralVariablesClick,
    });
  }

  function handleOpenConfirmDeleteVariablePayrollModal(
    variable: VariablePayroll
  ) {
    modals.openConfirmModal({
      modalId: 'confirm-delete-variable-payroll-modal',
      title: (
        <Title size={'h3'} component="p">{`${t('w.confirmDeletion')} "${
          variable.label
        }"`}</Title>
      ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.delete') },
      onConfirm: () => deleteVariablePayroll(variable.id ? variable.id : ''),
    });
  }

  function handleOpenAddVariablePayrollModal() {
    modals.open({
      modalId: `create-variable-payroll-modal`,
      title: <Title size={'h3'} component="p">{`${t('w.add')}`}</Title>,
      size: 'xl',
      children: (
        <VariablePayrollForm
          companyId={companyId}
          isCegidExpertModuleActive={isCegidExpertModuleActive}
          isOpenpayeModuleActive={isOpenpayeModuleActive}
          isIsapayeModuleActive={isIsapayeModuleActive}
          isDiapaieModuleActive={isDiapaieModuleActive}
          isSilaeModuleActive={isSilaeModuleActive}
          isQuadraModuleActive={isQuadraModuleActive}
          refetch={refetch}
        />
      ),
    });
  }

  function handleOpenEditVariablePayrollModal(variable: VariablePayroll) {
    modals.open({
      modalId: `variable-payroll-${variable.id}-edit-modal`,
      title: (
        <Title size={'h3'} component="p">
          {variable.label}
        </Title>
      ),
      size: 'xl',
      children: (
        <VariablePayrollForm
          variable={variable}
          companyId={companyId}
          isCegidExpertModuleActive={isCegidExpertModuleActive}
          isOpenpayeModuleActive={isOpenpayeModuleActive}
          isIsapayeModuleActive={isIsapayeModuleActive}
          isDiapaieModuleActive={isDiapaieModuleActive}
          isSilaeModuleActive={isSilaeModuleActive}
          isQuadraModuleActive={isQuadraModuleActive}
          refetch={refetch}
        />
      ),
    });
  }

  return (
    <Table
      onSelect={(variablesPayroll) =>
        setSelectedVariablesPayroll(variablesPayroll)
      }
      columns={columns.concat(moduleExportsColumns).concat(actionColumns)}
      rows={variablesPayrollsList}
      options={options}
      lang={lang}
      fetching={
        isVariablesPayrollsListDataLoading ||
        isDeleteVariablePayrollLoading ||
        isSynchroVariablesPayrollOpenPayeLoading
      }
      rightCustomActions={rightCustomActions}
    />
  );
}
