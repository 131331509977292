import React from 'react';

import { useI18n } from '../contexts/I18nProvider';
import Pappers from '../features/accountant/components/Pappers';
import Yoomonkeez from '../features/accountant/components/Yoomonkeez';
import CompanyDaysWorkedParams from '../features/company/features/components/CompanyDaysWorkedParams';
import DailyworkParamsFeature from '../features/company/features/components/Dailywork/DailyworkParamsFeature';
import ExpenseReportParams from '../features/company/features/components/ExpenseReportParams';
import ExportCegidExpertParams from '../features/company/features/components/ExportCegidExpertParams';
import ExportQuadraParams from '../features/company/features/components/ExportQuadraParams';
import ExportSilaeParams from '../features/company/features/components/ExportSilaeParams';
import HfwAzureSyncParams from '../features/company/features/components/HfwAzureSyncParams/HfwAzureSyncParams';
import ShowMessageBeforePaidLeaveRequestParams from '../features/company/features/components/ShowMessageBeforePaidLeaveRequestParams';
import FeatureSynchroOpenpayeParams from '../features/company/features/components/SynchroOpenpaye/FeatureSynchroOpenpayeParams';
import TimeRecorderParams from '../features/company/features/components/TimeRecorderParams';
import VariablesPayrollParams from '../features/company/features/components/variables-payroll/VariablesPayrollParams';
import { Module } from '../types/types';
import {
  CompanyDaysWorked,
  DailyWork,
  ExpenseReport,
  ExportCegidExpert,
  ExportQuadra,
  ExportSilae,
  HfwAzureSync,
  Pappers as PappersModuleName,
  ShowMessageBeforePaidLeaveRequest,
  TimeRecorder,
  VariablePayroll,
  Yoomonkeez as YoomonkeezModuleName,
} from '../variables/ModulesV2';
import CustomModal from './CustomModal';

export type UpdateModuleParamsFunction = (variables: {
  apiUrl: string;
  payload: any;
}) => void;

export type ModuleToEditComponentProps = {
  module: Module;
  updateModuleParams: UpdateModuleParamsFunction;
};

type Props = {
  opened: boolean;
  onClose: () => void;
  moduleToEdit: null | Module;
  updateModuleParams: UpdateModuleParamsFunction;
  companyId: string;
};

export default function ModuleToEditModal({
  opened,
  onClose,
  moduleToEdit,
  updateModuleParams,
  companyId,
}: Props) {
  const { t } = useI18n();

  function renderModuleToEditComponent() {
    if (!moduleToEdit) {
      return undefined;
    }

    switch (moduleToEdit.name) {
      case PappersModuleName:
        return (
          <Pappers
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case YoomonkeezModuleName:
        return (
          <Yoomonkeez
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case TimeRecorder:
        return (
          <TimeRecorderParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case 'SynchroOpenPaye':
        return (
          <FeatureSynchroOpenpayeParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
            companyId={companyId}
          />
        );
      case ShowMessageBeforePaidLeaveRequest:
        return (
          <ShowMessageBeforePaidLeaveRequestParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ExportQuadra:
        return (
          <ExportQuadraParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ExportSilae:
        return (
          <ExportSilaeParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ExportCegidExpert:
        return (
          <ExportCegidExpertParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case HfwAzureSync:
        return (
          <HfwAzureSyncParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case CompanyDaysWorked:
        return (
          <CompanyDaysWorkedParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ExpenseReport:
        return (
          <ExpenseReportParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case DailyWork:
        return (
          <DailyworkParamsFeature
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
            companyId={companyId}
          />
        );
      case VariablePayroll:
        return <VariablesPayrollParams companyId={companyId} />;
      /* case GererMesAffaires:
        return (
          <GererMesAffairesParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );*/
      default:
        console.log(`module ${moduleToEdit.name}`);
        return undefined;
    }
  }

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      title={moduleToEdit ? t(`module.${moduleToEdit?.name}`) : ''}
      size={moduleToEdit?.name === VariablePayroll || DailyWork ? '80%' : 'xl'}
    >
      {renderModuleToEditComponent()}
    </CustomModal>
  );
}
