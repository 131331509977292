import {
  ActionIcon,
  Container,
  Flex,
  Group,
  MantineColor,
  Paper,
  Text,
} from '@mantine/core';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { ReactNode } from 'react';

import { useI18n } from '../contexts/I18nProvider';
import { dateString } from '../utils/format';
import CustomUserAvatar from './CustomAvatar/CustomUserAvatar';
import CustomTooltip from './CustomTooltip';

type Props = {
  handleValidateClick: (agree: boolean) => void;
  userId: string;
  fullname: string;
  created: number;
  companyName?: string;
};

export default function AcceptOrRejectRequestWrapper({
  handleValidateClick,
  userId,
  fullname,
  created,
  companyName,
}: Props) {
  const { t } = useI18n();

  function renderActionIcon(
    label: string,
    color: MantineColor,
    agree: boolean,
    icon: ReactNode
  ) {
    return (
      <CustomTooltip label={label}>
        <ActionIcon
          size={'lg'}
          variant="subtle"
          color={color}
          onClick={() => handleValidateClick(agree)}
        >
          {icon}
        </ActionIcon>
      </CustomTooltip>
    );
  }
  return (
    <Container>
      <Paper shadow="xs" p="md" radius={'md'} withBorder>
        <Group justify={'space-between'}>
          <Group>
            <CustomUserAvatar userId={userId} isOauth isLinkProfile />
            <Flex direction={'column'}>
              <Text fz={'lg'} fw={'bold'}>
                {fullname} {companyName && `(${companyName})`}
              </Text>
              <Text mb={'4px'} fz={'xs'} fw={'bold'}>{`Le ${dateString(
                created
              )}`}</Text>
              <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
                {`${t('w.accept')} ${t('w.or')} ${t(
                  'w.reject'
                ).toLowerCase()} ${t('w.request').toLowerCase()} ?`}
              </Text>
            </Flex>
          </Group>
          <Group>
            {renderActionIcon(
              t('w.accept'),
              'green',
              true,
              <IconCircleCheck size={40} />
            )}
            {renderActionIcon(
              t('w.reject'),
              'red',
              false,
              <IconCircleX size={40} />
            )}
          </Group>
        </Group>
      </Paper>
    </Container>
  );
}
