import {
  FilterOption,
  FilterOptionChoice,
  Options,
  Table,
} from '@ckprivate/ckf-ui';
import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconExchange, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useEffect, useMemo, useState } from 'react';

import ActiveInactiveBadge from '../../../../components/badges/ActiveInactiveBadge';
import RoleBadge from '../../../../components/badges/RoleBadge';
import UnitBadge from '../../../../components/badges/UnitBadge';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchUsers from '../../../../hooks/useFetchUsers';
import ProfileService from '../../../../services/ProfileService';
import type { User } from '../../../../types/types';
import { milliToFullDateString } from '../../../../utils/format';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';

type Props = {
  securityRoleId: string | undefined;
  companyId: string;
};

const componentName = 'AssignUsersRolesTable';
export default function AssignUsersRolesTable({
  securityRoleId,
  companyId,
}: Props) {
  const { user } = useAuth();
  const { t, lang } = useI18n();
  const { users, loading, divisions, refetchUsers } = useFetchUsers(companyId);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'employment',
        title: t('w.jobType'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'role.name',
        title: t('w.role'),
        sortable: true,
        ellipsis: true,
        render: ({ role }) => <RoleBadge role={role.name} />,
      },
      {
        accessor: 'division.name',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => <UnitBadge unit={division?.name} />,
      },
      {
        accessor: 'valid',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ valid }) => <ActiveInactiveBadge value={valid} />,
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: ['employment'],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'valid',
          label: t('w.status'),
          choices: [
            { value: 'true', label: t('w.active') },
            { value: 'false', label: t('w.inactive') },
          ],
          selectedChoices: [],
        },
        {
          accessor: 'division.name',
          label: user.company?.divisionLabel
            ? user.company.divisionLabel
            : t('w.unit'),
          choices: [],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  const { mutate } = useMutation({
    mutationFn: (userId: string) =>
      ProfileService.updateUserProfile(userId, {
        securityRoleId,
      }),
    onSuccess: (data) => {
      if (data) {
        const { securityRoleId, firstname, id } = data;
        refetchUsers();
        showNotification({
          id: `update-user-${id}-securityRoleId-${securityRoleId}-successful`,
          title: t('w.success'),
          message: t('success.updateUserProfile', firstname),
          color: 'green',
          icon: <IconCheck />,
        });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'update-user-securityRoleId-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function assignClick() {
    selectedUsers.forEach((item: User) => mutate(item.id));
  }

  const rightCustomActions = useMemo(
    () => [
      <Button
        leftSection={<IconExchange />}
        key={'assign-role-to-user-button'}
        disabled={selectedUsers.length === 0}
        onClick={assignClick}
      >
        {t('w.assign')}
      </Button>,
    ],
    [selectedUsers]
  );

  useEffect(() => {
    if (divisions?.length) {
      const _unitNames: FilterOptionChoice[] = [];
      divisions.forEach((item: any) => {
        if (item.name === NO_WORK_UNIT) {
          _unitNames.push({ value: item.name, label: t('NO_WORK_UNIT') });
        } else {
          _unitNames.push({ value: item.name, label: item.name });
        }
      });
      const _options: Options = { ...options };
      if (_options?.filter?.[1].choices) {
        _options.filter[1].choices = _unitNames;
      }
      setOptions(_options);
    }
  }, [divisions]);

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      pinFirstColumn
      onSelect={(users) => setSelectedUsers(users)}
      options={options}
      columns={columns}
      rows={users}
      lang={lang}
      fetching={loading}
      rightCustomActions={rightCustomActions}
    />
  );
}
