import type {
  CounterType,
  CounterTypePayload,
  CounterTypeResponse,
} from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string): Promise<CounterTypeResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/counter/type/${companyId}`)
    .then((res) => res?.data);
}

function remove(companyId: string, counterKeyToDelete: string) {
  return request
    .delete(
      `${MS_PLANNING_ENDPOINT}/v2/counter/type/${companyId}/${counterKeyToDelete}`
    )
    .then((res) => res?.data);
}

function update(companyId: string, data: CounterType) {
  return request
    .post(`${MS_PLANNING_ENDPOINT}/v2/counter/type/${companyId}`, data)
    .then((res) => res?.data);
}

function create(companyId: string, data: CounterTypePayload) {
  return request
    .post(`${MS_PLANNING_ENDPOINT}/v2/counter/type/${companyId}`, data)
    .then((res) => res?.data);
}

const CounterTypeService = {
  list,
  remove,
  update,
  create,
};

export default CounterTypeService;
