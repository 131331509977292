export function typesContract(t: any) {
  return [
    { value: '', label: t('w.noValue') },
    { value: 'CA', label: t('type.contract.apprentice') },
    { value: 'CDD', label: t('type.contract.CDD') },
    { value: 'CDI', label: t('type.contract.CDI') },
    {
      value: 'CP',
      label: t('type.contract.professionalizationContract'),
    },
    { value: 'CTT', label: t('type.contract.temporary') },
    { value: 'OTHER', label: t('w.other') },
  ];
}
