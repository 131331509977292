export const FR = "FR";
export const CI = "CI";
export const MU = "MU";
export const SN = "SN";
export const CM = "CM";
export const CD = "CD";
export const CG = "CG";
export const BJ = "BJ";
export const ML = "ML";
export const BF = "BF";
export const TN = "TN";
export const DZ = "DZ";
export const GN = "GN";
export const GQ = "GQ";
export const MA = "MA";
export const MR = "MR";
export const NE = "NE";
export const TG = "TG";
export const BI = "BI";
export const GA = "GA";
export const CF = "CF";
export const TD = "TD";
export const RW = "RW";
export const KM = "KM";
export const DJ = "DJ";
export const MG = "MG";
export const SC = "SC";
