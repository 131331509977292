import { Group } from '@mantine/core';

import { Company } from '../../../../types/types';
import CompanyCountryFlag from './CompanyCountryFlag';
import CompanyCountryName from './CompanyCountryName';

type Props = {
  company: Company;
};

export default function CompanyCountry({ company }: Props) {
  return (
    <Group>
      <CompanyCountryName company={company} />
      <CompanyCountryFlag company={company} />
    </Group>
  );
}
