import type { Module, ViewModule } from '../types/types';
import { CK_MODULE_MANAGER_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string, view: ViewModule): Promise<Module[]> {
  return request
    .get(
      `${CK_MODULE_MANAGER_ENDPOINT}/v2/module-manager/list/${companyId}?view=${view}`
    )
    .then((res) => res?.data);
}

function update(
  companyId: string,
  moduleDescriptionId: string,
  active: boolean
) {
  return request.patch(
    `${CK_MODULE_MANAGER_ENDPOINT}/v2/module-manager/module/${companyId}/${moduleDescriptionId}?active=${active}`
  );
}

const CkModuleManagerServiceMS = {
  list,
  update,
};

export default CkModuleManagerServiceMS;
