import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconBrandPocket,
  IconCheck,
  IconCirclePlus,
  IconEdit,
  IconTableDown,
  IconTableImport,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import ActiveInactiveBadge from '../../../components/badges/ActiveInactiveBadge';
import MoreItemsBadge from '../../../components/badges/MoreItemsBadge';
import YesNoBadge from '../../../components/badges/YesNoBadge';
import CustomMenu from '../../../components/CustomMenu';
import CustomTooltip from '../../../components/CustomTooltip';
import ImportModal from '../../../components/ImportModal';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CounterTypeService from '../../../services/CounterTypeService';
import LeaveTypeService from '../../../services/LeaveTypeService';
import { LeaveType } from '../../../types/types';
import { hasPermission } from '../../../utils/authorization';
import { getTranslatedKey } from '../../../utils/counterTypesFormatter';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../utils/saveDownloadedFile';
import {
  ALL,
  ConfigureLeaveTypes,
} from '../../../variables/BuiltInPermissions';
import {
  ExportCegidExpert,
  ExportDIAPAIE,
  ExportISAPAYE,
  ExportQuadra,
  ExportSilae,
  SynchroOpenPaye,
} from '../../../variables/ModulesV2';
import { getIcon } from './components/IconSelector';
import PeriodTypeModal from './components/PeriodTypeModal';

export type EditLeaveType = {
  opened: boolean;
  leaveType: LeaveType | null;
  create: boolean;
};

const componentName = 'PeriodTypes';
export default function PeriodTypes() {
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { getModule, modules } = useModule();
  const { t, lang } = useI18n();
  const [edit, setEdit] = useState<EditLeaveType>({
    opened: false,
    leaveType: null,
    create: false,
  });
  const [
    openedImportModal,
    { open: openImportModal, close: closeImportModal },
  ] = useDisclosure(false);

  const { data: counterTypes } = useQuery({
    enabled: !!companyId,
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['LeaveTypesService.getLeaveTypes', companyId],
    queryFn: () => LeaveTypeService.getLeaveTypes(companyId),
    onError: (err) =>
      showNotification({
        id: 'get-allLeaveTypesWithRoles-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const columns = useMemo<
    DataTableColumn<LeaveType & { status: boolean }>[]
  >(() => {
    const cols: DataTableColumn<LeaveType & { status: boolean }>[] = [
      {
        accessor: 'name',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => <ActiveInactiveBadge value={status} />,
      },
      {
        accessor: 'associateCounter',
        title: t('w.associateCounters'),
        sortable: true,
        ellipsis: true,
        render: ({ associateCounter }) => {
          if (!associateCounter?.length || !counterTypes?.length) {
            return null;
          }

          const filtered = counterTypes.filter((ct) =>
            associateCounter.some(
              (ac) => ac.counterTypeId === ct.counterType.id
            )
          );

          return (
            <MoreItemsBadge
              items={associateCounter
                .map((ass) => ({
                  ...filtered.find(
                    (ct) => ct.counterType.id === ass.counterTypeId && ct
                  ),
                  ...ass,
                }))
                .sort((a, b) => a.order - b.order)
                .map((ct) => getTranslatedKey(t, ct.counterType?.key))}
            />
          );
        },
      },
      {
        accessor: 'description',
        title: 'Description',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'legalDelay',
        title: t('w.legalDelay'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'autoValid',
        title: t('w.autoValid'),
        sortable: true,
        ellipsis: true,
        render: ({ autoValid }) => <YesNoBadge value={Boolean(autoValid)} />,
      },
      {
        accessor: 'useAllDays',
        title: t('w.useAllDays'),
        sortable: true,
        ellipsis: true,
        render: ({ useAllDays }) => <YesNoBadge value={Boolean(useAllDays)} />,
      },
      {
        accessor: 'showLabel',
        title: t('w.showLabel'),
        sortable: true,
        ellipsis: true,
        render: ({ showLabel }) => <YesNoBadge value={Boolean(showLabel)} />,
      },
      {
        accessor: 'excludeEndMonth',
        title: t('w.excludeEndMonth'),
        sortable: true,
        ellipsis: true,
        render: ({ excludeEndMonth }) => (
          <YesNoBadge value={Boolean(excludeEndMonth)} />
        ),
      },
      {
        accessor: 'blockingPeriodAllowed',
        title: t('w.blockingPeriodAllowed'),
        sortable: true,
        ellipsis: true,
        render: ({ blockingPeriodAllowed }) => (
          <YesNoBadge value={Boolean(blockingPeriodAllowed)} />
        ),
      },
      {
        accessor: 'maxDays',
        title: t('w.maxDays'),
        sortable: false,
        ellipsis: true,
      },
      {
        accessor: 'repeatable',
        title: t('w.repeatable'),
        sortable: true,
        ellipsis: true,
        render: ({ repeatable }) => <YesNoBadge value={Boolean(repeatable)} />,
      },
      {
        accessor: 'cancelOthersLeaves',
        title: t('w.cancelOthersLeaves'),
        sortable: true,
        ellipsis: true,
        render: ({ cancelOthersLeaves }) => (
          <YesNoBadge value={Boolean(cancelOthersLeaves)} />
        ),
      },
      {
        accessor: 'icon',
        title: t('w.icons'),
        sortable: false,
        ellipsis: true,
        render: ({ color, icon }) =>
          getIcon({ color: color as string, name: icon }),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 80,
        render: (row) => (
          <Flex justify={'center'}>
            <CustomTooltip label={t('w.edit')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() =>
                  setEdit({ ...edit, opened: true, leaveType: row })
                }
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Flex>
        ),
      },
    ];
    if (modules?.length) {
      if (getModule(ExportQuadra)?.active) {
        cols.splice(1, 0, {
          accessor: 'codeQuadra',
          title: 'Code Quadra',
          sortable: true,
          ellipsis: true,
        });
      }
      if (getModule(ExportDIAPAIE)?.active) {
        cols.splice(1, 0, {
          accessor: 'codeDiapaie',
          title: 'Code Diapaie',
          sortable: true,
          ellipsis: true,
        });
      }
      if (getModule(ExportSilae)?.active) {
        cols.splice(1, 0, {
          accessor: 'codeSilae',
          title: 'Code Silae',
          sortable: true,
          ellipsis: true,
        });
      }
      if (getModule(ExportISAPAYE)?.active) {
        cols.splice(1, 0, {
          accessor: 'codeIsapaye',
          title: 'Code Isapaye',
          sortable: true,
          ellipsis: true,
        });
      }
      if (getModule(SynchroOpenPaye)?.active) {
        cols.splice(1, 0, {
          accessor: 'codeOpenpaye',
          title: 'Code Openpaye',
          sortable: true,
          ellipsis: true,
        });
      }
      if (getModule(ExportCegidExpert)?.active) {
        cols.splice(1, 0, {
          accessor: 'codeCegidExpert',
          title: 'Code Cegid Expert',
          sortable: true,
          ellipsis: true,
        });
      }
    }

    return cols;
  }, [lang, modules, counterTypes]);

  const rows: (LeaveType & { status: boolean })[] = useMemo(() => {
    if (data) {
      return data.map((item: LeaveType) => ({
        ...item,
        status: !!item?.securityRoles?.length,
      }));
    }
    return [];
  }, [data]);

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [
        'description',
        'useAllDays',
        'excludeEndMonth',
        'blockingPeriodAllowed',
        'cancelOthersLeaves',
        'legalDelay',
        'showLabel',
        'repeatable',
        'autoValid',
      ],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status',
          label: t('w.status'),
          choices: [
            { value: 'true', label: t('w.active') },
            { value: 'false', label: t('w.inactive') },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  const actionButtons = useMemo(
    () => [
      <Button
        key={'addANewTypeOfLeave-button'}
        leftSection={<IconCirclePlus />}
        onClick={() => setEdit({ ...edit, opened: true, create: true })}
      >
        {t('w.addANewTypeOfLeave')}
      </Button>,
    ],
    []
  );

  const { mutate: exportLeaveTypes } = useMutation({
    mutationFn: (variables: { type: 'XLSX' | 'CSV' }) =>
      LeaveTypeService.exportLeaveTypes(companyId, variables.type),
    onSuccess: (data, variables) => {
      saveDownloadedfile(data);
      showNotification({
        id: 'export-leave-types-success',
        message: t('w.success'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-leave-types-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  const rightCustomActions = useMemo(
    () => [
      <Button
        key={'import-button'}
        leftSection={<IconTableDown />}
        bg={'white'}
        variant={'subtle'}
        onClick={openImportModal}
      >
        {t('w.import')}
      </Button>,
      <CustomMenu
        key={'export-leave-types'}
        buttonLabel={t('w.export')}
        buttonVariant={'filled'}
        leftSection={<IconTableImport />}
        menuLabel={`${t('w.typesOfPeriod')} via :`}
        menuItems={[
          {
            label: 'XLSX',
            icon: <IconBrandPocket size={18} />,
            onClick: () => handleExportLeaveTypes('XLSX'),
          },
        ]}
      />,
    ],
    []
  );

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function closeModal() {
    setEdit({ opened: false, leaveType: null, create: false });
  }

  function handleExportLeaveTypes(type: 'XLSX' | 'CSV'): void {
    if (hasPermission({ permission: ConfigureLeaveTypes, scope: ALL }, user)) {
      exportLeaveTypes({ type: type });
    } else {
      showNotification({
        id: 'no-permission-to-configure-leave-types',
        title: t('w.warning'),
        message: t('permission.noConfigureWorkUnit'),
        icon: <IconAlertTriangle />,
        color: 'orange',
      });
    }
  }

  return (
    <ScreenWrapper
      title={t('w.typesOfPeriod')}
      actionButtons={actionButtons}
      paper
    >
      <Table
        pinFirstColumn
        pinLastColumn
        options={options}
        columns={columns}
        rows={rows}
        withTableBorder={false}
        lang={lang}
        fetching={isLoading}
        rightCustomActions={rightCustomActions}
      />
      <PeriodTypeModal
        opened={edit.opened}
        onClose={closeModal}
        leaveTypeToEdit={edit?.leaveType}
        leaveTypeToCreate={edit.create}
        refetch={refetch}
        companyId={companyId}
      />
      <ImportModal
        opened={openedImportModal}
        onClose={closeImportModal}
        title={t('w.import')}
        companyId={companyId}
        refetch={refetch}
        importType={'period-types'}
      />
    </ScreenWrapper>
  );
}
