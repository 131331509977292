import { useQueries, useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import CounterTypeService from '../../../../services/CounterTypeService';
import CounterUserService from '../../../../services/CounterUserService';
import UsersCounters, { UsersCountersData } from './UsersCounters';

type Props = {
  companyId: string;
};

export default function HolidayCounters({ companyId }: Props) {
  const { divisions } = useFetchDivisions({ companyId });

  const { data: users } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'divisionId',
        'valid',
      ]),
  });
  const usersCountersQueries = useQueries({
    queries: users
      ? users
          .filter((item) => item.valid)
          .map((user) => {
            return {
              queryKey: ['CounterUserService.getByUser', user.id],
              queryFn: () => CounterUserService.getByUser(user.id),
            };
          })
      : [],
  });
  const data: UsersCountersData[] | undefined = useMemo(() => {
    const isLoadingUsersCountersQueries = Boolean(
      usersCountersQueries.find((item) => item.isLoading)
    );

    if (isLoadingUsersCountersQueries || !users) return undefined;

    const dataArray = usersCountersQueries.map((item) => item.data);

    if (!dataArray?.length) return [];

    const result: UsersCountersData[] = [];

    users
      .filter((item) => item.valid)
      .forEach((user) => {
        const countersUsers = dataArray.find(
          (arr) => arr?.[0].userId === user.id
        );
        const division = divisions.find((item) => item.id === user.divisionId);

        result.push({
          userId: user.id,
          userFullname: user.fullname,
          division,
          countersUsers,
        });
      });

    return result;
  }, [usersCountersQueries, users]);

  const { data: counterTypeResponses } = useQuery({
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  return (
    <UsersCounters
      data={data}
      counterTypeResponses={counterTypeResponses}
      divisions={divisions}
      companyId={companyId}
    />
  );
}
