import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import { Accountants, Company } from '../../../../types/types';
import AddAccountantToCustomerDivisionForm from './AddAccountantToCustomerDivisionForm';

type Props = {
  opened: boolean;
  onClose: () => void;
  accountantEmployees: { value: string; label: string }[];
  customerDivisionCompany: Company | null;
  refetchCustomerDivisionsData: () => void;
  accountantsToConfigure: Accountants | null;
  refetchAccountantCustomersData: () => void;
};

export default function AddAccountantToCustomerDivisionModal({
  opened,
  onClose,
  accountantEmployees,
  customerDivisionCompany,
  refetchCustomerDivisionsData,
  accountantsToConfigure,
  refetchAccountantCustomersData,
}: Props) {
  const { t } = useI18n();
  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      title={`${t('w.add')} ${t('w.payrollOfficer').toLowerCase()} ${t(
        'w.to'
      )} ${customerDivisionCompany?.name}`}
      size={'xl'}
    >
      <AddAccountantToCustomerDivisionForm
        accountantEmployees={accountantEmployees}
        customerDivisionCompany={customerDivisionCompany}
        refetchCustomerDivisionsData={refetchCustomerDivisionsData}
        onClose={onClose}
        accountantsToConfigure={accountantsToConfigure}
        refetchAccountantCustomersData={refetchAccountantCustomersData}
      />
    </CustomModal>
  );
}
