import dayjs from 'dayjs';
import React, { useRef } from 'react';

import { UserDailyWork } from '../../types/types';
import DailyWorkInfoCell from './DailyWorkInfoCell';
import DayEventsCell from './DayEventsCell';
import css from './HorizontalMonthTimeline.module.sass';

export declare type TimelineProps = {
  periodDailyWorks: UserDailyWork;
};

interface TimelineInlineStyles {
  [Key: string]: number | string;
}

export default function HorizontalMonthTimeline({
  periodDailyWorks,
}: TimelineProps) {
  const timelinePanel = useRef<HTMLDivElement>(null);
  const cellWidth =
    timelinePanel.current?.clientWidth! / periodDailyWorks.dailyWorks.length >
    44
      ? Math.round(
          timelinePanel.current?.clientWidth! /
            periodDailyWorks.dailyWorks.length
        )
      : 44;
  const timelineInlineStyles: TimelineInlineStyles = {
    '--timeline-header-row-items-count': periodDailyWorks.dailyWorks.length,
    '--timeline-cell-width': `${cellWidth}px`,
  };

  return (
    <div
      className={css['timeline']}
      ref={timelinePanel}
      style={timelineInlineStyles}
    >
      <div className={css['timeline-header']}>
        <div className={css['timeline-header-row']}>
          {periodDailyWorks.dailyWorks.map((dailyWork, index) => {
            return (
              <div
                className={css['timeline-header-row-cell']}
                key={`${dailyWork.id}-${index}`}
              >
                {dayjs(dailyWork.date).format('ddd')}
                <br />
                {dayjs(dailyWork.date).format('D')}
              </div>
            );
          })}
        </div>
      </div>
      <div className={css['timeline-content']}>
        {!periodDailyWorks.dailyWorks[0].user.fixedPriceManager && (
          <div
            className={`${css['timeline-content-row']} ${css['timeline-content-row-workInfo']}`}
          >
            {periodDailyWorks.dailyWorks.map((dailyWork, index) => (
              <DailyWorkInfoCell
                dailyWork={dailyWork}
                key={`${dailyWork.id}-${index}`}
                day={dayjs(dailyWork.date)}
              />
            ))}
          </div>
        )}
        <div className={css['timeline-content-row']}>
          {periodDailyWorks.dailyWorks.map((dailyWork, index) => (
            <DayEventsCell
              dailyWork={dailyWork}
              key={`${dailyWork.id}-${index}`}
              day={dayjs(dailyWork.date)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
