import {
  ActionIcon,
  FileButton,
  Group,
  Image,
  LoadingOverlay,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowNarrowRight,
  IconCheck,
  IconEdit,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useRef } from 'react';

import CustomCompanyAvatar from '../../../../components/CustomAvatar/CustomCompanyAvatar';
import CustomTransition from '../../../../components/CustomTransition';
import ActionDeleteIcon from '../../../../components/icons/ActionDeleteIcon';
import { useI18n } from '../../../../contexts/I18nProvider';
import CompanyService from '../../../../services/CompanyService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
interface FormValues {
  logo?: File | null | undefined;
}

type Props = {
  companyId: string;
};

export default function CompanyLogoForm({ companyId }: Props) {
  const { t } = useI18n();

  const form = useForm<FormValues>({
    initialValues: {
      logo: null,
    },
  });

  const resetRef = useRef<() => void>(null);

  function clearFile() {
    form.setFieldValue('logo', null);
    resetRef.current?.();
  }

  const { data: companyLogoPath } = useQuery({
    queryKey: ['CompanyService.getLogoPath', companyId],
    queryFn: () => CompanyService.getLogoPath(companyId),
  });

  const { mutate: updateCompanyLogo, isLoading } = useMutation({
    mutationFn: (variables: { file: File; extension: string }) =>
      CompanyService.updateCompanyLogo(
        companyId,
        variables.file,
        variables.extension
      ),
    onSuccess: () => {
      clearFile();
      showNotification({
        id: `update-companyId-${companyId}-logo-successful`,
        title: t('w.success'),
        message: t('success.company.logo.updated'),
        color: 'green',
        icon: <IconCheck />,
      });
      setTimeout(() => window.location.reload(), 1000);
    },
    onError: (error) =>
      showNotification({
        id: `update-companyId-${companyId}-logo-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateCompanyLogoSubmit(values: FormValues) {
    if (values.logo) {
      updateCompanyLogo({
        file: values.logo,
        extension: values.logo?.name.split('.')[1],
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateCompanyLogoSubmit(values)
      )}
    >
      <LoadingOverlay visible={isLoading} />
      <Group gap={'lg'}>
        <Group gap={'xs'}>
          <CustomCompanyAvatar logoPath={companyLogoPath} size={'xl'} />
          <FileButton
            resetRef={resetRef}
            {...form.getInputProps('logo')}
            accept="image/png,image/jpeg"
          >
            {(props) => (
              <ActionIcon variant={'subtle'} {...props}>
                <IconEdit />
              </ActionIcon>
            )}
          </FileButton>
        </Group>
        <CustomTransition
          opened={!!form.values.logo}
          transition={'slide-left'}
          duration={400}
          timingFunction={'ease'}
        >
          <Group>
            <IconArrowNarrowRight color={'var(--mantine-color-hifivework-2)'} />
            {form.values.logo && (
              <Image
                h={100}
                w={100}
                radius="xl"
                src={URL.createObjectURL(form.values.logo)}
              />
            )}
            <ActionDeleteIcon label={t('w.delete')} onClick={clearFile} />
            <ActionIcon type={'submit'} variant={'subtle'} color={'green'}>
              <IconCheck />
            </ActionIcon>
          </Group>
        </CustomTransition>
      </Group>
    </form>
  );
}
