import { Options, Table } from '@ckprivate/ckf-ui';
import { Badge, Button, Flex, Switch, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useMemo, useState } from 'react';

import DailyworkTypeMode from '../../../../../components/DailyworkTypeMode';
import type { ModuleToEditComponentProps } from '../../../../../components/ModuleToEditModal';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useFetchUsers from '../../../../../hooks/useFetchUsers';
import ModuleWithParamsService from '../../../../../services/ModuleWithParamsService';
import { DailyWorkParams, User } from '../../../../../types/types';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';
import ShowMessageBeforeActivateOrDeactivateUser from './components/ShowMessageBeforeActivateOrDeactivateUser';
import VariablePayrollDailywork from './components/VariablePayrollDailywork';

const componentName = 'DailyworkParamsFeature';

type Props = {
  companyId: string;
};
export default function DailyworkParamsFeature({
  module,
  updateModuleParams,
  companyId,
}: ModuleToEditComponentProps & Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const {
    users,
    loading: isUsersLoading,
    refetchUsers,
  } = useFetchUsers(companyId);
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const { data: moduleDailyworkData, refetch: refetchModuleDailyworkData } =
    useQuery({
      queryKey: ['ModuleWithParamsService.getModuleParams', companyId],
      queryFn: () =>
        ModuleWithParamsService.getModuleParams(
          module.description.url,
          companyId
        ),
    });

  const moduleParams: DailyWorkParams = useMemo(() => {
    if (!moduleDailyworkData) {
      return module?.configuration?.params as DailyWorkParams;
    }

    return moduleDailyworkData;
  }, [moduleDailyworkData]);

  const columns: DataTableColumn<User>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ fullname }) => <Text fz={'sm'}>{fullname}</Text>,
      },
      {
        accessor: 'employment',
        title: t('w.jobType'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'division.name',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => (
          <Badge
            color={'#FCF0E0'}
            styles={{ label: { textTransform: 'none', color: '#EDB464' } }}
          >
            {division?.name === NO_WORK_UNIT
              ? t(`NO_WORK_UNIT`)
              : division?.name}
          </Badge>
        ),
      },
      {
        accessor: 'mode',
        title: 'Mode',
        sortable: true,
        ellipsis: true,
        render: ({ id, companyId, fixedPriceManager, paidFeatures }) => (
          <DailyworkTypeMode
            t={t}
            id={id}
            companyId={companyId}
            fixedPriceManager={fixedPriceManager}
            paidFeatures={paidFeatures}
            refetchUsers={refetchUsers}
          />
        ),
      },
    ],
    [isUsersLoading]
  );

  function onChangeActiveForAll(event: any) {
    modals.open({
      modalId: `confirm-to-active-user-dailywork`,
      title: (
        <Title size={'h3'} component="p">
          Confirmation
        </Title>
      ),
      size: '80%',
      children: (
        <ShowMessageBeforeActivateOrDeactivateUser
          selectedEmployees={selectedEmployees}
          activate={false}
          companyId={companyId}
          activeForAll={event.currentTarget.checked}
          allUsers={true}
          apiUrl={module.description.url}
          refetchModule={refetchModuleDailyworkData}
          moduleParams={moduleParams}
        />
      ),
    });
  }

  const leftCustomActions: ReactNode[] = useMemo(
    () => [
      <Button
        key={'dailywork-active-user-button'}
        disabled={selectedEmployees.length === 0}
        onClick={() =>
          modals.open({
            modalId: `confirm-to-active-user-dailywork`,
            title: (
              <Title size={'h3'} component="p">
                Confirmation
              </Title>
            ),
            size: '80%',
            children: (
              <ShowMessageBeforeActivateOrDeactivateUser
                selectedEmployees={selectedEmployees}
                activate={true}
                companyId={companyId}
                activeForAll={false}
                allUsers={false}
                apiUrl={module.description.url}
                refetchModule={refetchModuleDailyworkData}
                moduleParams={moduleParams}
              />
            ),
          })
        }
      >
        {t('w.activate')}
      </Button>,
      <Button
        key={'dailywork-desactive-user-button'}
        disabled={selectedEmployees.length === 0}
        onClick={() =>
          modals.open({
            modalId: `confirm-to-deactivate-user-dailywork`,
            title: (
              <Title size={'h3'} component="p">
                Confirmation
              </Title>
            ),
            size: '80%',
            children: (
              <ShowMessageBeforeActivateOrDeactivateUser
                selectedEmployees={selectedEmployees}
                activate={false}
                companyId={companyId}
                activeForAll={false}
                allUsers={false}
                apiUrl={module.description.url}
                refetchModule={refetchModuleDailyworkData}
                moduleParams={moduleParams}
              />
            ),
          })
        }
      >
        {t('w.deactivate')}
      </Button>,
    ],
    [selectedEmployees.length]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Flex direction={'column'} gap={'sm'}>
      <Flex justify={'space-between'} align={'center'}>
        <Flex align={'center'} gap={'sm'}>
          <Switch
            checked={
              moduleParams?.activeForAll ? moduleParams?.activeForAll : false
            }
            onChange={(event) => {
              onChangeActiveForAll(event);
            }}
          />
          <Text>Actif pour tous les utilisateurs</Text>
        </Flex>
        <VariablePayrollDailywork
          companyId={companyId}
          apiUrl={module.description.url}
          module={module}
          refetchModule={refetchModuleDailyworkData}
        />
      </Flex>
      {moduleParams?.activeForAll ? (
        <></>
      ) : (
        <Table
          pinFirstColumn
          pinLastColumn
          onSelect={(employee) => setSelectedEmployees(employee)}
          rows={users.filter((user: User) => user.valid)}
          columns={columns}
          lang={lang}
          fetching={isUsersLoading}
          withTableBorder={false}
          rightCustomActions={leftCustomActions}
          options={options}
        />
      )}
    </Flex>
  );
}
