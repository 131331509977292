import { useMutation } from '@tanstack/react-query';

import CustomModal from '../../../components/CustomModal';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { LoginResponse } from '../../../hooks/useHfwLogin';
import OauthServiceMS from '../../../services/OauthServiceMS';
import { CompanyButton } from './CompanyButton/CompanyButton';

type Props = {
  open: boolean;
  onClose: () => void;
  loginResponse?: LoginResponse;
};

export default function ProfileChoiceModal({
  open,
  onClose,
  loginResponse,
}: Props) {
  const { updateUser } = useAuth();
  const { t } = useI18n();

  const { mutate: updateOauthUserCurrentProfile } = useMutation({
    mutationFn: (variables: { access_token: string; user: any }) =>
      OauthServiceMS.setCurrentProfileToOauthUser(
        variables.access_token,
        variables.user?.id
      ),
    onSuccess: (_data, variables) => {
      updateUser(
        variables.user,
        loginResponse?.access_token as string,
        loginResponse?.refresh_token as string
      );
      onClose();
    },
  });

  function onSelectUser(_user: any) {
    if (loginResponse?.access_token) {
      updateOauthUserCurrentProfile({
        access_token: loginResponse.access_token,
        user: _user,
      });
    }
  }

  return (
    <CustomModal
      opened={open}
      onClose={onClose}
      title={t('w.selectYourAccount')}
      size={'md'}
    >
      {loginResponse?.users.map((user) => (
        <CompanyButton
          user={user}
          key={user.id}
          onClick={() => onSelectUser(user)}
        />
      ))}
    </CustomModal>
  );
}
