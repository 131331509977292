import { Box, Button, LoadingOverlay,Text, TextInput } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  CardElement,
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type { SetupIntentResult } from '@stripe/stripe-js';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import usePaymentAccounts from '../../../../../hooks/usePaymentAccounts';
import useStripeConfig from '../../../../../hooks/useStripeConfig';
import PaymentAccountService from '../../../../../services/PaymentAccountService';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';

type Props = {
  paymentAccountId: string;
  type: 'card' | 'iban';
};

export default function CheckoutForm({ paymentAccountId, type }: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { queryKey } = usePaymentAccounts();
  const { clientSecret } = useStripeConfig(paymentAccountId);
  const stripe = useStripe();
  const elements = useElements();
  const { mutate: addPaymentMethod, isLoading: isLoadingAddPaymentMethod } =
    useMutation({
      mutationFn: (variables: {
        paymentAccountId: string;
        setupIntentPaymentMethod: string;
      }) =>
        PaymentAccountService.addPaymentMethod(
          variables.paymentAccountId,
          variables.setupIntentPaymentMethod
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey });
        queryClient.invalidateQueries({
          queryKey: [
            'PaymentAccountService.getPaymentMethods',
            paymentAccountId,
          ],
        });
        setInvalidPaymentInfo(false);
        setOwnerName('');
        setOwnerNameError('');
        showNotification({
          id: 'addPaymentMethod-success',
          message: t('w.success'),
          color: 'green',
          icon: <IconCheck />,
        });
        modals.close('CheckoutForm');
      },
      onError: (error) => {
        showNotification({
          id: 'addPaymentMethod-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          icon: <IconX />,
          color: 'red',
        });
      },
    });
  const [ownerName, setOwnerName] = useState('');
  const [ownerNameError, setOwnerNameError] = useState('');
  const [invalidPaymentInfo, setInvalidPaymentInfo] = useState(false);
  const stripeStyle = {
    base: {
      color: '#666',
      fontSize: '20px',
    },
    invalid: {
      color: '#fa755a',
      fontSize: '20px',
    },
  };

  function submit(event: any) {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;

    if (ownerName === '') {
      setOwnerNameError(t('w.required'));
      return;
    }
    setOwnerNameError('');

    // SEE : https://stripe.com/docs/payments/save-and-reuse#web-save-payment-method
    switch (type) {
      case 'card':
        const card = elements.getElement(CardElement);
        if (!card) return;

        stripe
          .confirmCardSetup(clientSecret, {
            payment_method: {
              card: card,
              billing_details: { name: ownerName, email: user.mail },
            },
          })
          .then(onResponse);

        break;
      case 'iban':
        const iban = elements.getElement(IbanElement);
        if (!iban) return;

        stripe
          .confirmSepaDebitSetup(clientSecret, {
            payment_method: {
              sepa_debit: iban,
              billing_details: { name: ownerName, email: user.mail },
            },
          })
          .then(onResponse);
        break;
      default:
        return;
    }
  }

  function onResponse(result: SetupIntentResult) {
    if (result.error) {
      setInvalidPaymentInfo(true);
    } else {
      setInvalidPaymentInfo(false);
      addPaymentMethod({
        paymentAccountId,
        setupIntentPaymentMethod: result.setupIntent.payment_method as string,
      });
    }
  }

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isLoadingAddPaymentMethod} />
      {type === 'card' ? (
        <CardElement options={{ style: stripeStyle }} />
      ) : (
        <IbanElement
          options={{
            supportedCountries: ['SEPA'],
            placeholderCountry: 'FR',
            style: stripeStyle,
          }}
        />
      )}
      {invalidPaymentInfo && (
        <Text size="sm" c="red">
          {t('w.incorrectBankingInformation')}
        </Text>
      )}
      <TextInput
        mt={'md'}
        mb={'md'}
        label={t('w.ownerName')}
        value={ownerName}
        error={ownerNameError}
        onChange={(event) => setOwnerName(event.currentTarget.value)}
      />
      <Button onClick={submit}>{t('w.add')}</Button>
    </Box>
  );
}
