import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Avatar, Button, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCirclePlus, IconLogin2 } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomUserAvatar from '../../../../components/CustomAvatar/CustomUserAvatar';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import CompanyService from '../../../../services/CompanyService';
import OauthServiceMS from '../../../../services/OauthServiceMS';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import InviteProfileModal from './InviteProfileModal';

const componentName = 'MyProfilesTable';
export default function MyProfilesTable() {
  const { loginResponse, updateUser } = useAuth();
  const { t } = useI18n();
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();

  const rightCustomActions = useMemo(
    () => [
      <Button
        leftSection={<IconCirclePlus />}
        key={'invite-new-user-profile'}
        onClick={open}
      >
        {`${t('w.add')} ${t('w.aNewProfile').toLowerCase()}`}
      </Button>,
    ],
    []
  );

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ id, fullname }) => (
          <Group>
            <CustomUserAvatar userId={id} size={40} />
            <Text>{fullname}</Text>
          </Group>
        ),
      },
      {
        accessor: 'mail',
        title: t('w.companyMail'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'company.name',
        title: t('w.companyName'),
        sortable: true,
        ellipsis: true,
        render: ({ company }) => (
          <Group>
            <Avatar src={CompanyService.getLogoPath(company.id)} radius="xl" />
            <Text>{company.name}</Text>
          </Group>
        ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: (user) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.login')} withinPortal>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() => handleSelectedUserLogin(user)}
              >
                <IconLogin2 size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const { mutate: updateOauthUserCurrentProfile } = useMutation({
    mutationFn: (variables: { access_token: string; user: any }) =>
      OauthServiceMS.setCurrentProfileToOauthUser(
        variables.access_token,
        variables.user?.id
      ),
    onSuccess: (data, variables) => {
      updateUser(
        variables.user,
        loginResponse?.access_token,
        loginResponse?.refresh_token
      );
      navigate('/home');
    },
  });

  function handleSelectedUserLogin(_user: any) {
    if (loginResponse?.access_token) {
      updateOauthUserCurrentProfile({
        access_token: loginResponse.access_token,
        user: _user,
      });
    }
  }

  return (
    <>
      <Table
        pinFirstColumn
        pinLastColumn
        columns={columns}
        rows={loginResponse?.users}
        options={options}
        height={'calc(100vh - 220px)'}
        rightCustomActions={rightCustomActions}
      />
      <InviteProfileModal opened={opened} onClose={close} />
    </>
  );
}
