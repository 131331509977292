import { ActionIcon, Flex, Group, Indicator, Text } from '@mantine/core';
import { IconCalendar, IconSelect, IconTrash } from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import classes from './WorkTemplate.module.sass';

type Props = {
  template: any;
  handleOpenTemplateClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeleteTemplateClick: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  setSelectedTemplate: () => void;
  selectedTemplate?: any;
};

export default function WorkTemplate({
  template,
  handleOpenTemplateClick,
  handleDeleteTemplateClick,
  setSelectedTemplate,
  selectedTemplate,
}: Props) {
  const { t } = useI18n();

  return (
    <Indicator
      label={<IconSelect size={20} color={'var(--mantine-color-green-4)'} />}
      offset={13}
      size={16}
      color="transparent"
      disabled={selectedTemplate?.name !== template.name}
    >
      <Flex
        direction={'column'}
        className={classes.overviewWrapper}
        gap={'sm'}
        onClick={setSelectedTemplate}
        w={180}
        styles={{
          root: {
            backgroundColor:
              selectedTemplate?.name === template.name
                ? 'var(--mantine-color-hifivework-0)'
                : 'var(--mantine-color-body)',
            cursor: 'pointer',
            border: `1px solid ${
              selectedTemplate?.name === template.name
                ? 'var(--mantine-color-hifivework-2)'
                : 'var(--mantine-primary-color-light)'
            }`,
          },
        }}
      >
        <Text fz={'sm'} fw={'bold'} c={'dimmed'} truncate={'end'} w={'95%'}>
          {template.name}
        </Text>
        <Group justify={'flex-end'}>
          <CustomTooltip label={t('w.see')} position={'left'}>
            <ActionIcon
              variant={'subtle'}
              size={'sm'}
              onClick={(event) => handleOpenTemplateClick(event)}
            >
              <IconCalendar />
            </ActionIcon>
          </CustomTooltip>
          <CustomTooltip label={t('w.delete')} position={'right'}>
            <ActionIcon
              variant={'subtle'}
              size={'sm'}
              color={'red'}
              onClick={(event) => handleDeleteTemplateClick(event)}
            >
              <IconTrash />
            </ActionIcon>
          </CustomTooltip>
        </Group>
      </Flex>
    </Indicator>
  );
}
