import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Badge, Center, Flex, Group, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCheck,
  IconCircleCheck,
  IconCircleOff,
  IconCircleX,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import PayrollOfficerBadge from '../features/company/payroll-officer/components/PayrollOfficerBadge';
import useCompany from '../hooks/useCompany';
import CharteredAccountantService from '../services/CharteredAccountantService';
import { ACCOUNTANT_MODE, InvitationAccountantHistory } from '../types/types';
import { milliToFullDateString } from '../utils/format';
import handleErrorMessage from '../utils/handleErrorMessage';
import { getPersistedObject } from '../utils/localStorage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../utils/optionsPreferences';
import CustomUserAvatar from './CustomAvatar/CustomUserAvatar';
import CustomTooltip from './CustomTooltip';

type Props = {
  invitationAccountantHistoryData: InvitationAccountantHistory[] | null;
  isInvitationAccountantHistoryLoading: boolean;
  refetchInvitationAccountantHistoryData: () => void;
};

const componentName = 'InvitationsHistoryPayrollOfficerTable';
export default function InvitationsHistoryPayrollOfficerTable({
  invitationAccountantHistoryData,
  isInvitationAccountantHistoryLoading,
  refetchInvitationAccountantHistoryData,
}: Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const { mutate: validateCustomerAccountantInvitation } = useMutation({
    mutationFn: (variables: { validationId: string; status: boolean | null }) =>
      CharteredAccountantService.validateCustomerAccountantInvitation(
        companyId,
        variables.validationId,
        variables.status
      ),
    onSuccess: (
      _data,
      variables: { validationId: string; status: boolean | null }
    ) => {
      refetchInvitationAccountantHistoryData();
      showNotification({
        id: variables.status
          ? `accept-customer-invitation-${variables.validationId}-successful`
          : `reject-customer-invitation-${variables.validationId}-successful`,
        title: t('w.success'),
        message: variables.status
          ? `${t('w.invitationAccepted')} !`
          : `${t('w.invitationRefused')} !`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) => {
      // @ts-ignore
      const { status } = error?.response;

      showNotification({
        id: 'validate-customer-invitation-error',
        title: status === 400 ? t('w.warning') : t('w.error'),
        message:
          status === 400
            ? t(
                'w.youDoNotHaveAuthorisationToValidateCustomerInvitationRequests'
              )
            : handleErrorMessage(error, t),
        color: status === 400 ? 'orange' : 'red',
        icon: status === 400 ? <IconAlertTriangle /> : <IconX />,
      });
    },
  });

  const invitationAccountantHistoryList = useMemo(() => {
    if (!invitationAccountantHistoryData) {
      return [];
    }
    return invitationAccountantHistoryData;
  }, [invitationAccountantHistoryData]);

  function renderInvitationStatus(status: string): {
    text: string;
    color: string;
  } {
    let text: string;
    let color: string;
    switch (status) {
      case 'proccessing':
        text = t('w.inProgress');
        color = 'blue';
        break;
      case 'reject':
        text = t('w.rejected');
        color = 'red';
        break;
      case 'accept':
        text = t('w.accepted');
        color = 'green';
        break;
      case 'remove':
        text = t('w.deleted');
        color = 'red';
        break;
      default:
        color = 'orange';
        text = t('w.sent');
    }
    return { text, color };
  }

  const columns: DataTableColumn<any>[] = useMemo(() => {
    if (getPersistedObject('mode') === ACCOUNTANT_MODE) {
      return [
        {
          accessor: 'who.fullname',
          title: t('w.requester'),
          sortable: true,
          ellipsis: true,
          render: ({ who }) => (
            <Group>
              <CustomUserAvatar userId={who.userId} size={30} />
              <Flex direction={'column'}>
                <Text fz={'xs'} fw={'bold'}>
                  {who.fullname}
                </Text>
                <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
                  {who.companyName}
                </Text>
              </Flex>
            </Group>
          ),
        },
        {
          accessor: 'toMail',
          title: `${t('w.sent')} ${t('w.to')}`,
          sortable: true,
          ellipsis: true,
          render: ({ toMail, created }) =>
            toMail ? (
              <Flex direction={'column'}>
                <Text fz={'xs'} fw={'bold'}>
                  {toMail}
                </Text>
                <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
                  {milliToFullDateString(created)}
                </Text>
              </Flex>
            ) : (
              <Center>
                <IconCircleOff size={20} color={'#868e96'} />
              </Center>
            ),
        },
        {
          accessor: 'status',
          title: t('w.status'),
          sortable: true,
          ellipsis: true,
          render: ({ status }) => (
            <Badge
              color={renderInvitationStatus(status).color}
              variant="filled"
            >
              {renderInvitationStatus(status).text}
            </Badge>
          ),
        },
        {
          accessor: 'toManage',
          title: t('w.validatedBy'),
          sortable: true,
          ellipsis: true,
          render: ({ toManage }) =>
            toManage && toManage.mail ? (
              <Flex direction={'column'} gap={4} align={'center'}>
                <PayrollOfficerBadge
                  payrollOfficer={toManage}
                  isInvitationList
                />
              </Flex>
            ) : (
              <Text styles={{ root: { textAlign: 'center' } }}>-</Text>
            ),
        },
        {
          accessor: 'requestType',
          title: t('w.requestType'),
          sortable: true,
          ellipsis: true,
          render: ({ requestType }) =>
            requestType === 'delete' ? (
              <Text fz={'xs'}>
                {`${t('w.delete')} ${t('w.payrollOfficer').toLowerCase()}`}
              </Text>
            ) : (
              <Text fz={'xs'}>{t(`w.${requestType}`)}</Text>
            ),
        },
        {
          accessor: 'updated',
          title: t('w.modificationDate'),
          sortable: true,
          ellipsis: true,
          render: ({ updated }) =>
            updated ? (
              <Text fz={'sm'}>{milliToFullDateString(updated)}</Text>
            ) : (
              ''
            ),
        },
        {
          accessor: 'actions',
          title: t('w.actions'),
          textAlign: 'right',
          sortable: false,
          width: 100,
          render: ({ status, id }) => (
            <Group gap={'md'} justify="end" wrap={'nowrap'}>
              <CustomTooltip label={t('w.accept')} withinPortal>
                <ActionIcon
                  color="green"
                  variant={'subtle'}
                  onClick={() =>
                    validateCustomerAccountantInvitation({
                      validationId: id,
                      status: true,
                    })
                  }
                  disabled={
                    status === 'reject' ||
                    status === 'accept' ||
                    status === 'remove'
                  }
                >
                  <IconCircleCheck size={30} />
                </ActionIcon>
              </CustomTooltip>
              <CustomTooltip label={t('w.reject')} withinPortal>
                <ActionIcon
                  color="red"
                  variant={'subtle'}
                  onClick={() =>
                    validateCustomerAccountantInvitation({
                      validationId: id,
                      status: false,
                    })
                  }
                  disabled={
                    status === 'reject' ||
                    status === 'accept' ||
                    status === 'remove'
                  }
                >
                  <IconCircleX size={30} />
                </ActionIcon>
              </CustomTooltip>
            </Group>
          ),
        },
      ];
    } else {
      return [
        {
          accessor: 'who.fullname',
          title: t('w.requester'),
          sortable: true,
          ellipsis: true,
          render: ({ who }) => (
            <Group>
              <CustomUserAvatar userId={who.userId} size={30} />
              <Flex direction={'column'}>
                <Text fz={'xs'} fw={'bold'}>
                  {who.fullname}
                </Text>
                <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
                  {who.companyName}
                </Text>
              </Flex>
            </Group>
          ),
        },
        {
          accessor: 'toMail',
          title: `${t('w.sent')} ${t('w.to')}`,
          sortable: true,
          ellipsis: true,
          render: ({ toMail, created }) =>
            toMail ? (
              <Flex direction={'column'}>
                <Text fz={'xs'} fw={'bold'}>
                  {toMail}
                </Text>
                <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
                  {milliToFullDateString(created)}
                </Text>
              </Flex>
            ) : (
              <Center>
                <IconCircleOff size={20} color={'#868e96'} />
              </Center>
            ),
        },
        {
          accessor: 'status',
          title: t('w.status'),
          sortable: true,
          ellipsis: true,
          render: ({ status }) => (
            <Badge
              color={renderInvitationStatus(status).color}
              variant="filled"
            >
              {renderInvitationStatus(status).text}
            </Badge>
          ),
        },
        {
          accessor: 'toManage',
          title: t('w.validatedBy'),
          sortable: true,
          ellipsis: true,
          render: ({ toManage }) =>
            toManage && toManage.mail ? (
              <Flex direction={'column'} gap={4} align={'center'}>
                <PayrollOfficerBadge
                  payrollOfficer={toManage}
                  isInvitationList
                />
              </Flex>
            ) : (
              <Text styles={{ root: { textAlign: 'center' } }}>-</Text>
            ),
        },
        {
          accessor: 'requestType',
          title: t('w.requestType'),
          sortable: true,
          ellipsis: true,
          render: ({ requestType }) =>
            requestType === 'delete'
              ? `${t('w.delete')} ${t('w.payrollOfficer').toLowerCase()}`
              : t(`w.${requestType}`),
        },
        {
          accessor: 'created',
          title: t('w.requestDate'),
          sortable: true,
          ellipsis: true,
          render: ({ created }) =>
            created ? milliToFullDateString(created) : '',
        },
        {
          accessor: 'updated',
          title: t('w.modificationDate'),
          sortable: true,
          ellipsis: true,
          render: ({ updated }) =>
            updated ? milliToFullDateString(updated) : '',
        },
      ];
    }
  }, [getPersistedObject('mode')]);

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'who.fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status',
          label: t('w.status'),
          choices: [
            { value: 'send', label: t('w.sent') },
            { value: 'proccessing', label: t('w.inProgress') },
            { value: 'reject', label: t('w.rejected') },
            { value: 'accept', label: t('w.accepted') },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      pinFirstColumn
      pinLastColumn
      options={options}
      columns={columns}
      rows={invitationAccountantHistoryList}
      lang={lang}
      height={'calc(100vh - 220px)'}
      fetching={isInvitationAccountantHistoryLoading}
    />
  );
}
