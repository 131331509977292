import {
  VariablePayroll,
  VariablePayrollParams,
  VariablePayrollType,
} from '../types/types';
import { MS_ENDMONTH_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getVariables(companyId: string): Promise<VariablePayrollParams> {
  return request
    .get(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/variablepayroll/${companyId}/params`
    )
    .then((res) => res?.data);
}

export type CreateVariablePayrollPayload = {
  label: string;
  type: VariablePayrollType;
  editable: boolean;
  codeQuadra?: string;
  codeSilae?: string;
  codeDiapaie?: string;
  codeIsapaye?: string;
  codeOpenpaye?: string;
  codeCegidExpert?: string;
};

function addVariablePayroll(
  companyId: string,
  payload: CreateVariablePayrollPayload
): Promise<VariablePayroll> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/variablepayroll/${companyId}`,
      payload
    )
    .then((res) => res?.data);
}

function updateVariablePayroll(
  companyId: string,
  payload: VariablePayroll
): Promise<VariablePayroll> {
  return request
    .patch(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/variablepayroll/${companyId}`,
      payload
    )
    .then((res) => res?.data);
}

function deleteVariablePayroll(companyId: string, variablePayrollId: string) {
  return request
    .delete(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/variablepayroll/${companyId}/${variablePayrollId}`
    )
    .then((res) => res?.data);
}

function synchroVariablesPayrollOpenPaye(companyId: string) {
  return request
    .patch(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/variable-payroll`
    )
    .then((res) => res?.data);
}

export type UpdateEmployeeVariablePayrollPayload = {
  employeeId: string;
  endOfMonthId: string;
  variablePayrollId: string;
  variablePayrollValue?: string;
  file?: File | null;
  extension?: string;
};

function updateEmployeeVariablePayroll(
  companyId: string,
  payload: UpdateEmployeeVariablePayrollPayload
) {
  const formData = new FormData();
  formData.append('employeeId', payload.employeeId);
  formData.append('endOfMonthId', payload.endOfMonthId);
  formData.append('variablePayrollId', payload.variablePayrollId);
  payload.variablePayrollValue &&
    formData.append('variablePayrollValue', payload.variablePayrollValue);
  payload.file && formData.append('file', payload.file);
  payload.extension && formData.append('extension', payload.extension);

  return request
    .patch(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/variablepayroll/${companyId}/employee`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((res) => res?.data);
}

function getVariablePayrollFile(
  companyId: string,
  employeeId: string,
  endOfMonthId: string,
  variablePayrollId: string
): string {
  return `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/variablepayroll/${companyId}/employee/${employeeId}/${endOfMonthId}/${variablePayrollId}`;
}

function deleteVariablePayrollFile(
  companyId: string,
  employeeId: string,
  endOfMonthId: string,
  variablePayrollId: string
) {
  return request
    .delete(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/variablepayroll/${companyId}/employee/${employeeId}/${endOfMonthId}/${variablePayrollId}`
    )
    .then((res) => res?.data);
}

const VariablePayrollService = {
  getVariables,
  addVariablePayroll,
  updateVariablePayroll,
  deleteVariablePayroll,
  synchroVariablesPayrollOpenPaye,
  updateEmployeeVariablePayroll,
  getVariablePayrollFile,
  deleteVariablePayrollFile,
};

export default VariablePayrollService;
