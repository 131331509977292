export function familyState(t: any) {
  return [
    { value: '', label: t('w.noValue') },
    { value: 'married', label: t('familyState.married') },
    { value: 'single', label: t('familyState.single') },
    { value: 'civil_union', label: t('familyState.civil_union') },
    {
      value: 'divorced',
      label: t('familyState.divorced'),
    },
    { value: 'separated', label: t('familyState.separated') },
    { value: 'widowed', label: t('familyState.widowed') },
  ];
}
