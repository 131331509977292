import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import { LeaveType } from '../../../../types/types';
import PeriodTypeForm from './PeriodTypeForm';

type Props = {
  opened: boolean;
  onClose: () => void;
  leaveTypeToEdit: LeaveType | null;
  leaveTypeToCreate: boolean;
  refetch: () => void;
  companyId: string;
};

export default function PeriodTypeModal({
  opened,
  onClose,
  leaveTypeToEdit,
  refetch,
  leaveTypeToCreate,
  companyId,
}: Props) {
  const { t } = useI18n();

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      title={
        leaveTypeToEdit
          ? `${t('w.edit')} ${leaveTypeToEdit.name}`
          : leaveTypeToCreate
          ? t('w.addANewTypeOfLeave')
          : ''
      }
      size={'70%'}
    >
      <PeriodTypeForm
        leaveTypeToEdit={leaveTypeToEdit}
        refetch={refetch}
        onClose={onClose}
        companyId={companyId}
      />
    </CustomModal>
  );
}
