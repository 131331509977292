import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import { Division, LeaveType } from '../../../../types/types';
import BlockingPeriodsStepper from './blocking-periods/BlockingPeriodsStepper';
import CompanyMandatoryLeavesStepper from './company-mandatory-leaves/CompanyMandatoryLeavesStepper';

type Props = {
  opened: boolean;
  onClose: () => void;
  activeTab: string | null;
  divisions: Division[];
  allCompanyLeaveTypesListData?: LeaveType[];
  refetchCompanyLeavesListData: () => void;
  refetchCompanyBlockingPeriodsData: () => void;
};

export default function PeriodsModal({
  opened,
  onClose,
  activeTab,
  divisions,
  allCompanyLeaveTypesListData,
  refetchCompanyLeavesListData,
  refetchCompanyBlockingPeriodsData,
}: Props) {
  const { t } = useI18n();

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      title={
        activeTab === 'blocking-periods'
          ? t('w.addBlockingPeriod')
          : t('w.addLeave')
      }
      size={'100%'}
    >
      {activeTab === 'blocking-periods' ? (
        <BlockingPeriodsStepper
          divisions={divisions}
          onClose={onClose}
          refetchCompanyBlockingPeriodsData={refetchCompanyBlockingPeriodsData}
        />
      ) : (
        <CompanyMandatoryLeavesStepper
          divisions={divisions}
          allCompanyLeaveTypesListData={allCompanyLeaveTypesListData}
          refetchCompanyLeavesListData={refetchCompanyLeavesListData}
          onClose={onClose}
        />
      )}
    </CustomModal>
  );
}
