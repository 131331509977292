import { type FilterOption, type Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import {
  IconBrandDaysCounter,
  IconCalendarEvent,
  IconFileDollar,
  IconReport,
} from '@tabler/icons-react';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import type {
  Accountants,
  EndOfMonthCustomerStatus,
} from '../../../../types/types';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import TeamDailyWorks from '../../../activities/team-activities/components/TeamDailyWorks';
import HolidayCounters from '../../../end-of-month/holiday-counter/components/HolidayCounters';
import EndOfMonthPayslips from '../../../end-of-month/payslips/EndOfMonthPayslips';
import CompanyInfoWrapper from '../../components/CompanyInfoWrapper';
import AccountantCustomersEndOfMonthStatusBadge from './AccountantCustomersEndOfMonthStatusBadge';
import CustomerPlanning from './CustomerPlanning';

const componentName = 'AccountantCustomersEndOfMonthTable';

type Props = {
  selectedDate: DateValue | DatesRangeValue | Date[];
  rows: Accountants[];
  isLoading: boolean;
};

export default function AccountantCustomersEndOfMonthTable({
  selectedDate,
  rows,
  isLoading,
}: Props) {
  const { user } = useAuth();
  const { t, lang } = useI18n();
  const matches = useMediaQuery('(min-width: 1441px)');

  const columns: DataTableColumn<Accountants>[] = useMemo(
    () => [
      {
        accessor: 'customerCompany.name',
        title: t('w.companyName'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) => (
          <CompanyInfoWrapper
            companyId={customerCompany.id}
            companyName={customerCompany.name}
          />
        ),
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({
          status,
          clientCompanyId,
          customerCompany,
          divisionAccountant,
          generalManagers,
        }) => {
          const managedDivisionsIds: string[] = [];
          const isCompanyAccountant = Boolean(
            generalManagers.find((item) => item.id === user.id)
          );
          if (!isCompanyAccountant) {
            divisionAccountant.forEach((item) => {
              const isDivisionAccountant = Boolean(
                item.accountantUser.find((item) => item.id === user.id)
              );
              if (isDivisionAccountant) {
                managedDivisionsIds.push(item.id);
              }
            });
          }

          return (
            <AccountantCustomersEndOfMonthStatusBadge
              managedDivisionsIds={
                isCompanyAccountant ? null : managedDivisionsIds
              }
              status={status as EndOfMonthCustomerStatus}
              clientCompanyId={clientCompanyId}
              customerCompany={customerCompany}
              selectedDate={selectedDate}
            />
          );
        },
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: (accountants) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.planning')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() => openPlanning(accountants)}
              >
                <IconCalendarEvent size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.holidayCounters')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() => openHolidayCounters(accountants)}
              >
                <IconBrandDaysCounter size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.payslips')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() => openPayslips(accountants)}
              >
                <IconFileDollar size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.teamActivities')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() => openActivities(accountants)}
              >
                <IconReport size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    [matches, selectedDate, rows]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'customerCompany.name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function openPlanning(accountants: Accountants) {
    modals.open({
      modalId: `customer-planning-modal`,
      title: (
        <Title size={'h3'} component="p">
          {`${t('w.planning')} : ${accountants.customerCompany.name}`}
        </Title>
      ),
      size: '90%',
      children: <CustomerPlanning accountants={accountants} />,
    });
  }

  function openHolidayCounters(accountants: Accountants) {
    modals.open({
      modalId: `customer-holiday-counters-modal`,
      title: (
        <Title size={'h3'} component="p">
          {`${t('w.holidayCounters')} : ${accountants.customerCompany.name}`}
        </Title>
      ),
      size: '90%',
      children: <HolidayCounters companyId={accountants.clientCompanyId} />,
    });
  }

  function openPayslips(accountants: Accountants) {
    modals.open({
      modalId: `customer-payslips-modal`,
      title: (
        <Title size={'h3'} component="p">
          {accountants.customerCompany.name}
        </Title>
      ),
      size: '90%',
      children: (
        <EndOfMonthPayslips
          customerCompanyId={accountants.clientCompanyId}
          customerSelectedDate={selectedDate}
        />
      ),
    });
  }

  function openActivities(accountants: Accountants) {
    modals.open({
      modalId: `customer-activities-modal`,
      title: (
        <Title size={'h3'} component="p">
          {accountants.customerCompany.name}
        </Title>
      ),
      size: '90%',
      children: <TeamDailyWorks companyId={accountants.clientCompanyId} />,
    });
  }

  return (
    <Table
      idAccessor={'clientCompanyId'}
      rows={rows}
      columns={columns}
      options={options}
      lang={lang}
      fetching={isLoading}
    />
  );
}
